import { useCallback, useEffect, useState } from "react";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import SidePane from "../../../components/SidePane";
import closeIcon from "../../../assets/close-icon.svg";
import NotFound from "../../../components/NotFound";
import DashboardNavItem from "../../../components/DashboardNavItem/DashboardNavItem";
import CreateIcon from '../../../assets/new/createIcon.png';
import { createCharges, deleteCharge, getCharges, updateCharges } from "../../../actions/adminActions";
import Button from "../../../components/Button";
import Input, { Select as SelectComponent } from "../../../components/Input";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import './charges.scss';

const chargesEnums = {
  'All products': 'all_products',
  'Ajo': 'ajo',
  'RefundMe': 'refundme',
  'Kontribute': 'kontribute',
  'Business': 'business',
}

const chargesEnumsInvert = {
 'all_products': 'All products',
  'ajo': 'Ajo',
  'refundme': 'RefundMe',
  'kontribute': 'Kontribute',
  'business': 'Business',
}

const AdminCharges = () => {
    const [charges, setCharges] = useState([]);
    const [charge, setCharge] = useState(null);
    const [openCreate, setOpenCreate] = useState(false);
    const getAllCharges = (page) => {
        getCharges((resp) => {
          setCharges(resp?.data?.data || []);
        });
    }

    useEffect(() => {
      getAllCharges(1);
    }, []);

    const expenseNavItemsObj = [
        {
          label: 'Create Charge',
          action: () => handleCreateRefundMe(),
          bgColor: '#0898A0',
          img: CreateIcon,
        }
      ];
    
      const handleCreateRefundMe = () => {
        setOpenCreate(true);
      }
    
      const renderNavItems = () => {
        return (
          <DashboardNavItem
            navItems={[]}
            navItemsObj={expenseNavItemsObj}
            tab={'1'}
            dontShowWithdraw={true}
            setTab={handleCreateRefundMe}
          />
        )
      }
    return (
        <DashboardComponentWrapper
          subTitle="Charges"
          pageDetails="Manage all withdrawal charges here."
          renderNavItems={renderNavItems}
        >
            <> 
                <AdminChargeList setCharge={(charge) =>  {
                  setCharge(charge);
                  setOpenCreate(true)
                }} charges={charges} handleDeleteDone={() => getAllCharges(1)} />
                <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
                    <AdminCreateCharge handleDone={() => {
                      setOpenCreate(false);
                      getAllCharges(1);
                    }} charge={charge} />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminCharges;

const AdminChargeList = (props) => {
    const {charges} = props;
    return (
      <div className="bg-[#ffffff]  border border-white">
        <div className="">
          <div className="">
            <div className="body min-h-[50vh]">
              <div className="flex flex-row flex-wrap justify-start items-start content-center">
                {(!charges || charges.length === 0) ?
                  (
                    <NotFound 
                      text1="No Inquiry in the system yet!"
                      text2='Manage all referrals sent in here.'
                      btnLabel=""
                      onClick={() => {}}
                    />
                  ) :
                  charges?.map((item, index) => {
                    return (
                      <Charge
                        key={item.id}
                        charge={item}
                        setUser={props.setCharge}
                        handleDeleteDone={props.handleDeleteDone}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

const AdminCreateCharge = (props) => {
  const [isEdit, setIsEdit] = useState(false);
    const [data, setData] = useState({
        charges: '',
        product_affected: '0',
      });

      useEffect(() => {
        if(props.charge) {
          setIsEdit(true);
          setData({
            charges: props.charge.charges,
            product_affected: chargesEnumsInvert[props.charge.product_affected]
          })
        }
      }, []);

    
      const handleChange = useCallback((name: string, value: string) => {
        setData((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }, []);


      const handleAddFormSubmit = (event) => {
        event.preventDefault();
        const newData = {
          ...data,
          product_affected: chargesEnums[data.product_affected]
        }
        if (isEdit) {
          updateCharges(props.charge.id, newData, () => {
            props.handleDone(false);
          });
          return;
        }
        createCharges(newData, (resp) => {
          props.handleDone(false);
        });
      }
    
    return (
        <div className="w-full">
            <h1 className="text-3xl">{isEdit ? 'Update Charge' : 'Create Charge'}</h1>
             <form onSubmit={handleAddFormSubmit} className="">
              <Input
                label="Charge amount"
                placeholder="30"
                name="charges"
                type="number"
                value={data.charges}
                onChange={handleChange}
              />
              <SelectComponent
                data={['', 'All products', 'Ajo', 'RefundMe', 'Kontribute', 'Business']}
                label="Scope of charge"
                placeholder="All products"
                name="product_affected"
                required
                value={data.product_affected}
                onChange={handleChange}
            />
              <Button
                label={isEdit ? 'Update' : "Create "}
                type="flat"
                btnActionType="submit"
              />
            </form>
        </div>
    )
}

const Charge = ({ charge, setUser, handleDeleteDone }: any) => {
  const [toDelete, setToDelete] = useState<null>();
  const handleDelete = () => {
    deleteCharge(charge.id, () => {
      handleDeleteDone()
    });
  }
    return (
      <>
        <div className='flex cursor-pointer flex-col w-[200px] justify-start m-2 rounded-sm items-start px-3 py-2 border border-az-teal hover:bg-az-faint-red relative charge' onClick={() => setUser(charge)}>
          <img className='absolute h-6 w-6 right-0 top-0 delete' src={closeIcon} alt="" onClick={(event) => {
            event.stopPropagation();
            setToDelete(charge);
          }}/>
          <p>₦{charge?.charges}</p>
          <p>{chargesEnumsInvert[charge?.product_affected]}</p>
        </div>
        <ConfirmModal
          isOpen={!!toDelete}
          text='Are you sure you want to delete this Charge?'
          handleNo={() => setToDelete(null)}
          handleYes={() => handleDelete()}
          onClose={() => setToDelete(null)}
        />
      </>
    )
  }


import { useState } from "react";
import CreateIcon from '../../assets/new/createIcon.png';
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import SwitchBusiness from "../../components/SwitchBusiness";
import CreateCatalogue, { CatalogueList } from "../../components/CreateCatalogue";
import SidePane from "../../components/SidePane";


const Catalogues = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const expenseNavItemsObj = [
    {
      label: 'New Inventory',
      action: () => setOpenCreate(true),
      bgColor: '#0898A0',
      img: CreateIcon,
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        dontShowWithdraw
        navItems={[]}
        navItemsObj={expenseNavItemsObj}
        tab={''}
        setTab={() => {}}
      />
    )
  }
  return (
    <DashboardComponentWrapper
      overrideName={() => <SwitchBusiness />}
      renderNavItems={renderNavItems}
      title="Inventory"
    >
      <>
        <CatalogueList handleCreate={setOpenCreate} />
        <SidePane
          onClose={() => setOpenCreate(false)}
          open={openCreate}
          title={""}
        >
          <CreateCatalogue handleClose={() => setOpenCreate(false)} isOpen={true} />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  )
};

export default Catalogues;

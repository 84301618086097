import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import UnAuthWrapper from "../../components/UnAuthWrapper";
import useAuthRedirect from "../../hooks/useAuthRedirect";
import getBaseURL from "../../utils/getBaseURL";
import { toastWrapper } from "../../utils/toastWrapper";

function ResetPassword() {
  useAuthRedirect();
  const [misMatchPsd, setMisMatchPsd] = useState(false);
  const [passError, setPassError] = useState(false);
  const [data, setData] = useState({
    token: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent) => {
    const url = getBaseURL() as string;
    event.preventDefault();
    toastWrapper(
      axios.post(`${url}reset`, data), 
      'Reseting your Password...', 
      (resp) => {
        navigate('/login');
        return resp.data.message || 'Password Reset successfully!';
      }, 
      'Error resetting password: Incorrect Verification Code.',
    );
  }

  return (
    <UnAuthWrapper
      title="Reset Password."
      subTitle="Enter verification code sent to your email and new password below."
      ctaQuestion="Don’t have an account?"
      ctaRoute="register"
      ctaText="Sign Up"
    >
      <form onSubmit={handleSubmit} className="max-w-[400px]">
        <Input
          label="Verification Code"
          placeholder="Code"
          name="token"
          value={data.token}
          required
          onChange={(name, value) => handleChange(name, value)}
        />
        <Input
          label="Password"
          placeholder="Password"
          name="password"
          value={data.password}
          type="password"
          required
          pattern="^((?=.*\d)|(?=.*[!@#$%^&*]))(?=.*[a-z])(?=.*[A-Z]).{8,}$"
          onChange={(name:string, value:string) => {
            handleChange(name, value);
            setPassError(!(/^((?=.*\d)|(?=.*[!@#$%^&*]))(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)));
          }}
          onBlur={() => {
            setPassError(!(/^((?=.*\d)|(?=.*[!@#$%^&*]))(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(data.password)));
          }}
          hasError={passError}
          errorText='Password must 8 or more alpha-numberic, uppercase, lowercase and special characters!'
        />
        <Input
          label="Confirm Password"
          placeholder="Password"
          name="confirmPassword"
          value={data.confirmPassword}
          type="password"
          required
          onChange={(name:string, value:string) => {
            handleChange(name, value);
            if (misMatchPsd && data.password === value) {
              setMisMatchPsd(false);
            }
          }}
          onBlur={() => {
            if (data.password !== data.confirmPassword) {
              setMisMatchPsd(true);
              return;
            }
            misMatchPsd && setMisMatchPsd(false);
          }}
          hasError={misMatchPsd}
          errorText='Password and Confirm Password must match!'
        />
        <Button
          label="Save"
          onClick={() => null}
          type="flat"
          btnActionType="submit"
        />
      </form>
    </UnAuthWrapper>
  );
}

export default ResetPassword;
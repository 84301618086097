import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";

const AdminWithdrawals = () => {
    return (
        <DashboardComponentWrapper
            subTitle="Withdrawals"
            pageDetails="Here's an overview of all Withdrawals on Azatme."
        >
            <div>
                    Admin Withdrawals
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminWithdrawals;

import { useEffect, useState } from "react";
import { getAllKontribute, getAllKontributeByEmail } from "../../../actions/adminActions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import CreateUserGroup from "../../../components/CreateUserGroup";
import GroupDetails from "../../../components/GroupDetails";
import SidePane from "../../../components/SidePane";
import TabComponent from "../../../components/TabComponent";
import GetUserProduct from "../GetUserProduct";
import TablePaginationFooter from "../../../components/TablePaginationFooter/TablePaginationFooter";
import Accordion from "../../../components/UserGroup/Accordion";
import { AccordionData } from "../../../types";
import { handleShareAzatMe } from "../../../utils/common";
import NotFound from "../../../components/NotFound";

const AdminKontribute = () => {
    const { allUserGroups, allUserGroupsByEmail} = useAppSelector((state) => state.admin)
    const [openCreate, setOpenCreate] = useState(false);
    const [kontribute, setKontribute] = useState<Record<string, any> | null>(null);
    const [userEmail, setUserEmail] = useState('');

    const userGroup = userEmail ? allUserGroupsByEmail : allUserGroups;
  
    const renderUserGroups = () => <UserGroup handleMore={setKontribute} userGroup={userGroup}  handlePagination={handlePagination} />;
    // const renderOpenUserGroups = useCallback(() => <UserGroup tab="Open Kontribute" handleMore={setKontribute} handleCreate={handleCreateKontribute} />, [isVerificationDone]);
    
    useEffect(() => {
        getAllKontribute(1)
    }, []);

    const handleGetProduct = (email) => {
        getAllKontributeByEmail(1, email);
        setUserEmail(email);
    }

    const getAll = () => {
        setUserEmail('');
    }

    const handlePagination = (page) => {
        if (userEmail) {
            getAllKontributeByEmail(page, userEmail)
        } else {
            getAllKontribute(page)
        }
    }

    return (
        <DashboardComponentWrapper
            subTitle="Kontribute"
            pageDetails="Here's an overview of all Kontribute on Azatme."
        >
            <>
                <GetUserProduct handleGetProduct={handleGetProduct} product="Kontribute"  handleGetAll={getAll} />
                <TabComponent
                    components={{
                        // @ts-ignore
                        'Kontribute': renderUserGroups,
                        // 'Open Kontribute': renderOpenUserGroups,
                    }}
                />
                <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
                    <CreateUserGroup handleDone={() => setOpenCreate(false)} />
                </SidePane>
                <SidePane
                    onClose={() => setKontribute(null)}
                    open={!!kontribute}
                    title={""}
                >
                    <GroupDetails
                        group={kontribute}
                        handleCloseDetail={() => setKontribute(null)}
                        isMine
                    />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminKontribute;

const UserGroup = (props) => {
    const dispatch = useAppDispatch();

    const handlePagination = (payload) => {
      const page = payload.split('=')[1];
      props.handlePagination(page);
    }

  
    return (
      <div className="user-group mt-6 bg-white !w-full !p-0">
        {
          (!props.userGroup?.data.length) ?
          (
            <NotFound 
                text1={"You have not been invited to any Kontribute!"}
                text2='Share the app with Your Friends to experience this.'
                btnLabel='Share AzatMe.'
                onClick={() => handleShareAzatMe(dispatch)}
            />
          ) : (
            <div className="wrapper !w-full h-full">
                <div className="min-h-[50vh]">
                    <Accordion
                        items={props.userGroup?.data as unknown as AccordionData[]}
                        handleMore={props.handleMore}
                    />
                </div>
              <TablePaginationFooter
                total={props.userGroup?.total}
                from={props.userGroup?.from}
                to={props.userGroup?.to}
                nextUrl={props.userGroup?.next_page_url}
                prevUrl={props.userGroup?.prev_page_url}
                handlePagination={handlePagination}
              />
            </div>
          )
        }
      </div>
    )
  }

import { useEffect, useState } from "react";
import TransactionsListWrapper from "../Transactions/TransactionListWrapper";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import AllTransactions from "../Transactions/AllTransactions";
import './transactionList.scss'
import InvoiceTransactions from "../Transactions/InvoiceTransactions";
import { useLocation } from "react-router-dom";

const transactionNavItems = [];

const TransactionListTab = ({allExpenses, handleCreate, handleMore}) => {
  const [tab, setTab] = useState(transactionNavItems[0]);
  const [hideNav, setHideNav] = useState(false);
  const {state, pathname} = useLocation();
  
  useEffect(() => {
    // @ts-ignore
    if (state && state.order) {
      handleCreate();
    }
  }, []);

  const splittedPath = pathname.split('/')
  const isDirectLink = splittedPath[splittedPath.length - 1] === 'directlinks';

  return (
    <TransactionsListWrapper>
      { isDirectLink ? <AllTransactions handleCreate={handleCreate}  handleMore={handleMore} expenses={allExpenses} /> : <InvoiceTransactions handleCreate={handleCreate} handleMore={handleMore} />}
    </TransactionsListWrapper>
  );
}

export default TransactionListTab;

const NQR = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="15" fill="#3ABE77"/>
        <path d="M11.0625 11.0625H7.125V7.125H11.0625V11.0625ZM7.6875 10.5H10.5V7.6875H7.6875V10.5ZM22.875 11.0625H18.9375V7.125H22.875V11.0625ZM19.5 10.5H22.3125V7.6875H19.5V10.5ZM11.0625 22.875H7.125V18.9375H11.0625V22.875ZM7.6875 22.3125H10.5V19.5H7.6875V22.3125Z" fill="white"/>
        <path d="M9.9375 8.25H8.25V9.9375H9.9375V8.25Z" fill="white"/>
        <path d="M21.75 8.25H20.0625V9.9375H21.75V8.25Z" fill="white"/>
        <path d="M9.9375 20.0625H8.25V21.75H9.9375V20.0625Z" fill="white"/>
        <path d="M12.1875 8.25H11.625V8.8125H12.1875V8.25Z" fill="white"/>
        <path d="M12.75 8.8125H12.1875V9.375H12.75V8.8125Z" fill="white"/>
        <path d="M13.3125 9.375H12.75V9.9375H13.3125V9.375Z" fill="white"/>
        <path d="M13.875 9.9375H13.3125V10.5H13.875V9.9375Z" fill="white"/>
        <path d="M13.3125 9.9375H12.75V10.5H13.3125V9.9375Z" fill="white"/>
        <path d="M13.875 15.5625H13.3125V16.125H13.875V15.5625Z" fill="white"/>
        <path d="M20.1829 16.1897L19.7852 16.5874L20.1829 16.9851L20.5807 16.5874L20.1829 16.1897Z" fill="white"/>
        <path d="M21.7727 16.1887L21.375 16.5864L21.7727 16.9842L22.1705 16.5864L21.7727 16.1887Z" fill="white"/>
        <path d="M22.5657 16.1906L22.168 16.5884L22.5657 16.9861L22.9635 16.5884L22.5657 16.1906Z" fill="white"/>
        <path d="M19.6165 21.8132L19.2188 22.2109L19.6165 22.6087L20.0142 22.2109L19.6165 21.8132Z" fill="white"/>
        <path d="M20.4134 21.8151L20.0156 22.2129L20.4134 22.6106L20.8111 22.2129L20.4134 21.8151Z" fill="white"/>
        <path d="M21.2102 21.8127L20.8125 22.2104L21.2102 22.6082L21.608 22.2104L21.2102 21.8127Z" fill="white"/>
        <path d="M22.0071 21.8147L21.6094 22.2124L22.0071 22.6101L22.4049 22.2124L22.0071 21.8147Z" fill="white"/>
        <path d="M11.0625 11.625H10.5V12.1875H11.0625V11.625Z" fill="white"/>
        <path d="M11.625 12.1875H11.0625V12.75H11.625V12.1875Z" fill="white"/>
        <path d="M12.1875 12.75H11.625V13.3125H12.1875V12.75Z" fill="white"/>
        <path d="M12.75 13.3125H12.1875V13.875H12.75V13.3125Z" fill="white"/>
        <path d="M12.1875 13.875H11.625V14.4375H12.1875V13.875Z" fill="white"/>
        <path d="M11.625 14.4375H11.0625V15H11.625V14.4375Z" fill="white"/>
        <path d="M11.0625 15H10.5V15.5625H11.0625V15Z" fill="white"/>
        <path d="M18.375 21.1875V20.625H17.8125V21.1875H18.375Z" fill="white"/>
        <path d="M17.8125 21.75V21.1875H17.25V21.75H17.8125Z" fill="white"/>
        <path d="M17.25 22.3125V21.75H16.6875V22.3125H17.25Z" fill="white"/>
        <path d="M16.6875 22.875V22.3125H16.125V22.875H16.6875Z" fill="white"/>
        <path d="M18.375 9.9375V9.375H17.8125V9.9375H18.375Z" fill="white"/>
        <path d="M17.25 14.4375V13.875H16.6875V14.4375H17.25Z" fill="white"/>
        <path d="M17.25 16.6875V16.125H16.6875V16.6875H17.25Z" fill="white"/>
        <path d="M18.375 20.0625V19.5H17.8125V20.0625H18.375Z" fill="white"/>
        <path d="M17.8125 20.0625V19.5H17.25V20.0625H17.8125Z" fill="white"/>
        <path d="M17.8125 10.5V9.9375H17.25V10.5H17.8125Z" fill="white"/>
        <path d="M17.25 9.375V8.53125H16.6875V9.375H17.25Z" fill="white"/>
        <path d="M16.6875 10.2188V9.375H16.125V10.2188H16.6875Z" fill="white"/>
        <path d="M21.1875 18.375H22.0312V17.8125H21.1875V18.375Z" fill="white"/>
        <path d="M20.3438 17.8125H21.1875V17.25H20.3438V17.8125Z" fill="white"/>
        <path d="M17.25 11.0625V10.5H16.6875V11.0625H17.25Z" fill="white"/>
        <path d="M16.6875 11.625V11.0625H16.125V11.625H16.6875Z" fill="white"/>
        <path d="M16.125 22.3125V21.75H15.5625V22.3125H16.125Z" fill="white"/>
        <path d="M15.5625 21.75V21.1875H15V21.75H15.5625Z" fill="white"/>
        <path d="M15 21.1875V20.625H14.4375V21.1875H15Z" fill="white"/>
        <path d="M12.75 16.6875H12.1875V17.25H12.75V16.6875Z" fill="white"/>
        <path d="M13.3125 17.25H12.75V17.8125H13.3125V17.25Z" fill="white"/>
        <path d="M12.75 17.8125H12.1875V18.375H12.75V17.8125Z" fill="white"/>
        <path d="M13.3125 18.375H12.75V18.9375H13.3125V18.375Z" fill="white"/>
        <path d="M12.75 18.9375H12.1875V19.5H12.75V18.9375Z" fill="white"/>
        <path d="M13.3125 19.5H12.75V20.0625H13.3125V19.5Z" fill="white"/>
        <path d="M13.875 20.0625H13.3125V20.625H13.875V20.0625Z" fill="white"/>
        <path d="M12.1875 20.625H11.625V21.1875H12.1875V20.625Z" fill="white"/>
        <path d="M12.75 20.0625H12.1875V20.625H12.75V20.0625Z" fill="white"/>
        <path d="M18.375 12.75V13.3125H18.9375V12.75H18.375Z" fill="white"/>
        <path d="M18.9375 12.1875V12.75H19.5V12.1875H18.9375Z" fill="white"/>
        <path d="M19.5 12.75V13.3125H20.0625V12.75H19.5Z" fill="white"/>
        <path d="M20.0625 12.1875V12.75H20.625V12.1875H20.0625Z" fill="white"/>
        <path d="M20.625 12.75V13.3125H21.1875V12.75H20.625Z" fill="white"/>
        <path d="M21.1875 12.1875V12.75H21.75V12.1875H21.1875Z" fill="white"/>
        <path d="M21.75 11.625V12.1875H22.3125V11.625H21.75Z" fill="white"/>
        <path d="M14.4375 7.125H13.3125V8.25H14.4375V7.125Z" fill="white"/>
        <path d="M13.875 14.4375H12.75V15.5625H13.875V14.4375Z" fill="white"/>
        <path d="M11.625 16.125H10.5V17.25H11.625V16.125Z" fill="white"/>
        <path d="M12.1875 18.9375H11.625V19.5H12.1875V18.9375Z" fill="white"/>
        <path d="M12.1875 17.8125H11.0625V18.9375H12.1875V17.8125Z" fill="white"/>
        <path d="M20.0625 14.4375H18.9375V15.5625H20.0625V14.4375Z" fill="white"/>
        <path d="M21.1875 13.875H20.0625V15H21.1875V13.875Z" fill="white"/>
        <path d="M19.5 15H18.375V16.125H19.5V15Z" fill="white"/>
        <path d="M16.125 11.0625H15V12.1875H16.125V11.0625Z" fill="white"/>
        <path d="M17.25 10.5H16.125V11.625H17.25V10.5Z" fill="white"/>
        <path d="M15.5625 11.625H14.4375V12.75H15.5625V11.625Z" fill="white"/>
        <path d="M18.2609 11.2876H17.5859V11.9626H18.2609V11.2876Z" fill="white"/>
        <path d="M18.9367 10.9502H18.2617V11.6252H18.9367V10.9502Z" fill="white"/>
        <path d="M17.925 11.625H17.25V12.3H17.925V11.625Z" fill="white"/>
        <path d="M17.8125 7.125H16.6875V8.25H17.8125V7.125Z" fill="white"/>
        <path d="M22.875 12.75H22.3125V13.875H22.875V12.75Z" fill="white"/>
        <path d="M7.6875 15.5625H7.125V16.6875H7.6875V15.5625Z" fill="white"/>
        <path d="M19.5 16.6875H18.375V17.8125H19.5V16.6875Z" fill="white"/>
        <path d="M8.25 12.1875H7.125V13.3125H8.25V12.1875Z" fill="white"/>
        <path d="M8.8125 12.75H7.6875V13.875H8.8125V12.75Z" fill="white"/>
        <path d="M9.375 11.625H8.25V12.75H9.375V11.625Z" fill="white"/>
        <path d="M10.5 12.1875H9.375V13.3125H10.5V12.1875Z" fill="white"/>
        <path d="M10.5 13.875H9.375V15H10.5V13.875Z" fill="white"/>
        <path d="M12.75 21.75H11.625V22.875H12.75V21.75Z" fill="white"/>
        <path d="M13.875 11.0625H13.3125V13.3125H13.875V11.0625Z" fill="white"/>
        <path d="M12.1875 9.375H11.625V11.625H12.1875V9.375Z" fill="white"/>
        <path d="M12.75 10.5H12.1875V12.75H12.75V10.5Z" fill="white"/>
        <path d="M15 13.3125H14.4375V15.5625H15V13.3125Z" fill="white"/>
        <path d="M11.625 12.1875H11.0625V14.4375H11.625V12.1875Z" fill="white"/>
        <path d="M21.1875 12.75H20.625V13.3125H21.1875V12.75Z" fill="white"/>
        <path d="M21.75 13.3125H21.1875V13.875H21.75V13.3125Z" fill="white"/>
        <path d="M22.3125 13.875H21.75V14.4375H22.3125V13.875Z" fill="white"/>
        <path d="M22.875 14.4375H22.3125V15H22.875V14.4375Z" fill="white"/>
        <path d="M22.875 19.5H22.3125V20.0625H22.875V19.5Z" fill="white"/>
        <path d="M22.3125 15H21.75V15.5625H22.3125V15Z" fill="white"/>
        <path d="M21.75 15.5625H21.1875V16.125H21.75V15.5625Z" fill="white"/>
        <path d="M21.1875 16.125H20.625V16.6875H21.1875V16.125Z" fill="white"/>
        <path d="M21.75 13.3125H21.1875V15.5625H21.75V13.3125Z" fill="white"/>
        <path d="M7.125 17.8125V18.375H7.6875V17.8125H7.125Z" fill="white"/>
        <path d="M7.6875 17.25V17.8125H8.25V17.25H7.6875Z" fill="white"/>
        <path d="M8.25 16.6875V17.25H8.8125V16.6875H8.25Z" fill="white"/>
        <path d="M8.8125 16.125V16.6875H9.375V16.125H8.8125Z" fill="white"/>
        <path d="M9.375 16.6875V17.25H9.9375V16.6875H9.375Z" fill="white"/>
        <path d="M9.9375 17.25V17.8125H10.5V17.25H9.9375Z" fill="white"/>
        <path d="M10.5 17.8125V18.375H11.0625V17.8125H10.5Z" fill="white"/>
        <path d="M7.6875 17.25V17.8125H9.9375V17.25H7.6875Z" fill="white"/>
        <path d="M9.375 14.4375V13.875H7.125V14.4375H9.375Z" fill="white"/>
        <path d="M9.9375 16.125V15.5625H7.6875V16.125H9.9375Z" fill="white"/>
        <path d="M14.4375 16.6875V16.125H12.1875V16.6875H14.4375Z" fill="white"/>
        <path d="M15 16.4062H14.4375V18.6562H15V16.4062Z" fill="white"/>
        <path d="M15 21.75H14.4375V22.3125H15V21.75Z" fill="white"/>
        <path d="M15 20.625H13.875V21.75H15V20.625Z" fill="white"/>
        <path d="M15.5625 22.875V22.3125H13.3125V22.875H15.5625Z" fill="white"/>
        <path d="M18.9375 21.75H19.5V21.1875H18.9375V21.75Z" fill="white"/>
        <path d="M18.9375 22.875H20.0625V21.75H18.9375V22.875Z" fill="white"/>
        <path d="M18.375 20.625V21.1875H20.625V20.625H18.375Z" fill="white"/>
        <path d="M16.125 14.4375V13.875H13.875V14.4375H16.125Z" fill="white"/>
        <path d="M13.875 7.6875V7.125H11.625V7.6875H13.875Z" fill="white"/>
        <path d="M17.8125 7.6875V7.125H15.5625V7.6875H17.8125Z" fill="white"/>
        <path d="M18.375 13.3125V12.75H16.125V13.3125H18.375Z" fill="white"/>
        <path d="M13.875 9.375H14.4375V8.8125H13.875V9.375Z" fill="white"/>
        <path d="M13.3125 8.8125H13.875V8.25H13.3125V8.8125Z" fill="white"/>
        <path d="M14.4375 9.375H13.875V11.625H14.4375V9.375Z" fill="white"/>
        <path d="M16.125 19.5H16.6875V18.9375H16.125V19.5Z" fill="white"/>
        <path d="M15.5625 18.9375H16.125V18.375H15.5625V18.9375Z" fill="white"/>
        <path d="M16.125 18.375H16.6875V17.8125H16.125V18.375Z" fill="white"/>
        <path d="M15.5625 17.8125H16.125V17.25H15.5625V17.8125Z" fill="white"/>
        <path d="M16.125 17.25H16.6875V16.6875H16.125V17.25Z" fill="white"/>
        <path d="M15.5625 16.6875H16.125V16.125H15.5625V16.6875Z" fill="white"/>
        <path d="M15 16.125H15.5625V15.5625H15V16.125Z" fill="white"/>
        <path d="M16.6875 19.5H16.125V21.75H16.6875V19.5Z" fill="white"/>
        <path d="M15.5625 18.9375V18.375H13.3125V18.9375H15.5625Z" fill="white"/>
        <path d="M16.125 20.0625V19.5H13.875V20.0625H16.125Z" fill="white"/>
        <path d="M20.0625 18.9375V18.375H17.8125V18.9375H20.0625Z" fill="white"/>
        <path d="M17.8125 15.5625V15H15.5625V15.5625H17.8125Z" fill="white"/>
        <path d="M15.5625 8.25H15V10.5H15.5625V8.25Z" fill="white"/>
        <path d="M22.875 16.6875H22.3125V18.9375H22.875V16.6875Z" fill="white"/>
        <path d="M22.875 20.625H22.3125V22.875H22.875V20.625Z" fill="white"/>
        <path d="M18.375 13.875H17.8125V16.125H18.375V13.875Z" fill="white"/>
        <path d="M21.75 18.6562H20.625V19.5H21.75V18.6562Z" fill="white"/>
        <path d="M20.625 17.8125H20.0625V19.5H20.625V17.8125Z" fill="white"/>
        <path d="M17.8125 16.6875H17.25V18.9375H17.8125V16.6875Z" fill="white"/>
        <path d="M18.9375 19.5V20.0625H21.1875V19.5H18.9375Z" fill="white"/>
        <path d="M21.1875 21.75H21.75V19.5H21.1875V21.75Z" fill="white"/>
        </svg>
    )
};

export default NQR;


import { useEffect, useState } from "react";
import AllAzats from "../../components/Azats/AllAzats";
import AzatsListWrapper from "../../components/Azats/AzatsListWrapper";
import InProgressAzats from "../../components/Azats/InProgressAzats";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import { getRefundDraft } from "../../actions/dashboardActions";
import { useAppSelector } from "../../app/hooks";


const expenseNavItems = [
  'Created By Me',
  'Invited To',
  'My Drafts',
  // 'In Progress', 'Completed'
];

const AzatsListTab = ({allRefundMes, expense, setExpense, handleCreate }) => {
  const {draftRefund} = useAppSelector((state) => state.dashboard);
  const [refundMes, setRefundMes] = useState({
    byMe: {},
    invitedTo: {},
  });
  const [tab, setTab] = useState(expenseNavItems[0]);
  const [hideNav, setHideNav] = useState(false);

  useEffect(() => {
    setRefundMes({
      byMe: allRefundMes.getAuthUserExpensesCreated,
      invitedTo: allRefundMes.getExpensesInvitedTo,
    });
  }, [allRefundMes]);

  useEffect(() => {
    if (tab === expenseNavItems[2]) {
      handleGetKontributeDraft();
    }
  }, [tab]);
  
  const handleGetKontributeDraft = (page = 1) => {
    getRefundDraft(1, (resp) => {});
  }

  const renderChildren = () => {
    switch (tab) {
      case expenseNavItems[0]:
        return <AllAzats expenses={refundMes.byMe} setExpense={setExpense} setHideNav={setHideNav} handleCreate={handleCreate} />
      case expenseNavItems[1]:
        return <InProgressAzats expenses={refundMes.invitedTo} setExpense={setExpense} setHideNav={setHideNav}  handleCreate={handleCreate} />
      case expenseNavItems[2]:
        return <AllAzats expenses={draftRefund} setExpense={setExpense} setHideNav={setHideNav}  handleCreate={handleCreate} isDraft />
      default:
        return <AllAzats expenses={refundMes.byMe} expense={expense} setHideNav={setHideNav}  handleCreate={handleCreate} />
    }
  }

  const renderNavItems = () => {
    if (hideNav) return <span />;
    return (
      <DashboardNavItem 
        isSecondTier 
        navItems={expenseNavItems} 
        tab={tab} setTab={setTab} 
      />
    )
  }
  
  return (
    <AzatsListWrapper renderNavItems={renderNavItems}>
      {renderChildren()}
    </AzatsListWrapper>
  );
};

export default AzatsListTab;

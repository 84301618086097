import { useCallback, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import CreateUserGroup from "../../components/CreateUserGroup";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import UserGroup from "../../components/UserGroup";
import useVerificationDone from "../../hooks/useVerificationDone";
import { setShowVerificationPending } from "../Auth/authSlice";
import './usergroups.scss';
import WithdrawalHistory from "../../components/WithdrawalHistory";
import { getWithdrawalHistoryKontribute } from "../../actions/dashboardActions";
import CreateIcon from '../../assets/new/createIcon.png';
import SidePane from "../../components/SidePane";
import TabComponent from "../../components/TabComponent";
import GroupDetails from "../../components/GroupDetails";


const userGroupsNavItems = ['My Kontributes','Create', 'Withdrawal History'];

const UserGroups = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [kontribute, setKontribute] = useState<Record<string, any> | null>(null);
  const [tab] = useState(userGroupsNavItems[0]);
  const dispatch = useAppDispatch();
  const {isVerificationDone} = useVerificationDone();

  const handleCreateKontribute = useCallback(() => {
    if (!isVerificationDone) {
      dispatch(setShowVerificationPending(true));
      return;
    }
    setOpenCreate(true)
  }, [isVerificationDone])

  const kontributeNavItemsObj = [
    {
      label: 'New Kontribute',
      action: () => handleCreateKontribute(),
      bgColor: '#0898A0',
      img: CreateIcon,
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        navItems={userGroupsNavItems}
        tab={tab}
        setTab={handleCreateKontribute}
        navItemsObj={kontributeNavItemsObj}
      />
    )
  }

  const renderWithdrawHistory = useCallback(() => <WithdrawalHistory type="Kontribute" getFunction={getWithdrawalHistoryKontribute} />, []);
  const renderUserGroups = useCallback(() => <UserGroup handleMore={setKontribute} handleCreate={handleCreateKontribute} />, [isVerificationDone]);
  const renderOpenUserGroups = useCallback(() => <UserGroup tab="Open Kontribute" handleMore={setKontribute} handleCreate={handleCreateKontribute} />, [isVerificationDone]);
  
  return (
    <DashboardComponentWrapper
      title="Kontribute"
      pageDetails="Manage all your group contributions of varying purposes using Kontribute."
      renderNavItems={renderNavItems}
    >
      <>
        <TabComponent
          components={{
            // @ts-ignore
            'Close Kontribute': renderUserGroups,
            'Open Kontribute': renderOpenUserGroups,
            'Withdrawal History': renderWithdrawHistory
          }}
        />
        <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
          <CreateUserGroup handleDone={() => setOpenCreate(false)} />
        </SidePane>
        <SidePane
          onClose={() => setKontribute(null)}
          open={!!kontribute}
          title={""}
        >
          <GroupDetails
            group={kontribute}
            handleCloseDetail={() => setKontribute(null)}
            isMine
          />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  );
};

export default UserGroups;

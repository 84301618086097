import { useEffect, useState } from "react";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import { getAllUsers } from "../../../actions/adminActions";
import AdminUsersList from "../../../components/AdminUsersList";
import SidePane from "../../../components/SidePane";
import AdminUserDetails from "../../../components/AdminUserDetails";

const AdminUsers = () => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        getAllUsers(1);
    }, []);
    return (
        <DashboardComponentWrapper
            subTitle="Users"
            pageDetails="Here's an overview of all Users on Azatme."
        >
            <>
                <AdminUsersList getAllUsers={getAllUsers} setUser={(user) =>  setUser(user)} />
                <SidePane onClose={() => setUser(null)} open={!!user} title={""}>
                    <AdminUserDetails user={user} setUser={(user) =>  setUser(user)} />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminUsers;

import "./details.scss";
import DetailsCard from "./DetailCard";

const AdminUserDetails = (props) => {
    const user = props.user;

    return (
        <div className="c-user-details w-full flex !flex-col">
            <div className="flex flex-row flex-wrap w-full justify-between items-center border-b-2 border-gray-300 pb-6">
                <p className="text-2xl">User Details</p>
                <img className="w-20 h-20" src={user.image} alt="" />
            </div>
            <div className="my-2 flex flex-row w-full justify-between items-center">
                <p className="capitalize">{user.usertype}</p>
                <span className="flex capitalize flex-row px-4 w-fit bg-[#0898A01A] h-10 justify-center items-center text-az-teal">
                    {user.isVerified ? 'Verified' : 'UnVerified'}
                </span>
            </div>
            <div className="right mt-1 p-4 w-full !rounded-sm !bg-[#0898A008]">
                <DetailsCard 
                    // title={user.title || user.expense_name}
                    // description={user.description}
                    // id={user.id}
                    user={user}
                />
            </div>
        </div>
    );
};

export default AdminUserDetails;

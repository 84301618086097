import "./transactionDetails.scss";
// import DeleteIcon from "../../assets/delete.png";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useCallback, useState } from "react";
import { deleteExpensesActionCreator } from "../../actions/dashboardActions";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { formatCurrency } from "../../utils/common";
dayjs.extend(relativeTime);

interface IExpenseDetailsCard {
  id: string;
  title: string;
  category: string;
  description: string;
  paid: number;
  target: number;
  openEdit: () => void;
  uniqueCode?: string;
  handleCloseDetail: () => void;
  expense: any;
}



const ExpenseDetailsCard = ({
  id, 
  title, 
  description, 
  paid, 
  target, 
  openEdit,
  handleCloseDetail,
  expense
}: IExpenseDetailsCard) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  
  const handleCloseDeleteConfirm = useCallback(() => {
    setIsDeleteOpen(false);
  }, []);

  const deleteExpense = useCallback(() => {
    deleteExpensesActionCreator({id}, () => {
      setIsDeleteOpen(false);
      handleCloseDetail();
    })
  }, [id, handleCloseDetail]);
  
  return (
    <div className="right-wrapper">
      <div className="flex flex-col w-full">
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Date</p>
          <p className="text-sm text-az-black-900">{dayjs(expense.created_at).fromNow()}</p>
        </div>
        {
          title && (
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Title</p>
              <p className="text-sm text-az-black-900">{title}</p>
            </div>
          )
        }
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Transaction amount</p>
          <p className="text-sm text-az-black-900">{formatCurrency({num: target})}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Paid</p>
          <p className="text-sm text-az-black-900">{formatCurrency({num: paid})}</p>
        </div>
        {
          description && (
            <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">Description</p>
              <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{description}</p>
            </div>
          )
        }
      </div>
      <ConfirmModal
        isOpen={isDeleteOpen}
        onClose={handleCloseDeleteConfirm}
        text="Are you sure you want to delete this Transaction? All data about it will be lost after this action."
        handleNo={handleCloseDeleteConfirm}
        handleYes={deleteExpense}
      />
    </div>
  )
}

export default ExpenseDetailsCard
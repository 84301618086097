import axios from "axios";
import { silentHTTPCaller, toastWrapper } from "../utils/toastWrapper";
import {appDispatch} from '../app/store';
import getBaseURL from "../utils/getBaseURL";
import { addIssues, addInquiry, addExpenses, addExpensesByUser, addUserGroups, addUserGroupsByEmail, addAllBusinesses, addAllBusinessesByEmail, addAllAjosByEmail, addUsers, addReferrals, addReferral } from "../pages/AdminDashboard/adminSlice";

const url = getBaseURL() as string;

export const getAjoCount = (handleDone) => {
  const call = axios.get(`${url}countAllAjo`, )
  silentHTTPCaller(call, 'Fetching your ajo count...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Ajo count gotten successfully!';
  }, 'Error getting ajo count!');
}

export const getBusinessCount = (handleDone) => {
    const call = axios.get(`${url}countAllBusiness`, )
    silentHTTPCaller(call, 'Fetching your Business count...', (resp) => {
        handleDone(resp);
        return resp.data.message || 'Business count gotten successfully!';
      }, 'Error getting Business count!');
}

export const getRefundMeCount = (handleDone) => {
    const call = axios.get(`${url}countAllExpenses`, )
    silentHTTPCaller(call, 'Fetching your RefundMe count...', (resp) => {
        handleDone(resp);
        return resp.data.message || 'RefundMe count gotten successfully!';
      }, 'Error getting RefundMe count!');
}

export const getKontributeCount = (handleDone) => {
    const call = axios.get(`${url}countAllKontributes`, )
    silentHTTPCaller(call, 'Fetching your Kontribute count...', (resp) => {
        handleDone(resp);
        return resp.data.message || 'Kontribute count gotten successfully!';
      }, 'Error getting Kontribute count!');
  }

  export const getAllRefundMe = (page = 1) => {
    const call = axios.get(`${url}allExpenses?page=${page}`, )
    silentHTTPCaller(call, 'Fetching all RefundMe...', (resp) => {
        appDispatch(addExpenses(resp.data))
        return resp.data.message || 'Refundme gotten successfully!';
      }, 'Error getting Refundme!');
  }

  export const getAllKontribute = (page = 1) => {
    const call = axios.get(`${url}allKontributes?page=${page}`, )
    silentHTTPCaller(call, 'Fetching your Kontribute count...', (resp) => {
        appDispatch(addUserGroups(resp.data));
        return resp.data.message || 'Kontribute count gotten successfully!';
      }, 'Error getting Kontribute count!');
  }

  export const getAllBusiness = (page = 1) => {
    const call = axios.get(`${url}getAllBusiness?page=${page}`, )
    silentHTTPCaller(call, 'Fetching your business...', (resp) => {
        appDispatch(addAllBusinesses(resp.data));
        return resp.data.message || 'Businesses gotten successfully!';
      }, 'Error getting Businesses!');
  }

  export const getAllRefundMeByEmail = (page = 1, email) => {
    const call = axios.get(`${url}getAllExpensesByUserEmail/${email}?page=${page}`, )
    silentHTTPCaller(call, 'Fetching RefundMe...', (resp) => {
      appDispatch(addExpensesByUser(resp.data));
        return resp.data.message || 'RefundMe gotten successfully!';
      }, 'Error getting RefundMe!');
  }

  export const getAllKontributeByEmail = (page = 1, email) => {
    const call = axios.get(`${url}getAllKontributeByUserEmail/${email}?page=${page}`, )
    silentHTTPCaller(call, 'Fetching your Kontribute...', (resp) => {
        appDispatch(addUserGroupsByEmail(resp.data));
        return resp.data.message || 'Kontribute gotten successfully!';
      }, 'Error getting Kontribute!');
  }

  export const getAllBusinessByEmail = (page = 1, email) => {
    const call = axios.get(`${url}getAllBusinessByUserEmail/${email}?page=${page}`, )
    silentHTTPCaller(call, 'Fetching your business...', (resp) => {
        appDispatch(addAllBusinessesByEmail(resp.data));
        return resp.data.message || 'business gotten successfully!';
      }, 'Error getting Business!');
  }

  export const getAllAjoByEmail = (page = 1, email) => {
    const call = axios.get(`${url}getAllAjoByUserEmail/${email}?page=${page}`, )
    silentHTTPCaller(call, 'Fetching ajo...', (resp) => {
        appDispatch(addAllAjosByEmail(resp.data));
        return resp.data.message || 'Ajo gotten successfully!';
      }, 'Error getting Ajo!');
  }

  export const getAllComplains = (page = 1) => {
    const call = axios.get(`${url}getAllComplains?page=${page}`, )
    silentHTTPCaller(call, 'Fetching feedback...', (resp) => {
        appDispatch(addIssues({data: resp.data}));
        return resp.data.message || 'Feedback gotten successfully!';
      }, 'Error getting feedback!');
  }

  export const getAllInquiries = (page = 1) => {
    const call = axios.get(`${url}inquiry?page=${page}`, )
    silentHTTPCaller(call, 'Fetching inquiry...', (resp) => {
        appDispatch(addInquiry({data: resp.data}));
        return resp.data.message || 'Inquiry gotten successfully!';
      }, 'Error getting inquiry!');
  }

  export const getAllUsers = (page = 1) => {
    const call = axios.get(`${url}all-users?page=${page}`, )
    silentHTTPCaller(call, 'Fetching users...', (resp) => {
        appDispatch(addUsers({data: resp.data}));
        return resp.data.message || 'Users gotten successfully!';
      }, 'Error getting users!');
  }

  export const getAllReferralSettings = (page = 1) => {
    const call = axios.get(`${url}get-ref-settings/perAdmin?page=${page}`, )
    silentHTTPCaller(call, 'Fetching referrals...', (resp) => {
        appDispatch(addReferrals({data: resp.data}));
        return resp.data.message || '';
      }, '');
  }

  export const createReferral = (data, handleDone) => {
    const call = axios.post(`${url}set-ref`, data)
    toastWrapper(call, 'Creating referral...', (resp) => {
        appDispatch(addReferral({data: resp.data}));
        handleDone(resp);
        return resp.data.message || 'Successfully!';
      }, 'Failed!');
  }

  export const updateReferral = (id, data, handleDone) => {
    const call = axios.put(`${url}update-ref/${id}`, data);
    toastWrapper(call, 'Updating...', (resp) => {
        handleDone(resp);
        return resp.data.message || 'Successfully!';
      }, 'Failed!');
  }

  export const createCharges = (data, handleDone) => {
    const call = axios.post(`${url}create-charges`, data);
    toastWrapper(call, 'Creating charge...', (resp) => {
        handleDone(resp);
        return resp.data.message || 'Successfully!';
      }, 'Failed!');
  }

  export const updateCharges = (id, data, handleDone) => {
    const call = axios.put(`${url}charges/${id}`, data);
    toastWrapper(call, 'Updating...', (resp) => {
        handleDone(resp);
        return resp.data.message || 'Successfully!';
      }, 'Failed!');
  }

  export const getCharges = (handleDone) => {
    const call = axios.get(`${url}charges`);
    toastWrapper(call, 'Fetching charges...', (resp) => {
        handleDone(resp);
        return resp.data.message || 'Successfully!';
      }, 'Failed!');
  }

  export const deleteCharge = (id, handleDone) => {
    const call = axios.delete(`${url}charges/${id}`);
    toastWrapper(call, 'Deleting charges...', (resp) => {
        handleDone(resp);
        return resp.data.message || 'Successfully!';
      }, 'Failed!');
  }


import { useEffect, useState } from "react"
import { useAppSelector } from "../app/hooks";

const useIsAdmin = () => {
  const {user} = useAppSelector(state => state.auth);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    setIsAdmin(user?.usertype === "admin");
  }, [user?.usertype]);
  return isAdmin;
}

export default useIsAdmin;

import { useCallback, useState } from 'react';
import BusinessList from '../../components/BusinessList';
import DashboardComponentWrapper from '../../components/DashboardComponentWrapper';
import DashboardNavItem from '../../components/DashboardNavItem/DashboardNavItem';
import RegisterBusiness from '../../components/RegisterBusiness';
import CreateIcon from '../../assets/new/createIcon.png';
import './businessProfile.scss';
import SidePane from '../../components/SidePane';
import TabComponent from '../../components/TabComponent';
import WithdrawalHistory from '../../components/WithdrawalHistory';
import { getWithdrawalHistoryBusiness } from '../../actions/businessActions';

const businessNavItems = ['My Businesses','Add New'];

const BusinessesProfile = () => {
  const [tab, setTab] = useState(businessNavItems[0]);
  const [openCreate, setOpenCreate] = useState(false);

  const businessNavItemsObj = [
    {
      label: 'New Business',
      action: () => setOpenCreate(true),
      bgColor: '#0898A0',
      img: CreateIcon,
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        navItems={[]}
        tab={tab}
        navItemsObj={businessNavItemsObj}
        setTab={setTab}
      />
    )
  }

  const getHistory = useCallback(() => {
    getWithdrawalHistoryBusiness()
  }, []);

  const renderWithdrawHistory = useCallback(() => <WithdrawalHistory type="Business" getFunction={getHistory} />, []);
  return (
    <DashboardComponentWrapper
      title="Business Overview"
      pageDetails='All your created business profiles are displayed here, click to view more about the business'
      renderNavItems={renderNavItems}
    >
      <>
        <TabComponent
          components={{
            // @ts-ignore
            'All Businesses': () => <BusinessList handleCreate={() => setOpenCreate(true)} />,
            'Withdrawal History': renderWithdrawHistory
          }}
        />
        <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
          <RegisterBusiness handleDone={() => setOpenCreate(false)} />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  )
};

export default BusinessesProfile;

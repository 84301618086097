import { useCallback, useEffect, useState } from 'react';
import { emailPattern } from '../../hooks/useAuthRedirect';
import Button from '../Button';
import './index.scss';
import { useAppSelector } from '../../app/hooks';
import { inviteContact } from '../../actions/dashboardActions';
import Modal from '../Modal';
import Input from '../Input';

export const CustomMultiSelect = ({
  data,
  value,
  name,
  ...props
}) => {

  return (
    <div className={`input-wrapper ${props.className}`}>
      <div className='label-wrapper'>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
      </div>
      {props.hasError && <p>{props.errorText}</p>}
    </div>
  )
};


export const CustomSelect = ({
  data,
  value,
  name,
  handleAddMember,
  participants,
  handleSetShowPartForm,
  doneBtnText = "Close",
  ...props
}) => {
  const [showInvite, setShowInvite] = useState(false);
  const {isLoading} = useAppSelector(state => state.loader);
  const [inviteData, setData] = useState({
    firstname: '',
    lastname: '',
    type: 'refundMe'
  });
  const [searchItem, setItem] = useState('');
  const [searchResult, setSearchResult] = useState<Array<Record<string, string>>>([]);
  const [searchResultFiltered, setSearchFiltered] = useState<Array<Record<string, string>>>([]);


  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  useEffect(() => {
    if (searchItem) {
      // eslint-disable-next-line array-callback-return
      const result = data.filter((item) => {
        if (item.email.toLowerCase().match(searchItem.toLowerCase())) {
          return item
        }
      })
      setSearchResult(result);
    }
  }, [searchItem, data]);

  useEffect(() => {
    setSearchFiltered(searchResult.filter((item) => !participants[item.email]))
  }, [searchResult, participants]);

  const handleInviteUser = (event) => {
    event.preventDefault();
    inviteContact({
      first_name: inviteData.firstname, 
      last_name: inviteData.lastname, 
      email: searchItem,
      type: props.from.toLowerCase(),
    }, (resp) => {
      if (resp.status === 200) {
        handleAddMember('add', resp.data.id, resp.data.email);
        setData(prev => ({
          ...prev,
          firstname: '',
          lastname: '',
        }));
        setShowInvite(false);
      }
    });
  }

  const participantsLength = Object.values(participants).length;
  return (
    <div className="custom-select">
      <div className='input-wrapper'>
        <input
          placeholder='Enter Invitees email address.'
          type="text"
          onChange={({target}) => setItem(target.value)}
          value={searchItem}
        />
        {
          participantsLength > 0 && (
            <Button
              label={doneBtnText}
              type='flat'
              disabled={isLoading}
              onClick={() => {
                setItem('');
                handleSetShowPartForm(false);
              }}
              // className='secondary-btn'
            />
         )
        }
      </div>
      {
        (searchItem) && (
          <div className='display' style={props.overrideInputDisplay}>
            {/* {
              searchItem && (
                <Button
                  label='Done'
                  type='text'
                  onClick={() => setItem('')}
                  overrideStyle={{
                    border: '1px solid red',
                    width: 'fit-content',
                    height: 'fit-content',
                    position: 'absolute'
                  }}
                />
              )
            } */}
            <div className='wrapper'>
              {
                searchResultFiltered.length ? searchResultFiltered.map((item) => {
                  return (
                    <div className='result' key={item?.email}>
                      <div>{item?.email}</div>
                      <Button 
                        label='Add'
                        btnActionType='button'
                        onClick={() => {
                          handleAddMember('add', item?.id, item?.email)
                        }}
                        type="contained"
                        disabled={isLoading}
                      />
                    </div>
                  )
              }) : <p>No record found!</p>
              }
              {
                searchResult.length === 0 && searchItem.match(emailPattern) && !participants[searchItem] && (
                  <div className='result'>
                    <span style={{
                      width: '75%',
                      overflow: 'hidden',
                    }}>{searchItem}</span>
                    <Button 
                      label='Invite'
                      btnActionType='button'
                      onClick={() => setShowInvite(true)}
                      type="flat"
                    />
                  </div>
                )
              }
            </div>
          </div>
        )
      }
      {
        showInvite && (
          <Modal
            onClose={() => setShowInvite(false)}
            open={showInvite}
            overrideStyle={{
              maxWidth: "350px",
              width: "100%",
              height: "max-content",
            }}
            title="Add contact to Azatme"
          >
            <form className='invite-user-wrapper' onSubmit={handleInviteUser}>
              <Input required onChange={handleChange} label='Firstname' name={'firstname'} value={inviteData.firstname} />
              <Input required onChange={handleChange} label='Lastname' name={'lastname'} value={inviteData.lastname} />
              <Input
                label='email'
                name={''}
                value={searchItem} 
                onChange={(name, val) => setItem(val)}
                required
              />
              <Button 
                label='Send Invite'
                type="flat"
                btnActionType="submit"
              />
            </form>
          </Modal>
        )
      }
    </div>
  )
}
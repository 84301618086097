import './switchUserRole.scss';

const SwitchUserRole = () => {
  return (
    <div>
      Switch ROle
    </div>
  );
};

export default SwitchUserRole;

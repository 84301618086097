import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";

const useGetInvitationData = () => {
    const {search} = useLocation();
    const [invitationData, setInvitationData] = useState<Record<string, string>>({});
    useEffect(() => {
        const queries = search.split('?')[1]?.split('&');
        const querieObj = {};
        queries?.forEach(element => {
            const splited = element.split('=');
            querieObj[splited[0]] = splited[1];
        });
        if (queries?.length) {
            localStorage.setItem('inviteLink', search);
            localStorage.setItem('pendingInvitation', JSON.stringify(querieObj));
        }
        setInvitationData(querieObj)

    }, [search]);
    return [invitationData];
};

export default useGetInvitationData;

import { useEffect, useState } from "react"
import { useAppSelector } from "../app/hooks";

const useVerificationDone = () => {
  const {user} = useAppSelector((state) => state.auth);
  const [isVerificationDone, setIsVerificationDone] = useState(false);
  const [pendingVerification, setPendingVerification] = useState({
    bvn: true,
    nimc: true,
    fullname: true,
  });
  useEffect(() => {
     // @ts-ignore
    if (
      // @ts-ignore
        user?.bvn && user?.nimc && user?.first_name && user?.last_name && user?.middle_name
        // @ts-ignore
        && user?.isVerified == 1
      ) {
      setIsVerificationDone(true);
      setPendingVerification({
        bvn: !user.bvn,
        nimc: !user.nimc,
        // @ts-ignore
        fullname: !(user?.first_name && user?.last_name && user?.middle_name),
      })
    }
  }, [user]);
  return {isVerificationDone, pendingVerification};
};

export default useVerificationDone;

import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import Input from '../Input';
import Button from '../Button';
import './createTransaction.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import toast from 'react-hot-toast';
import { 
  createMPOSTransaction,
  createMPOSWithInventory,
  getCustomers,
  getMPOSTransactionStatus,
  getProducts,
  getTransactionOptions,
} from '../../actions/businessActions';
import QRCode from 'react-qr-code';
import CreatableSelect from 'react-select/creatable';
import useGetBusinessCode from '../../hooks/useGetBusinessCode';
import CreateCustomer from '../CustomerList/CreateCustomer';
import Modal from '../Modal';
import { formatCurrency } from '../../utils/common';


const CreateTransaction = (props) => {
  const {isLoading} = useAppSelector(state => state.loader);
  const [code] = useGetBusinessCode();
  const [transType, setType] = useState<'without-inv' | 'with-inv'>('without-inv');
  const [showQR, setShowQR] = useState(false);
  let interval = useRef('');
  const [data, setData] = useState({
    amount: '',
    id: '',
    link: '',
    status: false,
    paymentRef: '',
  });

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const getStatus = () => {
    getMPOSTransactionStatus(data.paymentRef, (resp) => {
      if (resp?.data?.data?.original?.status) {
        // @ts-ignore
        clearInterval(interval.current);
        // @ts-ignore
        handleChange('status', false);
      }
    });
  }

  const handleFetchStatus = () => {
    // @ts-ignore
    interval.current = setInterval(getStatus, 10000);
  }

  const handleAddFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (parseFloat(data.amount) <= 0) {
      toast.error('Please enter a value');
      return;
    }
    createMPOSTransaction({
      amount: data.amount
    }, code, (resp) => {
      const link = resp.data?.payLink;
      handleChange('link', link);
      const list = link.split('/');
      handleChange('paymentRef', list[list.length - 1]);
    });
  }

  useEffect(() => {
      if (data.paymentRef) {
        handleFetchStatus();
      }
      return () => {
        // @ts-ignore
        clearInterval(interval.current);
      }
  }, [data.paymentRef]);

  const handleClose = () => {
    props.handleDone();
    setData({
      amount: '',
      id: '',
      link: '',
      status: false,
      paymentRef: ''
    });
    // @ts-ignore
    clearInterval(interval.current);
  }

  const isActive = (typ: 'without-inv' | 'with-inv') => {
    return typ === transType ? 'text-white bg-az-teal' : 'text-az-teal bg-white';
  };

  const handleDone = (resp, allProducts, uniqueCode, quantity ) => {
    const link = resp?.data?.pay_link;
    handleChange('link', link);
    const list = link.split('/');
    handleChange('paymentRef', list[list.length - 1]);
    const prodPriceMap = {};
    let amount = 0;
    allProducts.forEach((itm) => {
      prodPriceMap[itm.unique_code] = itm.amount 
    });
    uniqueCode.forEach((code, index) => {
      amount += prodPriceMap[code] * quantity[index]
    });
    handleChange('amount', amount.toString());
  }

  return (
    <div className="h-full w-full">
      <div className='flex flex-col justify-start items-start mb-6'>
        <h3 className='text-3xl'>New Transaction</h3>
      </div>
      {
        !data.link && (
          <>
            <div className='h-10 mb-5'>
              <button className={`w-6/12 h-full rounded-tl-md rounded-bl-md border border-az-teal ${isActive('without-inv')}`} type="button" onClick={() => setType('without-inv')}>Without inventory</button>
              <button className={`w-6/12 h-full rounded-tr-md rounded-br-md border border-az-teal ${isActive('with-inv')}`} type="button" onClick={() => setType('with-inv')}>With inventory</button>
            </div>
            {
              transType === 'without-inv' ? (
                <form onSubmit={handleAddFormSubmit} className='!h-[80%] flex flex-col flex-auto flex-grow'>
                  <Input
                    label="Amount (NGN)"
                    placeholder="0"
                    type="number"
                    name="amount"
                    required
                    value={data.amount}
                    min={0}
                    onChange={handleChange}
                  />
                  <div className="w-full flex flex-row justify-around">
                    <div className='w-5/12'>
                      <Button
                        label="Continue"
                        type="flat"
                        btnActionType="submit"
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </form>
              ) : (
                <div>
                  <MPOSWithInventory handleDone={handleDone} />
                </div>
              )
            }
          </>
        )
      }
      {
        data.link && data.status !== true && (
          <div className={`w-full h-[90%] flex flex-col justify-center items-center  ${data.status ? 'bg-green-200' : 'bg-red-100'} `}>
            {
              !showQR && (
                <div className='my-6 text-center'>
                  <h2 className='my-6 font-bold text-3xl'>Payment pending</h2>
                  <img src="" alt="" />
                  <p className='my-6 font-bold text-4xl'>{formatCurrency({num: data.amount})}</p>
                  <p></p>
                </div>
              )
            }
            
              {
                showQR && (
                  <div className='my-6 text-center'>
                    <h2 className='my-6 font-bold text-3xl'>Payment pending</h2>
                    <p className='my-6 font-bold text-4xl'>{formatCurrency({num: data.amount})}</p>
                    <QRCode
                      value={data.link}
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                )
              }
            <div>
              <button onClick={handleClose} className='mr-3 border border-az-teal text-az-teal h-10 w-40 rounded font-bold font-Roobert'>Close</button>
              <button className='mr-3 bg-az-teal text-white h-10 w-40 rounded font-bold font-Roobert' onClick={() => setShowQR(!showQR)}>{showQR ? 'Hide' : 'Show'} QR Code</button>
            </div>
          </div>
        )
      }
      {
        data.status && (
          <div className={`w-full h-[90%] flex flex-col justify-center items-center  bg-green-200`}>
            <h4 className='my-20 text-center text-3xl'>
              Transactions successful
            </h4>
          </div>
        )
      }
    </div>
  );
};

export default CreateTransaction;

const MPOSWithInventory = ({handleDone}) => {
  const [createCustomer, setCreateCustomer] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [productQty, setProductQty] = useState({});
  const dispatch = useAppDispatch();
  const customerObj = useAppSelector((state) => state.business.customers);
  const productsObj = useAppSelector((state) => state.business.products);
  const transactionOptions = useAppSelector((state) => state.business.transactionOptions);
  const [businessCode] = useGetBusinessCode();
  const customers = customerObj[businessCode as string];
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    name: '',
    total: '',
    category: '',
    subcategory: '',
    description: '',
    id: '',
    link: '',
    splittingId: '',
    moto: '',
    dueDate: '',
    bankName: '',
    transactionSendType: [],
    uniqueCode: '',
    customerName: '',
    customerEmail: '',
    quantity: '0',
  });
  const products = productsObj[businessCode as string];
  useEffect(() => {
    if (!products) {
      businessCode && getProducts(dispatch, businessCode, 1);
    }
  }, [businessCode, products]);

  useEffect(() => {
    if (!transactionOptions) {
      getTransactionOptions(dispatch);
    }
  }, [dispatch, step, transactionOptions]);

  useEffect(() => {
    if (!customers) {
      businessCode && getCustomers(dispatch, businessCode);
    }
  }, [businessCode, customers]);

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const removeProduct = (itm) => {
    // @ts-ignore
    const newProducts = allProducts.filter((prod) => prod.value !== itm.value);
    setAllProducts(newProducts);
  }

  const handleCreateTransaction = async (event: FormEvent) => {
    event.preventDefault();
    if (allProducts.length === 0) {
      toast.error('Please select a one or more products to continue!');
      return;
    }
    if (!data.customerEmail) {
      toast.error('Please add a customer to continue!')
      return;
    }
    const quantity = [];
    const uniqueCode = [];
    Object.entries(productQty).forEach(([key, value]) => {
      // @ts-ignore
      quantity.push(value);
      // @ts-ignore
      uniqueCode.push(key);
    }); 
    await createMPOSWithInventory({
      email: data.customerEmail,
      unique_code: uniqueCode,
      quantity,
    }, businessCode, (resp) => {
      // @ts-ignore
      handleDone(resp, products.data, uniqueCode, quantity);
    });
  }

  return (
    <form onSubmit={handleCreateTransaction} className='!h-[80%] flex flex-col flex-auto flex-grow'>
      <div
        className='flex flex-col justify-start items-start !w-full'
        style={{
          display: 'flex'
        }}
      >
        <label htmlFor="">Select Products/Services</label>
        <CreatableSelect
          isMulti
          // @ts-ignore
          options={products?.data?.map((item) => ({
            label: item?.name, 
            value: item?.unique_code
          }))}
          value={allProducts}
          // name="bankName"
          onChange={(item) => {
            if (item) {
              // @ts-ignore
              setAllProducts(item);
            }
          }}
          className='w-full text-left'
        />
      </div>
      <div className='w-full flex flex-col flex-auto justify-start items-start flex-grow h-[200px] overflow-auto mt-4 p-1'>
        {
          allProducts?.map((product, index) => (
            // @ts-ignore
            <div className='flex flex-row justify-between items-center my-2 w-full px-4' key={`${product.label}-${index}`}>
              {/* @ts-ignore */}
              <p>{product.label}</p>
              <div className='flex flex-row justify-end items-center'>
                <input
                  className='border border-black px-2 py-1 !w-28 !m-0 !mx-2'
                  type="number"
                  placeholder='Enter Quantity'
                  onChange={({target}) => {
                    // @ts-ignore
                    setProductQty((prev) => ({
                      ...prev,
                      // @ts-ignore
                      [product.value]: target.value
                    }));
                  }}
                  // @ts-ignore
                  value={productQty[product.value]}
                  min={1}
                />
                <svg className='cursor-pointer' onClick={() => removeProduct(product)} xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                  <path d="M16.3248 7.46826C16.3248 7.46826 15.7818 14.2033 15.4668 17.0403C15.3168 18.3953 14.4798 19.1893 13.1088 19.2143C10.4998 19.2613 7.88779 19.2643 5.27979 19.2093C3.96079 19.1823 3.13779 18.3783 2.99079 17.0473C2.67379 14.1853 2.13379 7.46826 2.13379 7.46826" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M17.708 4.23975H0.75" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M14.4406 4.23973C13.6556 4.23973 12.9796 3.68473 12.8256 2.91573L12.5826 1.69973C12.4326 1.13873 11.9246 0.750732 11.3456 0.750732H7.11258C6.53358 0.750732 6.02558 1.13873 5.87558 1.69973L5.63258 2.91573C5.47858 3.68473 4.80258 4.23973 4.01758 4.23973" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </div>
          ))
        }
      </div>
      <div className='custom-wrapper !w-full'>
        <label htmlFor="">Enter Customer's Name</label>
        <CreatableSelect
          className="react-select-container"
          // @ts-ignore
          options={customers?.map((item) => (
            {
              label: item?.customer_name, 
              value: item?.customer_email
            }))
          }
          onCreateOption={(option) => {
            handleChange('customerName', option);
            setCreateCustomer(true);
          }}
          name="bankName"
          value={{
            label: data.customerName,
            value: data.customerName
          }}
          onChange={(item) => {
            if (item) {
              handleChange('customerName', item.label);
              handleChange('customerEmail', item.value);
            }
          }}
          formatCreateLabel={(inputValue: string) => (
            <div style={{
              height: '50px',
            }}>
              <Button
                label={`Create new Customer: "${inputValue}"`}
                type="flat"
                btnActionType="button"
                overrideStyle={{
                  margin: '0',
                }}
              />
            </div>
          )}
        />
      </div>
      <Modal
        onClose={() => setCreateCustomer(false)}
        open={createCustomer}
        title={''}
      >
        <CreateCustomer
          isOpen={createCustomer}
          handleClose={() => {
            setCreateCustomer(false);
          }}
          name={data.customerName}
          handleDone={(val: string) => {
            handleChange('customerEmail', val);
          }}
        />
      </Modal>
      <div className="w-full flex flex-row justify-around my-10">
        <div className='w-5/12'>
          <Button
            label="Continue"
            type="flat"
            btnActionType="submit"
          />
        </div>
      </div>
    </form>
  )
}

import { useAppDispatch } from "../../app/hooks";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import CreateAjo from "../../components/CreateUserGroup/ajo";
import useVerificationDone from "../../hooks/useVerificationDone";
import { setShowVerificationPending } from "../Auth/authSlice";
import { useCallback, useState } from "react";
import AjoGroup from "../../components/AjoGroup";
import WithdrawalHistory from "../../components/WithdrawalHistory";
import { getAjoWithdrawalhistory } from "../../actions/dashboardActions";
import CreateIcon from '../../assets/new/createIcon.png';
import SidePane from "../../components/SidePane";
import TabComponent from "../../components/TabComponent";
import './ajo.scss';
import AjoDetails from "../../components/AjoDetails";


const userGroupsNavItems = ['My Kontributes','Create', 'Withdrawal History'];

const Ajo = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [kontribute, setKontribute] = useState<Record<string, any> | null>(null);
  const [tab, setTab] = useState(userGroupsNavItems[0]);
  const dispatch = useAppDispatch();
  const {isVerificationDone} = useVerificationDone();

  const handleCreateAjo = useCallback(() => {
    if (!isVerificationDone) {
      dispatch(setShowVerificationPending(true));
      return;
    }
    setOpenCreate(true)
  }, [isVerificationDone]);

  const kontributeNavItemsObj = [
    {
      label: 'Start new Ajo',
      action: () => handleCreateAjo(),
      bgColor: '#0898A0',
      img: CreateIcon,
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        navItems={userGroupsNavItems}
        tab={tab}
        setTab={handleCreateAjo}
        navItemsObj={kontributeNavItemsObj}
      />
    )
  }

  const getHistory = () => {
    getAjoWithdrawalhistory(1, () => {});
  }

  const renderWithdrawHistory = useCallback(() => <WithdrawalHistory type="Ajo" getFunction={getHistory} />, [isVerificationDone]);
  const renderAjoGroup = useCallback(() => <AjoGroup handleMore={setKontribute} handleCreate={handleCreateAjo} />, [isVerificationDone]);
  
  return (
    <DashboardComponentWrapper
      title="Ajo"
      pageDetails="Manage all your periodic contributions hassel free."
      renderNavItems={renderNavItems}
    >
      <>
        <TabComponent
          components={{
            // @ts-ignore
            'Ajo History': renderAjoGroup,
            'Withdrawal History': renderWithdrawHistory
          }}
        />
        <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
          <CreateAjo renderCancel={() => setOpenCreate(false)} />
        </SidePane>
        <SidePane
          onClose={() => setKontribute(null)}
          open={!!kontribute}
          title={""}
        >
          <AjoDetails
            group={kontribute}
            handleCloseDetail={() => setKontribute(null)}
            isMine
          />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  );
};


export default Ajo;

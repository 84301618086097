import ContactImgLg from  '../../assets/new/contactLg.png';
import ContactImgMob from  '../../assets/new/contactMob.png';
import ContactUsForm from './ContactUsForm';

const ContactUs  = () => {

    return (
        <div className='w-full'>
            <div className='relative h-fit w-full'>
                <img src={ContactImgLg} alt="" className='w-full hidden lg:flex' />
                <img src={ContactImgMob} alt="" className='w-full lg:hidden flex' />
                <h1 className='absolute lg:top-[50%] top-[10%] text-white text-3xl lg:text-[40px] lg:leading-[51px] font-medium lg:left-[6%] left-[25%]'>Contact us</h1>
            </div>
            <div>
                <div></div>
                <div>
                    <ContactUsForm />
                </div>
            </div>
        </div>
    )
};

export default ContactUs;

const howItWorks = [
	{
			title: 'Sign up',
			details: `Fill out the short form to create a personal
			account. You’ll gain access to start
			exploring our products.`
	},
	{
			title: 'Verification',
			details: `Our compliance team will inform you of 
			the outcome within the shortest
			possible time.`
	},
	{
			title: 'Start using any of our products',
			details: `Once your KYC process is approved, go live 
			with a click of a button and start accepting
			payments seamlessly with AzatMe.`
	},
];

const HowItWorks = () => {
	return (
			<div className='w-screen px-[7%] py-[6%] min-h-[70vh] h-fit relative flex flex-col justify-center items-center' id='howitworks'>
					<p className='text-sm font-medium uppercase bg-az-light-red p-3 px-7 rounded-full mb-8'>How to get Started</p>
					<p className='text-4xl font-medium mb-20'>Open an account seamlessly in minutes</p>
					<div className='flex flex-row flex-wrap justify-around w-full relative'>
							<div className='w-1 md:w-8/12 bg-[#0898A01A] h-[70%] md:h-1 absolute top-8' />
							{
									howItWorks.map((itm, index) => (
											<div className='flex flex-col justify-center items-center w-full max-w-[300px] h-full max-h-[300px] my-6 md:my-0' key={itm.title}>
													<div className='w-[70px] h-[70px] bg-[#0898A01A] rounded-full flex justify-center items-center mb-7'>
															<div  className='w-[50px] h-[50px] bg-az-teal text-white flex justify-center items-center rounded-full font-medium text-xl'>{index + 1}</div>
													</div>
													<div className='bg-white z-10'>
															<p className='text-xl font-medium mb-5'>{itm.title}</p>
															<p className='text-sm w-[276px] font-normal text-center'>{itm.details}</p>
													</div>
											</div>
									))
							}
					</div>
			</div>
	)
};

export default HowItWorks;
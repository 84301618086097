const KontributeSVG = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="bill 1">
                <path className="stroked" id="Vector" d="M6.17352 15.485H2.10352V3.96203C2.10352 3.41993 2.31886 2.90003 2.70219 2.5167C3.08551 2.13338 3.60541 1.91803 4.14752 1.91803C4.41602 1.9179 4.68192 1.97067 4.93003 2.07333C5.17813 2.17599 5.40358 2.32653 5.59349 2.51635C5.7834 2.70617 5.93405 2.93154 6.03683 3.17959C6.13961 3.42765 6.19252 3.69352 6.19252 3.96203V22.082H21.8955V4.19503C21.8934 3.58971 21.6526 3.00966 21.2253 2.58089C20.798 2.15211 20.2188 1.90924 19.6135 1.90503L4.10352 1.91903H4.97352M10.3355 13.533H15.1425M10.3355 10.188H15.1425M10.3355 6.84303H17.3725" stroke="#222222" strokeOpacity="0.7" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square"/>
            </g>
        </svg>
    )
};

export default KontributeSVG;

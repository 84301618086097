import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { logout } from '../Auth/authSlice';

export interface Bank {
  bankName: string;
  account_number: string;
  account_name: string;
  bankCode: string
  id: number
  balance?: number;
  name: string;
}

interface BankState {
  banks: Bank[] | null;
  systemBanks: Bank[] | null;
  systemBanksObj: Record<string, Bank>;
}

const initialState: BankState = {
  banks: null,
  systemBanks: null,
  systemBanksObj: {}
}

export const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Bank[]>) => {
      state.banks = action.payload;
    },
    addSystemBanks: (state, action: PayloadAction<Bank[]>) => {
      state.systemBanks = action.payload;
      action.payload?.forEach((item) => {
        state.systemBanksObj[item.bankName] = item;
      });
    },
    addNewBank: (state, action: PayloadAction<Bank>) => {
      state.banks?.push(action.payload);
    },
    update: (state, action: PayloadAction<Bank>) => {
      const newBanks = state.banks?.map((bank) => {
        if (bank.id === action.payload.id) {
          return action.payload;
        } else {
          return bank;
        }
      });
      state.banks = newBanks || null;
    },
    deleteBank: (state, action: PayloadAction<Bank>) => {
      const newBanks = state.banks?.filter((bank) => bank.id !== action.payload.id);
      state.banks = newBanks || null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state = initialState;
      return state;
    })
  }
})

export const { add, update, deleteBank, addNewBank, addSystemBanks } = bankSlice.actions;

export default bankSlice.reducer;

import FAQ from "../Components/Personal/FAQ";
import { useNavigate } from "react-router-dom";
import { Reason } from '../../../types';
import { useEffect, useState } from 'react';
import Blogs, { Blog } from "../Components/Personal/Blogs"; 

import RetailImg from '../../../assets/new/business/retail.png';
import Logistics from '../../../assets/new/business/logistics.png';
import Supermark from '../../../assets/new/business/supermark.png';
import './builder.scss';

const solutions = [
    {
      title: 'Retail Business',
      img: RetailImg
    },
    {
      title: 'Logistics and Transportations',
      img: Logistics
    },
    {
      title: 'Supermarkets',
      img: Supermark
    },
    {
      title: 'Restaurants',
      img: Supermark
    },
  ];

interface IProps {
    title: string;
    name: string;
    tagline: string;
    image: string;
    imgText: string[];
    summary: string;
    titleIcon?: string;
    reasons?: Array<Reason>
    howItWorks?: Record<string, Record<string, string>[]>
    benefits?: Array<Record<string, string>>;
    overrideReasonHeader?: string;
    faq: Array<Record<string, string| any>>;
}


const BusinessPageBuilder = (props: IProps) => {
    const navigate = useNavigate();
    const [selectedHowItWorks, setHowItWorks] = useState('');


    useEffect(() => {
        // @ts-ignore
        if (Object.keys(props.howItWorks).length > 1) {
            // @ts-ignore
            setHowItWorks(Object.keys(props.howItWorks)[0])
        }
    }, []);
    
    const getHowItWorks = () => {
        // @ts-ignore
        if (Object.keys(props.howItWorks).length === 1) {
            // @ts-ignore
            return props.howItWorks.howItWorks;
        }
        // @ts-ignore
        return selectedHowItWorks ? props.howItWorks[selectedHowItWorks] : [];
    }

    const steps = [
        { text: "Sign on to the business part of the AzatMe App" },
        { text: "Select a business from the business list and tap on the mPOS icon to launch it" },
        { text: "Sign on to the business part of the AzatMe App" },
        { text: "Select a business from the business list and tap on the mPOS icon to launch it" },
        { text: "Sign on to the business part of the AzatMe App" },
        { text: "Select a business from the business list and tap on the mPOS icon to launch it" },
        // ... other steps
      ];
    return (
        <div className="w-screen h-fit">
            <div className="w-screen bg-[#0898A008] min-h-[calc(100vh)] h-fit pt-16">
                <div className="flex flex-col md:flex-row px-[6%] sm:px-[6%] items-center justify-between pt-14 lg:pt-6">
                    <div className="text-left w-full h-fit">
                        <div className="flex flex-row h-9 my-4 mb-8 bg-[#0898A01A] rounded-full w-fit p-2 items-center text-xs">
                            <img src={props.titleIcon} alt="" className='object-fill' />
                            <p className="px-2 font-medium text-sm text-[#001213] uppercase">{props.name}</p>
                        </div>
                        <h1 className="font-Onest text-3xl lg:text-[45px] font-medium w-full xl:w-[500px] leading-[56px] text-[#001213]">{props.title}</h1>
                        <p className="font-Onest text-base  w-full xl:w-[480px] my-8  text-[#001213CC]">{props.tagline}</p>
                        <div className="flex flex-row justify-start items-center">
                            <button className="bg-az-teal text-white w-fit h-10 px-5 rounded font-medium font-Onest" onClick={() => navigate('/register')}>Let’s Get You Started</button>
                        </div>
                    </div>
                    <div className="relative w-[100vw] h-[80vh] flex flex-row justify-center items-center">
                        <img src={props.image} alt="" className="absolute w-11/12 h-[60%] lg:h-full mt-0 lg:mt-[250px] object-contain z-10" />
                    </div>
                </div>
            </div>
            <div className='w-full p-[6%] pt-[15%] bg-[#001213] text-left'>
                <p className='text-3xl text-white font-medium w-full text-center rounded-full mb-8 max-w-[600px] m-auto'>{props.overrideReasonHeader ? props.overrideReasonHeader : `Why use ${props.name}?`}</p>
                {props.summary && <p className="text-white max-w-[739px] text-center w-full m-auto mb-14">{props.summary}</p>}
                <div className='flex flex-row justify-center lg:justify-between flex-wrap w-full'>
                    {
                        props.reasons?.map((itm, index) => (
                            <div key={index} className='flex bg-[#0898A01A] flex-col justify-between h-[390px] max-w-[375px] w-full text-left my-5 rounded-md p-8'>
                                <img src={itm.img} alt="" className="w-[70px] h-[70px]" />
                                <p className='my-6 uppercase text-[#FFFFFF80] text-xs'>{itm.tagline}</p>
                                <p className='text-2xl font-medium w-[86%] text-white'>{itm.title}</p>
                                <p className='my-6 text-white'>{itm.details}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='w-screen p-[7%] bg-[#0898A01A] min-h-[70vh] h-fit relative flex flex-col justify-center items-center' id='howitworks'>
                <p className='text-sm font-medium uppercase text-[#0898A0] bg-az-light-red p-3 px-7 rounded-full mb-8'>How it works</p>
                <p className='text-4xl font-medium max-w-[667px]'>Let's dive into the flow process for ({props.name})</p>
                {
                    // @ts-ignore
                    Object.keys(props.howItWorks).length > 1 && (
                        <div className='flex justify-between p-2 rounded-full bg-[#0898A01A] w-full ml-2 max-w-[460px] my-16'>
                            {
                                // @ts-ignore
                                Object.keys(props.howItWorks).map((itm) => (
                                    <button onClick={() => setHowItWorks(itm)} className={`${selectedHowItWorks === itm ? 'text-white bg-az-teal' : 'bg-transparent text-[#001213B2]'} rounded-full w-6/12 h-10 font-normal font-Onest`}>{itm}</button>
                                ))
                            }
                        </div>
                    )
                }
                <div className='flex flex-col flex-wrap justify-center items-center w-full relative mt-10'>
                    <hr className=' border-[#0898A01A] h-[80%] absolute top-8 border-2 border-dashed' />
                    {
                        getHowItWorks()?.map((itm, index) => (
                            <div className='how-to-tree flex flex-row justify-start items-start h-[120px] my-6 md:my-0 mb-10 relative' key={`${itm.title} ${index}`}>
                                <div className='w-[50px] h-[50px] rounded-full flex justify-center items-center'>
                                    <div  className='w-[50px] h-[50px] bg-az-teal text-white flex justify-center items-center rounded-full font-medium text-xl'>{index + 1}</div>
                                </div>
                                <div className='z-10 bg-[#EAF4F5] ml-10 details absolute'>
                                    <p className='text-xl font-medium mb-5 title'>{itm.title}</p>
                                    <p className='text-sm w-[40vw] font-normal'>{itm.details}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='w-screen px-[7%] bg-[#001213] py-[6%] h-fit relative flex flex-col justify-start items-center bg-az-faint-red' id='services'>
                <p className='font-Onest text-white text-4xl mb-[6%] text-center'>
                    Built for every kind of business
                </p>
                <div className='flex flex-row justify-start items-start overflow-hidden w-full h-fit'>
                    {
                        solutions.map((service, index) => (
                            <div key={index} className={`flex flex-col !w-[360px] justify-between rounded-md !h-[370px] text-left m-2 bg-white relative`}>
                                <p className='font-Onest text-white text-3xl rounded-sm flex justify-center items-center w-fit px-4 py-2 absolute left-5 bottom-10'>
                                    {service.title}
                                </p>
                                <div className='!w-[360px] !h-[370px] rounded-md '>
                                    <img src={service.img} alt="" className='w-full h-full rounded-md ' />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <Blogs />
            <FAQ faqs={props.faq} />
        </div>
    )
}

export default BusinessPageBuilder;
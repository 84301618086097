import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Help from '../../assets/help.png';
import './input.scss';
import PhoneNumberInput from '../PhoneNumberInput';

interface IInput {
  pattern?: string;
  type?: string;
  label?: string;
  ctaText?: string;
  ctaRoute?: string;
  obscureText?: boolean;
  onChange?: (name: string, value: string) => void;
  hasError?: boolean;
  errorText?: string;
  readonly?: boolean;
  placeholder?: string;
  name: string;
  value: string;
  required?: boolean;
  onBlur?: () => void;
  defaultValue?: string;
  handleCTAClick?: () => void;
  info?: string;
  accept?: string;
  min?: number;
  maxLength?: number;
}


function Input(props: IInput) {
  const [inputType, setType] = useState(props.type || 'text');
  const isPassword = inputType === 'password';
  const handleViewPassword = (event) => {
    event.preventDefault();
    if (isPassword) {
      setType('text')
    } else {
      setType('password');
    }
  }

  return (
    <div className="input-wrapper">
      <InputLabel props={props} />
      {props.type === 'password' && (
          <button onClick={handleViewPassword} className="open-password">
            <span>{isPassword ? 'Show' : 'Hide'}</span>
          </button>
        )
      }
      {
        props.type && props.type === 'file' ? (
          <input
            type="file"
            required={props.required}
            onChange={({target}) => {
              //@ts-ignore
              props.onChange && props.onChange(props.name, target.files[0])
            }}
            onBlur={() => {
              props.onBlur && props.onBlur()
            }}
            accept={props.accept}
            style={{
              backgroundColor: 'transparent',
            }}
            className='outline-black'
          />
        ) : props.type === "tel" ? 
        (
          <PhoneNumberInput 
            height={"12"} 
            value={props.value}
            required={props.required} 
            handleChange={(e) => {
              props.onChange && props.onChange(props.name, e.target.value);
            }}
            onBlur={() => {
              props.onBlur && props.onBlur()
            }}
            min={props.min}
            name={props.name}
            pattern={props.pattern || '.*?'}
            readOnly={props.readonly}
            defaultValue={props.defaultValue}
          />
        )
        : props.maxLength ? (
          <textarea
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            required={props.required}
            onChange={({target}) => {
              props.onChange && props.onChange(props.name, target.value);
            }}
            onBlur={() => {
              props.onBlur && props.onBlur()
            }}
            className='!py-2 !resize-none !h-20 !mt-0 outline-black'
            rows={10}
            maxLength={props.maxLength}
          >

          </textarea>
        ) : (
          <input
            type={inputType}
            readOnly={props.readonly}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            required={props.required}
            pattern={props.pattern || '.*?'}
            onChange={({target}) => {
              props.onChange && props.onChange(props.name, target.value)
            }}
            onBlur={() => {
              props.onBlur && props.onBlur()
            }}
            min={props.min}
            className='outline-black'
          />
        )
      }
      {props.hasError && <p>{props.errorText}</p>}
      {props.maxLength ? <span className='self-end'>{props.value?.length || 0}/{props.maxLength}</span> : ''}
    </div>
  );
}

export default Input;

export const Select = ({
  data,
  value,
  ...props
}: {
  data: Array<string>;
  type?: string;
  value?: string;
  label?: string;
  ctaText?: string;
  ctaRoute?: string;
  obscureText?: boolean;
  onChange?: (name: string, value: string) => void;
  hasError?: boolean;
  errorText?: string;
  readonly?: boolean;
  placeholder?: string;
  name: string;
  required?: boolean;
  onBlur?: () => void;
  defaultValue?: string;
  className?: string;
  selectClassName?: string;
  info?: string;
}) => {
  return (
    <div className={`input-wrapper ${props.className}`}>
      <InputLabel props={props} />
      <select
        className={`${props.selectClassName}`}
        name={props.name}
        value={value}
        required={props.required}
        onChange={({target}) => {
          props.onChange && props.onChange(props.name, target.value)
        }}
        onBlur={() => {
          props.onBlur && props.onBlur()
        }}
      >
        <option disabled></option>
        {
          data && data.map((item) => <option value={item} key={item}>{item}</option>)
        }
      </select>
      {props.hasError && <p>{props.errorText}</p>}
    </div>
  )
}


const InputLabel = ({props}) => {
  const [openInfo, setOpenInfo] = useState(false);
  useEffect(() => {
    let setHolder;
    if (openInfo) {
      setHolder = setTimeout(() => setOpenInfo(false), 2000);
    }
    return () => {
      clearTimeout(setHolder);
    }
  }, [openInfo]);
  return (
    <div className='label-wrapper'>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <Link to={`/${props.ctaRoute}`}><span>{props.ctaText}</span></Link>
      {props.info && <img className='open-info' src={Help} alt="" onClick={() => setOpenInfo(!openInfo)} />}
      {props.info && openInfo && <div className="info">{props.info}</div>}
    </div>
  )
}

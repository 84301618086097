
interface IAzatsListWrapper {
  children: JSX.Element;
  renderNavItems?: () => JSX.Element;
}

const AzatsListWrapper = ({ children, renderNavItems }: IAzatsListWrapper) => {
  return (
    <div style={{width: '100%'}} className="bg-white mt-6">
      <div className=" px-6" style={{width: '100%'}}>
        {renderNavItems && renderNavItems()}
      </div>
      <div className="" style={{width: '100%'}}>{children}</div>
    </div>
  );
};

export default AzatsListWrapper;

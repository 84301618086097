import RefundIcon from '../../../../../assets/new/products/refund.png'
import RefundImg from '../../../../../assets/new/products/refundImg.jpg'
import Ajo from '../../../../../assets/new/products/ajo.png'
import AjoImg from '../../../../../assets/new/products/ajoImg.jpg'
import Open from '../../../../../assets/new/products/open.png'
import OpenImg from '../../../../../assets/new/products/openImg.jpg'
import Close from '../../../../../assets/new/products/close.png'
import CloseImg from '../../../../../assets/new/products/closeImg.jpg'
import RightArrow from '../../../../../assets/new/rightArrow.png'
import { useNavigate } from 'react-router-dom';

const services = [
    {
        title: 'Refund Me',
        icon: RefundIcon,
        link: 'refundme',
        img: RefundImg,
        tagline: 'RefundMe makes splitting group expenses a breeze! ',
        details: "When you're out with friends, one person can cover the full bill, then quickly and easily collect reimbursements from the rest of the group through our intuitive platform. Embrace the convenience of seamless group payments with RefundMe",
        cta: 'Explore RefundMe',
        color: '#FB97011A',
        bgColor: '#FFF9F0',
        textColor: '#222',
    },
    {
        title: 'Closed Kontribute',
        icon: Close,
        link: 'close_kontribute',
        img: CloseImg,
        tagline: 'Make your financial dreams a reality with Kontribute!',
        details: "Targeted Fundraising designed for focused groups with shared financial goals, enabling targeted fundraising and capital collection within your trusted controlled network circle, like a family reunion or hobby group..",
        color: '#3468EF1A',
        cta: 'Explore Closed Kontribute',
        textColor: '#3468EF1A',
        bgColor: '#F5F8FF',
    },
    {
        title: 'Open Kontribute',
        icon: Open,
        link: 'open_kontribute',
        img: OpenImg,
        tagline: 'Make your financial dreams a reality with Kontribute!',
        cta: 'Explore Open Kontribute',
        details: "This innovative crowdfunding platform connects you with a supportive community and friends to collaboratively raise pool funds for your goals, opening up endless opportunities. Together, achieve anything!",
        color: '#EF34E71A',
        textColor: '#ffffff',
        bgColor: '#FFEFFE',
    },
    {
        title: 'Ajo',
        icon: Ajo,
        link: 'ajo',
        img: AjoImg,
        tagline: 'Save Together & Reach Goals Faster',
        details: "Ajo is a modern twist on the traditional rotating savings money pool model, facilitating collective saving and withdrawal among friends or family, simplifying the pursuit of shared financial objectives.",
        color: '#1A8FD81A',
        textColor: '#ffffff',
        bgColor: '#F2FAFF',
        cta: 'Explore Ajo',
    }
];

const Service = () => {
    const navigate = useNavigate();
    return (
        <div className='w-screen px-[6%] min-h-screen h-fit relative my-[100px]' id="services">
            <div className='w-full flex flex-col justify-start'>
                <div className='flex flex-row justify-start mb-10'>
                    <p className='font-Onest uppercase  bg-az-teal-100 px-5 py-3 rounded-full font-medium'>OUR products</p>
                </div>
                <p className='text-left font-Onest w-full text-az-black-200 font-medium lg:w-7/12 text-4xl'>
                    We offer a comprehensive and innovative solution
                    that streamlines and makes your financial
                    management effortless.
                </p>
            </div>
            <div className='columns-1 lg:columns-2 flex-row gap-5 mt-10 justify-between relative'>
                {
                    services.map((service) => (
                        <div className={`flex flex-col w-full border justify-between rounded-[20px] mb-10 text-left`} style={{
                            background: service.bgColor,
                        }} key={service.title}>
                            <div className='flex flex-col gap-5 h-fit w-full justify-between items-start p-[20px] md:p-[35px]'>
                                <div className='flex flex-row justify-start items-center w-fit p-3 rounded-full' style={{background: service.color}}>
                                    <img src={service.icon} alt=""  />
                                    <p className='font-Onest text-sm mx-2 font-medium'>{service.title}</p>
                                </div>
                                <p className='font-Onest text-md lg:text-4xl font-medium max-w-[500px] w-full'>{service.tagline}</p>
                                <p className='font-Onest text-sm lg:text-md font-normal w-full max-w-[700px]'>{service.details}</p>
                                <button className='font-Onest flex text-xs md:text-sm font-medium flex-row items-center cursor-pointer my-5 rounded w-fit p-3 px-6'  style={{background: service.color}}  onClick={() => navigate(service.link)}>
                                    {service.cta}
                                    <img src={RightArrow} alt="" className='ml-2' />
                                </button>
                            </div>
                            <div className='h-[321px] w-full flex justify-center'>
                                <img src={service.img} alt="products" className='w-full h-full object-cover rounded-b-[20px]' />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

export default Service;

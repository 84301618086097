import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import UnAuthWrapper from "../../components/UnAuthWrapper";
import getBaseURL from "../../utils/getBaseURL";
import GobackArrow from '../../assets/new/goBackArr.png';
import { toastWrapper } from "../../utils/toastWrapper";

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent) => {
    const url = getBaseURL() as string;
    event.preventDefault();
    toastWrapper(
      axios.post(`${url}forgot`, {email}), 
      'Getting your reset information ready...', 
      (resp) => {
        if (resp.data.message === "user doesn't exists") {
          return resp.data.message || 'Please check your email for further instructions!';
        }
        localStorage.setItem('email', email);
        navigate('/reset-password');
        return resp.data.message || 'Please check your email for further instructions!';
      }, 
      'Error getting the reset information.',
    );
  }

  return (
    <UnAuthWrapper
      title="Forgot Password."
      subTitle="Kindly input the email address linked to your account in order to reset your password"
      ctaQuestion="Don’t have an account?"
      ctaRoute="register"
      ctaText="Sign Up"
    >
      <form onSubmit={handleSubmit} className="max-w-[400px]">
        <Input
          label="Email"
          placeholder="Email"
          name="email"
          value={email}
          required
          onChange={(name, value) => setEmail(value)}
        />
        <Button
          label="Continue"
          onClick={() => null}
          type="flat"
          btnActionType="submit"
        />
        <button onClick={() => navigate('/login')} type="button" className="text-az-teal flex justify-center items-center m-auto cursor-pointer">
          <img src={GobackArrow} alt="" className="mr-2" />
          Go back to Login
        </button>
      </form>
    </UnAuthWrapper>
  );
}

export default ForgotPassword;
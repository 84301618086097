import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import SidePane from "../../../components/SidePane";
import NotFound from "../../../components/NotFound";
import DashboardNavItem from "../../../components/DashboardNavItem/DashboardNavItem";
import CreateIcon from '../../../assets/new/createIcon.png';
import { createReferral, getAllReferralSettings, updateReferral } from "../../../actions/adminActions";
import Button from "../../../components/Button";
import Input, { Select as SelectComponent } from "../../../components/Input";
import dayjs from "dayjs";
import AdminRefDetails from "../../../components/AdminRefDetails";

export const refEnums = {
  'Fund Wallet': 'fund_wallet',
  'Evergreen': 'evergreen',
  'Fixed': 'fixed',
  'Charges deduction': 'charges_deduction',
  'Active': 'active', 
  'Not active': 'not_active'
}

export const refEnumsInvert = {
 'fund_wallet': 'Fund Wallet',
  'evergreen': 'Evergreen',
  'fixed': 'Fixed',
  'charges_deduction': 'Charges deduction',
  'active': 'Active', 
  'not_active': 'Not active'
}

const AdminReferrals = () => {
    const [openCreate, setOpenCreate] = useState(false);
    const [referral, setReferral] = useState(null);
    const getReferrals = (page) => {
      getAllReferralSettings(page)
    }

    useEffect(() => {
      getReferrals(1);
    }, []);

    const expenseNavItemsObj = [
        {
          label: 'Create Referral',
          action: () => handleCreateRefundMe(),
          bgColor: '#0898A0',
          img: CreateIcon,
        }
      ];
    
      const handleCreateRefundMe = () => {
        setOpenCreate(true);
      }
    
      const renderNavItems = () => {
        return (
          <DashboardNavItem
            navItems={[]}
            navItemsObj={expenseNavItemsObj}
            tab={'1'}
            dontShowWithdraw={true}
            setTab={handleCreateRefundMe}
          />
        )
      }

      const closeReferralModal = () => {
        setOpenCreate(false);
        getReferrals(1);
      }
    return (
        <DashboardComponentWrapper
            subTitle="Referrals"
            pageDetails="Manage all Azatme referrals here."
            renderNavItems={renderNavItems}
        >
            <> 
                <AdminReferralList getAllReferrals={getReferrals} setReferral={(referral) =>  setReferral(referral)} />
                <SidePane onClose={() => setReferral(null)} open={!!referral} title={""}>
                  <AdminRefDetails referral={referral} setReferral={(referral) =>  setReferral(referral)} handleEditDone={() => setReferral(null)} />
                </SidePane>
                <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
                  <AdminCreateReferral handleDone={closeReferralModal} />
                </SidePane>
                <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
                  <AdminCreateReferral handleDone={closeReferralModal} />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminReferrals;

const AdminReferralList = (props) => {
    const {referrals} = useAppSelector((state) => state.admin);
    return (
      <div className="bg-[#ffffff]  border border-white">
        <div className="">
          <div className="">
            <div className='head bg-[#0898A01A] h-14 hidden lg:flex'>
              <div className='row flex flex-row justify-between !w-full items-center !h-14'>
                <div className='!w-[10%] pl-3'>Name</div>
                <div className='!w-[10%] pl-3'>Point Conversion</div>
                <div className='!w-[10%] pl-3'>Duration</div>
                <div className='!w-[12%] pl-3'>Start Date</div>
                <div className='!w-[12%] pl-3'>End Date</div>
                <div className='!w-[12%] pl-3'>Point Limit</div>
                <div className='!w-[12%] pl-3'>Referral point source</div>
                <div className='!w-[12%] pl-3'>Referral active point</div>
                <div className='!w-[12%] pl-3'>Status</div>
              </div>
            </div>
            <div className="body min-h-[50vh]">
              <div className="body-section ">
                {(!referrals || referrals?.data?.length === 0) ?
                  (
                    <NotFound 
                      text1="No referral in the system yet!"
                      text2='Manage all referrals sent in here.'
                      btnLabel=""
                      onClick={() => {}}
                    />
                  ) :
                  referrals?.data?.map((item, index) => {
                    return (
                      <Referral
                        key={item.id}
                        referral={item}
                        setReferral={props.setReferral}
                      />
                    );
                  })}
              </div>
            </div>
              {/* {
                  referrals?.data?.length !== 0 && (
                  <TablePaginationFooter 
                      total={referrals?.data.total}
                      from={referrals?.data.from}
                      to={referrals?.data.to}
                      nextUrl={referrals?.data.next_page_url}
                      prevUrl={referrals?.data.prev_page_url}
                      handlePagination={props.getReferrals}
                  />
                  )
              } */}
          </div>
        </div>
      </div>
    )
}

const pointSourceMap = {
  'Single Product': 'A_single_product', 
  'All product': 'Across_All_products'
}

export const AdminCreateReferral = (props) => {
    const [isEdit, setIsEdit] = useState(false);
    const [data, setData] = useState({
        // name: '',
        // description: '',
        status: '',
        point_limit: '0',
        start_date: '',
        duration: '',
        point_conversion: '',
        end_date: '',
        product_getting_point: '',
        referral_active_point: ''
      });

      useEffect(() => {
        if (props.referral) {
          setIsEdit(true);
          const {status, point_limit,  start_date, end_date, duration, point_conversion, product_getting_point, referral_active_point} = props.referral;
          setData({
            status: refEnumsInvert[status],
            point_limit,
            start_date,
            duration: refEnumsInvert[duration],
            point_conversion: refEnumsInvert[point_conversion],
            product_getting_point: pointSourceMap[product_getting_point],
            end_date,
            referral_active_point,
          })
        }
      }, [props.referral]);
    
      const handleChange = useCallback((name: string, value: string) => {
        setData((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }, []);


      const handleAddFormSubmit = (event) => {
        event.preventDefault();
        const newData = {
          ...data,
          status: refEnums[data.status],
          duration: refEnums[data.duration],
          point_conversion: refEnums[data.point_conversion],
          product_getting_point: pointSourceMap[data.product_getting_point],
          start_date: dayjs(data.start_date).format('YYYY-MM-DD'),
          end_date: dayjs(data.end_date).format('YYYY-MM-DD')
        }
        if (data.duration === 'Evergreen') {
          // @ts-ignore
          delete newData.end_date;
        }
        if (isEdit) {
          updateReferral(props.referral.id, newData, (resp) => {
            props.handleDone(false);
          });
          return;
        }
        createReferral(newData, (resp) => {
          props.handleDone(false);
        });
      }
    
    return (
        <div className="w-full">
            <h1 className="text-3xl">{isEdit ? '' : 'Create Referral'}</h1>
             <form onSubmit={handleAddFormSubmit} className="">
              {/* <Input
                label="Name"
                placeholder="Referral bundle"
                name="name"
                value={data.name}
                onChange={handleChange}
              /> */}
              <Input
                label="Point limit"
                placeholder="5"
                name="point_limit"
                value={data.point_limit}
                onChange={handleChange}
              />
              <SelectComponent
                data={['Evergreen', 'Fixed']}
                label="Duration"
                placeholder="Evergreen"
                name="duration"
                required
                value={data.duration}
                onChange={handleChange}
            />
            <SelectComponent
                data={['Fund Wallet', 'Charges deduction']}
                label="Point conversion"
                placeholder="Fund wallet"
                name="point_conversion"
                value={data.point_conversion}
                onChange={handleChange}
            />
            <SelectComponent
                data={['Active', 'Not active']}
                label="Status"
                placeholder="Active"
                name="status"
                value={data.status}
                onChange={handleChange}
            />
            <Input
              label="Referral active point"
              placeholder="5"
              type="number"
              name="referral_active_point"
              value={data.referral_active_point}
              onChange={handleChange}
            />
            <SelectComponent
              data={['Single Product', 'All product']}
              label="Referral point source"
              placeholder=""
              name="product_getting_point"
              value={data.product_getting_point}
              onChange={handleChange}
            />
            <Input
              label="Select Starting Date"
              placeholder=""
              name="start_date"
              type="date"
              required
              value={data.start_date}
              onChange={handleChange}
            />
            {
              data.duration === 'Fixed' && (
                <Input
                  label="End Date"
                  placeholder=""
                  name="end_date"
                  type="date"
                  required
                  value={data.end_date}
                  onChange={handleChange}
                />
              )
            }
              <Button
                label={isEdit ? 'Update' : 'Create'}
                type="flat"
                btnActionType="submit"
              />
            </form>
        </div>
    )
}

export const Referral = ({ referral, setReferral }: any) => {
    return (
      <>
        <div className='row flex-row w-full  hidden lg:flex cursor-pointer hover:bg-az-faint-red' onClick={() => setReferral(referral)}>
          <div className='w-[10%] pl-3'>{referral.name}</div>
          <div className='w-[10%] pl-3'>{refEnumsInvert[referral.point_conversion]}</div>
          <div className='w-[12%] pl-3'>{refEnumsInvert[referral.duration]}</div>
          <div className='w-[12%] pl-3'>{referral.start_date}</div>
          <div className='w-[12%] pl-3'>{referral.end_date}</div>
          <div className='w-[12%] pl-3'>{referral.point_limit}</div>
          <div className='w-[12%] pl-3'>{referral.product_getting_point}</div>
          <div className='w-[12%] pl-3'>{referral.referral_active_point}</div>
          <div className='w-[10%]'>{refEnumsInvert[referral.status]}</div>
        </div>
        <div  onClick={() => setReferral(referral)} className='row rounded !flex-col !p-4 !justify-start !items-start !m-auto flex my-2 lg:hidden border border-gray !min-h-[100px] !h-max !w-[96%] cursor-pointer'>
            <div className='w-[5%]'>{referral.name}</div>
            <div className='w-[5%]'>{refEnumsInvert[referral.point_conversion]}</div>
            <div className='w-[5%]'>{refEnumsInvert[referral.duration]}</div>
            <div className='w-[10%] pl-3'>{referral.start_date}</div>
            <div className='w-[10%] pl-3'>{referral.end_date}</div>
            <div className='w-[10%] pl-3'>{referral.point_limit}</div>
            <div className='w-[10%] pl-3'>{referral.product_getting_point}</div>
            <div className='w-[10%] pl-3'>{referral.referral_active_point}</div>
            <div className='w-[10%]'>{refEnumsInvert[referral.status]}</div>
        </div> 
      </>
    )
  }

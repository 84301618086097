import DashboardSideBar from '../../components/DashboardSideBar';
import BusinessIcon from '../../assets/business1.png';
import HomeSVG from '../../components/SVG/Home';
import BankSVG from '../../components/SVG/Bank';
import './business.scss';
import { Outlet } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useEffect } from 'react';
import { getBusinesses, getNQRMerchants } from '../../actions/businessActions';

const Business = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const business = useAppSelector((state) => state.business);

  useEffect(() => {
    !business.businesses && getBusinesses(dispatch, 1, auth?.user?.id);
    !business.nqrs && getNQRMerchants();
  }, [auth?.user?.id, business.businesses]);

  const navItems = [
    {
      name: "businesses",
      path: `/business`,
      title: "Businesses",
      img: BusinessIcon,
      Icon: HomeSVG,
    },
    {
      name: "bank",
      path: `/business/bank`,
      title: "Bank",
      img: '',
      Icon: BankSVG,
    },
    // {
    //   name: "nqr",
    //   path: `/business/nqr/opt-in`,
    //   title: "NGR",
    //   img: NQR,
    // },
  ];

  return (
    <div className="dashboard">
      <DashboardSideBar firstItem="Businesses" items={navItems} />
      <div className="outlet">
        <Outlet />
      </div>
    </div>
  );
};

export default Business;

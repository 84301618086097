import './builder.scss';
import CallToAction from "../Components/Personal/CallToAction";
import FAQ from "../Components/Personal/FAQ";
import CoilArrow from '../../../assets/new/productImgs/whitearrow.png';
import { useNavigate } from "react-router-dom";
import { Reason } from '../../../types';
import { Link } from 'react-router-dom';

interface IProps {
    title: string;
    name: string;
    tagline: string;
    image: string;
    imgText: string[];
    summary: string;
    titleIcon?: string;
    reasons?: Array<Reason>;
    howItWorks?: Array<Record<string, string>>;
    benefits?: Array<Record<string, string>>;
    faq: Array<Record<string, string| any>>;
}


const PageBuilder = (props: IProps) => {
    const navigate = useNavigate();

    return (
        <div className="w-screen h-fit">
            <div className="w-screen bg-[#0898A008] min-h-[calc(100vh)] h-fit pt-28" id='builder-hero'>
                <div className="flex flex-col md:flex-row px-[6%] sm:px-[6%] items-center justify-between pt-6">
                    <div className="text-left w-full h-fit">
                        <div className="flex flex-row h-9 my-4 bg-[#0898A01A] rounded-full w-fit p-2 items-center text-xs">
                            <p className="bg-az-teal rounded-full h-[30px] w-[30px] flex flex-row justify-center items-center text-white font-medium px-2 py-2">
                                <img src={props.titleIcon} alt="" className='' />
                            </p>
                            <p className="px-2 font-medium text-sm text-white">{props.name}</p>
                        </div>
                        <h1 className="font-Onest text-5xl font-medium w-full xl:w-[500px] leading-[56px] text-white">{props.title}</h1>
                        <p className="font-Onest text-base  w-full xl:w-[450px] my-8  text-white">{props.tagline}</p>
                        <div className="flex flex-row justify-start items-center">
                            <button className="bg-white text-az-teal w-40 h-10 rounded font-medium font-Onest" onClick={() => navigate('/register')}>Get Started</button>
                            <img src={CoilArrow} alt="" className="mx-10 mb-6" />
                        </div>
                    </div>
                    <div className="relative w-[100vw] h-[80vh] flex flex-row justify-center items-center">
                        <img src={props.image} alt="" className="absolute w-10/12 h-[60%] lg:h-[80%] object-contain z-10" />
                    </div>
                </div>
            </div>
            <div className='w-full p-[6%] text-left'>
                <p className='text-sm font-medium px-8 py-4 text-az-black-900 bg-[#0898A01A] w-fit rounded-full uppercase'>Why use {props.name}?</p>
                <p className='text-4xl text-az-black-900 w-full max-w-[520px] my-14'>{props.summary}</p>
                <div className='flex flex-row justify-between flex-wrap w-full'>
                    {
                        props.reasons?.map((itm, index) => (
                            <div key={index} style={{background: itm.bg}} className='flex flex-col justify-between h-[540px] max-w-[390px] w-full text-left rounded-[20px]'>
                                <div className='h-[60%] p-10'>
                                    <p className='text-2xl font-medium w-[86%]'>{itm.title}</p>
                                    <p className='my-6'>{itm.details}</p>
                                </div>
                                <img className='h-[45%] object-cover w-full rounded-br-[20px] rounded-bl-[20px]' src={itm.img} alt="" />
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='w-screen p-[7%] bg-[#0898A01A] min-h-[70vh] h-fit relative flex flex-col justify-center items-center' id='howitworks'>
                <p className='text-sm font-medium uppercase bg-az-light-red p-3 px-7 rounded-full mb-8'>How to get Started</p>
                <p className='text-4xl font-medium mb-20'>Get started in minutes</p>
                <div className='flex flex-row flex-wrap justify-around w-full relative'>
                    <div className='w-1 md:w-9/12 bg-[#0898A01A] h-[80%] md:h-1 absolute top-8' />
                    {
                        props.howItWorks?.map((itm, index) => (
                            <div className='flex flex-col justify-center items-center w-full max-w-[300px] h-full max-h-[300px] my-6 md:my-0' key={itm.title}>
                                <div className='w-[70px] h-[70px] bg-[#0898A01A] rounded-full flex justify-center items-center mb-7'>
                                    <div  className='w-[50px] h-[50px] bg-az-teal text-white flex justify-center items-center rounded-full font-medium text-xl'>{index + 1}</div>
                                </div>
                                <div className='z-10 bg-[#EAF4F5]'>
                                    <p className='text-xl font-medium mb-5'>{itm.title}</p>
                                    <p className='text-sm w-[276px] font-normal text-center'>{itm.details}</p>
                                </div>
                            </div>
                        ))
                    }
                    
                </div>
                <button className='text-white text-sm bg-az-teal px-8 py-4 rounded mt-20'><Link to="/register">Get started in minutes</Link></button>
            </div>
            <div className="min-h-[50vh] h-fit px-[6%] flex flex-col justify-start items-start mt-28">
                <p className="text-az-black-900 px-8 py-4 rounded-full bg-[#0898A01A] uppercase font-medium">Benefits</p>
                <p className="text-4xl text-az-black-900 max-w-[600px] text-left font-medium leading-[45px] my-10">
                    Why you should use the AzatMe {props.name}?
                </p>
                <div className="flex flex-row flex-wrap w-full h-fit justify-center lg:justify-between items-center">
                    {
                        props.benefits?.map((benefit) => (
                            <div className="benefit-card w-[380px] h-[300px] p-10 flex flex-col justify-between items-start text-left rounded-2xl my-10">
                                <img className='w-[70px] h-[70px]' src={benefit.img} alt="" />
                                <p className="text-[20px] leading-[26px] font-medium text-white my-4">{benefit.title}</p>
                                <p className="text-sm text-white font-normal opacity-80">{benefit.details}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <FAQ faqs={props.faq} />
            <CallToAction />
        </div>
    )
}

export default PageBuilder;

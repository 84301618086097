import { useEffect } from "react";
import ContentBuilder from "../../components/ContentBuilder";
import agreement from "../../data/agreement";
import otherTerms from "../../data/otherTerms";
import { scrollUp } from "../../utils/common";


const UserAgreement = () => {
  useEffect(() => {
    scrollUp();
  }, []);
  return (
    <>
      <ContentBuilder data={agreement} />
      <ContentBuilder data={otherTerms} />
    </>
  );
};

export default UserAgreement;

import './benefits.scss';
import Why1 from '../../../../../assets/new/why1.png';
import Why2 from '../../../../../assets/new/productImgs/support.png';
import Why3 from '../../../../../assets/new/productImgs/card-payment.png';

const Benefits = () => {
    const benefits = [
        {
            title: 'Approved and Secure',
            details: `AzatMe uses up-to-date cybersecurity 
            technology to protect your information and
            prevent unauthorised use.`,
            img: Why1
        },
        {
            title: 'Personalized Support',
            details: `We’re always here to talk. Reach our support
            team however you need to - by phone, email,
            in the app, or check out the Help Center.`,
            img: Why2
        },
        {
            title: 'Quick Withdrawal',
            details: `Facilitate faster and more efficient payouts/
            withdrawals to enhance the overall user
            experience.`,
            img: Why3
        }
    ]
    return (
        <div className="min-h-[50vh] w-full h-fit p-[6%] px-[6%] flex flex-col justify-start items-start mt-28 bg-[#042527]">
            <p className="text-white px-8 py-4 rounded-full bg-[#1D3B3D] uppercase font-medium">Benefits</p>
            <p className="text-4xl text-white max-w-[600px] text-left font-medium leading-[45px] my-10">
                Why you should use the AzatMe
                Personal?
            </p>
            <div className="flex flex-row gap-10 flex-wrap w-full h-fit justify-center lg:justify-between items-center">
                {
                    benefits.map((benefit) => (
                        <div className="benefit-card w-[380px] h-[370px] p-10 py-14 flex flex-col justify-between items-start rounded-2xl ">
                            <img src={benefit.img} alt="" className='w-[100px] h-[90px]' />
                            <p className="text-[24px] leading-[26px] font-medium text-white mt-4 mb-2 text-left">{benefit.title}</p>
                            <p className="text-base text-white font-normal text-left">{benefit.details}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Benefits;

import PageBuilder from "./ProductBuilder"
import Hero1 from '../../assets/new/productImgs/AjoHero.png';
import AjoIcon from '../../assets/new/productImgs/user.png';
import Why2 from '../../assets/new/why1.png';
import { Reason } from "../../types";
import Ajo1 from '../../assets/new/productImgs/ajo1.png';
import Ajo2 from '../../assets/new/productImgs/ajo2.png';
import Ajo3 from '../../assets/new/productImgs/ajo3.png';
import Investment from '../../assets/new/productImgs/investment-report.png';
import CardPaymeny from '../../assets/new/productImgs/card-payment.png';
import Security from '../../assets/new/productImgs/security.png';
import Ring from '../../assets/new/productImgs/ring-abstract-shape.png';
import Trust from '../../assets/new/productImgs/trust-wallet-token1.png';
import { ajo } from "../../data/faqData";

const AjoPage = () => {
    const reasons: Reason[] = [
        {
            title: `Financial 
            Empowerment`,
            details: `Ajo provides a structured way for individuals, 
            especially those with limited incomes, to save and 
            accumulate funds they may not have been able to 
            on their own. It's an empowering financial tool.`,
            img: Ajo1,
            bg: '#FFF9F0'
        },
        {
            title: `Quick Rotational
            Access  `,
            details: `Rather than long-term locked savings, Ajo provides
            quicker rotational access to the pooled funds which
            can help meet short-term needs or goals.`,
            img: Ajo2,
            bg: '#FFEFFE'
        },
        {
            title: `No Complex 
            Requirements`,
            details: `Unlike traditional banking products, Ajo has few
            bureaucratic requirements, making it easy for
            anyone to participate and self-organize groups.`,
            img: Ajo3,
            bg: '#F5F8FF'
        }
    ];
    const howItWorks = [
        {
            title: 'Sign up',
            details: `Create account in a few clicks,
            it's that simple.`
        },
        {
            title: 'Group Formation',
            details: `Create a group with trusted and reliable
            members of your friends or family.`
        },
        {
            title: 'Collection and Distribution',
            details: `Designate date of each period, the Fund is 
            collected from the account of each member.`
        },
        {
            title: 'The Cycle Continues',
            details: `After each member receives their payout, the 
            process restarts with a new contribution cycle.`
        },
    ];
    const benefits = [
        {
            title: 'Consistency',
            details: `Regular contributions become a routine, making
            it easier to save money consistently, even for
            those who find it challenging on their own.`,
            img: Investment
        },
        {
            title: 'Reaching Larger Goals',
            details: `This help achieve bigger financial goals you might
            not be able to reach quickly on your own.`,
            img: CardPaymeny
        },
        {
            title: 'Flexibility',
            details: `Tailor contribution amounts to accommodate
            different income levels and budgets.`,
            img: Ring
        },
        {
            title: 'Security',
            details: `Integrates secure payment gateways to protect
            users' financial information during refund 
            transactions.`,
            img: Security
        },
        {
            title: 'Record Keeping',
            details: `AzatMe Ajo platform offers features for secure
            and convenient contribution, record-keeping, 
            and communication.`,
            img: Why2
        },
        {
            title: 'Transparency and Trust',
            details: `A well-run Ajo operates with clear rules and 
            transparency about how funds are managed, 
            building trust among members.`,
            img: Trust
        }
    ]
    
    return (
        <PageBuilder
            title="Save together, achieve together"
            titleIcon={AjoIcon}
            name={"Ajo"}
            tagline={`Unlock the power of collective savings with Ajo - a traditional
            community pooled funds-driven system among members, that
            empowers you to achieve your financial goals together.`}
            image={Hero1}
            imgText={[]}
            summary={`Save Together & Reach Goals Faster`}
            reasons={reasons}
            howItWorks={howItWorks}
            benefits={benefits}
            faq={ajo}
        />
    );
};

export default AjoPage;

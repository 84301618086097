import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";

const AdminTransactions = () => {
    return (
        <DashboardComponentWrapper
            subTitle="Transactions"
            pageDetails="Here's an overview of all Transactions on Azatme."
        >
            <div>
                    Admin Transactions
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminTransactions;

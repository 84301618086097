import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import getBaseURL from '../utils/getBaseURL';
import type { CategoryPayload, LoginPayload, LoginResponse } from './types'


export const azatmeApi = createApi({
  reducerPath: 'azatme',
  baseQuery: fetchBaseQuery({ baseUrl: getBaseURL() as string }),

  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (data) => ({
        url: 'AttemptLogin',
        method: 'POST',
        body: data,
      }),
    }),

    getCategories: builder.query<CategoryPayload[], void>({
      query: category => ({
        url: 'getCateList',
        method: 'GET',
        body: category
      })
    }),

    createCategory: builder.mutation<{}, CategoryPayload>({
      query: category => ({
        url: 'category',
        method: 'POST',
        body: category
      })
    })
  }),
})

export const { useLoginMutation, useCreateCategoryMutation, useGetCategoriesQuery } = azatmeApi;

const RefundMeSVG = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 19.75C13.5 19.087 13.7634 18.4511 14.2322 17.9822C14.7011 17.5134 15.337 17.25 16 17.25H17C17.1326 17.25 17.2598 17.3027 17.3536 17.3964C17.4473 17.4902 17.5 17.6174 17.5 17.75V19.75C17.5 20.1478 17.342 20.5294 17.0607 20.8107C16.7794 21.092 16.3978 21.25 16 21.25H14C13.8674 21.25 13.7402 21.1973 13.6464 21.1036C13.5527 21.0098 13.5 20.8826 13.5 20.75V19.75ZM16 18.25C15.6022 18.25 15.2206 18.408 14.9393 18.6893C14.658 18.9706 14.5 19.3522 14.5 19.75V20.25H16C16.1326 20.25 16.2598 20.1973 16.3536 20.1036C16.4473 20.0098 16.5 19.8826 16.5 19.75V18.25H16ZM10.5 19.75C10.5 19.087 10.2366 18.4511 9.76777 17.9822C9.29893 17.5134 8.66304 17.25 8 17.25H7C6.86739 17.25 6.74021 17.3027 6.64645 17.3964C6.55268 17.4902 6.5 17.6174 6.5 17.75V19.75C6.5 20.1478 6.65804 20.5294 6.93934 20.8107C7.22064 21.092 7.60218 21.25 8 21.25H10C10.1326 21.25 10.2598 21.1973 10.3536 21.1036C10.4473 21.0098 10.5 20.8826 10.5 20.75V19.75ZM8 18.25C8.39782 18.25 8.77936 18.408 9.06066 18.6893C9.34196 18.9706 9.5 19.3522 9.5 19.75V20.25H8C7.86739 20.25 7.74021 20.1973 7.64645 20.1036C7.55268 20.0098 7.5 19.8826 7.5 19.75V18.25H8ZM12 10.75C11.7348 10.75 11.4804 10.8554 11.2929 11.0429C11.1054 11.2304 11 11.4848 11 11.75C11 12.0152 11.1054 12.2696 11.2929 12.4571C11.4804 12.6446 11.7348 12.75 12 12.75C12.2652 12.75 12.5196 12.6446 12.7071 12.4571C12.8946 12.2696 13 12.0152 13 11.75C13 11.4848 12.8946 11.2304 12.7071 11.0429C12.5196 10.8554 12.2652 10.75 12 10.75ZM10 11.75C10 11.2196 10.2107 10.7109 10.5858 10.3358C10.9609 9.96071 11.4696 9.75 12 9.75C12.5304 9.75 13.0391 9.96071 13.4142 10.3358C13.7893 10.7109 14 11.2196 14 11.75C14 12.2804 13.7893 12.7891 13.4142 13.1642C13.0391 13.5393 12.5304 13.75 12 13.75C11.4696 13.75 10.9609 13.5393 10.5858 13.1642C10.2107 12.7891 10 12.2804 10 11.75Z" fill="#222222" fillOpacity="0.7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.5 4.25C1.5 3.85218 1.65804 3.47064 1.93934 3.18934C2.22064 2.90804 2.60218 2.75 3 2.75H21C21.3978 2.75 21.7794 2.90804 22.0607 3.18934C22.342 3.47064 22.5 3.85218 22.5 4.25V9.25C22.5 9.64782 22.342 10.0294 22.0607 10.3107C21.7794 10.592 21.3978 10.75 21 10.75H17.5C17.3674 10.75 17.2402 10.6973 17.1464 10.6036C17.0527 10.5098 17 10.3826 17 10.25C17 10.1174 17.0527 9.99021 17.1464 9.89645C17.2402 9.80268 17.3674 9.75 17.5 9.75H21C21.1326 9.75 21.2598 9.69732 21.3536 9.60355C21.4473 9.50979 21.5 9.38261 21.5 9.25V4.25C21.5 4.11739 21.4473 3.99021 21.3536 3.89645C21.2598 3.80268 21.1326 3.75 21 3.75H3C2.86739 3.75 2.74021 3.80268 2.64645 3.89645C2.55268 3.99021 2.5 4.11739 2.5 4.25V9.25C2.5 9.38261 2.55268 9.50979 2.64645 9.60355C2.74021 9.69732 2.86739 9.75 3 9.75H6.5C6.63261 9.75 6.75979 9.80268 6.85355 9.89645C6.94732 9.99021 7 10.1174 7 10.25C7 10.3826 6.94732 10.5098 6.85355 10.6036C6.75979 10.6973 6.63261 10.75 6.5 10.75H3C2.60218 10.75 2.22064 10.592 1.93934 10.3107C1.65804 10.0294 1.5 9.64782 1.5 9.25V4.25Z" fill="#222222" fillOpacity="0.7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.5 6.75C6.5 6.61739 6.55268 6.49021 6.64645 6.39645C6.74021 6.30268 6.86739 6.25 7 6.25H17C17.1326 6.25 17.2598 6.30268 17.3536 6.39645C17.4473 6.49021 17.5 6.61739 17.5 6.75V19.75C17.5 20.1478 17.342 20.5294 17.0607 20.8107C16.7794 21.092 16.3978 21.25 16 21.25H8C7.60218 21.25 7.22064 21.092 6.93934 20.8107C6.65804 20.5294 6.5 20.1478 6.5 19.75V6.75ZM7.5 7.25V19.75C7.5 19.8826 7.55268 20.0098 7.64645 20.1036C7.74021 20.1973 7.86739 20.25 8 20.25H16C16.1326 20.25 16.2598 20.1973 16.3536 20.1036C16.4473 20.0098 16.5 19.8826 16.5 19.75V7.25H7.5Z" fill="#222222" fillOpacity="0.7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.5 7.25H4.5V6.25H19.5V7.25Z" fill="#222222" fillOpacity="0.7"/>
        </svg>
    )
};

export default RefundMeSVG;

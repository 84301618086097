import PageBuilder from "./ProductBuilder"
import Hero1 from '../../assets/new/productImgs/Kontribute.png';
import Icon from '../../assets/new/productImgs/user.png';
import RefProd2 from '../../assets/new/refProd2.png';
import RefProd1 from '../../assets/new/refProd1.png';
import RefProd3 from '../../assets/new/refProd3.png';
import Why1 from '../../assets/new/why1.png';
import Why2 from '../../assets/new/why1.png';
import { Reason } from "../../types";
import Convenience from '../../assets/new/productImgs/investment-report.png';
import CardPaymeny from '../../assets/new/productImgs/card-payment.png';
import Security from '../../assets/new/productImgs/security.png';
import Ring from '../../assets/new/productImgs/ring-abstract-shape.png';
import { kontribute } from "../../data/faqData";

const KontributePage = () => {
    const reasons: Reason[] = [
        {
            title: `Reach Your Financial
            Goals`,
            details: `Set clear objectives and track progress toward 
            achieving them. Stay motivated and organized with
            tools designed to help you reach your aspirations.`,
            img: RefProd2,
            bg: '#FFF9F0'
        },
        {
            title: `Community and 
            Collaboration`,
            details: `Kontribute fosters a sense of community and shared
            purpose as you work toward your goal with others.`,
            img: RefProd1,
            bg: '#FFEFFE'
        },
        {
            title: `Credibility, Transparency,
            and Accountability`,
            details: `The platform provides tools for clear communication
            and tracking progress toward your goal.`,
            img: RefProd3,
            bg: '#F5F8FF'
        }
    ];
    const howItWorks = [
        {
            title: 'Sign up',
            details: `Complete your profile and get 
            verified to get started.`
        },
        {
            title: 'Create a Campaign',
            details: `Define your financial goal and clearly
            outline what you're raising funds for.`
        },
        {
            title: 'Collect Contributions',
            details: `Azatme integrates with a secure payment
            gateway to facilitate donations.`
        },
        {
            title: 'Track Progress',
            details: `Keep an eye on your campaign's progress
            with detailed analytics.`
        },
    ];
    const benefits = [
        {
            title: 'Convenience',
            details: `No more fiddling with cash or complicated 
            calculations.`,
            img: Convenience
        },
        {
            title: 'Payment Flexibility',
            details: `RefundMe supports various payment methods
            (cards, mobile wallets, bank transfers etc.), giving
            users flexibility in how they refund.`,
            img: CardPaymeny
        },
        {
            title: 'Time Savings',
            details: `It saves a lot of time and hassle compared to
            traditional methods of splitting bills.`,
            img: Why1
        },
        {
            title: 'Security',
            details: `Integrates secure payment gateways to protect
            users' financial information during refund 
            transactions.`,
            img: Security
        },
        {
            title: 'Record Keeping',
            details: `The app records refund requests, and amounts
            paid/owed for better expense tracking across
            the group.`,
            img: Why2
        },
        {
            title: 'Transparency',
            details: `Everyone sees the total bill and their share 
            clearly within the app.`,
            img: Ring
        }
    ]
    
    return (
        <PageBuilder
            title="Empower Your Financial Aspirations with Kontribute"
            name={"Kontribute"}
            titleIcon={Icon}
            tagline={`Unlock the power of collective crowdfunding with Kontribute - a 
            collaborative platform that empowers you to raise funds and reach
            your financial goals together.`}
            image={Hero1}
            imgText={[]}
            summary={`Make your financial dreams a
            reality with Kontribute!`}
            reasons={reasons}
            howItWorks={howItWorks}
            benefits={benefits}
            faq={kontribute}
        />
    );
};

export default KontributePage;

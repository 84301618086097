import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import RefundMe from '../../assets/new/refundmehome.png';
import Kontribute from '../../assets/new/kontribute.png';
import Support from '../../assets/new/supporthome.png';
import { Link } from "react-router-dom";
import { openWhatsapp } from "../../App";

const DashboardHome = () => {
    const data = [
        {
            title: 'Refund Me',
            description: 'Effortlessly receive your refunds from others within minutes using our user friendly platform.',
            link: '/dashboard/refundme',
            linkText: 'Refund Me Now',
            color: '#0898A01A',
            img: RefundMe,
        },
        {
            title: 'Kontribute',
            description: 'Collaboratively raise funds towards your goals and explore boundless opportunities in crowdfunding together. ',
            link: '/dashboard/kontribute',
            linkText: 'Kontribute Now',
            color: '#0898A01A',
            img: Kontribute,
        },
        {
            title: 'Need help?',
            description: 'Require assistance or have inquiries? Reach out to our dedicated support team.',
            link: '/dashboard',
            linkText: 'Contact support',
            color: '#1D0000',
            img: Support,
            textColor: '#fff',
            handleLinkClick: openWhatsapp
        },
    ]
    return (
        <DashboardComponentWrapper
            // title='Overview'
            subTitle={``}
            pageDetails=""
        >
        <div className="w-full flex flex-col h-screen justify-start py-10">
            <div className="mb-6">
                <p className="text-4xl">Welcome to Azatme</p>
                <p className="text-lg my-2">Here's a few things to help you get started on Azatme.</p>
            </div>
            <div className="flex flex-row mt-6 justify-center flex-wrap">
                {
                    data.map((itm) => (
                        <Link to={itm.link} key={itm.title}>
                            <div
                                className={`m-2 h-[400px] flex flex-col justify-start items-start w-full max-w-[330px] p-8 rounded-md relative`}
                                style={{
                                    backgroundColor: itm.color,
                                    color: itm.textColor,
                                }}
                                onClick={(event) => {
                                    if (itm.handleLinkClick) {
                                        event.stopPropagation();
                                        itm.handleLinkClick();
                                    }
                                }}
                            >
                                <p className="text-2xl">{itm.title}</p>
                                <p className="text-left text-sm my-4">{itm.description}</p>
                                <button className="!w-fit pr-10 bg-az-teal text-white rounded h-9 !pl-4 text-sm flex flex-row justify-start items-center">
                                    {itm.linkText}
                                    <svg className="ml-3" xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                        <path d="M14.2812 6.25977L1.15625 6.25977" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8.9873 0.988374L14.2811 6.25937L8.9873 11.5312" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <img
                                    src={itm.img} alt=""
                                    className="h-[150px] absolute bottom-0 self-center object-contain"
                                />
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
        </DashboardComponentWrapper>
    )
};

export default DashboardHome;

const ProfileSVG = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.47717 2 2 6.47717 2 12C2 17.5228 6.47717 22 12 22C17.5202 21.9937 21.9937 17.5202 22 12C22 6.47717 17.5228 2 12 2ZM12 21C9.60071 21 7.42639 20.0552 5.81299 18.5248C6.46436 16.4861 8.07373 14.8932 10.1278 14.2755C13.5301 13.2524 17.1115 15.1526 18.1881 18.5238C16.5746 20.0547 14.3998 21 12 21ZM9 10C9 8.34314 10.3431 7 12 7C13.6561 7.00183 14.9982 8.34387 15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10ZM18.9521 17.7151C18.1132 15.622 16.379 14.0023 14.2025 13.3335C15.2834 12.6174 15.9985 11.3935 16 10C16 7.79083 14.2092 6 12 6C9.79083 6 8 7.79083 8 10C8 11.3925 8.71313 12.6162 9.79248 13.3326C7.68726 13.9832 5.90643 15.55 5.04279 17.7083C3.76678 16.1548 3 14.1672 3 12C3 7.02942 7.02942 3 12 3C16.9683 3.00562 20.9944 7.03174 21 12C21 14.1702 20.2314 16.1607 18.9521 17.7151Z" fill="#222222" fillOpacity="0.7"/>
        </svg>

    )
};

export default ProfileSVG;

// import FAQsFolder from "../../components/FAQsFolder";
import { scrollUp } from "../../utils/common";
import "./faqs.scss";
import { useEffect } from "react";

// const FaqsData = [
//   {
//     title: "Question 1",
//     content:
//       "Make sure you’re trying to sign in to the right application system. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumharum ducimus voluptatem dolorum id, natus deleniti ratione laborum voluptatibus! Commodi placeat, voluptatem doloribus iure aliquid sint dolores qui incidunt.",
//   },
//   {
//     title: "Question 2",
//     content:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumharum ducimus voluptatem dolorum id, natus deleniti ratione laborum voluptatibus! Commodi placeat, voluptatem doloribus iure aliquid sint dolores qui incidunt. Ea?",
//   },
//   {
//     title: "Question 3",
//     content:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumharum ducimus voluptatem dolorum id, natus deleniti ratione laborum voluptatibus! Commodi placeat, voluptatem doloribus iure aliquid sint dolores qui incidunt. Ea?",
//   },
//   {
//     title: "Question 4",
//     content:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumharum ducimus voluptatem dolorum id, natus deleniti ratione laborum voluptatibus! Commodi placeat, voluptatem doloribus iure aliquid sint dolores qui incidunt. Ea?",
//   },
//   {
//     title: "Question 5",
//     content:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrumharum ducimus voluptatem dolorum id, natus deleniti ratione laborum voluptatibus! Commodi placeat, voluptatem doloribus iure aliquid sint dolores qui incidunt. Ea?",
//   },
// ];

const FAQs = () => {
  useEffect(() => {
    scrollUp();
  }, []);
  return (
    <div className="container">
      <div className="wrapper">
          <div className="title-wrapper">
            <h2>Coming Soon</h2>
            {/* <h2>Frequently Asked Questions</h2> */}
            {/* <p>Need answers? Find them here...</p> */}
          </div>
        
        {/* <FAQsFolder data={Faqs Data} /> */}
      </div>
    </div>
  );
};

export default FAQs;

export const refundme = [
    {
      "que": "What is RefundMe?",
      "ans": "RefundMe is a feature designed to help friends, colleagues, and acquaintances split their bills after events like funfairs, gatherings, and more. It allows users to refund money back to the source easily and efficiently."
    },
    {
      "que": "How does RefundMe work?",
      "ans": "One person pays the bill for the group outing, enters the total amount and everyone else in the app, and RefundMe facilitates the transfer of individual shares from your friends to you."
    },
    {
      "que": "Can I use RefundMe for any type of group expense?",
      "ans": "Yes, RefundMe can be used for a wide range of group expenses, including dining out, trips, shared subscriptions, gifts, and more."
    },
    {
      "que": "Do participants need to have a RefundMe account to pay?",
      "ans": "No, participants do not need to create a RefundMe account before making a payment to the recipient. They can use the payment link provided in the received email to complete the transaction."
    },
    {
      "que": "How do I create a group on RefundMe?",
      "ans": "To create a group on RefundMe, simply supply the emails of your friends or colleagues. These emails will be used to invite them to the group, allowing everyone to participate in the refund process."
    },
    {
      "que": "Can I split the bill unevenly?",
      "ans": "Yes, RefundMe allows you to specify percentages or custom amounts for each person based on what they ordered, enabling an uneven split of the bill."
    },
    {
      "que": "What happens if someone doesn't pay their share?",
      "ans": "If someone doesn't pay their share, you may need to remind them manually. RefundMe keeps track of outstanding balances but does not automatically enforce payments."
    },
    {
      "que": "What payment methods does RefundMe accept?",
      "ans": "Payments are made via transfer to a virtual account number provided by the RefundMe payment gateway."
    },
    {
      "que": "Are there any fees for using RefundMe?",
      "ans": "There may be a small transaction fee (either flat or percentage-based) that RefundMe charges to process payments. Additionally, RefundMe may offer a free service with a premium tier that includes extra features. Outward transfers from collections to your account are FREE."
    },
    {
      "que": "How long do transfers take?",
      "ans": "The time taken should be instant. However, various potential limitations affect interbank transfers, which may delay the appearance of the money in your account."
    },
    {
      "que": "How is the total bill amount split among the group?",
      "ans": "By default, RefundMe splits the total bill amount equally among all group members. However, you can customize the split amounts if some members need to pay different shares."
    },
    {
      "que": "Can I set a refund deadline?",
      "ans": "No, you cannot set a specific refund deadline. However, the refund link expires after 42 hours."
    },
    {
      "que": "Can I make adjustments after a refund request is sent?",
      "ans": "No, you cannot adjust amounts once a RefundMe transaction has been initiated."
    },
    {
      "que": "How do group invitations work?",
      "ans": "When a refund request is initiated, the provided emails are used to send invite/payment emails to the group members or friends, inviting them to participate in the refund process."
    },
    {
      "que": "How do I track the status of my payment requests?",
      "ans": "You can monitor the status of your payment requests through the RefundMe platform on the RefundMe History page."
    },
    {
      "que": "Can I cancel a payment request?",
      "ans": "No, once the payment request is initiated and a payment link is generated and sent to the participants, you cannot cancel or revert the process."
    },
    {
      "que": "How does RefundMe protect my financial information?",
      "ans": "RefundMe uses banking-grade security practices, such as encryption, to protect users' payment credentials and transaction data."
    },
    {
      "que": "Is RefundMe safe?",
      "ans": "YES!! RefundMe uses secure technology to protect your financial information and ensure safe transactions. Every data is adequately and privately protected. No data is shared with third parties."
    },
    {
      "que": "What if I need to cancel or dispute a refund?",
      "ans": "RefundMe's policies cover situations such as processing refunds sent in error or disputing transactions for fraud reasons. You can contact customer support for assistance with cancellations or disputes."
    }
];
  
export const mPOS = [
    {
      "que": "What is AzatMe Mobile POS (mPOS) System?",
      "ans": "The AzatMe Mobile POS System is a wireless device (tablet, smartphone, etc.) that functions like an electronic point of sale terminal or cash register. It allows customers to make payments remotely or on the go, providing a flexible and convenient payment experience."
    },
    {
      "que": "How do I log in to the AzatMe mPOS system?",
      "ans": "Log in via your browser from any device at http://AzatMe.com. Use the same credentials you used to create your AzatMe account."
    },
    {
      "que": "How do I create a payment using the mPOS system?",
      "ans": "Follow these steps:\n1. Log in to your account on AzatMe.com.\n2. Click on \"Business\".\n3. On the sidebar, select \"mPOS\" and click on the \"CREATE PAYMENT\" button.\n4. Enter the payment amount and click the \"create\" button.\n5. You will then see a payment pending screen."
    },
    {
      "que": "How is the payment processed?",
      "ans": "After creating the payment, click the \"SHOW QR CODE\" button to generate a digital QR code. Your customer can then scan this QR code to open the PayThru payment gate, which will display the payment options, including the amount and transaction fees. Once the customer completes the payment, the merchant device will change to GREEN to indicate the successful transaction."
    },
    {
      "que": "How does the customer make the payment?",
      "ans": "The customer scans the presented QR code with their mobile device, which opens the PayThru payment gate. They can then select their payment method and complete the transaction."
    },
    {
      "que": "What happens after the customer makes the payment?",
      "ans": "Once the payment is completed, the server notifies the merchant device, which will change to GREEN to indicate a successful transaction."
    },
    {
      "que": "Is there a record of customer transactions?",
      "ans": "Yes, there is a transaction history for each customer that can be viewed by the merchant."
    },
    {
      "que": "What types of devices can be used with AzatMe mPOS?",
      "ans": "The AzatMe mPOS system can be used on tablets, smartphones, or any wireless devices that can connect to the internet."
    },
    {
      "que": "What formats are supported for the QR codes?",
      "ans": "The QR codes generated are in Portable Network Graphic (PNG) format, which is widely supported across various devices."
    },
    {
      "que": "What should I do if I encounter an issue with the mPOS system?",
      "ans": "If you encounter any issues, please contact our support team via the \"WhatsApp\" icon on AzatMe.com or email us at support@AzatMe.com."
    },
    {
      "que": "How can I reset my password?",
      "ans": "Click on the \"Forgot Password\" link on the login page, enter your registered email address, and follow the instructions to reset your password."
    }
];

export const smoto = [
    {
      "que": "What is SMOTO?",
      "ans": "SMOTO stands for Social Media Order/Telephone Order. It refers to the process of accepting and processing orders through social media platforms and telephone calls."
    },
    {
      "que": "How do I place an order through social media?",
      "ans": "You can place an order by sending a direct message (DM) on platforms like Instagram, Facebook, or WhatsApp, or by commenting on posts. Some businesses also provide links to order forms."
    },
    {
      "que": "How do I place an order over the phone?",
      "ans": "You can call the business's customer service or sales line, provide your order details, and make payment over the phone."
    },
    {
      "que": "Is it safe to make payments through social media?",
      "ans": "Yes, it is safe if the business uses secure payment links and encryption methods. Always ensure you are dealing with a verified and reputable business."
    },
    {
      "que": "What payment methods are accepted for SMOTO?",
      "ans": "Payment methods typically include credit/debit cards, PayPal, mobile wallets (like Apple Pay and Google Pay), and in some cases, cash on delivery."
    },
    {
      "que": "Will I receive an order confirmation?",
      "ans": "Yes, after placing your order and making payment, you will receive an order confirmation via social media message, email, or phone call."
    },
    {
      "que": "How can I track my order?",
      "ans": "The business will provide tracking information through social media messages, email, or SMS once your order has been shipped."
    },
    {
      "que": "What should I do if I don't receive my order?",
      "ans": "Contact the business's customer service through social media or phone to inquire about the status of your order."
    },
    {
      "que": "Can I cancel or change my order after it's been placed?",
      "ans": "Policies vary by business, but generally, you can contact customer service to request a cancellation or change. It's best to do this as soon as possible."
    },
    {
      "que": "How is my personal information protected?",
      "ans": "Businesses should follow data protection regulations and use encryption to protect your personal information. Ensure the business is reputable and follows best practices for data security."
    },
    {
      "que": "What should I do if I suspect fraud?",
      "ans": "Immediately contact the business to report your concerns. Additionally, inform your bank or payment provider to investigate the transaction."
    },
    {
      "que": "Are there any additional fees for ordering through social media or phone?",
      "ans": "It depends on the business. Some may charge additional fees for telephone orders or specific payment methods, so it's best to check with the business directly."
    },
    {
      "que": "Can I return or exchange items ordered through SMOTO?",
      "ans": "Return and exchange policies vary by business. Check the specific policy provided by the business when placing your order."
    },
    {
      "que": "How can I leave feedback or a review?",
      "ans": "You can leave feedback or a review by commenting on the business's social media posts, sending a DM, or using any review links provided by the business."
    },
    {
      "que": "What if I need help with my order?",
      "ans": "Reach out to the business's customer service through social media or phone for assistance with your order. They should be able to help with any issues or questions you have."
    },
    {
      "que": "Additional Tips for Businesses",
      "ans": "Clear Communication: Ensure that all order processes, payment methods, and policies are clearly communicated to customers through social media and telephone interactions.\nCustomer Support: Provide robust customer support to address any issues promptly and efficiently.\nSecurity Measures: Implement advanced security measures to protect customer data and ensure safe transactions."
    }
];  

export const kontribute = [
    {
        que: 'What is Kontribute?',
        ans: 'Kontribute is a crowdfunding platform that allows individuals and groups to raise funds for various purposes, from personal projects to charitable causes and business ventures.'
    },
    {
        que: 'How does Kontribute work?',
        ans: 'Users can create fundraising campaigns, set financial goals and deadlines, invite contributors, and track contributions in real-time. Once the campaign reaches its goal or deadline, funds can be withdrawn and used for the intended purpose.'
    },
    {
        que: 'Is Kontribute free to use?',
        ans: 'Yes, signing up and creating campaigns on Kontribute is free of charge. However, a small processing fee may apply to contributions made by contributors.'
    },
    {
        que: 'What types of campaigns can I create on Kontribute?',
        ans: 'You can create both closed group campaigns (Close Kontribute) for specific participants and open campaigns (Open Kontribute) for anyone to contribute. Campaigns can be for personal projects, charitable causes, community initiatives, or business ventures.'
    },
    {
        que: 'How can I contribute to a campaign on Kontribute?',
        ans: 'Contributors can support campaigns by making monetary contributions via various payment methods - Bank Transfer.'
    },
    {
        que: 'Is my financial information secure on Kontribute?',
        ans: 'Yes, Kontribute employs robust security measures to protect users\' financial information and ensure secure transactions.'
    },
    {
        que: 'How do I withdraw funds from my Kontribute campaign?',
        ans: 'Once your campaign reaches its goal or deadline, you can initiate a fund withdrawal to your bank account through the platform.'
    },
    {
        que: 'Can I cancel or edit my campaign after it has been created?',
        ans: 'There may be a limited window to edit details for a live campaign based on Kontribute\'s policies.'
    },
    {
        que: 'What if my campaign doesn\'t reach its fundraising goal?',
        ans: 'You can choose to withdraw the funds raised if the campaign doesn\'t reach its initial target and create another one.'
    },
    {
        que: 'How can I promote my Kontribute campaign to attract more contributors?',
        ans: 'You can share your campaign link on social media platforms, email newsletters, and other channels to reach a wider audience.'
    },
    {
        que: 'Are there any fees involved?',
        ans: 'Currently, Kontribute doesn’t charge a platform fee.'
    },
    {
        que: 'How does Kontribute help promote campaigns?',
        ans: 'The platform provides tools to easily share campaign links on social media and other channels.'
    }
];

export const ajo = [
    {
        que: 'What is Ajo (also known as Esusu or Thrift)?',
        ans: 'Ajo is a traditional community savings program where a group of people contribute a fixed amount of money regularly. The collected funds are then remitted to a member of the group on a rotating basis.',
    },
    {
        que: 'How does Ajo benefit me?',
        ans: '',
        more: [
            {
                title: 'Ajo offers several advantages:',
                data: [
                    {
                        title: 'Enforced savings:',
                        text: 'Regular contributions help you build a consistent savings habit.'
                    },
                    {
                        title: 'Reaching larger goals:',
                        text: 'Pooling resources allows you to save for bigger financial targets.'
                    },
                    {
                        title: 'Community and accountability:',
                        text: 'The group setting provides support and motivation to stay on track.'
                    },
                    {
                        title: 'Potential for interest:',
                        text: 'Some Ajo groups generate interest on contributions.'
                    },
                    {
                        title: 'Cultural connection (in some cultures):',
                        text: 'Ajo can connect you with your community and heritage.'
                    },
                ]
            }
        ]
    },
    {
        que: 'What are the potential drawbacks of Ajo?',
        ans: '',
        more: [
            {
                title: 'There are a few risks to consider:',
                data: [
                    {
                        title: 'Risk of default:',
                        text: 'If a member fails to contribute, it can affect the entire group.'
                    },
                    {
                        title: 'Limited accessibility:',
                        text: 'Finding a trustworthy Ajo group can be challenging, especially for newcomers.'
                    },
                ]
            }
        ]
    },
    {
        que: 'What size should an Ajo group be?',
        ans: 'There\'s no fixed size, but a manageable number (around 10-20 people) is ideal for smooth operation and communication.'
    },
    {
        que: 'What is the contribution schedule for Ajo?',
        ans: 'The contribution schedule for Ajo can vary depending on the preferences of the group members. Contributions can be made daily, weekly, or monthly, with each member contributing a fixed amount of money.'
    },
    {
        que: 'Is my financial information secure in Ajo?',
        ans: 'Azateme prioritizes the security and privacy of its members\' financial information. Measures are in place to safeguard data and ensure secure transactions within the platform. '
    },
    {
        que: 'How do we choose who receives the money?',
        ans: `Rotation (most common): Each member receives the pooled funds in turn, following a predetermined order (sequential or random draw).`
    },
    {
        que: 'How do we collect and distribute the money?',
        ans: 'Traditionally, a designated member collects the contributions. However, the digital Azatme Ajo platform offers secure online options. With this platform, the funds are collected directly by the chosen recipient for each cycle.'
    },
    {
        que: 'Can I withdraw my funds from Ajo at any time?',
        ans: 'Yes, funds can be withdrawn once it is a member\'s turn to receive the pooled contributions.'
    },
    {
        que: 'How do we keep track of everything?',
        ans: 'The recipient can track who has paid and who is still pending on the Ajo summary page.',
    },
    {
        que: 'What happens if someone can\'t contribute?',
        ans: 'Establish clear rules for handling missed contributions to minimize disruption for the group. Anyone who cannot keep up with their contributions should provide a replacement.'
    },
    {
        que: 'Are there digital Ajo platforms available?',
        ans: 'Yes, there are online platforms like AzateMe that facilitate secure transactions, record-keeping, and communication within your Ajo group.'
    },
    {
        que: 'How can I find a trustworthy Ajo group?',
        ans: 'Reach out to friends, family, or community organizations.'
    }
];

export const invoice = [
  {
    "que": "What is an AzatMe Invoice with a PayNow button?",
    "ans": "An AzatMe invoice with a PayNow button is a digital invoice sent to customers via email or text message or WhatsApp, containing a clickable link that directs them to a secure payment page where they can complete the transaction."
  },
  {
    "que": "How does the PayNow Button work?",
    "ans": "The PayNow Button embedded with the pay link in the invoice takes the customer to a secure online payment gateway. Here, they can review the payment options and make the payment via their preferred payment method."
  },
  {
    "que": "What payment methods are supported?",
    "ans": "AzatMe uses the PayThru Payment gateway. PayThru uses various payment options and keeps adding more options. A visit will give you the many secure options that you will make a choice from."
  },
  {
    "que": "Can I pay the invoice in installments?",
    "ans": "This depends on the terms set by the business. Some businesses may offer installment payment options. Some businesses may carry the leftover to another invoice while others may not honor the transaction until payment is fully collected."
  },
  {
    "que": "Can I use the Request to Pay Link embedded in the PayNow Button for recurring payments?",
    "ans": "Yes, for businesses that offer subscription services or recurring billing, the request to pay link can be set up for automatic recurring payments."
  },
  {
    "que": "Is it secure to use the Request to Pay Link?",
    "ans": "Yes, the payment link is secure. It uses encryption and secure payment gateways to ensure that your payment information is protected."
  },
  {
    "que": "How can I ensure the PayNow Button with the embedded payment link is legitimate?",
    "ans": "Ensure the email or message containing the payment link is from a recognized and trusted sender. Look for signs of phishing, such as suspicious email addresses or poor grammar. When in doubt, contact the business directly."
  },
  {
    "que": "Can I view my invoice details before making a payment?",
    "ans": "Yes, the payment portal will display all invoice details, including the amount due, due date, and itemized charges, before you complete the payment."
  },
  {
    "que": "Will I receive a confirmation after making the payment?",
    "ans": "Yes, after completing the payment, you will receive a confirmation email or message from the payment gateway, including a payment receipt of the transaction."
  },
  {
    "que": "How quickly is my payment processed?",
    "ans": "Payments made through the request to pay link are typically processed instantly or within a few minutes. You will receive a confirmation once the payment is completed."
  },
  {
    "que": "Can I save my payment information for future invoices?",
    "ans": "Depending on the chosen payment channel. Some payment channels allow you to save your payment information for future use. This option will be presented during the payment process if available."
  },
  {
    "que": "What happens if the embedded payment link in PayNow Button expires?",
    "ans": "If the embedded payment link expires, you will need to contact the business to request a new link. Typically, businesses will resend the invoice with an updated PayNow Button."
  },
  {
    "que": "What should I do if the payment fails?",
    "ans": "If the payment fails, you will typically receive an error message with further instructions. You can try an alternative payment method or contact the business's customer service for assistance."
  },
  {
    "que": "What should I do if I accidentally delete the email or message containing the invoice?",
    "ans": "Contact the business’s customer service to request a new invoice. They can resend the invoice with the link to your email or phone number."
  },
  {
    "que": "What if I need a refund after paying through the PayNow link?",
    "ans": "Refunds are handled by the business. Contact their customer service department to request a refund, and they will process it according to their refund policy."
  },
  {
    "que": "Can I update my payment information if I made a mistake?",
    "ans": "If you need to update your payment information before completing the transaction, you can usually go back and edit the details. If you need to make changes after submitting the payment, contact the business’s customer service for assistance."
  },
  {
    "que": "Can I pay from my mobile device?",
    "ans": "The PayNow when clicked opens a web page, which is designed to be mobile-friendly, allowing you to make payments easily from your smartphone or tablet."
  },
  {
    "que": "Is there a fee for using the AzatMe Business Invoice solution?",
    "ans": "Usually, there are no additional fees for using the AzatMe Invoice service."
  },
  {
    "que": "Can I share the AzatMe Invoice with someone else to pay on my behalf?",
    "ans": "Yes, you can share the invoice with someone else if you prefer them to make the payment. Ensure that the person paying on your behalf has all the necessary details to complete the transaction accurately."
  },
  {
    "que": "Can I view my payment history?",
    "ans": "As the Business owner, AzatMe portal offers a feature to view your payment history. Check the portal for information on accessing your payment records."
  }
];

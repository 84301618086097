import { useState } from "react";

const GetUserProduct = ({ handleGetProduct, product, handleGetAll }) => {
    const [email, setEmail] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        handleGetProduct(email)
    }

    const getAll = () => {
        setEmail('');
        handleGetAll();
    }
    return (
        <div className="flex flex-col justify-start items-start px-[2%] flex-wrap">
            <label htmlFor="">Enter user email to get {product} for a user</label>
            <form onSubmit={handleSubmit} className="flex flex-row justify-start items-center flex-wrap">
                <input value={email} onChange={({target}) => setEmail(target.value)} required className="h-10 min-w-[250px] text-sm px-2 border mr-2 border-black" type="email" placeholder="user@mail.com" />
                <button type="submit" className="w-fit px-2 h-10 bg-az-teal text-white">Get for user</button>
                <button type="button" onClick={getAll} disabled={!email} className="w-fit px-2 h-10 bg-az-teal text-white ml-2">Get all</button>
            </form>
        </div>
    )
};

export default GetUserProduct;

import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import SwitchBusiness from "../../components/SwitchBusiness";
import Link from "../../components/Templates/Link";

const EmailTemplate = () => {

  return (
    <DashboardComponentWrapper overrideName={() => <SwitchBusiness />} title="Email Template" >
      <Link />
    </DashboardComponentWrapper>
  )
};

export default EmailTemplate;

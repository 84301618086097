import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface ShareState {
  open: boolean;
  title: string;
  text: string;
  url: string;
  email: string;
}

const initialState: ShareState = {
  open: false,
  title: '',
  text: '',
  url: '',
  email: '',
}

export const shareSlice = createSlice({
  name: 'share',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<ShareState>) => {
      state.open = true;
      state.title = action.payload.title;
      state.text = action.payload.text;
      state.url = action.payload.url;
      state.email = action.payload.email; 
    },
    closeShare: (state) => {
      state.open = false;
      state.title = '';
      state.text = '';
      state.url = '';
      state.email = '';
    },
  },
})

export const { open, closeShare } = shareSlice.actions;

export default shareSlice.reducer;

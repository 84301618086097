import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { Tooltip } from 'react-tooltip';
import './index.scss';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'react-tooltip/dist/react-tooltip.css';

import { store } from './app/store';
import NewVersion from './components/NewVersion';

const AppComponent = () => {
  const [showUpdate, setShowUpdate] = useState(false);

  const onSWUpdate = (registration) => {
    registration.update();
    setShowUpdate(true);
  } 
  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);
  
  return (
    <>
      <NewVersion showUpdate={showUpdate} />
      <App />
    </>
  )
};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Tooltip anchorId="my-element" />
      <AppComponent />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useNavigate } from 'react-router-dom';
import RetailImg from '../../../../../assets/new/business/retail.png';
import Logistics from '../../../../../assets/new/business/logistics.png';
import Supermark from '../../../../../assets/new/business/supermark.png';

const solutions = [
    {
      title: 'Retail Business',
      img: RetailImg
    },
    {
      title: 'Logistics and Transportations',
      img: Logistics
    },
    {
      title: 'Supermarkets',
      img: Supermark
    },
    {
      title: 'Restaurants',
      img: Supermark
    },
  ];

const Solutions = () => {
    const navigate = useNavigate();
    return (
        <div className='w-screen px-[7%] py-[6%] min-h-screen h-fit relative my-[100px] flex flex-col justify-between items-center bg-az-faint-red' id='services'>
            <div className='w-full flex flex-row flex-wrap justify-between items-start'>
                <div className='flex flex-row justify-start mb-4 max-w-[400px]'>
                    <p className='font-Onest text-4xl text-left'>
                        Built for every kind of business
                    </p>
                </div>
                <div className='flex flex-col justify-start items-start'>
                    <p className=' text-left font-Onest w-full sm:w-[500px] text-base'>
                        Tailored financial solutions crafted specifically for
                        your business needs.
                    </p>
                    <button type='submit' onClick={() => navigate('/register')} className="bg-az-teal h-[50px] text-white mt-4 w-fit font-medium text-sm px-10 font-Onest rounded-md">Create an account - It’s free</button>
                </div>
            </div>
            <div className='flex flex-row mt-10 justify-start items-start overflow-hidden w-full h-fit'>
                {
                    solutions.map((service, index) => (
                        <div key={index} className={`flex flex-col !w-[360px] justify-between rounded-md !h-[370px] text-left m-2 bg-white relative`}>
                            <p className='font-Onest text-white text-3xl rounded-sm flex justify-center items-center w-fit px-4 py-2 absolute left-5 bottom-10'>
                                {service.title}
                            </p>
                            <div className='!w-[360px] !h-[370px] rounded-md '>
                                <img src={service.img} alt="" className='w-full h-full rounded-md ' />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

export default Solutions;
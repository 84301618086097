import { useState } from 'react';
import Button from '../Button';
import CreateGenericGroup from './genericGroup';
import { grpTypes } from '../../pages/Landing/Components/Personal/HowPeopleUseKontribute'
import './index.scss';

const CreateUserGroup = (props) => {
  const [selectedGrpType, setSelectedGrpType] = useState<string | null>(null);

  const renderCancel = () => {
    return (
      <Button
        onClick={() => setSelectedGrpType(null)} 
        label={'x'} 
        type={'flat'} 
        className="cancel-action-btn"
      />
    )
  }

  if (!selectedGrpType) {
    return (
      <KontributeType
        overrideSectionStyle={{
          flexDirection: 'column'
        }} 
        text="What type of Group will you like to create?" 
        onClick={setSelectedGrpType}
      />
    );
  }

  return (
    <CreateGenericGroup
      renderCancel={renderCancel} 
      handleDone={props.handleDone} 
      selectedType={selectedGrpType}
    />
  )
};

export default CreateUserGroup;

export const KontributeType = ({ text, onClick, overrideTextStyle = {}, overrideSectionStyle = {}, overrideCardStyle = {}, }) => {
  return (
    <div className="grps-wrapper" style={overrideSectionStyle}>
      <h3 style={overrideTextStyle} className='py-6 text-lg font-semibold'>{text}</h3>
      <div className='grp-types'>
        {
          grpTypes.map((type) => (
            <div key={type.name} onClick={() => {
              onClick(type.name)
            }} className="flex flex-col justify-around items-center bg-[#222] w-[210px] h-[200px] m-2 p-3 cursor-pointer !hover:shadow-2xl">
              <p className="rounded-full px-4 py-1 text-white font-Onest" style={{
                  background: type.color
              }}>{type.name}</p>
              <p className="font-Onest text-[#ccc] text-sm text-center">{type.description}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
}

/* eslint-disable eqeqeq */
import "./expenseDetails.scss";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useCallback, useState } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

interface IExpenseDetailsCard {
  id: string;
  title: string;
  description: string;
  target: number;
  openEdit: () => void;
  uniqueCode?: string;
  handleCloseDetail: () => void;
  referral: any;
  hasMembers: boolean;
  isMine?: boolean;
  members?: Array<Record<string, any>>
  pendingPayment: boolean;
}



const RefDetailsCard = ({
  id, 
  openEdit,
  handleCloseDetail,
  referral,
}: IExpenseDetailsCard) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleOpenDeleteConfirm = useCallback(() => {
    setIsDeleteOpen(true);
  }, []);
  
  const handleCloseDeleteConfirm = useCallback(() => {
    setIsDeleteOpen(false);
  }, []);

  const handleOpenEditConfirm = useCallback(() => {
      openEdit();
  }, [openEdit]);


  const deleteExpense = useCallback(() => {
    setIsDeleteOpen(false);
    handleCloseDetail();
  }, [id, handleCloseDetail]);

  
  return (
    <div className="right-wrapper">
      <div className="flex flex-col w-full">
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Start date</p>
          <p className="text-sm text-az-black-900">{dayjs(referral.start_date).fromNow()}</p>
        </div>
        {
          referral.end_date && (
            <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
              <p className="text-sm text-az-black-900">End date</p>
              <p className="text-sm text-az-black-900">{dayjs(referral.end_date).fromNow()}</p>
            </div>
          )
        }
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Title</p>
          <p className="text-sm text-az-black-900">{referral.name}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Point conversion</p>
          <p className="text-sm text-az-black-900">{referral.point_conversion}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Duration</p>
          <p className="text-sm text-az-black-900">{referral.duration}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Status</p>
          <p className="text-sm text-az-black-900">{referral.status}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Point limit</p>
          <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{referral.point_limit}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4">
          <div onClick={handleOpenEditConfirm} className="!border-2 border-az-teal rounded px-8 py-2">
            <span>Edit</span>
          </div>
          <button onClick={handleOpenDeleteConfirm} className="bg-az-teal rounded text-white w-fit h-10 px-8">
            <span>Delete</span>
          </button>
        </div>
      </div>
      <ConfirmModal
        isOpen={isDeleteOpen}
        onClose={handleCloseDeleteConfirm}
        text="Are you sure you want to delete this RefundMe? All data about it will be lost after this action."
        handleNo={handleCloseDeleteConfirm}
        handleYes={deleteExpense}
      />
    </div>
  )
}

export default RefDetailsCard
import CoilArrow from '../../../../../assets/svgs/coilArrow.svg';
import busImg1 from '../../../../../assets/new/busImg1.png';
import busImg2 from '../../../../../assets/new/busImg2.png';
import busImg3 from '../../../../../assets/new/busImg3.png';
import busImg4 from '../../../../../assets/new/business/largeOrgF.png';
import typeTextBg from '../../../../../assets/new/business/typeTextBg.png';
import './hero.scss';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

const businesses = [busImg1, busImg2, busImg3, busImg4]

const Hero = () => {
    const navigate = useNavigate();
    const [typeVal, setTypeVal] = useState<'Enterprise' | 'SMEs'>('Enterprise');

    useEffect(() => {
        const interval = setInterval(() => {
            setTypeVal(prevTypeVal => (prevTypeVal === 'Enterprise' ? 'SMEs' : 'Enterprise'));
        }, 5000);
        return () => {
            clearInterval(interval);
        }
    }, []);
    return (
        <div className="w-screen bg-az-light-red min-h-screen flex flex-col h-fit">
            <div className="flex flex-col md:flex-row px-[7%] sm:px-[7%] items-center justify-center !pt-36" id="hero">
                <div className="w-full h-fit flex flex-col justify-center items-center">
                    <div className="flex flex-row h-9 my-4 mb-14 bg-[#0898A01A] rounded-full w-fit p-2 items-center text-xs">
                        <p className="bg-az-teal rounded-full h-[100%] flex flex-row justify-center items-center text-white font-medium px-2 py-2">New</p>
                        <p className="px-2">Announcing our new brand experience</p>
                    </div>
                    <h1 className="font-Onest text-5xl leading-[58px] max-w-[950px] w-11/12">
                        Powering innovation in financial services and payments for <div className='inline relative px-4'>
                            {typeVal}
                            <img src={typeTextBg} alt="" className='absolute top-0 left-0 h-full w-full' />
                        </div>
                    </h1>
                    <div className="relative flex flex-row justify-center items-center my-8 w-full">
                        <p className="font-Onest text-lg text-center max-w-[600px] w-10/12">
                            Embrace the convenience of a unified and secure platform for payments,
                            receiving funds, and optimizing your financial management.
                        </p>
                    </div>
                    <div className="flex flex-row w-full justify-center items-center">
                        <button className="bg-az-teal text-white h-10 w-40 rounded font-bold font-Onest" onClick={() => navigate('/register')}>Get Started</button>
                        <img src={CoilArrow} alt="" className="mx-10 mb-10" />
                    </div>
                </div>
            </div>
            <div className="pb-10">
                <div className="flex flex-col lg:flex-row flex-no-wrap overflow-hidden justify-center items-center">
                    {
                        businesses && businesses?.map((business, index) => <img src={business} alt='' key={index} className="m-[30px] w-[365px] h-[400px]" /> )
                    }
                </div>
                {/* <div className="flex flex-col justify-center items-center">
                    <p className="font-Onest text-base mt-10 mb-6">Trusted by 100+ businesses</p>
                    <div className="flex flex-row justify-center items-center h-[50px] relative w-fit">
                        {
                            trusts.map((trust, index) => <img src={trust} key={index} className="m-3" />)
                        }
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Hero;

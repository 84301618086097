import { formatCurrency } from '../../utils/common';
import './order.scss';

interface IOrder {
  isNew?: boolean;
  order: Record<string, any> | null;
  setOrder: (val: Record<string, any>) => void;
}

const Order = ({
  isNew,
  order,
  setOrder
} :IOrder) => {

  if (isNew) {
    return (
      <div className='order isNew' onClick={setOrder}>
        <p>Create New Service/Product</p>
      </div>
    )
  }
  if (order) {
    return (
      <div onClick={setOrder} className="order flex !flex-row justify-start items-center !px-4">
        <div className='border border-black h-16 w-16 mr-4 rounded-[40px] flex justify-center items-center text-2xl'>
          {order.name[0]}
        </div>
        <div className='flex flex-col justify-start items-center'>
          <p className='name text-left w-full'>{order.name}</p>
          <p className='text-left w-full'>{formatCurrency({num: order.amount})}</p>
        </div>
      </div>
    )
  }
  return null;
};

export default Order;

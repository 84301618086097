const terms = {
  title: "SITE TERMS & CONDITIONS",
  effective: "April 2023",
  details: [
    {
      title: '',
      item: ['AzatMe (referred to as ‘us’, ‘our’ or ‘we’) is a trading name of Pethahiah Rehoboth Ltd, a company registered in the Federal Republic of Nigeria, (RC. 809123). Pethahiah Rehoboth Ltd is authorised by the Central Bank of Nigeria (CBN) as a Payment Solutions Services Provider, able to provide payment initiation services and account information services.'] 
    },
    {
      title: "",
      item: ["‘You’ means any user of any AzatMe services and ‘your’ is construed accordingly."]
    },
    {
      title: "",
      item: ["These terms and conditions govern your use of the AzatMe site (AzatMe.com), and by visiting this site you agree to be bound by them. We may update these terms and conditions at any time and your continued access to this site constitutes your agreement to such update."],
    },
    {
      title: "",
      item: ["We may end your access to the AzatMe site for any reason at our sole discretion. The services described within the AzatMe site are intended for individuals with Nigerian bank accounts only."],
    },
    {
      title: "COPYRIGHT",
      item: [
        "The content of the AzatMe site is subject to copyright, and AzatMe reserves all rights. You may not download, transmit or modify (all or part of) the AzatMe site without our prior written permission.",
        "All content is posted ‘as is’ and your use or reliance on any content is at your own risk. AzatMe accepts no liability to you for the consequences of such use or reliance.",
        "You may not use any of our intellectual property (including, without limitation, our trademarks, trade names, service marks, logos, domain names and software) without our prior written permission."
      ]
    },
    {
      title: "THIRD PARTY LINKS",
      item: [
        "Links to third party sites are not an endorsement by us of those sites and such links are used entirely at your own risk. AzatMe accepts no responsibility or liability for the content, use or availability of third-party sites.",
        "The AzatMe site may contain material provided by third parties and AzatMe accepts no responsibility or liability for the accuracy of such material.",
        "No third party is permitted to link to the AzatMe site without obtaining our prior written consent."
      ]
    },
    {
      title: "NO OFFER OR LIABILITY",
      item: [
        "Nothing on the AzatMe site should be construed as a recommendation or offer to engage in any transaction.",
        "AzatMe accepts no liability whatsoever in connection with your use or inability to use the AzatMe site or services, or in connection with any error, omission, defect, virus or system failure, or loss of profit, goodwill or reputation, even if expressly advised of the possibility of the same arising out of or in connection with the AzatMe site.",
        "We reserve the right to modify, suspend or discontinue the AzatMe site or any part of it without notice at any time. AzatMe accepts no liability for any such action, howsoever caused."
      ]
    },
    {
      title: "COMPLAINTS",
      item: [
        "If you are unhappy with any AzatMe service, please get in touch with us at info@pethahiah.com.",
        "If, after contacting us, the problem is not resolved to your satisfaction you can contact the CBN’s Consumer Protection Office to look into it. The The CBN CPO is a free, independent service and further information can be found at https://www.cbn.gov.ng/supervision/cpdcomgt.asp ",
      ]
    },
    {
      title: "GOVERNING LAW AND JURISDICTION",
      item: [
        "These terms and conditions are governed by the laws of Federal Republic of Nigeria, and by accessing the AzatMe site you agree to submit any disputes (including non-contractual disputes) to the exclusive jurisdiction of the Ikeja Judiciary Division Courts.",
      ]
    }
  ]
};

export default terms;
import { useState } from "react";
import CreateIcon from '../../assets/new/createIcon.png';
import CustomerList from "../../components/CustomerList";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import SidePane from "../../components/SidePane";
import SwitchBusiness from "../../components/SwitchBusiness";
import CreateCustomer from "../../components/CustomerList/CreateCustomer";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";

const Customers = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const expenseNavItemsObj = [
    {
      label: 'New Customer',
      action: () => setOpenCreate(true),
      bgColor: '#0898A0',
      img: CreateIcon,
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        dontShowWithdraw
        navItems={[]}
        navItemsObj={expenseNavItemsObj}
        tab={''}
        setTab={() => {}}
      />
    )
  }

  return (
    <DashboardComponentWrapper
      overrideName={() => <SwitchBusiness />}
      renderNavItems={renderNavItems}
      title="Customers"
    >
      <>
        <CustomerList handleCreate={setOpenCreate} />
        <SidePane
          onClose={() => setOpenCreate(false)}
          open={openCreate}
          title={""}
        >
          <CreateCustomer handleClose={() => setOpenCreate(false)} isOpen={true} />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  )
};

export default Customers;

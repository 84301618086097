import Hero1 from '../../../../../assets/refundmeHero.png';
import CoilArrow from '../../../../../assets/svgs/coilArrow.svg';
import Trust1 from '../../../../../assets/trusts/trust1.png';
import Trust2 from '../../../../../assets/trusts/trust2.png';
import Trust3 from '../../../../../assets/trusts/trust3.png';
import Trust4 from '../../../../../assets/trusts/trust4.png';
import Trust5 from '../../../../../assets/trusts/trust5.png';
import Trust6 from '../../../../../assets/trusts/trust6.png';
import HeroItem1 from '../../../../../assets/new/heroItem1.png';
import HeroItem3 from '../../../../../assets/new/heroItem3.png';
import HeroItem2 from '../../../../../assets/new/heroItem2.png';
import HeroItem4 from '../../../../../assets/new/heroItem4.png';
import './hero.scss';
import { useNavigate } from "react-router-dom";

const trusts = [Trust1, Trust2, Trust3, Trust4, Trust5, Trust6];

const Hero = () => {
    const navigate = useNavigate();
    return (
        <div className="w-screen bg-[#0898A008] min-h-screen pt-28 h-fit" id='hero'>
            <div className="flex flex-col md:flex-row px-[6%] items-center justify-between pt-6">
                <div className="text-left w-full h-fit">
                    <div className="flex flex-row h-9 my-4 bg-[#0898A01A] rounded-full w-fit p-2 items-center text-xs">
                        <p className="bg-az-teal rounded-full h-[100%] flex flex-row justify-center items-center text-white font-medium px-2 py-2">New</p>
                        <p className="px-2">Announcing our new brand experience</p>
                    </div>
                    <h1 className="font-Onest text-4xl font-medium w-full xl:w-[450px] leading-[50px]">Easy Money Management: Get Refunds, Contribute, and Save Together.</h1>
                    <p className="font-Onest text-base  w-full xl:w-[450px] my-8">Handle your money hassle-free! Our platform makes it simple to get refunds, donate, and join savings groups. Take control of your finances effortlessly.</p>
                    <div className="flex flex-row justify-start items-center">
                        <button className="bg-az-teal text-white w-40 h-10 rounded font-bold font-Onest" onClick={() => navigate('/register')}>Get Started</button>
                        <img src={CoilArrow} alt="" className="mx-10 mb-6" />
                    </div>
                    <div>
                        <p className="font-Onest text-base mt-10 mb-6">Trusted by 100+ beautiful individuals</p>
                        <div className="flex flex-row justify-start items-center h-[50px] relative w-[310px]">
                            {
                                trusts.map((trust, index) => <img key={trust} alt='' src={trust} className={`absolute ml-${index}`} style={{
                                    marginLeft: `${index * 50}px`,
                                }} />)
                            }
                            <div className="bg-gray-900 h-[60px] w-[60px] rounded-full flex justify-center items-center z-10 font-Onest opacity-50 text-white font-medium absolute right-0">
                                94+
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative w-[100vw] h-[60vh] lg:h-[80vh]  flex flex-row justify-center items-center">
                    <div className="relative w-[70%]  h-[60%] lg:h-[80%] z-10 bg-contain bg-center bg-no-repeat" 
                    style={{backgroundImage: `url(${Hero1})`}}>
                    {/* <img src={Hero1} alt="" className="absolute w-10/12 h-[60%] lg:h-[80%] object-contain z-10" /> */}
                    <img src={HeroItem1} alt="" className="absolute z-20 h-[40px] lg:h-auto top-[20%] lg:top-[35%] right-[-15%] pulse" />
                    <img src={HeroItem2} alt="" className="absolute z-20 h-[40px] lg:h-auto top-[65%] right-[-15%] pulse" />
                    <img src={HeroItem3} alt="" className="absolute z-20 h-[40px] lg:h-auto top-[40%] lg:top-[10%] left-[-15%] pulse" />
                    <img src={HeroItem4} alt="" className="absolute z-20 h-[40px] lg:h-auto top-[80%] left-[-15%] pulse" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import IssuesListTab from "./IssuesList";
import CreateIcon from '../../assets/new/createIcon.png';
import { useAppSelector } from '../../app/hooks';
import './issues.scss';
import CreateIssues from "../../components/CreateIssues";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import Modal from "../../components/Modal";
import { getIssues } from "../../actions/dashboardActions";
import SidePane from "../../components/SidePane";
import IssueDetail from "../../components/IssueDetails";


const IssueNavItems = ['New Feedback'];

const Issues = () => {
  const {state} = useLocation() as unknown as Record<string, any>;
  const [tab, setTab] = useState(IssueNavItems[0]);
  const [openCreate, setOpenCreate] = useState(false);
  const {issues} = useAppSelector((state) => state.dashboard);
  const [issue, setIssue] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    if (state?.openCreate) {
      setTab(IssueNavItems[1]);
    }
  }, [state]);

  useEffect(() => {
    getIssues();
  }, []);

  const feedbackNavItemsObj = [
    {
      label: 'New Feedback',
      action: () => setOpenCreate(true),
      bgColor: '#0898A0',
      img: CreateIcon,
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem navItems={[]} tab={tab} setTab={setTab} navItemsObj={feedbackNavItemsObj} dontShowWithdraw />
    )
  }

  const closeAddIssueForm = () => {
    setTab('All Feedback');
    setOpenCreate(false);
  }
  
  return (
    <DashboardComponentWrapper title="Feedback" renderNavItems={renderNavItems}>
      <>
        <Modal
          onClose={() => setOpenCreate(false)}
          open={openCreate}
          title={"New Feedback"}
          overrideStyle={{
            maxWidth: '400px',
            width: '80%',
            height: 'max-content',
            marginTop: '70px',
            border: '1px solid red',
            paddingBottom: '30px'
          }}
        >
          <CreateIssues
            handleDone={closeAddIssueForm}
            name={state?.name}
            uniqueCode={state?.uniqueCode}
          />
        </Modal>
        <IssuesListTab handleCreate={() => setOpenCreate(true)} issues={issues} setIssue={(issue) => setIssue(issue)} />
        <SidePane onClose={() => setIssue(null)} open={!!issue} title={""}>
          <IssueDetail issue={issue} setIssue={(issue) => setIssue(issue)} />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  );
};

export default Issues;

import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";

const AdminProfile = () => {
    return (
        <DashboardComponentWrapper
            subTitle="Profile"
            pageDetails="Here's your admin profile."
        >
            <div>
                    Admin Profile
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminProfile;

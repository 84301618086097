import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { IBusiness } from "../pages/Business/businessSlice";

const useGetBusinessCode = () => {
  const businesses = useAppSelector((state) => state.business.businesses);
  const [businessCode, setBusinessCode] = useState('');
  const [business, setBusiness] = useState<IBusiness>();
  const location = useLocation();
  const code = location.pathname.split('/')[3];
  useEffect(() => {
    const business = businesses?.find((business) => business.business_code === code);
    setBusinessCode(code);
    setBusiness(business);
  }, [businesses, code]);
  return [businessCode, business];
};

export default useGetBusinessCode

import BusinessPageBuilder from "./BusinessProductBuilder";
import Hero1 from '../../assets/new/business/mposImg.png';
import RefundIcon from '../../assets/new/business/mposs.svg';
import RefProd2 from '../../assets/new/business/wallet.svg';
import RefProd1 from '../../assets/new/business/options.svg';
import RefProd3 from '../../assets/new/business/security.svg';
import RefProd4 from '../../assets/new/business/secure.svg';
import RefProd5 from '../../assets/new/business/chart.svg';
import RefProd6 from '../../assets/new/business/flex.svg';
import { Reason } from "../../types";
import { mPOS } from "../../data/faqData";

const Mpos = () => {
    const reasons: Reason[] = [
        {
            tagline: 'AFFORDABILITY',
            title: `Cost-effective`,
            details: `AzatMe mPOS is generally much cheaper
                than traditional POS systems. We require
                no upfront investment nor does it puts a
                pressure on minimum turnover.`,
            img: RefProd2,
            bg: ''
        },
        {
            tagline: 'PAYMENT',
            title: `Wider Payment Options`,
            details: `AzatMe mPOS systems accept more
                payment options than traditional POS
                systems. This caters to the growing
                preference for cashless transactions.`,
            img: RefProd1,
            bg: ''
        },
        {
            tagline: 'SECURITY',
            title: `Improve Security`,
            details: `AzatMe mPOS systems typically use
                encryption to protect customer data and
                often don't store card information on the
                device, minimizing the risk of a security
                breach.`,
            img: RefProd3,
            bg: '#F5F8FF'
        },
        {
            tagline: 'MOBILITY',
            title: `Increased Mobility`,
            details: `Unlike traditional POS systems tied to a
                specific location, MPOS allows you to take
                payments on the go. This is ideal for
                businesses like food trucks, farmers' 
                markets, or delivery services.`,
            img: RefProd4,
            bg: ''
        },
        {
            tagline: 'DATA AND INSIGHTS',
            title: `Real-time Data`,
            details: `AzatMe mPOS systems offer real-time
                sales data and inventory tracking. This 
                allows you to make better business
                decisions and manage your stock more
                efficiently.`,
            img: RefProd5,
            bg: ''
        },
        {
            tagline: 'SIMPLICITY',
            title: `Simple to use`,
            details: `AzatMe mPOS systems are designed to be 
                user-friendly, with intuitive interfaces that
                are easy for staff to learn, even with
                minimal training.`,
            img: RefProd6,
            bg: '#F5F8FF'
        }
    ];
    const howItWorks = [
        {
            title: 'Sign on to the business part of the AzatMe App.',
            details: ``
        },
        {
            title: `Select a business from the business list
and tap on the mPOS icon to launch it.`,
            details: ``
        },
        {
            title: `Click the new transaction button and
enter the amount being requested to
be paid.`,
            details: ``
        },
        {
            title: `Enter the mobile number of the payment.
This is may also be used as the loyalty 
number identification. `,
            details: ``
        },
        {
            title: `Tap on the send button. It will return the
link. Click on the show QR code and scan
to make payment.`,
            details: ``
        },
    ];
    const benefits = []
    
    return (
        <BusinessPageBuilder
            title="Enable instant payments: Anywhere, Anytime"
            name={"MPOS"}
            tagline={`Turn smartphones into payment terminals, without the need for any
                extra investments in hardware.`}
            titleIcon={RefundIcon}
            image={Hero1}
            imgText={[]}
            summary={`AzatMe mPOS (Mobile Point of Sale) systems offer a variety of advantages for businesses, 
especially those that are just starting out or don't need the complexity of a traditional POS system.`}
            reasons={reasons}
            howItWorks={{howItWorks}}
            benefits={benefits}
            faq={mPOS}
        />
    );
};

export default Mpos;

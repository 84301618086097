import Button from "../Button";
import Modal from "../Modal"

const ConfirmModal = ({
  text,
  isOpen,
  onClose,
  handleYes,
  handleNo,
} : {
  text: string,
  isOpen: boolean,
  onClose: () => void,
  handleYes: () => void,
  handleNo: () => void,
}) => {
  return ( 
    <Modal
      open={isOpen}
      onClose={onClose}
      overrideStyle={{
          maxWidth: '400px',
          width: '80%',
          height: 'max-content',
      }}
      title="Confirm Action"
      footer={() => (
        <>
          <Button
            type="contained"
            label="No"
            onClick={handleNo}
          />
          <Button
            type="flat"
            label="Yes"
            onClick={handleYes}
          />
        </>
      )}
    >
      <div className="confirm-delete">
        <p>
          {text}
        </p>
      </div>    
    </Modal>
  );
};

export default ConfirmModal;

import axios from "axios";
import { 
  useEffect,
  // useEffect, 
  useState 
} from "react";
import toast from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";
import { 
  useNavigate, 
  // useParams 
} from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import UnAuthWrapper from "../../components/UnAuthWrapper";
import { scrollUp } from "../../utils/common";
import getBaseURL from "../../utils/getBaseURL";
import { signalObj, toastWrapper } from "../../utils/toastWrapper";

import './signup.css';
import useGetInvitationData from "../../hooks/useGetInvitationData";

function SignUp() {
  const [invitationData] = useGetInvitationData();
  const navigate = useNavigate();
  const [misMatchPsd, setMisMatchPsd] = useState(false);
  const [passError, setPassError] = useState(false);
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    name: '',
    phone: '',
    usertype: 'user',
    unique_code: '',
  });

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    const referralCode = searchParams.get('referral_code');
    setData((prev) => ({
      ...prev,
      unique_code: referralCode || '',
    }))
  }, [searchParams]);

  useEffect(() => {
    scrollUp();
    if (Object.keys(invitationData).length  && invitationData.action === 'accept') {
      setData((prev) => ({
        ...prev,
        email: invitationData.email,
        phone: invitationData.phone_number,
        name: invitationData.invitee_name
      }));
      toast('Provide your password to Continue');
    }
  }, [invitationData]);


  const handleSubmit = (event: React.FormEvent) => {
    const url = getBaseURL() as string;
    event.preventDefault();
    if (data.password !== data.password_confirmation) {
      toast.error('Password and Confirm Password do not match!');
      return;
    }
    toastWrapper(
      axios.post(`${url}register`, data), 
      'Creating your profile...', 
      (resp: any) => {
        // navigate('/login');
        toastWrapper(
          axios.post(`${url}AttemptLogin`, {
            email: data.email,
            password: data.password,
          }, signalObj), 
          'Logging you in...', 
          (resp) => {
            localStorage.setItem('email', data.email);
            if ((Object.keys(invitationData)).length) {
              localStorage.setItem('pendingInvitation', JSON.stringify(invitationData));
            }
            navigate('/verify', { state: {item: data}});
            return resp.data.message || 'Login Successful, Enter OTP sent to your email!';
          }, 
          'Email or Password Incorrect.',
          () => {
            return 'Email or Password Incorrect.'
          }
        );
        return 'Account created Successfully!'
      }, 
      'Account Creation failed.',
      (err) => {
        // @ts-ignore
        let msg = err?.response?.data?.message;
        // @ts-ignore
        if (err?.response?.status && err.response.data.errors) {
          // @ts-ignore
          const errors = Object.values(err.response.data.errors);
          // @ts-ignore
          msg = errors.map((error) => `${error[0]}\n`);
        }
        return msg || err?.message || 'Account Creation failed.';
      }
    );
  }

  return (
    <UnAuthWrapper
      title="Create your account"
      subTitle="Enter your details to begin this process."
      ctaQuestion="Have an account?"
      ctaRoute="login"
      ctaText="Log In"
      overrideContentClass='signup-form'
    >
      <form onSubmit={handleSubmit} className="max-w-[400px]">
          <Input
            label="Username"
            placeholder="Username"
            name="name"
            required
            value={data.name}
            onChange={handleChange}
          />
          <Input
            label="Email"
            placeholder="Email"
            required
            name="email"
            value={data.email}
            onChange={handleChange}
          />

          <Input
            label="Phone"
            type="tel"
            placeholder="+2347XXXXXXXXX"
            required
            // @ts-ignore
            pattern="[789][0-9]{9}"
            name="phone"
            value={data.phone}
            onChange={handleChange}
          />

          <Input
            label="Referral Code"
            placeholder="Cdef0993"
            name="unique_code"
            value={data.unique_code}
            onChange={handleChange}
          />
  
          <Input
            label="Password"
            placeholder="Password"
            name="password"
            value={data.password}
            required
            pattern="^((?=.*\d)|(?=.*[!@#$%^&*]))(?=.*[a-z])(?=.*[A-Z]).{8,}$"
            type="password"
            onChange={(name:string, value:string) => {
              handleChange(name, value);
              setPassError(!(/^((?=.*\d)|(?=.*[!@#$%^&*]))(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)));
            }}
            onBlur={() => {
              setPassError(!(/^((?=.*\d)|(?=.*[!@#$%^&*]))(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(data.password)));
            }}
            hasError={passError}
            errorText='Password must 8 or more alpha-numberic, uppercase, lowercase and special characters!'
          />
          <Input
            label="Confirm Password"
            placeholder="Password"
            name="password_confirmation"
            value={data.password_confirmation}
            required
            type="password"
            onChange={(name:string, value:string) => {
              handleChange(name, value);
              if (misMatchPsd && data.password === value) {
                setMisMatchPsd(false);
              }
            }}
            onBlur={() => {
              if (data.password !== data.password_confirmation) {
                setMisMatchPsd(true);
                return;
              }
              misMatchPsd && setMisMatchPsd(false);
            }}
            hasError={misMatchPsd}
            errorText='Password and Confirm Password must match!'
          />
          <p>By creating an account, you agree to all our <Link to="/terms"><b>Terms & Conditions</b></Link> and <Link to="/privacy"><b>Privacy Policy</b></Link>.</p>
        <Button
          label="Continue"
          onClick={() => null}
          type="flat"
          btnActionType="submit"
        />
      </form>
    </UnAuthWrapper>
  );
}

export default SignUp;
import { useEffect, useState } from "react";
import { getAllRefundMe, getAllRefundMeByEmail } from "../../../actions/adminActions";
import { useAppSelector } from "../../../app/hooks";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import ExpenseDetails from "../../../components/ExpenseDetails";
import SidePane from "../../../components/SidePane";
import TabComponent from "../../../components/TabComponent";
import { useLocation } from "react-router-dom";
import AllAzats from "../../../components/Azats/AllAzats";
import AzatsListWrapper from "../../../components/Azats/AzatsListWrapper";
import GetUserProduct from "../GetUserProduct";

const AdminRefundMe = () => {
    const {allExpenses, allExpensesByUser} = useAppSelector((state) => state.admin);
    const location = useLocation();
    const [expense, setExpense] = useState<Record<string, any> | null>(null);
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        // @ts-ignore
        if (location.state?.expense) {
        // @ts-ignore
        setExpense(location.state?.expense);
        }
    }, [location?.state]);

    useEffect(() => {
        getAllRefundMe(1);
    }, []);

    const handleGetProduct = (email: string) => {
        getAllRefundMeByEmail(1, email);
        setUserEmail(email)
    }

    const handleByEmailPagination = (page) => {
        getAllRefundMeByEmail(page, userEmail);
    }

    const getAll = () => {
        setUserEmail('');
    }

    const allRefundMes = userEmail ? allExpensesByUser : allExpenses;
    const handlePagination = userEmail ? handleByEmailPagination : null;
    return (
        <DashboardComponentWrapper
            subTitle="RefundMe"
            pageDetails="Here's an overview of all RefundMe on Azatme."
        >
            <div>
                <GetUserProduct handleGetProduct={handleGetProduct} product="RefundMe"   handleGetAll={getAll}  />
                <TabComponent
                    components={{
                        // @ts-ignore
                        'RefundMe History': () => <AzatsListTab setExpense={setExpense} allRefundMes={allRefundMes} handleCreate={() => {}} handlePagination={handlePagination} />,
                    }}
                />
                <SidePane
                    onClose={() => setExpense(null)}
                    open={!!expense}
                    title={""}
                >
                    <ExpenseDetails expense={expense} handleCloseDetail={() => setExpense(null)} isMine />
                </SidePane>
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminRefundMe;

const AzatsListTab = ({allRefundMes, setExpense, handleCreate, handlePagination }) => {
    const [hideNav, setHideNav] = useState(false);
  
    const renderChildren = () => {
        return <AllAzats expenses={allRefundMes} setExpense={setExpense} setHideNav={setHideNav} handleCreate={handleCreate} handlePagination={handlePagination} />
    }
  
    
    return (
      <AzatsListWrapper>
        {renderChildren()}
      </AzatsListWrapper>
    );
  };

const BankSVG = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.5 20C1.5 19.6022 1.65804 19.2206 1.93934 18.9393C2.22064 18.658 2.60218 18.5 3 18.5H21C21.3978 18.5 21.7794 18.658 22.0607 18.9393C22.342 19.2206 22.5 19.6022 22.5 20V21C22.5 21.3978 22.342 21.7794 22.0607 22.0607C21.7794 22.342 21.3978 22.5 21 22.5H3C2.60218 22.5 2.22064 22.342 1.93934 22.0607C1.65804 21.7794 1.5 21.3978 1.5 21V20ZM3 19.5C2.86739 19.5 2.74021 19.5527 2.64645 19.6464C2.55268 19.7402 2.5 19.8674 2.5 20V21C2.5 21.1326 2.55268 21.2598 2.64645 21.3536C2.74021 21.4473 2.86739 21.5 3 21.5H21C21.1326 21.5 21.2598 21.4473 21.3536 21.3536C21.4473 21.2598 21.5 21.1326 21.5 21V20C21.5 19.8674 21.4473 19.7402 21.3536 19.6464C21.2598 19.5527 21.1326 19.5 21 19.5H3Z" fill="#222222" fillOpacity="0.7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17 8C17 7.86739 17.0527 7.74021 17.1464 7.64645C17.2402 7.55268 17.3674 7.5 17.5 7.5H20.5C20.6326 7.5 20.7598 7.55268 20.8536 7.64645C20.9473 7.74021 21 7.86739 21 8V19C21 19.1326 20.9473 19.2598 20.8536 19.3536C20.7598 19.4473 20.6326 19.5 20.5 19.5H17.5C17.3674 19.5 17.2402 19.4473 17.1464 19.3536C17.0527 19.2598 17 19.1326 17 19V8ZM18 8.5V18.5H20V8.5H18ZM10 8C10 7.86739 10.0527 7.74021 10.1464 7.64645C10.2402 7.55268 10.3674 7.5 10.5 7.5H13.5C13.6326 7.5 13.7598 7.55268 13.8536 7.64645C13.9473 7.74021 14 7.86739 14 8V19C14 19.1326 13.9473 19.2598 13.8536 19.3536C13.7598 19.4473 13.6326 19.5 13.5 19.5H10.5C10.3674 19.5 10.2402 19.4473 10.1464 19.3536C10.0527 19.2598 10 19.1326 10 19V8ZM11 8.5V18.5H13V8.5H11ZM3 8C3 7.86739 3.05268 7.74021 3.14645 7.64645C3.24021 7.55268 3.36739 7.5 3.5 7.5H6.5C6.63261 7.5 6.75979 7.55268 6.85355 7.64645C6.94732 7.74021 7 7.86739 7 8V19C7 19.1326 6.94732 19.2598 6.85355 19.3536C6.75979 19.4473 6.63261 19.5 6.5 19.5H3.5C3.36739 19.5 3.24021 19.4473 3.14645 19.3536C3.05268 19.2598 3 19.1326 3 19V8ZM4 8.5V18.5H6V8.5H4Z" fill="#222222" fillOpacity="0.7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.443 1.6844C11.8006 1.5414 12.1994 1.5414 12.557 1.6844L21.557 5.2844C22.127 5.5124 22.5 6.0644 22.5 6.6774V7.0004C22.5 7.39822 22.342 7.77976 22.0607 8.06106C21.7794 8.34236 21.3978 8.5004 21 8.5004H3C2.60218 8.5004 2.22064 8.34236 1.93934 8.06106C1.65804 7.77976 1.5 7.39822 1.5 7.0004V6.6774C1.49995 6.37755 1.58977 6.08457 1.75785 5.83627C1.92594 5.58797 2.16459 5.39574 2.443 5.2844L11.443 1.6844ZM12.186 2.6134C12.0666 2.56555 11.9334 2.56555 11.814 2.6134L2.814 6.2134C2.72131 6.25054 2.64187 6.31459 2.58591 6.39729C2.52994 6.47999 2.50002 6.57755 2.5 6.6774V7.0004C2.5 7.13301 2.55268 7.26018 2.64645 7.35395C2.74021 7.44772 2.86739 7.5004 3 7.5004H21C21.1326 7.5004 21.2598 7.44772 21.3536 7.35395C21.4473 7.26018 21.5 7.13301 21.5 7.0004V6.6774C21.5 6.57755 21.4701 6.47999 21.4141 6.39729C21.3581 6.31459 21.2787 6.25054 21.186 6.2134L12.186 2.6134Z" fill="#222222" fillOpacity="0.7"/>
        </svg>
    )
};

export default BankSVG;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useAuthRedirect = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const email = localStorage.getItem('email');
    if (!email || !email.toLocaleLowerCase().match(emailPattern)) {
      localStorage.removeItem('email');
      navigate('/login');
    }
  });
};

export default useAuthRedirect;

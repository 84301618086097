import React, { StrictMode, useEffect, useMemo, useRef, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import './App.scss';
import { ApolloProvider } from '@apollo/client';
import client from './appolloClient';
import Nav from './pages/Landing/Components/Personal/Nav';
import Footer from './pages/Landing/Components/Personal/Footer';
import ShareComponent from './components/ShareComponent';
import axios, { AxiosError } from 'axios';
import DeclineAjo from './components/AjoInvitation/Decline';
import AcceptAjo from './components/AjoInvitation/Accept';
import { WhatsappIcon } from 'react-share';
import Modal from './components/Modal';
import ScrollToTop from './utils/ScrollToTop';

axios.interceptors.response.use((response) => {
  return response;
}, function (error: AxiosError) {
  if (error?.response?.status === 401) {
    localStorage.removeItem('tazat');
    window.location.href = '/';
  }
  return Promise.reject(error);
});

export const AuthContext = React.createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {}
});

export const ProfileContext = React.createContext({
  profile: {},
  setProfile: () => {}
});

export const openWhatsapp = () => {
  window.open("https://wa.me/2349044556688?text=Hello%20Azatme%20");
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState({});
  const [inAppInstallPrompt, showInAppInstallPromotion] = useState(false);
  const value = useMemo(
    () => ({ isAuthenticated, setIsAuthenticated }), 
    [isAuthenticated]
  );

  const deferredPrompt = useRef();

  const profileValue = useMemo(
    () => ({ profile, setProfile }), 
    [profile]
  );

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      // Save the event because you'll need to trigger it later.
      deferredPrompt.current = e;
      // Show your customized install prompt for your PWA
      // Your own UI doesn't have to be a single element, you
      // can have buttons in different locations, or wait to prompt
      // as part of a critical journey.
      showInAppInstallPromotion(true);
    });
  }, []);

  const handleInstall = () => {
    // @ts-ignore
    deferredPrompt.current?.prompt();
    showInAppInstallPromotion(false);
  }

  return (
    <StrictMode>
    {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore */}
    
      <AuthContext.Provider value={value}>
        <ApolloProvider client={client}>
          {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore */}
          <ProfileContext.Provider value={profileValue}>
            <BrowserRouter>
							<ScrollToTop />
              <div className="app relative">
                <DeclineAjo />
                <AcceptAjo />
                <ShareComponent />
                <Nav />
                <Toaster position="top-center" />
                <Routes />
                <Footer />
              </div>
            </BrowserRouter>
          </ProfileContext.Provider>
        </ApolloProvider>
      </AuthContext.Provider>
      <WhatsappIcon className='rounded-full fixed right-2 bottom-2 w-10 h-10 animate-pulse cursor-pointer z-[999999]' onClick={openWhatsapp} />
      <Modal
        onClose={() => showInAppInstallPromotion(false)}
        open={inAppInstallPrompt}
        title={'App Install'}
        overrideStyle={{
          height: '300px',
        }}
      >
        <div className='flex flex-col justify-around items-center h-full'>
          <p className='my-2 text-center font-semibold'>You can install this app by pressing the install button for easy access from your phone launcher</p>
          <div className='flex flex-row justify-around items-center w-full'>
            <button className='border border-az-teal text-az-teal px-4 py-2' onClick={() => showInAppInstallPromotion(false)}>Cancel</button>
            <button className='text-white bg-az-teal px-4 py-2' onClick={handleInstall}>Install</button>
          </div>
        </div>
      </Modal>
    </StrictMode>
  );
}

export default App;
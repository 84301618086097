import { FormEvent, useCallback, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Input from '../Input';
import Button from '../Button';
import './createTransaction.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import toast from 'react-hot-toast';
import CreateCustomer from '../CustomerList/CreateCustomer';
import useGetBusinessCode from '../../hooks/useGetBusinessCode';
import { 
  createTransaction, 
  getBusinessInvoicesSilent, 
  getBusinessLinksSilent, 
  getCustomers, 
  getProducts, 
  getTransactionOptions
} from '../../actions/businessActions';
import { navigatorShare } from '../../utils/common';
import { IProduct } from '../../pages/Business/businessSlice';
import Modal from '../Modal';
import dayjs from 'dayjs';
import QRCode from 'react-qr-code';
import { useLocation } from 'react-router-dom';


const CreateTransaction = (props) => {
  const [hideProdSelector, setHideProdSelector] = useState(true);
  const {isLoading} = useAppSelector(state => state.loader);
  const [order, setOrder] = useState<IProduct | null>(null);
  const [createCustomer, setCreateCustomer] = useState(false); 
  const {user} = useAppSelector((state) => state.auth);
  const [allProducts, setAllProducts] = useState([]);
  const [productQty, setProductQty] = useState({});
  const dispatch = useAppDispatch();
  const customerObj = useAppSelector((state) => state.business.customers);
  const productsObj = useAppSelector((state) => state.business.products);
  const transactionOptions = useAppSelector((state) => state.business.transactionOptions);
  const [businessCode] = useGetBusinessCode();
  const customers = customerObj[businessCode as string];
  const [step, setStep] = useState(0);
  const [showQR, setShowQR] = useState(false);
  const [response, setResponse] = useState({});
  // const [selectedBank, setSelectedBank] = useState<Bank>();
  const [downloadLink, setDownloadLink] = useState(null);
  const [data, setData] = useState({
    name: '',
    total: '',
    category: '',
    subcategory: '',
    description: '',
    id: '',
    link: '',
    splittingId: '',
    moto: '',
    dueDate: '',
    bankName: '',
    transactionSendType: [],
    uniqueCode: '',
    customerName: '',
    customerEmail: '',
    quantity: '0',
  });
  const products = productsObj[businessCode as string];
  const location = useLocation();
  useEffect(() => {
    if (!products) {
      businessCode && getProducts(dispatch, businessCode, 1);
    }
  }, [businessCode, products]);

  useEffect(() => {
    if (!transactionOptions) {
      getTransactionOptions(dispatch);
    }
  }, [dispatch, step, transactionOptions]);

  useEffect(() => {
    if (!customers) {
      businessCode && getCustomers(dispatch, businessCode);
    }
  }, [businessCode, customers]);

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const splittedPath = location.pathname.split('/')
  const transactionOpt = splittedPath[splittedPath.length - 1] === 'directlinks' ? 'direct_mail' : 'invoice';

  const handleCreateTransaction = (event: FormEvent) => {
    event.preventDefault();
    if (new Date(data.dueDate) < new Date()) {
      toast.error('Please select a future due date!')
      return;
    }
    if (!data.customerEmail) {
      toast.error('Please add a customer to continue!')
      return;
    }
    const quantity = [];
    const uniqueCode = [];
    Object.entries(productQty).forEach(([key, value]) => {
      // @ts-ignore
      quantity.push(value);
      // @ts-ignore
      uniqueCode.push(key);
    });
    createTransaction({
      moto_id: transactionOptions?.find((option) => option.option === transactionOpt)?.id,
      email: data.customerEmail,
      unique_code: uniqueCode,
      quantity,
      due_days: dayjs(new Date(data.dueDate)).diff(dayjs(new Date()), 'day') + 1,
    }, dispatch, businessCode, order, transactionOpt === 'invoice', (resp, link) => {
      if  (transactionOpt === 'invoice') {
        setResponse({...data, ...order, ...resp});
        setDownloadLink(link);
        getBusinessInvoicesSilent(businessCode);
      } else {
        setResponse({...data, ...resp});
        getBusinessLinksSilent(businessCode)
      }
      setStep(3);
    });
  }


  const handleShare = () => {
    navigatorShare({
      title: `Azatme Business Transaction by ${user?.name} | ${user?.email}`,
      // @ts-ignore
      text: `You are expected to pay NGA for product ${order?.name || ''}, ${order?.description || ''}. To pay click`,
      // @ts-ignore
      url: response.payLink || response.link,
      email: data.customerEmail,
    }, dispatch);
  }

  const removeProduct = (itm) => {
    // @ts-ignore
    const newProducts = allProducts.filter((prod) => prod.value !== itm.value);
    setAllProducts(newProducts);
  }

  const isFirst = step === 0;
  const isSecond = step === 1;
  const isThird = step === 3;
  const renderStep = () => {
    return (
      <div className="stepper w-fit flex flex-row justify-start items-start border bg-az-light-red">
        <div className={`step ${isFirst ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isSecond ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isThird ? 'active' : ''}`} />
      </div>
    )
  };

  const handleAddFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (allProducts.length === 0) {
      toast.error('Please select a one or more products to continue!');
      return;
    }
    let noQty = false
    allProducts.forEach(({value}) => {
      if (!productQty[value] || !parseInt(productQty[value])) {
        noQty = true;
      }
    });
    if (noQty) {
      toast.error('Please enter a quantity for all products!');
      return;
    }
    setStep(1);
  }

  return (
    <div className="h-full w-full">
      <div className='flex flex-col justify-start items-start mb-6'>
        {renderStep()}
        <h3 className='text-3xl'>New Transaction</h3>
      </div>
      {
        isFirst && products && (
          <form onSubmit={handleAddFormSubmit} className='!h-[80%] flex flex-col flex-auto flex-grow'>
            <div
              className='flex flex-col justify-start items-start !w-full'
              style={{
                display: hideProdSelector || allProducts.length === 0 ? 'flex' : 'none'
              }}
            >
              <label htmlFor="">Select Products/Services</label>
              <CreatableSelect
                isMulti
                // @ts-ignore
                options={products.data.map((item) => ({
                  label: item?.name, 
                  value: item?.unique_code
                }))}
                value={allProducts}
                onChange={(item) => {
                  if (item) {
                    // @ts-ignore
                    setAllProducts(item);
                    hideProdSelector && setHideProdSelector(false);
                  }
                }}
                formatCreateLabel={(inputValue: string) => (
                  <div style={{
                    height: '50px',
                  }}>
                    <Button
                      label={`Create new Customer: "${inputValue}"`}
                      type="flat"
                      btnActionType="button"
                      overrideStyle={{
                        margin: '0',
                      }}
                    />
                  </div>
                )}
                className='w-full text-left'
              />
            </div>
            <div className='w-full flex flex-col flex-auto justify-start items-start flex-grow h-[200px] overflow-auto mt-4 p-1'>
              {
                allProducts.map((product, index) => (
                  // @ts-ignore
                  <div className='flex flex-row justify-between items-center my-2 w-full px-4' key={`${product.label}-${index}`}>
                    
                    <p>
                      {/* @ts-ignore */}
                      {product.label}
                    </p>
                    <div className='flex flex-row justify-end items-center'>
                      <input
                        className='border border-black px-2 py-1 !w-36 !m-0 !mx-2'
                        type="number"
                        placeholder='Enter Quantity'
                        onChange={({target}) => {
                          // @ts-ignore
                          setProductQty((prev) => ({
                            ...prev,
                            // @ts-ignore
                            [product.value]: target.value
                          }));
                        }}
                        // @ts-ignore
                        value={productQty[product.value]}
                      />
                      <svg className='cursor-pointer' onClick={() => removeProduct(product)} xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                        <path d="M16.3248 7.46826C16.3248 7.46826 15.7818 14.2033 15.4668 17.0403C15.3168 18.3953 14.4798 19.1893 13.1088 19.2143C10.4998 19.2613 7.88779 19.2643 5.27979 19.2093C3.96079 19.1823 3.13779 18.3783 2.99079 17.0473C2.67379 14.1853 2.13379 7.46826 2.13379 7.46826" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M17.708 4.23975H0.75" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.4406 4.23973C13.6556 4.23973 12.9796 3.68473 12.8256 2.91573L12.5826 1.69973C12.4326 1.13873 11.9246 0.750732 11.3456 0.750732H7.11258C6.53358 0.750732 6.02558 1.13873 5.87558 1.69973L5.63258 2.91573C5.47858 3.68473 4.80258 4.23973 4.01758 4.23973" stroke="#0898A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="w-full flex flex-row justify-around">
              {
                allProducts.length > 0 && (
                  <div className='w-5/12'>
                    <Button
                      label="Add More"
                      type="flat"
                      btnActionType="button"
                      disabled={isLoading}
                      onClick={() => setHideProdSelector(true)}
                    />
                  </div>
                )
              }
              <div className='w-5/12'>
                <Button
                  label="Continue"
                  type="flat"
                  btnActionType="submit"
                  disabled={isLoading}
                />
              </div>
            </div>
          </form>
        )
      }
      {
        isSecond && products && (
          <form onSubmit={handleCreateTransaction}>
            <div className='custom-wrapper !w-full'>
              <label htmlFor="">Enter Customer's Name</label>
              <CreatableSelect
                className="react-select-container"
                // @ts-ignore
                options={customers?.map((item) => (
                  {
                    label: item?.customer_name, 
                    value: item?.customer_email
                  }))
                }
                
                name="bankName"
                value={{
                  label: data.customerName,
                  value: data.customerName
                }}
                onChange={(item) => {
                  if (item) {
                    handleChange('customerName', item.label);
                    handleChange('customerEmail', item.value);
                  }
                }}
                onCreateOption={(option) => {
                  handleChange('customerName', option);
                  setCreateCustomer(true);
                }}
                formatCreateLabel={(inputValue: string) => (
                  <div style={{
                    height: '50px',
                  }}>
                    <Button
                      label={`Create new Customer: "${inputValue}"`}
                      type="flat"
                      btnActionType="button"
                      overrideStyle={{
                        margin: '0',
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <Input
              label="Due Date"
              placeholder=""
              type="date"
              name="dueDate"
              required
              value={data.dueDate}
              onChange={handleChange}
            />
            {/* <SelectComponent
              data={transactionOptions?.map(({option}) => option) || []}
              label="Select Transaction Type"
              placeholder="moto"
              name="moto"
              required
              value={transactionOpt}
              onChange={handleChange}
              className="input"
            /> */}
            <Button
              label="Continue"
              type="flat"
              btnActionType="submit"
              disabled={isLoading}
            />
          </form>
        )
      }
      {
        isThird && (
          <div>
            <h4 className='my-20 text-center'>
              Transaction created, with payment details sent to the customer. 
              <br/> 
              <small>Kindly advise the recipients to check their spam or junk folder if they are unable to locate the email in their primary inbox.</small>
              <br/> Click Share Button to manually share to other media.
              {transactionOpt === 'invoice' && 'The Invoice has being downloaded to your device'}  
            </h4>
            {
                showQR && (
                  <div className='my-6 text-center'>
                    <h2 className='my-6 font-bold text-3xl'>Payment pending</h2>
                    {/* <p className='my-6 font-bold text-4xl'>NGN {'data.amount'}</p> */}
                    <QRCode
                      // @ts-ignore
                      value={response.payLink || response.link}
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                )
              }
            <div className='share-wrapper flex flex-row flex-wrap justify-evenly items-center'>
              {
                transactionOpt !== 'invoice' && (
                  <Button
                    type="contained"
                    label={showQR ? 'Hide QR Code' : 'Show QR Code'}
                    onClick={() => {
                      // @ts-ignore
                      setShowQR(!showQR);
                    }}
                    overrideStyle={{width: '45%'}}
                  />
                )
              }
              <Button
                type="contained"
                label='Share'
                onClick={handleShare}
                overrideStyle={{width: '45%'}}
              />
              {
                transactionOpt === 'invoice' && (
                  <Button
                    type="contained"
                    label='Download Invoice'
                    onClick={() => {
                      // @ts-ignore
                      downloadLink?.click();
                    }}
                    overrideStyle={{width: '45%'}}
                  />
                )
              }
            </div>
          </div>
        )
      }
      <Modal
        onClose={() => setCreateCustomer(false)}
        open={createCustomer}
        title={''}
      >
        <CreateCustomer
          isOpen={createCustomer}
          handleClose={() => {
            setCreateCustomer(false);
          }}
          name={data.customerName}
          handleDone={(val: string) => {
            handleChange('customerEmail', val);
          }}
        />
      </Modal>
    </div>
  );
};

export default CreateTransaction;

const Invoicing = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="30" height="30" rx="15" fill="#0192FB"/>
        <path d="M17.7861 18.1675H12.3711" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.7861 15.0278H12.3711" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.4373 11.895H12.3711" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.931 8.0625C17.931 8.0625 12.1732 8.0655 12.1642 8.0655C10.0942 8.07825 8.8125 9.44025 8.8125 11.5177V18.4148C8.8125 20.5028 10.104 21.87 12.192 21.87C12.192 21.87 17.949 21.8677 17.9587 21.8677C20.0287 21.855 21.3113 20.4923 21.3113 18.4148V11.5177C21.3113 9.42975 20.019 8.0625 17.931 8.0625Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
};

export default Invoicing;

import Button from "../../components/Button";
import Input from "../../components/Input";
import UnAuthWrapper from "../../components/UnAuthWrapper";

function VerifyEmail() {
  return (
    <UnAuthWrapper
      title="Verify Email."
      subTitle="Enter code sent to your email below."
      ctaQuestion="Did not receive code?"
      ctaRoute="register
      "
      ctaText="Resend"
    >
      <div>
        <Input
          label="Verification Code"
          placeholder="Code"
          name="code"
          value=""
          onChange={(name, value) => console.log(name, value)}
        />
        <Button
          label="Verify"
          onClick={() => null}
          type="flat"
        />
      </div>
    </UnAuthWrapper>
  );
}

export default VerifyEmail;
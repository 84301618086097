import PageBuilder from "./ProductBuilder";
import Hero1 from '../../assets/new/productImgs/RefundHero.png';
import RefundIcon from '../../assets/new/productImgs/Swap.png';
import RefProd2 from '../../assets/new/refProd2.png';
import RefProd1 from '../../assets/new/refProd1.png';
import RefProd3 from '../../assets/new/refProd3.png';
import Why1 from '../../assets/new/why1.png';
import Why2 from '../../assets/new/why1.png';
import Convenience from '../../assets/new/productImgs/investment-report.png';
import CardPaymeny from '../../assets/new/productImgs/card-payment.png';
import Security from '../../assets/new/productImgs/security.png';
import Ring from '../../assets/new/productImgs/ring-abstract-shape.png'
import { refundme } from "../../data/faqData";
import { Reason } from "../../types";

const RefundMePage = () => {
    const reasons: Reason[] = [
        {
            title: `Simplifies group
            payments`,
            details: `Instead of everyone having to split the bill and pay
            their portion separately, one person can pay the full
            amount upfront, and the others can easily refund
            their shares through the app.`,
            img: RefProd2,
            bg: '#FFF9F0'
        },
        {
            title: `Avoids awkward cash or 
                payment exchanges`,
            details: `No more needing to have the exact cash on hand or
                making complicated peer-to-peer payment transfers.
                RefundMe streamlines the refund process.`,
            img: RefProd1,
            bg: '#FFEFFE'
        },
        {
            title: `Reduces confusion/
                disagreements`,
            details: `With a central platform recording who owes what,
                there's less ambiguity about refund amounts owed.`,
            img: RefProd3,
            bg: '#F5F8FF'
        }
    ];
    const howItWorks = [
        {
            title: 'Sign up',
            details: `Fill out the short form to create a personal
            account. You’ll gain access to start
            exploring our products.`
        },
        {
            title: 'Verification',
            details: `Our compliance team will inform you of 
            the outcome within the shortest
            possible time.`
        },
        {
            title: 'Start using any of our products',
            details: `Once your KYC process is approved, go live 
            with a click of a button and start accepting
            payments seamlessly with AzatMe.`
        },
        {
            title: 'Start using any of our products',
            details: `Once your KYC process is approved, go live 
            with a click of a button and start accepting
            payments seamlessly with AzatMe.`
        },
    ];
    const benefits = [
        {
            title: 'Convenience',
            details: `No more fiddling with cash or complicated 
            calculations.`,
            img: Convenience
        },
        {
            title: 'Payment Flexibility',
            details: `RefundMe supports various payment methods
            (cards, mobile wallets, bank transfers etc.), giving
            users flexibility in how they refund.`,
            img: CardPaymeny
        },
        {
            title: 'Time Savings',
            details: `It saves a lot of time and hassle compared to
            traditional methods of splitting bills.`,
            img: Why1
        },
        {
            title: 'Security',
            details: `Integrates secure payment gateways to protect
            users' financial information during refund 
            transactions.`,
            img: Security
        },
        {
            title: 'Record Keeping',
            details: `The app records refund requests, and amounts
            paid/owed for better expense tracking across
            the group.`,
            img: Why2
        },
        {
            title: 'Transparency',
            details: `Everyone sees the total bill and their share 
            clearly within the app.`,
            img: Ring
        }
    ]
    
    return (
        <PageBuilder
            title="Split Bills Seamlessly with Ease!"
            name={"RefundMe"}
            tagline={`Let one person pay the group outing bill, and effortlessly receive 
            your refunds from friends within minutes using our user-friendly
            platform.`}
            titleIcon={RefundIcon}
            image={Hero1}
            imgText={[]}
            summary={`Make splitting group expenses
            a breeze!`}
            reasons={reasons}
            howItWorks={howItWorks}
            benefits={benefits}
            faq={refundme}
        />
    );
};

export default RefundMePage;

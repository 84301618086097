import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Button from "../../components/Button";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import Input from "../../components/Input";
import SwitchBusiness from "../../components/SwitchBusiness";
import { Select } from "../../components/Input";
import { businessCategory } from "../../utils/data";

import './profile.scss';
import { handleUpdateBusiness } from "../../actions/businessActions";
import toast from "react-hot-toast";
import TabComponent from "../../components/TabComponent";

const ProfileDetails = () => {
  const dispatch = useAppDispatch();
  const {businesses} = useAppSelector((state) => state.business);
  const {pathname} = useLocation() as Record<string, any> || {};
  const business = businesses?.find((busi) => busi.business_code === pathname.split('/')[3]);
  const [isEditMode, setEditMode] = useState(false);
  const [data, setData] = useState({
    name: '',
    type: '',
    logo: '',
    phone: '',
    email: '',
    description: '',
    vatId: '',
    address: '',
    businessRC: '',
    imageData: '',
  });

  useEffect(() => {
    if (business) {
      setData({
        email: business.business_email || '',
        phone: business.business_phone || '',
        businessRC: business.registration_number || '',
        vatId: business.vat_id || '',
        description: business.description || '',
        type: business.type || '',
        logo: business.business_logo || '',
        name: business.business_name || '',
        address: business.business_address || '',
        imageData: '',
      })
    }
  }, [business])

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // @ts-ignore
    if (data.logo.size > 500000) {
      toast.error('Logo must be less than 500kb')
      return;
    }
    const businessData = new FormData();
    businessData.append('business_name', data.name);
    businessData.append('business_email', data.email);
    businessData.append('business_address', data.address);
    data.logo && businessData.append('business_logo', data.logo);
    businessData.append('type', data.type);
    businessData.append('vat_id', data.vatId);
    businessData.append('business_phone', data.phone);
    businessData.append('description', data.description);
    businessData.append('registration_number', data.businessRC);
    handleUpdateBusiness(dispatch, businessData, business?.id, (resp) => {
      if (resp) {
        setData({
          name: '',
          type: '',
          logo: '',
          phone: '',
          email: '',
          description: '',
          vatId: '',
          address: '',
          businessRC: '',
          imageData: '',
        });
      }
    })
  }

  const handleImageChange = ({target}) => {
    if (target.files[0].size > 500000) {
      toast.error('Logo must be less than 500kb');
      return;
    }
    handleChange('logo', URL.createObjectURL(target.files[0]));
    handleChange('imageData', target.files[0]);
  }

  const handleSaveImg = () => {
    const businessData = new FormData();
    businessData.append('business_name', data.name);
    businessData.append('business_email', data.email);
    businessData.append('business_address', data.address);
    data.logo && businessData.append('business_logo', data.logo);
    businessData.append('type', data.type);
    businessData.append('vat_id', data.vatId);
    businessData.append('business_phone', data.phone);
    businessData.append('description', data.description);
    businessData.append('registration_number', data.businessRC);
    handleUpdateBusiness(dispatch, businessData, business?.id,() => {
      setData(prevState => ({
        ...prevState,
        imageData: '',
      }));
    })
  }

  return (
    <div className=" w-full h-full p-0">
      <div className="flex flex-row justify-start items-start flex-wrap bg-white p-10">
        <div className="flex flex-col w-full lg:w-[200px] justify-center items-center">
          <div className="img-wrapper !relative">
            {
              data.logo ? <img className="profile-img" src={data.logo} alt="" /> : (
                <span className="img-placeholder">
                  {data.name && data.name[0]}
                </span>
              )
            }
            {
              data.imageData ? (
                <div className="" onClick={handleSaveImg}>
                  <button className="bg-az-teal text-white h-10">Save Profile</button>
                </div>
              ) : (
                <label htmlFor="image" className="flex flex-col">
                  <span className="text-sm font-medium">Upload your Profile</span>
                  <p className="bg-az-teal text-white h-10 px-4 py-4 flex my-2 flex-row justify-center items-center cursor-pointer">Edit Profile</p>
                  <input className="hidden" type="file" name="image" accept="image/*" id="image" onChange={handleImageChange} />
                </label>
              )
            }
          </div>
        </div>
        <form className="profile-form w-full max-w-[700px] !mt-0 !pt-0 px-0 lg:px-10 flex flex-col flex-auto flex-grow !bg-transparent" onSubmit={handleSubmit}>
          <div className="input-grp-wrapper">
            <Input
              label="Name"
              placeholder="Name"
              name="name"
              required
              value={data.name}
              onChange={handleChange}
            />
            <Input
              label="Email"
              placeholder="Email"
              name="email"
              value={data.email}
              onChange={handleChange}
            />
          </div>
          <div className="input-grp-wrapper">
            <Input
              label="Company RC"
              placeholder="0121222111"
              required
              name="businessRC"
              value={data.businessRC}
              onChange={handleChange}
            />
            <Select
              data={businessCategory}
              label="What does your business do?"
              placeholder="Category"
              name="type"
              required
              value={data.type}
              onChange={handleChange}
            />
          </div>
          <div className="input-grp-wrapper">
            <Input
              label="Phone"
              placeholder="+2347011221122"
              name="city"
              value={data.phone}
              // required
              onChange={handleChange}
            />
            <Input
              label="VAT ID"
              placeholder="1122112211"
              name="vatId"
              value={data.vatId}
              required
              onChange={handleChange}
            />
          </div>
          <div className="input-grp-wrapper">
            <Input
              type="file"
              label="Logo"
              placeholder=""
              accept="image/*"
              name="logo"
              value={data.logo}
              onChange={handleChange}
            />
            <Input
              label="Address"
              placeholder="Address"
              name="address"
              value={data.address}
              required
              onChange={handleChange}
            />
          </div>
          <Input
            label="Description"
            placeholder="description"
            name="state"
            value={data.description}
            required
            onChange={handleChange}
            maxLength={100}
          />
          <div className="input-grp-wrapper profile-btn-wrapper">
            <Button
              overrideStyle={{
                width: '45%',
              }}
              label="Cancel"
              onClick={() => setEditMode(false)}
              type="contained"
              btnActionType="button"
            />
            <Button
              label="Update"
              onClick={() => null}
              type="flat"
              btnActionType="submit"
              overrideStyle={{
                width: '45%',
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const Profile = () => {
  return (
    <DashboardComponentWrapper overrideName={() => <SwitchBusiness />} title="Profile" pageDetails="You can update your profile information here">
      <TabComponent
        components={{
          Profile: ProfileDetails,
        }}
      />
    </DashboardComponentWrapper>
  );
};

export default Profile;

import './button.scss';

interface IButton {
  label: string;
  type: 'flat' | 'contained' | 'text';
  onClick?: () => void;
  disabled?: boolean;
  overrideStyle?: React.CSSProperties
  btnActionType?: 'submit' | 'reset' | 'button'
  className?: string
}

function Button(props: IButton) {
  return (
    <button
      className={`genericBtn px-3  ${props.className} ${props.type}`}
      onClick={() => {
        props.onClick && props.onClick()
      }}
      disabled={props.disabled}
      style={props.overrideStyle}
      type={props.btnActionType || 'button'}
    >
      {props.label}
    </button>
  );
}

export default Button;
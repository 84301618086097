import { useEffect, useState } from "react";
import Plus  from '../../../../../assets/new/plus.png';
import Close from '../../../../../assets/new/close.png';


const faqs = [
    {
        que: 'What is AzatMe?',
        ans: 'AzatMe is a comprehensive financial platform designed to streamline and enhance the way individuals and businesses manage, collect, and raise funds. With a focus on simplicity and efficiency, our platform facilitates various financial activities, including seamless contributions and fundraising efforts. Whether you are managing day-to-day payments or collecting funds for a project, AzatMe provides a robust and user-friendly solution that adapts to your financial needs.'
    },
    {
        que: 'What product does AzatMe offer?',
        ans: 'AzatMe offers a suite of financial products tailored to enhance your monetary dealings. Our product line includes:',
        more: [
            {
                title: 'For Individual Customers:',
                data: [
                    {
                        title: 'Refund Me:',
                        text: 'Ideal for individuals seeking a straightforward way to get refunds, making the process quicker and hassle-free.'
                    },
                    {
                        title: 'Close Kontribute:',
                        text: 'Designed for a closed group with specific financial objectives, providing a targeted fundraising and capital collection approach.'
                    },
                    {
                        title: 'Open Kontribute:',
                        text: 'Perfect for anyone looking to participate in flexible fundraising efforts or to accumulate capital for personal projects or causes.'
                    },
                    {
                        title: 'Ajo:',
                        text: 'A modern take on the traditional rotating savings system, allowing individuals to save and withdraw funds collectively, making it easier to manage savings goals among friends or family.'
                    },
                ]
            },
            {
                title: 'For Businesses:',
                data: [
                    {
                        title: 'Invoice Settlement:',
                        text: 'Offers businesses a streamlined method for settling invoices, enhancing efficiency for both payers and recipients and facilitating smoother transactional experiences.'
                    },
                ]
            }
        ]
    },
    {
        que: 'Does AzatMe work for customer or businesses?',
        ans: 'AzatMe is crafted to serve both individual customers and businesses, delivering a versatile financial management solution. We understand that each client has unique financial transactions and processes, which is why our services are designed to be adaptable to a wide range of needs. Whether you are an individual looking to manage personal finances or a business aiming to optimize financial operations, AzatMe provides the tools and support necessary to achieve your financial objectives.'
    },
    {
        que: 'How much do I pay to sign up on Azatme?',
        ans: 'It is free to sign up on Azatme.'
    },
];

const FAQ = (props) => {
    const usableFAQ = props.faqs || faqs; 
    return (
        <div className="p-[6%] flex flex-col lg:flex-row relative h-fit">
            <div className="flex flex-col justify-start items-start w-full lg:w-6/12">
                <p className="bg-[#0898A01A] px-6 py-3 rounded-full text-sm font-medium mb-4">FAQ</p>
                <p className="font-Onest text-4xl leading-[46px] max-w-[600px] w-full my-4 text-left font-medium">
                    Let’s address some
                    common inquiries we
                    receive frequently
                </p>
                {/* <button type='submit' className="bg-az-teal text-white w-40 h-10 rounded font-bold font-Onest">See all FAQs</button> */}
            </div>
            <div className="w-full lg:w-6/12 h-fit z-10">
                {
                    usableFAQ.map((faq, index) => <FAQCard faq={faq} index={index} key={index} />)
                }
            </div>
            {/* <img src={Spiral} alt="" className="absolute left-0 bottom-0 z-0" /> */}
        </div>
    )
};

export default FAQ;

const FAQCard = ({faq, index}) => {
    const [isOpen, handleOpen] = useState(false);
    useEffect(() => {
        if (index === 0) {
            handleOpen(true);
        }
    }, [index]);
    return (
        <div id="faqs" className="flex flex-col justify-start items-start py-6 relative cursor-pointer border-b-2" onClick={() => handleOpen(!isOpen)}>
            <p className="font-medium my-3 text-left">{faq.que}</p>
            {isOpen ? <p className="text-left w-11/12 text-base">{faq.ans}</p> : null}
            {
                (isOpen && faq.more) && (
                    <div className="flex flex-col justify-start items-start text-left mt-2">
                        {
                            faq.more.map((moreItm) => (
                                <div className="">
                                    <ul className="list-disc list-inside">
                                        <h2 className="font-bold">{moreItm.title}</h2>
                                        {
                                            moreItm.data.map(({title, text}) => (
                                                <li className="my-2 pl-6">
                                                    <span className="mr-1 text-[16px] font-medium">{title}</span>
                                                    <span className="text-sm font-normal">{text}</span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            ))
                        }
                    </div>
                )
            }
            <button className="text-4xl absolute right-[20px] top-5">
                <img src={isOpen ? Close : Plus} alt="" className="" />
            </button>
        </div>
    )
}

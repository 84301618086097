import FAQ from "./Components/Personal/FAQ";
import Hero from "./Components/Business/Hero";
import Solutions from "./Components/Business/Solutions";
import Features from "./Components/Business/Features";
import Blogs from "./Components/Personal/Blogs";

const NewBusinessLanding = () => {
    return (
        <div className="w-screen h-fit">
            <Hero />
            <Features />
            <Blogs />
            <FAQ />
            <Solutions />
        </div>
    )
};

export default NewBusinessLanding;

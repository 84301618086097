import BusinessPageBuilder from "./BusinessProductBuilder";
import Hero1 from '../../assets/new/business/invoiceImg.png';
import RefundIcon from '../../assets/new/business/mobility.svg';
import RefProd2 from '../../assets/new/business/wallet.svg';
import RefProd1 from '../../assets/new/business/options.svg';
import RefProd3 from '../../assets/new/business/security.svg';
import RefProd4 from '../../assets/new/business/secure.svg';
import RefProd5 from '../../assets/new/business/chart.svg';
import RefProd6 from '../../assets/new/business/flex.svg';
import { Reason } from "../../types";
import { invoice } from "../../data/faqData";

const Invoicing = () => {
    const reasons: Reason[] = [
        {
            tagline: 'Efficient Record Keeping',
            title: `Automated Tracking`,
            details: `Payments are automatically tracked and 
                recorded, simplifying accounting and
                financial reporting.`,
            img: RefProd2,
            bg: ''
        },
        {
            tagline: 'REDUCED ADMINISTRATIVE COSTS',
            title: `Cost Effective`,
            details: `Automating the invoicing and payment 
                process reduces the need for manual
                intervention, saving time and administrative
                costs.`,
            img: RefProd1,
            bg: ''
        },
        {
            tagline: 'IMPROVED SECURITY',
            title: `Secured Transactions`,
            details: `Payment links in the PayNow Button are 
                designed with security in mind, using 
                encryption and secure payment gateways
                to protect sensitive information.`,
            img: RefProd3,
            bg: '#F5F8FF'
        },
        {
            tagline: 'Environmental Benefits',
            title: `Paperless Transactions`,
            details: `Digital invoicing and payments reduce the
                need for paper, contributing to 
                environmental sustainability.`,
            img: RefProd4,
            bg: ''
        },
        {
            tagline: 'Customization and Flexibility',
            title: `Personalized Invoices`,
            details: `Invoices can be customized to include 
                branding, detailed itemization, and 
                personalized messages.`,
            img: RefProd5,
            bg: ''
        },
        {
            tagline: 'CONNIVENCE',
            title: `Easy Payment Process`,
            details: `This removes the need for manual entry 
                of payment details, reducing the time and
                effort required, by simply clicking PayNow.`,
            img: RefProd6,
            bg: '#F5F8FF'
        }
    ];
    const howItWorks = [
        {
            title: 'Create an invoice',
            details: `Add product and customer information to create your invoice. 
Then include a more detailed product description, size, quantity,
discount and tax; as needed.`
        },
        {
            title: `Share Invoice`,
            details: `In only two clicks, you can send a professional invoices to your
customers by emailing them a PDF invoice or even sharing a link.`
        },
        {
            title: `Receive payments`,
            details: `Enjoy the convenience of receiving payment seamlessly from 
customers directly into your Azatme account.`
        },
        {
            title: `Manage Invoice `,
            details: `Manage every aspect of your invoice within this single platform;
creating. tracking, payments status monitoring, automating
payment reminders and auto settlement.`
        },
        {
            title: `Scheduled reminders`,
            details: `Manage your invoices with scheduled reminders to enhance
productivity and never miss a deadline.`
        },
    ];
    const benefits = []
    
    return (
        <BusinessPageBuilder
            title="Enable Your Invoices Payable Online"
            name={"INVOICING"}
            tagline={`Use AzatMe Business to effortlessly generate invoices embedded
                with PayNow button.  Send these to your customers instantly via
                email, SMS, or WhatsApp, allowing quick and convenient online
                payments.`}
            titleIcon={RefundIcon}
            image={Hero1}
            imgText={[]}
            summary={``}
            overrideReasonHeader={`Azatme Invoice as a tool to upgrade
            your business.`}
            reasons={reasons}
            howItWorks={{howItWorks}}
            benefits={benefits}
            faq={invoice}
        />
    );
};

export default Invoicing;

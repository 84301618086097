
import { useEffect, useState } from "react";
import ExpenseDetails from "../ExpenseDetails";
import Table from "../Table";
import { TableAction } from "../ExpenseList";


const AllIssues = (props) => {
  const [expense, setExpense] = useState<Record<string, any>>({});
  const handleCloseDetail = () => {
    setExpense({});
  }

  const {setHideNav} = props;

  useEffect(() => {
    if (expense.id) {
      setHideNav(true);
    }
  }, [expense.id, setHideNav]);

  if (expense.id) {
    return <ExpenseDetails expense={expense} handleCloseDetail={handleCloseDetail} />;
  }

  const header = [
    {field: 'expense_name', headerName:  'Item'},
    {field: 'description', headerName:  'Message'},
    {field: 'unique_code', headerName:  'Code', cellRenderer: ({data}: any) => data.unique_code || data.complain_reference_code },
    {field: 'severity', headerName:  'Severity'},
    {field: 'created_at', headerName: 'Created At'},
    {
      field: 'action',
      headerName:  'Action',
      cellRenderer: ({data}: any) =>  <TableAction handleMore={() => props.openIssue(data as unknown as Record<string, string | number>)} />
    }
  ];

  const mobileSeverityColorMap = {
    urgent: '#0898A0',
    high: '#FF9900',
    medium: '#0032B4',
    low: '#00A9B4'
  }

  const mobileConfig = {
    topLeft: {
      field: 'expense_name',
      renderer: (data) => <span className="capitalize">{data.expense_name}</span>
    },
    bottomLeft:  {
      field: 'severity',
      renderer: (data) => {
        const bgColor = mobileSeverityColorMap[data.severity.toLowerCase()]
        return (
          <div className="flex flex-row justify-start items-center">
            <div className={`w-3 h-3 rounded-full mr-3`} style={{
              backgroundColor: bgColor
            }}></div>
            <span className="capitalize">{data.severity}</span>
          </div>
        )
      }
    },
    topRight: {
      field: 'complain_reference_code',
    },
    bottomRight:  {
      field: 'status',
      renderer: (data) => <span className={`${data.status === 'progress' ? 'text-[#FB9701]' : 'text-[#27AE60]'}`}>{data.status === 'progress' ? 'In Progress' : 'Resolved'}</span>
    }
  }

  return (
    <div className="bg-[#ffffff]  border border-white">
      <div className="w-full h-[65vh] text-left px-[2%]">
        <Table
          data={props.issues?.data as unknown as Record<string, string | number | boolean>[] || []}
          header={header}
          fitStrategy="fitGridWidth"
          mobileConfig={mobileConfig}
        />
      </div>
    </div>
  );
};

export default AllIssues;

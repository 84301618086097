import axios from "axios";
import { add, addNewBank, deleteBank, update, addSystemBanks } from "../pages/Bank/bankSlice";
import getBaseURL from "../utils/getBaseURL";
import { toastWrapper } from "../utils/toastWrapper";

const url = getBaseURL() as string;

type IGetBanks = (dispatch: any, handleDone?: () => void) => void;

export const getBanks:IGetBanks = (dispatch, handleDone) => {
  const axiosCall = axios.get(`${url}getBankPerUser`);
  toastWrapper(
    axiosCall, 
    'Getting your banking details...', 
    (resp) => {
      dispatch(add(resp.data));
      if (resp.data.length === 0) {
        return 'No banking detail configured yet!';
      }
      handleDone && handleDone();
      return resp.data.message || 'Banking details gotten successfully!';
    },
    'Error getting banking details.'
  );
}

export const getSystemBanks = (dispatch, handleDone) => {
  const axiosCall = axios.get(`${url}getBanks`);
  toastWrapper(
    axiosCall, 
    'Fetching supported banks details...', 
    (resp) => {
      // if (resp.data.status !== 200) {
      //   return new Error('Error getting configured banking details.');
      // }
      dispatch(addSystemBanks(resp.data.fiList));
      if (resp.data.fiList.length === 0 || !resp.data) {
        return 'No banking detail configured yet!';
      }
      handleDone();
      return resp.data.message || 'Banking details gotten successfully!';
    },
    'Error getting configured banking details.'
  );
}

export const addBank = (data, dispatch, closeForm) => {
  const axiosCall = axios.post(`${url}addBank`, data);
  toastWrapper(
    axiosCall, 
    'Adding new Bank...', 
    (resp) => {
      if (resp.status === 208) {
        throw new Error(resp.data.message);
      }
      dispatch(addNewBank(resp.data[0]));
      closeForm();
      return resp.data.message || 'Bank added successfully!';
    },
    'Error adding banking detail.'
  );
}

export const deleteBanks = (data, dispatch, closeForm) => {
  const axiosCall = axios.delete(`${url}bank/${data.id}`);
  toastWrapper(
    axiosCall, 
    'Deleting your bank...', 
    (resp) => {
      dispatch(deleteBank(data));
      closeForm();
      return resp.data.message || 'Bank deleted successfully!';
    },
    'Error deleting bank.'
  );
}

export const updateBank = (data, dispatch, closeForm) => {
  const updateBankCall = axios.put(`${url}updateBank/${data.id}`, data);
  toastWrapper(
    updateBankCall, 
    'Updating your banking details...', 
    (resp) => {
      dispatch(update(resp.data));
      closeForm();
      return resp.data.message || 'Bank details updated successfully!';
    },
    'Error updating banking details.'
  );
}

export const verifyAccount = (data, handleDone) => {
  const axiosCall = axios.post(`${url}verify-account`, data);
  toastWrapper(
    axiosCall, 
    'Verifying your account details...', 
    (resp) => {
      handleDone(resp.data);
      if (!resp.data) {
        return new Error('Error verifying bank details!')
      }
      return resp.data.message || 'Account verified!';
    },
    'Error verifying banking details!'
  );
}

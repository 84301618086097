
import { useState } from "react";
import AzatsListWrapper from "../../components/Azats/AzatsListWrapper";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import AllIssues from "../../components/Issues/AllIssues";


const expenseNavItems = [
  'All Feedback'
];

const IssuesListTab = (props) => {
  const {issues, handleCreate} = props;
  const [tab, setTab] = useState(expenseNavItems[0]);
  const [hideNav, setHideNav] = useState(false);

  const renderChildren = () => {
    switch (tab) {
      case expenseNavItems[0]:
        return <AllIssues handleCreate={handleCreate} openIssue={props.setIssue} issues={issues} setHideNav={setHideNav} />
      default:
        return <AllIssues handleCreate={handleCreate} openIssue={props.setIssue} issues={issues} setHideNav={setHideNav} />
    }
  }

  const renderNavItems = () => {
    if (hideNav) return <span />;
    return (
      <DashboardNavItem isSecondTier navItems={expenseNavItems} tab={tab} setTab={setTab} />
    )
  }
  
  return (
    <div className="px-[2%]">
      <AzatsListWrapper renderNavItems={renderNavItems}>
        {renderChildren()}
      </AzatsListWrapper>
    </div>
  );
};

export default IssuesListTab;


import { gql } from '@apollo/client';

export const BLOGS_QUERY = gql`
 query Posts {
    posts(stage: PUBLISHED, locales: en) {
        publishedAt
        updatedAt
        createdAt
        id
        title
        slug
        date
        excerpt
        stage
        coverImage {
            width
            height
            url
        }
        author {
            name
            picture {
                width
                height
                url
            }
        }
    }
}
`;

// export const GET_BLOG = gql`
//   query GetBlog($id: ID!) {
//     blog(id: $id) {
//       id
//       title
//       content
//     }
//   }
// `;

export const BLOG_QUERY = gql`
 query Post($id: ID!) {
    post(where: { id: $id }, stage: PUBLISHED, locales: en) {
        publishedAt
        updatedAt
        createdAt
        id
        title
        slug
        date
        excerpt
        stage
        coverImage {
            width
            height
            url
        }
        author {
            name
            picture {
                width
                height
                url
            }
        }
        content {
            raw
            json
            html
            markdown
            text
        }
    }
}
`;

import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";

const AdminUserEntities = () => {
    return (
        <DashboardComponentWrapper
            subTitle="User Entities"
            pageDetails="Manage all User Entities on Azatme here."
        >
            <div>
                    Admin User Entities
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminUserEntities;

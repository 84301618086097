import {  useEffect, useState } from "react";
import { getAllBusiness, getAllBusinessByEmail } from "../../../actions/adminActions";
import { useAppSelector } from "../../../app/hooks";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";
import AjoDetails from "../../../components/AjoDetails";
import SidePane from "../../../components/SidePane";
import TabComponent from "../../../components/TabComponent";
import GetUserProduct from "../GetUserProduct";
import { BusinessCard } from "../../../components/BusinessList";
import NotFound from "../../../components/NotFound";
import TablePaginationFooter from "../../../components/TablePaginationFooter/TablePaginationFooter";

const AdminBusiness = () => {
    const { allBusinesses, allBusinessesByEmail} = useAppSelector(state => state.admin);
    const [kontribute, setKontribute] = useState<Record<string, any> | null>(null);
    const [userEmail, setUserEmail] = useState('');

    const handleGetProduct = (email) => {
        getAllBusinessByEmail(1, email);
        setUserEmail(email);
    }
    const getAll = () => {

    }
    const handlePagination = (payload) => {
        const page = payload.split('=')[1];
        if (userEmail) {
            getAllBusinessByEmail(page, userEmail);
        } else {
            getAllBusiness(page);
        }
    }
    const businesses = userEmail ? allBusinessesByEmail : allBusinesses;
    const renderAjoGroup = () => <BusinessList handleMore={setKontribute} businesses={businesses} handlePagination={handlePagination} />;
    useEffect(() => {
        getAllBusiness(1);
    }, []);
    return (
        <DashboardComponentWrapper
            subTitle="Business"
            pageDetails="Here's an overview of all Businesses on Azatme."
        >
            <>
                <GetUserProduct handleGetProduct={handleGetProduct} product="Business" handleGetAll={getAll} />
                <TabComponent
                    components={{
                        'Business': renderAjoGroup,
                    }}
                />
                <SidePane
                    onClose={() => setKontribute(null)}
                    open={!!kontribute}
                    title={""}
                >
                    <AjoDetails
                        group={kontribute}
                        handleCloseDetail={() => setKontribute(null)}
                        isMine
                    />
                </SidePane>
            </>
        </DashboardComponentWrapper>
    )
}

export default AdminBusiness;

const BusinessList = (props) => {
  
    return (
      <div className='businesses'>
        {
          props.businesses?.data?.length === 0 && (
            <NotFound 
              text1="You have not profiled Any business yet!"
              text2='Create a business profile and Manage payments and generate invoices for your products and services hassle free.'
              btnLabel="Create a Business"
              onClick={() => props.handleCreate()}
            />
          )
        }
        {
          Array.isArray(props.businesses?.data) && props.businesses?.data?.map((business, index) => (
            <BusinessCard
              logo={business.business_logo}
              name={business.business_name}
              category={business.type}
              rc={business.business_code}
              key={`${index}${business.business_name}`}
              setToDelete={(event) => {
                event.stopPropagation();
                // setToDelete(business)
              }}
            />
          ))
        }
        <TablePaginationFooter
            total={props.businesses?.total}
            from={props.businesses?.from}
            to={props.businesses?.to}
            nextUrl={props.businesses?.next_page_url}
            prevUrl={props.businesses?.prev_page_url}
            handlePagination={props.handlePagination}
        />
      </div>
    )
  };

import "./groupDetails.scss";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useCallback, useState } from "react";
import { deleteUserGroupActionCreator } from "../../actions/dashboardActions";
import toast from 'react-hot-toast';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { formatCurrency } from "../../utils/common";
dayjs.extend(relativeTime);

interface IGroupDetailsCard {
    id: string;
    title: string;
    category: string;
    description: string;
    paid: number;
    target: number;
    openEdit: () => void;
    uniqueCode?: string;
    hasMembers: boolean;
    members: any;
    isMine?: boolean;
    pendingPayment: boolean;
    handleCloseDetail: () => void;
		group: any;
}



const GroupDetailsCard = ({
    id, 
    title, 
    description, 
    paid, 
    target, 
    openEdit,
    uniqueCode,
    handleCloseDetail,
	group,
    isMine,
    members,
    pendingPayment
}: IGroupDetailsCard) => {
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleOpenDeleteConfirm = useCallback(() => {
        if (paid > 0) {
            toast.error('You can not delete a group ongoing transaction!')
            return;
        }
        setIsDeleteOpen(true);
    }, [paid]);

    const handleOpenEditConfirm = useCallback(() => {
        if (paid > 0) {
            toast.error('You can not edit a group with an ongoing transaction!')
        } else  {
            openEdit();
        }
    }, [openEdit, paid]);
    
    const handleCloseDeleteConfirm = useCallback(() => {
        setIsDeleteOpen(false);
    }, []);

    const deleteExpense = useCallback(() => {
        deleteUserGroupActionCreator({id}, () => {
            setIsDeleteOpen(false);
            handleCloseDetail();
        })
    }, [id, handleCloseDetail]);

  
    return (
        <div className="right-wrapper">
            <div className="flex flex-col w-full">
                <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Date</p>
                    <p className="text-sm text-az-black-900">{dayjs(group.created_at).fromNow()}</p>
                </div>
                <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Title</p>
                    <p className="text-sm text-az-black-900">{title}</p>
                </div>
                <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Amount to Kontribute</p>
                    <p className="text-sm text-az-black-900">{formatCurrency({num: target})}</p>
                </div>
                <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Paid</p>
                    <p className="text-sm text-az-black-900">{formatCurrency({num: paid})}</p>
                </div>
                <div className="flex w-full flex-row justify-start items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Description</p>
                    <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{description}</p>
                </div>
                <div className="flex w-full flex-row justify-center items-center py-4">
                {/* <div onClick={handleOpenEditConfirm} className="!border-2 border-az-teal rounded px-8 py-2">
                    <span>Edit</span>
                </div> */}
                <button onClick={handleOpenDeleteConfirm} className="bg-az-teal rounded text-white w-fit h-10 px-8">
                    <span>Delete</span>
                </button>
                </div>
            </div>
            <ConfirmModal
                isOpen={isDeleteOpen}
                onClose={handleCloseDeleteConfirm}
                text="Are you sure you want to delete this Kontribute? All data about it will be lost after this action."
                handleNo={handleCloseDeleteConfirm}
                handleYes={deleteExpense}
            />
        </div>
    )
}

export default GroupDetailsCard

interface ITransactionsListWrapper {
  children: JSX.Element;
  renderNavItems?: () => JSX.Element;
}

const TransactionsListWrapper = ({ children, renderNavItems }: ITransactionsListWrapper) => {
  return (
    <div className=" bg-white  !w-[95%] !p-0 !m-auto">
      <div className="sub-nav-wrapper px-4">
        {renderNavItems && renderNavItems()}
      </div>

      <div className="!w-full !p-0">{children}</div>
    </div>
  );
};

export default TransactionsListWrapper;

import Logo from '../../../../../assets/Logo.svg';
import Facebook from '../../../../../assets/new/facebook.png';
import Instagram from '../../../../../assets/new/instagram.png';
import Youtube from '../../../../../assets/new/youtube.png';
import './footer.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import useIsAuthLocation from '../../../../../hooks/useIsAuthLocation';
import MessageIcon from '../../../../../assets/new/Message.png';
import { useState } from 'react';
import { trackAction } from '../../../../../utils/tracker';
import { useHideOldComponents } from '../../../../../hooks/useHideOldComponant';
import { submitInquiry } from '../../../../../actions/authActions';

const socials = [
  {
    icon: Facebook,
    link: 'https://www.facebook.com/people/Azatmepay/100090231233829/?mibextid=ZbWKwL'
  },
  {
    icon: Youtube,
    link: 'https://www.youtube.com/@azatmepay'
  },
  {
    icon: Instagram,
    link: 'https://www.instagram.com/azatmepay/'
  }
]

const Footer = () => {
  const [isAuthLocation] = useIsAuthLocation();
  const hide = useHideOldComponents();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const path = pathname === '/business_app' ? `${pathname}/` : pathname !== '/' ? '/' : '';

  if (isAuthLocation || hide) return null;

  const saveSub = (event) => {
    event.preventDefault();
    setLoading(true);
    submitInquiry({
      first_name: 'Subscription',
      last_name: 'Subscription',
      issue: 'Subscription',
      phone_number: 'Subscription',
      email,
    }, () => {
      setLoading(false);
      setEmail('');
    }, setLoading, 'Subscription')
  };

  return (
    <div className="new-footer h-fit bg-[#042527] px-[6%] py-[6%]">
      {
        pathname.includes('business_app') && (
          <div className='flex flex-col justify-center items-center w-full border-b border-[#FFFFFF1A] mb-[100px] pb-[100px]'>
            <h1 className='text-white font-medium text-[40px] w-10/12 max-w-[620px] text-center'>Open your Azatme account now. It’s fast and easy.</h1>
            <button type='submit' onClick={() => navigate('/register')} className="bg-az-teal h-[50px] text-white mt-10 w-fit font-medium text-sm px-10 font-Onest rounded-md">Create an account - It’s free</button>
          </div>
        )
      }
      <div className="subscribe section mb-20">
        <p className='text-[26px] lg:text-4xl font-medium max-w-[650px] w-full text-white mb-[40px]'>
          Subscribe to our weekly newsletter
          for fun, finance, update and more.
        </p>
        <form className='footer-form w-full lg:w-fit flex flex-col lg:flex-row ' onSubmit={saveSub}>
          <div className='flex flex-row h-[50px] w-full lg:w-fit rounded-md'>
            <div className='h-[50px] w-[50px] flex flex-row justify-center items-center border border-[#FFFFFF4D] px-4 rounded-s-md'>
              <img src={MessageIcon} alt="" />
            </div>
            <input
              type="email"
              value={email}
              onChange={({target}) => {
                setEmail(target.value);
              }}
              placeholder='Email Address' 
              required
              className='w-full lg:w-[30rem] bg-transparent border border-[#FFFFFF4D] px-3 rounded-e-md'
            />
          </div>
          <button type='submit' className="bg-az-teal h-[50px] text-white mt-5 lg:mt-0 lg:mx-5 w-full lg:w-40 font-medium text-sm  font-Onest rounded-md">{loading ? 'Loading' : 'Subscribe'}</button>
        </form>
      </div>
      <div className="top !py-10 h-fit">
        <div className="home section">
            <img src={Logo} alt="" className='w-24 mb-5 lg:mb-0' />
            <p className='text-sm font-normal mb-5 lg:mb-0 w-[290px]'>
              Your Go To, End to End Payment and Finance
              Solution.
            </p>
        </div>
        <div className="home section">
          <h5 className='text-white text-[16px] font-medium'>Home</h5>
          <ul className='text-[#FFFFFFB2] text-[14px] font-normal'>
            <NavHashLink to={`${path}#hero`} smooth><li>Home</li></NavHashLink>
            <NavHashLink to={`${path}#contact`} smooth><li>Contact</li></NavHashLink>
            <NavHashLink to={`${path}#features`} smooth><li>Features</li></NavHashLink>
            <NavHashLink to={`${path}#howitworks`} smooth><li>How it Works</li></NavHashLink>
          </ul>
        </div>
        <div className="resources section">
          <h5 className='text-white text-[16px] font-medium'>Resources</h5>
          <ul className='text-[#FFFFFFB2] text-[14px] font-normal'>
            <NavHashLink to={`${path}#faqs`}><li>FAQs</li></NavHashLink>
            <NavLink to="/privacy"><li>Privacy Policy</li></NavLink>
            <NavLink to="/user-agreement"><li>User Agreement</li></NavLink>
            <NavLink to="/terms"><li>Terms & Conditions</li></NavLink>
          </ul>
        </div>
        <div className="links  section">
          <h5 className='text-white text-[16px] font-medium'>Quick Links</h5>
          <ul className='text-[#FFFFFFB2] text-[14px] font-normal'>
            <NavLink to="/login"><li>Login</li></NavLink>
            <NavLink to={`${path}#business`}><li>Business</li></NavLink>
            <NavLink to="/developers"><li>Developer</li></NavLink>
            <NavLink to="/register"><li>Get Started</li></NavLink>
          </ul>
        </div>
      </div>
      <div className="bottom h-fit border border-transparent border-t-[#FFFFFF4D] pt-14">
        <div className='brand'>
          <p className='text-base font-normal mb-5 lg:mb-0'>Pethahiah Rehoboth &copy; {new Date().getFullYear()}</p>
        </div>
        <div className="s-media">
          {
            socials.map(({link, icon}) => (
              <a
                key={link} 
                href={link} 
                target="_blank" 
                rel="noreferrer"
                onClick={() => trackAction(link.split('//')[1], 'Social Media Click')}
              >
                <img src={icon} alt="" />
              </a>
            ))
          }
        </div>
      </div>
    </div>
  )
};

export default Footer;

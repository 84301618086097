import './newversion.scss';

const NewVersion = ({showUpdate}) => {
  const handleReload = () => {
    // @ts-ignore
    const newWindow = window.open('https://azatme.com','_blank');
    newWindow?.opener.close();
  }
  if (!showUpdate) return null;
  return (
    <div className='update-wrapper'>
      <div className='update-card'>
        <h2>App Update.</h2>
        <h3>There is a new version of this app.</h3>
        <button className='!px-4 !py-2' onClick={handleReload}>Click to Update</button>
      </div>
    </div>
  )
};

export default NewVersion;

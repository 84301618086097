const privacy = {
  title: "PRIVACY POLICY",
  effective: "April 2023",
  details: [
    {
      title: "",
      listType: '',
      item: ["This Privacy Policy (referred to below as the ‘Policy’) sets out how we use the data you provide to us. AzatMe takes your privacy very seriously, and we pledge to only collect and use your data in accordance with this Policy"],
      description: ''
    },
    {
      title: "",
      listType: '',
      item: ["AzatMe (referred to as ‘us’, ‘our’ or ‘we’) is a trading name of Pethahiah Rehoboth Ltd, a company registered in Federal Republic of Nigeria (No. 809123), who is the data controller and responsible for your personal data. Pethahiah Rehoboth Ltd is authorised by the Central Bank of Nigeria as a Payment Solutions Services Provider, able to provide payment initiation services and account data services. ‘You’ means any user of any AzatMe Services or other category of individual referred to or addressed in this Policy and ‘your’ is construed accordingly."],
      description: ''
    },
    {
      title: "",
      listType: '',
      item: ["From time to time we may update this Policy by posting it on our Website. Where we make significant and material changes to this Policy, we will endeavour to notify you of those changes. By continuing to use our Services, you acknowledge our use of your personal data as set forth in the relevant sections of this Policy."],
      description: ''
    },
    {
      title: "",
      listType: 'list',
      item: ["online, via our website (mobile and desktop versions), https://AzatMe.com/ (“Website”), and our app, AzatMe (“App”) (together, the “Services”), and other online communication mechanism (e.g. email); and", "offline, via all channels of communication including post, phone or otherwise."],
      description: "This Policy explains how we use the data we collect about the people who use our Services or otherwise interact with us, how we keep it secure and the conditions under which we may disclose it to others. This Policy applies to data we collect and process:"
    },
    {
      title: "WHAT DATA DO WE COLLECT AND HOW DO WE PROCESS IT?",
      listType: 'list',
      listTitle: "Data we collect:",
      item: ["Contact data, including your name and email address. Where you are a business or a charity, we also collect the registered company/charity name, registered number and registered address.", "Financial data, your bank account name, BVN, NIN, account number, any nickname which you assign to the account and the account ID.", "Feedback and correspondence, such as information you provide using the Website chat function, report a problem with the Services, receive customer support or otherwise correspond or interact with us.", "Technical data, including your IP address, certain technical information regarding your device and how you use and interact with the Services."],
      description: "We are a privacy-centric organisation and AzatMe does not collect more information than is absolutely necessary to provide our services and meet our legal and regulatory obligations. We have summarised our collection and use of your data below:"
    },
    {
      title: "",
      listType: 'list',
      listTitle: "Why we collect it:",
      item: ["To provide our Services.", "To respond to your inquiries and fulfil your requests.", "To send you marketing communications (in accordance with your preferences).", "To communicate Service updates.", "For legal compliance and fraud prevention (including anti-money laundering).", "To improve our Services and develop new services.", "For operating and expanding our business."],
      description: ''
    },
    {
      title: "",
      listType: 'list',
      listTitle: "Legal basis:",
      item: ["To provide the Services to you or to take steps at your request prior to providing our Services.", "Your consent (in relation to downloading the app, cookies and marketing only).", "To comply with our legal obligations.", "Our legitimate interests, including fulfilling our legal and contractual duties, complying with regulations that apply to us, ensuring the safety and security of our Services, preventing fraud, and developing our Services and expanding our business."],
      description: ''
    },
    {
      title: "All users",
      listType: '',
      listTitle: "",
      item: ["In order to provide our Services, where you make a payment through AzatMe or access our Website, we collect your IP address. If you have used the Website or our chat function to contact us, we collect your name, the email address you use to contact us and any other information you provide."],
      description: ''
    },
    {
      title: "Individual users",
      listType: 'list',
      listTitle: "",
      item: ["Where you as an individual receive a payment through AzatMe, we collect your name, email address, your bank account name, NIN, BVN, account number, any nickname which you assign to the account, the account ID and your IP address."],
      description: ''
    },
    {
      title: "Business / Organisation users",
      listType: '',
      listTitle: "",
      item: ["For businesses or charities receiving payments through AzatMe, we are obliged to collect the following information for “know your customer” purposes: contact name and email address, the registered company/charity name, registered number and registered address. In addition, we collect the following information in order to perform our Services: the bank account name, account number, any nickname which you assign to the account, the bank account ID and your IP address. We also collect certain personal data of the end users or customers (i.e. the users or customers of the services into which our Services are integrated) of your business or charity as described above under “individual users.”"],
      description: ''
    },
    {
      title: "Commercial counterparties",
      listType: '',
      listTitle: "",
      item: ["We collect certain personal data of the personnel, representatives and/or agents of the commercial counterparties (for example, app developers and our service providers) with which we engage as part of our day-to-day business operations and the management of our business. In most cases, this includes full name, work email address and the organization they work for."],
      description: ''
    },
    {
      title: "Downloading the App",
      listType: '',
      listTitle: "",
      item: ["When you download our App, we do not collect data at the point of download but the respective app store enabling the download may collect information. Please refer to the privacy policy of the app store in question."],
      description: ''
    },
    {
      title: "Sharing data",
      listType: 'list',
      listTitle: "We may share personal data with third parties as follows:",
      item: ["Our service providers: We may share your data with AzatMe’s third party service providers (including third party agents and/or subcontractors) for the purpose of providing the Services to you. Where we do so, we only disclose the data that is necessary to deliver the Services. We require each such third-party provider to keep your data secure to the same standards as that of AzatMe and restrict them from any processing of your data for their own purposes.", "Business transfers: If we sell, transfer or merge parts of our business or our assets, we share personal data with the purchaser of that business/those assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use any personal data in the same way as set out in this Policy.", "Compliance with laws and the protection of our rights and the rights of others: We may share your personal data when we, in good faith, believe disclosure is appropriate to comply with the law, a court order or a subpoena. We may also disclose your personal data to prevent or investigate a possible crime, such as fraud or identity theft; to protect the security of the Website; to enforce or apply our online terms of use or other agreements; or to protect our own rights or property or the rights, property or safety of our users or others. The recipients of this type of disclosure include law enforcement, court and regulatory authorities, government agencies (including tax authorities) and professional advisors (e.g. lawyers, accountants, auditors etc.)."],
      description: ''
    },
    {
      title: "RETENTION OF YOUR DATA",
      listType: '',
      listTitle: "",
      item: ["To determine the appropriate retention period for the personal data we collect from you, we consider the amount, nature, and sensitivity of the data, the potential risk of harm from unauthorized use or disclosure of the data, the purposes for which we process the data and whether we can achieve those purposes through other means, and the applicable legal requirements. As a general rule, the period we keep personal data for is linked to the amount of time to bring legal claims, which is six or seven years after a payment or receipt of a payment. After this time, we will only keep your data where it is required by applicable law or regulation. In some circumstances we may anonymize your personal data (so that it can no longer be associated with you) in which case we may use this information indefinitely without further notice to you."],
      description: 'We will only retain your personal data for as long as necessary to fulfil the purposes for which it was collected, including for the purposes of satisfying legal, accounting, or reporting requirements. This will include the period of our relationship with you, together with a retention period after our relationship has ended (for example, when you delete your account).'
    },
    {
      title: "TRANSFER OF YOUR DATA OUTSIDE OF NIGERIA",
      listType: '',
      listTitle: "",
      item: ["Your data may be stored in cloud infrastructure, and this may mean it is transferred outside of Nigeria. To the extent we transfer or process your personal data outside Nigeria, we ensure the transfer is either (a) to a country deemed to provide an adequate level of protection or (b) pursuant to specific contracts to ensure your data is adequately protected."],
      description: ''
    },
    {
      title: "YOUR RIGHTS AS A DATA SUBJECT",
      listType: 'list',
      listTitle: "Data we collect:",
      item: ["Request access to your personal data.", "Request correction of your personal data.", "Request erasure of your personal data.", "Object to processing of your personal data.", "Request restriction of processing your personal data.", "Request transfer of your personal data.", "Right to withdraw consent (where we rely on your consent as our basis for processing)."],
      description: 'Under certain circumstances, you have rights under data protection laws in relation to any personal data we have about you. We try to respond to all legitimate requests within one month, although we will notify you if it takes longer.'
    },
    {
      title: "",
      listType: '',
      listTitle: "",
      item: [""],
      description: 'You have the right to make a complaint at any time to your supervisory authority for data protection issues. For Nigeria, this is the National Commissioner’s Office (https://ndpb.gov.ng/). If you wish to exercise any of these rights, please contact us at info@pethahiah.com.'
    },
    {
      title: "MARKETING",
      listType: '',
      listTitle: "",
      item: ["You may opt out of marketing-related emails by clicking on a link at the bottom of each such email, or by contacting us using the information below. You may continue to receive service-related and other non-marketing emails for which you have not opted out.", "We do not sell, rent or share your data with third parties for their marketing purposes."],
      description: 'Where we send marketing communications, we will provide you with an “opt in” or “opt out” mechanism depending on your status as an individual user or a business user. An “opt in” mechanism will provide you the opportunity to positively indicate that you would like or do not object to our sending you such further communications and we will not send you any unless you have “opted in”. An “opt out” mechanism (e.g., “unsubscribe”) will provide you the opportunity to indicate that you do not want us to send you such further communications, and if you “opt out” we will not send you any.'
    },
    {
      title: "SECURITY",
      listType: '',
      listTitle: "",
      item: [""],
      description: 'We have implemented appropriate technical and organizational measures designed to protect your personal data. For example, we use industry-standard encryption methods to ensure the security of your data in accordance with applicable law and regulations. This includes utilising appropriate measures to safeguard data against unauthorised access, disclosure, alteration, or destruction. These measures may also include, among others, encryption, physical access security, auditing, and other appropriate technologies. However, we cannot guarantee that this information will never be disclosed in a manner inconsistent with this Policy (for example, because of unauthorized acts by third parties that violate applicable law).'
    },
    {
      title: "AUTOMATED INTERACTIONS AND TECHNOLOGIES",
      listType: '',
      listTitle: "",
      item: ["We collect information using automated technologies such as cookies and other tracking technologies. Cookies are files that gather small amounts of data from the device they are stored on. We may ask you accept cookies and/or other similar automated technologies (together “Cookies”) in order to use AzatMe Services.", "We use Cookies to enhance your experience of AzatMe and to better understand how our Services are used. Cookies may tell us, for example, whether you have visited our site before or whether you are a new visitor. This helps us to provide you with a good experience when you browse our Website and also allows us to improve our site.", "We use both ‘session cookies’ (which exist only while your browser is open) and ‘permanent cookies’ which survive after your browser is closed. Permanent cookies can be used by the Website to recognise your computer or mobile device when you open your browser and browse the Internet again.", "You can find more information about cookies and how to manage them at www.allaboutcookies.org."],
      description: ''
    },
    {
      title: "",
      listType: 'list',
      listTitle: "Our Services use the following types of Cookies for the purposes set out below:",
      item: ["Essential Cookies: These cookies are essential to provide you with services available through our Services and to enable you to use some of their features. Without these cookies, the services that you request via our Services may not be possible to provide. We only use these cookies to provide you with those Services. Under applicable law, we do not require your consent to use these cookies.", "Analytics and Performance Cookies: These cookies are used to collect information about traffic to our Services and how users use our Services. The information gathered may include the number of visitors to our Services, the websites that referred them to our Services, the pages they visited on our Services, what time of day they visited our Services, whether they have visited our Services before, the region/territory in which you download our App, and other similar information. We use this information to help operate our Services more efficiently, to gather demographic information and to monitor the level of activity on our Services."],
      description: ''
    },
    {
      title: "",
      listType: '',
      listTitle: "",
      item: ["You can typically remove or reject Cookies via your browser settings. To do this, follow the instructions provided in your browser (usually located within the “settings,” “help,” “tools” or “edit” facility). Many browsers are set to accept Cookies until you change your settings."],
      description: ''
    },
    {
      title: "",
      listType: '',
      listTitle: "",
      item: ["If you do not accept our Cookies, you may experience some inconvenience in your use of our Services."],
      description: ''
    },
    {
      title: "CHILDREN",
      listType: '',
      listTitle: "",
      item: ["AzatMe is available to anyone with a Nigerian bank account, including children. As described in this Privacy Policy we collect and retain the minimum amount of personal data on our users in order to make our Services work for everyone. We may limit how we collect and use the personal data of users under 16. If we need to rely on consent as a legal basis for processing your personal data and you are under the age of 13, we will require your parent’s consent before we collect and use that information."],
      description: ''
    },
    {
      title: "QUESTIONS AND CONTACT DATA",
      listType: '',
      listTitle: "",
      item: ["If you would like to access, correct, amend or delete any personal data we have about you, register a complaint, or simply want more information about how we process your data, you can contact our Data Protection Officer at info@pethahiah.com."],
      description: ''
    }
  ]
};

export default privacy;
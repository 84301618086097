import { useEffect, useState } from "react"
import { useAppSelector } from "../app/hooks";

const useIsAuthLocation = () => {
  const {isAuthenticated} = useAppSelector((state) => state.auth);
  const [isAuthLocation, setIsAuthLocation] = useState(false);
  useEffect(() => {
    setIsAuthLocation(isAuthenticated);

  }, [isAuthenticated]);
  return [isAuthLocation];
};

export default useIsAuthLocation;

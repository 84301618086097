/* eslint-disable eqeqeq */
import "./details.scss";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);


const ExpenseDetailsCard = ({
  user,
}: {
  user: Record<string, any>
}) => {

  return (
    <div className="right-wrapper">
      <div className="flex flex-col w-full">
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Name</p>
          <p className="text-sm text-az-black-900">{user.first_name || user.name} {user.last_name} {user.middle_name}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Email</p>
          <p className="text-sm text-az-black-900">{user.email}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Phone</p>
          <p className="text-sm text-az-black-900 uppercase">{user.phone}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">Address</p>
          <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{user.address}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">LGA</p>
          <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{user.lga_of_origin}</p>
        </div>
        <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
          <p className="text-sm text-az-black-900">State</p>
          <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{user.state}</p>
        </div>
        {/* <div className="flex w-full flex-row justify-end items-center py-4">
          <div onClick={handleResolveFeedback} className="!border-2 border-az-teal rounded px-8 py-2 cursor-pointer">
            <span>View Entities</span>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default ExpenseDetailsCard
import { useEffect, useState, useCallback } from 'react';
import { getBanks } from '../../actions/bankActions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import CreatableSelect from 'react-select/creatable';
import { AddBankForm } from '../../pages/Bank';
import Button from '../Button';

const BankInput = (props) => {
  const dispatch = useAppDispatch();
  const [addBank, setAddBank] = useState(false);
  const invitationData = localStorage.getItem('pendingInvitation');
  const [showNoBankModal, setShowNoBankModal] = useState(false);
  // const [selectedBank, setSelectedBank] = useState<Bank>();
  const [data, setData] = useState({
    bankName: '',
  });
  const {banks} = useAppSelector((state) => state.bank);
  useEffect(() => {
    !banks && getBanks(dispatch);
  }, [banks]);

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const handleSelectBank = (bankName) => {
    const [name, numba] = bankName.split('-');
    const selectedBank = banks?.find((bank) => ((bank.bankName === name && (bank.account_number === numba))));
    props.setSelectedBank(selectedBank);
  }

  return (
    <div className='custom-wrapper' style={props.overrideStyle}>
      <label htmlFor="" className='text-sm'>{props.label || 'Select Collection Bank Account'} </label>
      <CreatableSelect
        className="react-select-container !rounded"
        options={banks?.map((item) => (
        {
            label: `${item.bankName}-${item.account_number}`, 
            value: `${item.bankName}-${item.account_number}`
        }))
        }
        name="bankName"
        value={{
            label: data.bankName,
            value: data.bankName
        }}
        onChange={(item) => {
          if (item) {
            handleSelectBank(item.value);
            handleChange('bankName', item.value);
          }
        }}
        onMenuOpen={() => {
          if (banks && banks.length === 0 && invitationData) {
            setShowNoBankModal(true);
          }
        }}
        onCreateOption={(option) => {
          setShowNoBankModal(true);
        }}
        formatCreateLabel={(inputValue: string) => (
          <div style={{
            height: '50px',
          }}>
            <Button
              label={`Add new Bank: "${inputValue}"`}
              type="flat"
              btnActionType="button"
              overrideStyle={{
                margin: '0',
              }}
            />
          </div>
        )}
      />
      {showNoBankModal  && <AddBankForm showForm={showNoBankModal} />}
    </div>
  )
}

export default BankInput;

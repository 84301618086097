import { useEffect } from 'react';
import { scrollUp } from './common';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
	const { pathname, hash } = useLocation();

	useEffect(() => {
		if (pathname && !hash) {
			scrollUp();
		}
	}, [hash, pathname]);

	return null;
};

export default ScrollToTop;

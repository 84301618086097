import { useCallback, useState } from 'react';
import Menu from '../../assets/menu.png';
import WithdrawIcon from '../../assets/new/withdrawIcon.png';
import Close from '../../assets/close-icon.png';
import './dashboardNavItem.scss';
import { Select } from '../Input';
import WithdrawMFA from '../WithdrawMFA';
import { useLocation } from 'react-router-dom';
import { getLedger, handleSetWithdrawalSource } from '../../actions/authActions';
import { withDrawFundExpense, withDrawFundGroup, withdrawAjo } from '../../actions/dashboardActions';
import { withdrawTransaction } from '../../actions/businessActions';
import { useAppDispatch } from '../../app/hooks';
import useVerificationDone from '../../hooks/useVerificationDone';
import { setShowVerificationPending } from '../../pages/Auth/authSlice';
import useIsAdmin from '../../hooks/useIsAdmin';

const GenericWithdrawalComponent = () => {
  const {isVerificationDone} = useVerificationDone();
  const [withdrawSource, setWithdrawState] = useState('');
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const {pathname} = useLocation();
  const handleBtnPress = () => {
    if (!isVerificationDone) {
      dispatch(setShowVerificationPending(true));
      return;
    }
    setShow(true);
    const path = pathname.split('/');
    const currentPath = path[path.length - 1];
    setWithdrawState(currentPath)
    handleSetWithdrawalSource(currentPath as 'refundMe' | 'kontribute' | 'transaction' | '');
  }

  const handleMakeWithdraw = async (amt: number, selectedBank: any, closeWithdraw) => {
    let handlerFunc;
    switch (withdrawSource) {
      case 'refundme':
        handlerFunc = withDrawFundExpense;
        break;
      case 'kontribute':
        handlerFunc = withDrawFundGroup;
        break;
      case 'business':
        handlerFunc = withdrawTransaction;
        break;
      case 'ajo':
        handlerFunc = withdrawAjo;
        break
    }

    handlerFunc({
      account_number: selectedBank.account_number,
      amount: amt,
      bank: selectedBank.bankName,
      bankcode: selectedBank.bankCode,
    }, (resp) => {
      if (resp) {
        setShow(false);
        handleSetWithdrawalSource('');
        closeWithdraw();
        getLedger();
      }
    });
  }
  return (
    <div className='withdraw-wrapper'>
      <button
        onClick={handleBtnPress}
        className='w-fit px-4 h-10 flex flex-row justify-around items-center bg-az-black-900 text-white border-none'
      >
        <img src={WithdrawIcon} alt="" className='mr-4' />
        Withdraw
      </button>
      <WithdrawMFA
        openWithdrawMFA={show}
        handleCloseModal={() => setShow(false)}
        handleDone={handleMakeWithdraw}
        item={undefined}
        source={withdrawSource}
      />
    </div>
  )
}

const DashboardNavItem = ({navItems, tab, setTab, isSecondTier, navItemsObj, dontShowWithdraw} : {
  navItems: Array<string>; 
  tab: string; 
  setTab: (val: string) => void;
  isSecondTier?: boolean;
  navItemsObj?: Array<Record<string, any>>;
  dontShowWithdraw?: boolean;
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const isAdmin = useIsAdmin();

  const handleClick = useCallback((item) => {
    setTab(item);
    showDropDown && setShowDropDown(false);
  }, [showDropDown, setTab]);

  if (isSecondTier) {
    return (
      <div className='filter-wrapper flex-wrap my-0 !h-fit'>
        {
          !isAdmin && (
            <>
              <input type="" className='h-10 border border-gray-300 w-96 px-4 my-0' placeholder='Search' />
              <div className='flex flex-row w-auto justify-around items-center my-0'>
                <div className='flex flex-row justify-between items-center w-auto'>
                  <label htmlFor="" className='mr-4 w-[100px]'>Filter by:</label>
                  <Select
                    name="filter"
                    label=''
                    data={navItems}
                    onChange={(field, value) => {
                      setTab(value);
                    }}
                    value={tab}
                    selectClassName='!bg-gray-300'
                  />
                </div>
              </div>
            </>
          )
        }
        
      </div>
    )
  }
  if (navItemsObj) {
    return (
      <div className="!w-fit h-10 flex flex-row">
        {
          !dontShowWithdraw && <GenericWithdrawalComponent />
        }
        {
          navItemsObj.map((itm) => {
            return (
              <button key={itm.label} className='text-white px-4 ml-4 flex flex-row w-fit justify-around items-center h-10' onClick={itm.action} style={{background: itm.bgColor}}>
                <img src={itm.img} alt="" className='mr-2' />
                {itm.label}
              </button>
            )
          })
        }
      </div>
    )
  }
  return (
    <ul className="sub-nav dashboard-nav">
      <img
        src={showDropDown ? Close : Menu}
        alt="options"
        className="nav-menu"
        onClick={() => setShowDropDown(!showDropDown)}
      />
      {
        navItems.map((item) => (
          <li key={item} className={item === tab ? 'selected' : ''} onClick={() => handleClick(item)}>
            {item}
          </li>
        ))
      }
      {
        showDropDown && (
          <div className='subnav-dropdown'>
            {
              navItems.map((item) => (
                <li key={item} className={item === tab ? 'selected' : ''} onClick={() => handleClick(item)}>
                  {item}...
                </li>
              ))
            }
          </div>
        )
      }
    </ul>
  )
}

export default DashboardNavItem;

import LoaderImg from '../../assets/loader.gif';

import './pageloader.scss';

const PageLoader = () => {
  return (
    <div className="page-loader">
      <img src={LoaderImg} alt="Loading..." />
    </div>
  )
};

export default PageLoader;

const UserSVG = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="stroked" d="M17.8887 10.8967C19.2837 10.7007 20.3577 9.50473 20.3607 8.05573C20.3607 6.62773 19.3197 5.44373 17.9547 5.21973" stroke="#222222" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroked" d="M19.7285 14.25C21.0795 14.452 22.0225 14.925 22.0225 15.9C22.0225 16.571 21.5785 17.007 20.8605 17.281" stroke="#222222" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroked" fillRule="evenodd" clipRule="evenodd" d="M11.8867 14.6641C8.67273 14.6641 5.92773 15.1511 5.92773 17.0961C5.92773 19.0401 8.65573 19.5411 11.8867 19.5411C15.1007 19.5411 17.8447 19.0591 17.8447 17.1131C17.8447 15.1671 15.1177 14.6641 11.8867 14.6641Z" stroke="#222222" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroked" fillRule="evenodd" clipRule="evenodd" d="M11.8874 11.888C13.9964 11.888 15.7064 10.179 15.7064 8.069C15.7064 5.96 13.9964 4.25 11.8874 4.25C9.77839 4.25 8.06839 5.96 8.06839 8.069C8.06039 10.171 9.75739 11.881 11.8594 11.888H11.8874Z" stroke="#222222" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroked" d="M5.88411 10.8967C4.48811 10.7007 3.41511 9.50473 3.41211 8.05573C3.41211 6.62773 4.45311 5.44373 5.81811 5.21973" stroke="#222222" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="stroked" d="M4.044 14.25C2.693 14.452 1.75 14.925 1.75 15.9C1.75 16.571 2.194 17.007 2.912 17.281" stroke="#222222" strokeOpacity="0.7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};

export default UserSVG;

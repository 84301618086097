
import { useEffect, useState } from "react";
import ExpenseDetails from "../ExpenseDetails";
import NotFound from "../NotFound";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import { useAppSelector } from "../../app/hooks";


const AdminUsersList = (props) => {
  const {users} = useAppSelector((state) => state.admin)
  const [expense, setExpense] = useState<Record<string, any>>({});
  const handleCloseDetail = () => {
    setExpense({});
  }

  const {setHideNav} = props;

  useEffect(() => {
    if (expense.id) {
      setHideNav(true);
    }
  }, [expense.id, setHideNav]);

  if (expense.id) {
    return <ExpenseDetails expense={expense} handleCloseDetail={handleCloseDetail} />;
  }
  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    props.getAllUsers(page);
  }

  return (
    <div className="bg-[#ffffff]  border border-white">
      <div className="">
        <div className="">
          <div className='head bg-[#0898A01A] h-14 hidden lg:flex'>
            <div className='row flex flex-row justify-between !w-full items-center !h-14'>
              <div className='!w-[10%] pl-3'>Firstname</div>
              <div className='!w-[10%] pl-3'>Lastname</div>
              <div className='!w-[10%] pl-3'>Phone Number</div>
              <div className='!w-[12%] pl-3'>Email</div>
              <div className='!w-[12%] pl-3'>User Type</div>
              <div className='!w-[10%] !border !border-red'>Status</div>
            </div>
          </div>
          <div className="body min-h-[50vh]">
            <div className="body-section ">
              {(!users || users?.data?.data.length === 0) ?
                (
                  <NotFound 
                    text1="No Inquiry in the system yet!"
                    text2='Manage all users sent in here.'
                    btnLabel=""
                    onClick={() => {}}
                  />
                ) :
                users?.data?.data.map((item) => {
                  return (
                    <Inquiry
                      key={item.id}
                      user={item}
                      setUser={props.setUser}
                    />
                  );
                })}
            </div>
          </div>
            {
                users?.data?.length !== 0 && (
                <TablePaginationFooter 
                    total={users?.data.total}
                    from={users?.data.from}
                    to={users?.data.to}
                    nextUrl={users?.data.next_page_url}
                    prevUrl={users?.data.prev_page_url}
                    handlePagination={handlePagination}
                />
                )
            }
        </div>
      </div>
    </div>
  );
};

export default AdminUsersList;

export function Inquiry({ user, setUser }: any) {
    
    return (
      <>
        <div className='row flex-row w-full  hidden lg:flex cursor-pointer hover:bg-az-faint-red' onClick={() => setUser(user)}>
          <div className='w-[10%] pl-3'>{user.first_name || user.name}</div>
          <div className='w-[10%] pl-3'>{user.last_name}</div>
          <div className='w-[12%] pl-3'>{user.phone}</div>
          <div className='w-[12%] pl-3'>{user.email}</div>
          <div className='w-[12%] pl-3'>{user.usertype}</div>
          <div className='w-[10%]'>{user.isVerified ? 'Verified' : 'Unverified'}</div>
        </div>
        <div  onClick={() => setUser(user)} className='row rounded !flex-col !p-4 !justify-start !items-start !m-auto flex my-2 lg:hidden border border-gray !min-h-[100px] !h-max !w-[96%] cursor-pointer'>
            <div className='w-[5%]'>{user.first_name} {user.last_name}</div>
            <div className='w-[5%]'>{user.phone}</div>
            <div className='w-[5%]'>{user.email}</div>
            <div className='w-[10%] pl-3'>{user.usertype}</div>
            <div className='w-[10%]'>{user.isVerified ? 'Verified' : 'Unverified'}</div>
        </div> 
      </>
    )
  }

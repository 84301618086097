import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper";

const AdminCategories = () => {
    return (
        <DashboardComponentWrapper
            subTitle="Categories"
            pageDetails="Here's an overview of all Categories on Azatme."
        >
            <div>
                    Admin Categories
            </div>
        </DashboardComponentWrapper>
    )
}

export default AdminCategories;

import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import Bank from '../../assets/new/bank.png';
import Expense from '../../assets/new/refundme.png';
import UserGroupImage from '../../assets/new/contribute.png';
import Profile from '../../assets/new/profile.png';
import ComplainIcon from "../../assets/new/feedback.png";
import Overview from '../../assets/overview.png';
import HomeImg from '../../assets/new/home.png';
import AjoImg from '../../assets/new/ajo.png'
import { getCategoriesActionCreator, getUserAjoSilent, getUserGroupsActionCreator } from '../../actions/dashboardActions';

import "./dashboard.scss";
import { useEffect } from "react";
import { getExpenses } from "../../actions/dashboardActions";
import DashboardSideBar from '../../components/DashboardSideBar';
import useIsAdmin from '../../hooks/useIsAdmin';
import { getLedger } from '../../actions/authActions';
import HomeSVG from '../../components/SVG/Home';
import DashboardSVG from '../../components/SVG/Dashboard';
import BankSVG from '../../components/SVG/Bank';
import RefundMeSVG from '../../components/SVG/RefundMe';
import KontributeSVG from '../../components/SVG/Kontribute';
import ProfileSVG from '../../components/SVG/Profile';
import UserSVG from '../../components/SVG/User';
import FeedbackSVG from '../../components/SVG/Feedback';
import useVerificationDone from '../../hooks/useVerificationDone';

function Dashboard() {
  const {allExpenses, categories, allUserGroups, ajo} = useAppSelector((state) => state.dashboard);
  const {ledger} = useAppSelector((state) => state.auth);
  const {isVerificationDone} = useVerificationDone();
  const isAdmin = useIsAdmin();
  
  useEffect(() => {
    if (isVerificationDone) {
      if (!allExpenses) {
        getExpenses(1, isAdmin);
      }
      if (!categories) {
        getCategoriesActionCreator();
      }
      if (!allUserGroups) {
        getUserGroupsActionCreator();
      }
      if (Object.entries(ledger).length === 0) {
        getLedger();
      }
      if (!ajo) {
        getUserAjoSilent(1);
      }
    }
  }, [isVerificationDone]);
  

  const navItems = [
    {
      name: "home",
      path: `/dashboard/`,
      title: "Home",
      img: HomeImg,
      Icon: HomeSVG,
    },
    {
      name: "overview",
      path: `/dashboard/overview`,
      title: "Overview",
      img: Overview,
      Icon: DashboardSVG
    },
    {
      name: "bank",
      path: `/dashboard/bank`,
      title: "Bank",
      img: Bank,
      Icon: BankSVG
    },
    {
      name: 'refundme',
      path: `/dashboard/refundme`,
      title: "RefundMe",
      img: Expense,
      Icon: RefundMeSVG
    },
    {
      name: 'kontribute',
      path: `/dashboard/kontribute`,
      title: 'Kontribute',
      img: UserGroupImage,
      Icon: KontributeSVG
    },
    {
      name: 'ajo',
      path: `/dashboard/ajo`,
      title: 'Ajo Klub',
      img: AjoImg,
      Icon: UserSVG
    },
    {
      name: 'feedback',
      path: `/dashboard/feedback`,
      title: 'Feedback',
      img: ComplainIcon,
      Icon: FeedbackSVG
    },
    // {
    //   name: "referrer",
    //   path: `/dashboard/referrer`,
    //   title: "Referrer",
    //   img: Profile,
    //   Icon: ProfileSVG
    // },
    {
      name: "profile",
      path: `/dashboard/profile`,
      title: "Profile",
      img: Profile,
      Icon: ProfileSVG
    },
  ];


  return (
    <div className="dashboard flex flex-row justify-between items-start !relative">
      <DashboardSideBar firstItem="Overview" items={navItems} />
      <div className="outlet flex flex-auto flex-grow">
        <Outlet />     
      </div>
    </div>
  );
}

export default Dashboard;

import { formatCurrency } from "../../utils/common"

export const TableStatus = ({amountPaid, target}) => {
  return (
    <span className={`status-chip ${parseInt(amountPaid) >= parseInt(target) ? 'paid' : 'unpaid'}`}>{formatCurrency({num: amountPaid || 0})} of {formatCurrency({num: target})}</span>
  )
}

export const TableAction = ({handleMore}) => {
  return (
    <button type='button' className='status link-btn !rounded-full' onClick={() => handleMore && handleMore()}>
      <p className='!w-20 h-8 flex flex-row justify-center items-center !bg-az-light-red text-az-teal !rounded-full'>More</p>
    </button>
  )
}
import "./groupDetails.scss";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useCallback, useState } from "react";
import { deleteUserGroupActionCreator } from "../../actions/dashboardActions";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';


import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { AcceptAjoForm } from "../AjoInvitation/Accept";
import { DeclineAjoForm } from "../AjoInvitation/Decline";
import { formatCurrency } from "../../utils/common";
dayjs.extend(relativeTime);

interface IGroupDetailsCard {
    id: string;
    title: string;
    category: string;
    description: string;
    paid: number;
    target: number;
    openEdit: () => void;
    uniqueCode?: string;
    hasMembers: boolean;
    members: any;
    isMine?: boolean;
    pendingPayment: boolean;
    membersCount: number;
    handleCloseDetail: () => void;
		group: any;
}



const GroupDetailsCard = ({
    id, 
    title,  
    paid, 
    target, 
    openEdit,
    handleCloseDetail,
	group,
    isMine,
}: IGroupDetailsCard) => {
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [showAccept, setShowAccept] = useState(false);
    const [showDecline, setShowDecline] = useState(false);
    const [acceptData, setAcceptData] = useState({
        position: group.position,
        action: 'accept',
        invitee_name: '',
        email: '',
        inviter_token: '',
    });
    const [declineData, setDeclineData] = useState({
        position: '',
        action: 'reject',
        invitee_name: '',
        email: '',
        inviter_token: '',
    });

    const handleOpenDeleteConfirm = useCallback(() => {
        if (paid > 0) {
            toast.error('You can not delete a group ongoing transaction!')
            return;
        }
        setIsDeleteOpen(true);
    }, [paid]);

    const handleOpenEditConfirm = useCallback(() => {
        if (paid > 0) {
            toast.error('You can not edit a group with an ongoing transaction!')
        } else  {
            openEdit();
        }
    }, [openEdit, paid]);


    
    const handleCloseDeleteConfirm = useCallback(() => {
        setIsDeleteOpen(false);
    }, []);

    const deleteExpense = useCallback(() => {
        deleteUserGroupActionCreator({id}, () => {
            setIsDeleteOpen(false);
            handleCloseDetail();
        })
    }, [id, handleCloseDetail]);


    const handleAcceptClick = () => {
        setShowAccept(true);
        setAcceptData({
            position: group.position,
            action: 'accept',
            invitee_name: group.name,
            email: group.email,
            inviter_token: group.token,
        });
    }

    const handleDeclineClick = () => {
        setShowDecline(true);
        setDeclineData({
            position: '',
            action: 'reject',
            invitee_name: group.name,
            email: group.email,
            inviter_token: group.token,
        });
    }
  
    return (
        <div className="right-wrapper">
            <div className="flex flex-col w-full">
                <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Date</p>
                    <p className="text-sm text-az-black-900">{dayjs(group.created_at).fromNow()}</p>
                </div>
                <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Title</p>
                    <p className="text-sm text-az-black-900">{title}</p>
                </div>
                <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Amount per Individual</p>
                    <p className="text-sm text-az-black-900">{formatCurrency({num: target})}</p>
                </div>
                {/* <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Current collector</p>
                    <p className="text-sm text-az-black-900">{'-'}</p>
                </div> */}
                <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Members</p>
                    <p className="text-sm text-az-black-900">{isMine ? group.member_count : group.members}</p>
                </div>
                <div className="flex w-full flex-row justify-between items-center py-4 border-b-2 border-gray-300">
                    <p className="text-sm text-az-black-900">Frequency</p>
                    <p className="text-sm text-az-black-900">{isMine ? group.frequency : group.freq}</p>
                </div>
                {
                    isMine ? (
                        <div className="flex w-full flex-row justify-center items-center py-4">
                            {/* <div onClick={handleOpenEditConfirm} className="!border-2 border-az-teal rounded px-8 py-2">
                                <span>Edit</span>
                            </div> */}
                            <button onClick={handleOpenDeleteConfirm} className="bg-az-teal rounded text-white w-fit h-10 px-8">
                                <span>Delete</span>
                            </button>
                        </div>
                    ) : (
                        <div className="flex w-full flex-row justify-between items-center py-4">
                            {
                                group.status === null ? (
                                    <>
                                        <div onClick={handleDeclineClick} className="!border-2 border-az-teal rounded px-8 py-2">
                                            <span>Decline</span>
                                        </div>
                                        <button onClick={handleAcceptClick} className="bg-az-teal rounded text-white w-fit h-10 px-8">
                                            <span>Accept</span>
                                        </button>
                                    </>
                                ) : ''
                            }
                        </div>
                    )
                }
                
            </div>
            <ConfirmModal
                isOpen={isDeleteOpen}
                onClose={handleCloseDeleteConfirm}
                text="Are you sure you want to delete this Kontribute? All data about it will be lost after this action."
                handleNo={handleCloseDeleteConfirm}
                handleYes={deleteExpense}
            />
            <AcceptAjoForm showForm={showAccept} inviteData={acceptData} closeFrmParent={(val) => setShowAccept(val)} />
            <DeclineAjoForm showForm={showDecline} linkData={declineData} closeFrmParent={(val) => setShowDecline(val)} />
        </div>
    )
}

export default GroupDetailsCard
import axios from "axios";
import OtpInput from 'react-otp-input';
import CryptoJS from 'crypto-js';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import Button from "../../components/Button";
import UnAuthWrapper from "../../components/UnAuthWrapper";
import useAuthRedirect from "../../hooks/useAuthRedirect";
import getBaseURL from "../../utils/getBaseURL";
import { toastWrapper } from "../../utils/toastWrapper";
import {login, User} from '../Auth/authSlice';
import VerifyEmailImg from "../../assets/new/verifyEmail.png";

function VerifyOTP() {
  const [time, setTime] = useState(10 * 60); // Initial time in seconds

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId); // Clear interval on component unmount
    }
  }, [time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const dispatch = useAppDispatch(); 
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const {state} = useLocation();
  useAuthRedirect();

  const handleResend = () => {
    if (time !== 0) {
      return;
    }
    const url = getBaseURL() as string;
    const login = axios.post(`${url}AttemptLogin`, {
      email: localStorage.getItem('email'),
      // @ts-ignore
      password: state.item.password,
    });
    toastWrapper(
      login, 
      'Resending code...', 
      (resp) => {
        setTime(10 * 60);
        return resp.data.message || 'Code sent Successful!';
      }, 
      'Error resending code.',
      (err) => {
        return err?.message || 'Error resending code.'
      }
    );
  }

  const handleSubmit = (event: React.FormEvent) => {
    const url = getBaseURL() as string;
    event.preventDefault();
    const verify = axios.post(`${url}loginViaOtp`, {
      otp: code,
      email: localStorage.getItem('email')
    });
    toastWrapper(
      verify, 
      'Verifying you...', 
      (resp) => {
        if (resp.data.status !== 200) {
          throw Error('Invalid OTP supplied!');
        }
        dispatch(login(resp.data.user as unknown as User))
        const secretKey = process.env.REACT_APP_TAZAT_SECRET;
        const encryptedString = CryptoJS.AES.encrypt(resp.data.access_token, secretKey).toString();
        localStorage.setItem('tazat', encryptedString);
        axios.defaults.headers.common['Authorization'] = `Bearer ${resp.data.access_token}`;
        localStorage.removeItem('email');
        navigate('/dashboard');
        return 'Welcome, Login Successful.';
      }, 
      'Error validating OTP!',
    );
  }

  const label = time === 0 ? 'Resend' : `Resend in ${formatTime(time)} minutes`;
  return (
    <UnAuthWrapper
      title="Verify your email."
      subTitle="Enter code sent to your email below."
      ctaQuestion="Have an account?"
      ctaRoute="login"
      ctaText="Login"
      img={VerifyEmailImg}
    >
      <form onSubmit={handleSubmit} className="max-w-[400px]">

        <OtpInput
          value={code}
          onChange={setCode}
          numInputs={6}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            border: '1px solid #0898A0',
            width: '50px',
            height: '50px',
            marginRight: '10px',
            borderRadius: '5px'
          }}
        />
        
        <Button
          label="Verify Email"
          onClick={() => null}
          type="flat"
          btnActionType="submit"
        />
        <p>I didn’t get verification email? 

          <Button
            label={label}
            type='text'
            overrideStyle={{
              color: 'rgba(80,44,43,.882)',
              width: 'max-content',
              marginLeft: '0.5rem',
              textTransform: 'unset',
            }}
            onClick={handleResend}
          />
        </p>
      </form>
    </UnAuthWrapper>
  );
}

export default VerifyOTP;
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../pages/Auth/authSlice';
import bankReducer from '../pages/Bank/bankSlice';
import shareReducer from '../pages/Share/shareSlice';
import dashboardReducer from '../pages/Dashboard/dashboardSlice';
import businessReducer from '../pages/Business/businessSlice';
import loaderReducer from '../pages/Loader/loaderSlice';
import adminReducer from '../pages/AdminDashboard/adminSlice';
import { azatmeApi } from '../services/azatme';


export const store = configureStore({
  reducer: {
    loader: loaderReducer,
    share: shareReducer,
    auth: authReducer,
    bank: bankReducer,
    dashboard: dashboardReducer,
    business: businessReducer,
    admin: adminReducer,
    [azatmeApi.reducerPath]: azatmeApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(azatmeApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export const appDispatch = store.dispatch;

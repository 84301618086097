import { useCallback, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import Button from "../../components/Button";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import Input, { Select } from "../../components/Input";
import Modal from "../../components/Modal";
import toast from "react-hot-toast";
import PrintIcon from "../../assets/print.png";
import { useAppSelector } from "../../app/hooks";
import BankInput from "../../components/BankInput";
import './nqr.scss';
import { getNQRMerchants, getSubMerchants, newSubMerchant, optInToNQR } from "../../actions/businessActions";
import useGetBusinessCode from "../../hooks/useGetBusinessCode";
import { IBusiness } from "../Business/businessSlice";
import SwitchBusiness from "../../components/SwitchBusiness";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import SidePane from "../../components/SidePane";
import TabComponent from "../../components/TabComponent";

const navItems = ['QR codes', 'Collections'];

const NQROptIn = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [tab, setTab] = useState(navItems[0]);
  const [hasNQR, setHasNQR] = useState<string | undefined | Record<string, any>>('');
  const {nqrs} = useAppSelector(state => state.business)
  const [_, _business] = useGetBusinessCode();
  const business = _business as IBusiness;

  const [showNewNQRForm, setShowNewNQRForm] = useState(false);
  const [isLoading] = useState(false);
  const [data, setData] = useState({
    amt: '0',
    email: '',
    phone: '',
    businessTIN: '',
    name: '',
    phoneNumber: '',
    generateQrCodeWithFixedAmount: '',
    qrCodeAmount: '',
  });

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);


  const handleNewNQR = () => {
    if (!data.name || !data.phoneNumber || !data.email) {
      toast('Enter all form details to continue!')
      return;
    }
    if ((data.generateQrCodeWithFixedAmount === 'Yes' && parseInt(data.qrCodeAmount) < 0)) {
      toast('Enter an amount for the QR Code or set Generate QRCode With Fixed Amount to false!')
      return;
    }
    newSubMerchant({
      name: data.name,
      phoneNumber: data.phoneNumber,
      generateQrCodeWithFixedAmount: data.generateQrCodeWithFixedAmount === 'Yes',
      qrCodeAmount: data.qrCodeAmount,
      email: data.email,
    }, (res) => {
        setShowNewNQRForm(false);
        setData((prev) => ({
          ...prev,
          name: '',
          phoneNumber: '',
          generateQrCodeWithFixedAmount: '',
          qrCodeAmount: '',
          email: '',
        }))
    })
  }


  useEffect(() => {
    if (!nqrs) {
      getNQRMerchants()
    }
    if (nqrs && business) {
      // @ts-ignore
      const nqr = nqrs.find((item) => item.name === business.business_name && item.auth_id == business.owner_id);
      setHasNQR(nqr);
    }
  }, [nqrs, business]);

  const expenseNavItemsObj = [
    {
      label: 'Create SubMerchant',
      action: () => setOpenCreate(true),
      bgColor: '#0898A0',
      img: '',
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        navItems={[]}
        navItemsObj={hasNQR ? expenseNavItemsObj : []}
        tab={tab}
        setTab={setTab}
        dontShowWithdraw
      />
    )
  }

  return (
    <DashboardComponentWrapper
      renderNavItems={renderNavItems}
      overrideName={() => <SwitchBusiness />}
      title='NQR'
      pageDetails="With NQR you and your customers can benefit from scan and pay."
    >
       <>
        <TabComponent
          components={{
            // @ts-ignore
            'SubMerchants': () => <OptIn hasNQR={hasNQR} />,
            // 'Collections': () => <Collections hasNQR={hasNQR}/>
          }}
        />
        <SidePane onClose={() => setOpenCreate(false)} open={openCreate} title={""}>
          <form onSubmit={handleNewNQR} style={{width: '100%', padding: '30px 0', maxHeight: '60vh', overflow: 'auto'}}>
            <h3 className="text-3xl mb-5 text-left w-full">Create SubMerchant</h3>
            <Input
              label="Name"
              placeholder="Cashier A"
              name="name"
              required
              value={data.name}
              onChange={handleChange}
            />
            <Input
              label="Email"
              placeholder="cashiera@merchant.com"
              name="email"
              required
              value={data.email}
              onChange={handleChange}
            />
            <Input
              type="tel"
              label="Phone"
              placeholder="09011223344"
              name="phoneNumber"
              required
              value={data.phoneNumber}
              onChange={handleChange}
            />
            <Select
              data={['Yes', 'No']}
              label="Generate QRCode With Fixed Amount"
              placeholder="No"
              name="generateQrCodeWithFixedAmount"
              required
              value={data.generateQrCodeWithFixedAmount}
              onChange={handleChange}
            />
            {
              data.generateQrCodeWithFixedAmount === 'Yes' && (
                <Input
                  label="Amount"
                  placeholder="200.00"
                  name="qrCodeAmount"
                  required
                  value={data.qrCodeAmount}
                  onChange={handleChange}
                  type="number"
                />
              )
            }
            <div className="flex flex-row justify-between items-center w-full mt-8">
              <Button
                label="Cancel"
                type="contained"
                btnActionType="button"
                onClick={() => setShowNewNQRForm(false)}
                overrideStyle={{
                  width: '45%'
                }}
              />
              <Button
                label="Submit"
                type="flat"
                btnActionType="submit"
                disabled={isLoading}
                onClick={handleNewNQR}
                overrideStyle={{
                  width: '45%'
                }}
              />
            </div>
          </form>
        </SidePane>
        {/* <SidePane
          onClose={() => setExpense(null)}
          open={!!expense}
          title={""}
        >
          <ExpenseDetails expense={expense} handleCloseDetail={() => setExpense(null)} isMine />
        </SidePane> */}
      </>
    </DashboardComponentWrapper>
  );
};

export default NQROptIn;

interface INQRCompProps {
  hasNQR: string | undefined | Record<string, any>;
}

const OptIn = ({hasNQR}: INQRCompProps) => {
  const [showForm, setShowForm] = useState(false);
  const [showNewNQRForm, setShowNewNQRForm] = useState(false);
  const [bank, setBank] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [_, _business] = useGetBusinessCode();
  const business = _business as IBusiness;
  const [data, setData] = useState({
    amt: '0',
    email: '',
    phone: '',
    businessTIN: '',
    name: '',
    phoneNumber: '',
    generateQrCodeWithFixedAmount: '',
    qrCodeAmount: '',
  });

  const handleNewNQR = () => {
    if (!data.name || !data.phoneNumber || !data.email) {
      toast('Enter all form details to continue!')
      return;
    }
    if ((data.generateQrCodeWithFixedAmount === 'Yes' && parseInt(data.qrCodeAmount) < 0)) {
      toast('Enter an amount for the QR Code or set Generate QRCode With Fixed Amount to false!')
      return;
    }
    newSubMerchant({
      name: data.name,
      phoneNumber: data.phoneNumber,
      generateQrCodeWithFixedAmount: data.generateQrCodeWithFixedAmount === 'Yes',
      qrCodeAmount: data.qrCodeAmount,
      email: data.email,
    }, (res) => {
        setShowNewNQRForm(false);
        setData((prev) => ({
          ...prev,
          name: '',
          phoneNumber: '',
          generateQrCodeWithFixedAmount: '',
          qrCodeAmount: '',
          email: '',
        }))
    })
  }

  const handleNQROptIn = () => {
    const phoneValid = /^(\+)?(234)[0-9]{10}$/.test(data.phone)
    if (!data.businessTIN || !bank.account_name || !(phoneValid || business.business_phone)) {
      toast('Enter all form details to continue!')
      return;
    }
    if (!business) {
      return;
    }
    optInToNQR({
      "name": business.business_name,
      "tin": data.businessTIN,
      "contact": business.business_address,
      "phone": business.business_phone || data.phone,
      "email": business.business_email,
      "address": business.business_address,
      "bankNo": bank.bankCode,
      "accountName": bank.account_name,
      "accountNumber": bank.account_number,
      "remarks": business.description
    }, (res) => {
      setShowForm(false);
    })
  }

  useEffect(() => {
    if (hasNQR) {
      const nqr = hasNQR as Record<string, any>;
      getSubMerchants(nqr.merchantNumber, (resp) => {
        console.log(resp)
      });
    }
  }, [hasNQR]);

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  return (
    <div className="nqr-wrapper w-full h-[60vh] flex flex-col justify-center items-center">
      {
        hasNQR === '' ? (
          <div className="">
            <p>Loading...</p>
            <span>Processing the NQR status of this business, here you can manage NQR status of your business, view all QR code collects, create QR code for receiving funds and share the created codes</span>
          </div>
        ) : hasNQR === undefined  ? (
          <div className="no-nqr flex flex-col justify-center items-center">
            <svg className="my-10" xmlns="http://www.w3.org/2000/svg" width="78" height="65" viewBox="0 0 78 65" fill="none">
              <path d="M23.9235 38.1528C24.2849 37.7871 24.2849 37.1776 23.9235 36.8119L21.0331 33.8863L24.7666 30.1074C25.1279 29.7417 25.1279 29.1322 24.7666 28.7665C24.4053 28.4008 23.8031 28.4008 23.4418 28.7665L19.7083 32.5454L16.336 29.1322C15.9747 28.7665 15.3725 28.7665 15.0112 29.1322C14.6499 29.4979 14.6499 30.1074 15.0112 30.4731L18.3835 33.8863L15.0112 37.2995C14.6499 37.6652 14.6499 38.2747 15.0112 38.6404C15.3725 39.0061 15.9747 39.0061 16.336 38.6404L19.7083 35.2272L22.5987 38.1528C23.0805 38.5185 23.5622 38.5185 23.9235 38.1528ZM23.5622 50.2209C23.2009 50.5866 23.2009 51.1961 23.6827 51.5618C24.044 51.9275 24.6462 51.9275 25.0075 51.4399C30.9089 44.8573 41.0256 44.9792 46.8066 51.0742C47.1679 51.4399 47.7701 51.4399 48.1314 51.0742C48.4927 50.7085 48.4927 50.099 48.1314 49.7333C41.5073 42.9069 30.1863 42.785 23.5622 50.2209Z" fill="#0898A0"/>
              <path d="M68.6055 57.537L71.7369 25.2335C71.8573 23.8926 71.3756 22.5517 70.5325 21.5765C69.6895 20.6013 68.3646 19.9918 67.0398 19.9918V11.5807C67.0398 8.41125 64.6311 5.85135 61.4997 5.72945L10.7958 4.99805C7.544 4.99805 5.13526 7.55795 5.01483 10.6055V12.7997C3.56959 13.1654 2.48565 14.1406 1.88347 15.6034C1.16085 16.4567 0.679099 17.5538 0.438225 18.4071C-0.163959 20.6013 -0.0435223 22.9174 0.197351 25.2335C1.16085 35.9607 2.12434 46.566 2.9674 57.2932C3.32871 61.9254 4.53308 63.8758 11.1571 64.2415C30.0657 65.2167 48.9743 65.0948 67.8829 64.7291C68.6055 64.7291 69.4486 64.7291 70.0508 64.3634C70.7734 63.9977 71.2551 63.2663 71.1347 62.5349C70.7734 60.9502 68.1238 61.194 66.5581 61.0721C67.642 60.3407 68.3646 59.1217 68.6055 57.537ZM10.7958 6.94845L61.4997 7.67985C63.5472 7.67985 65.3537 9.50835 65.2333 11.5807V20.1137H47.5291C46.2043 20.1137 44.8794 19.5042 43.916 18.529L41.1459 15.3596C39.7007 13.7749 37.6532 12.7997 35.4854 12.7997H6.82138V10.8493C6.82138 8.53315 8.62794 6.82655 10.7958 6.94845ZM63.9085 60.0969H9.59143C8.14619 60.0969 6.82138 58.9998 6.70094 57.4151L3.20827 17.6757C3.08784 15.9691 4.41264 14.5063 6.09876 14.5063H35.4854C37.1715 14.5063 38.7372 15.2377 39.8211 16.4567L42.5911 19.6261C43.7955 21.0889 45.6021 21.9422 47.5291 21.9422H67.0398C67.8829 21.9422 68.6055 22.3079 69.2077 22.9174C69.8099 23.5269 70.0508 24.3802 69.9303 25.1116L66.799 57.4151C66.6785 58.8779 65.3537 60.0969 63.9085 60.0969Z" fill="#0898A0"/>
              <path d="M51.2626 32.3035L53.9122 29.6217C54.2735 29.256 54.2735 28.6465 53.9122 28.2808C53.5509 27.9151 52.9487 27.9151 52.5874 28.2808L49.9377 30.9626L46.5655 27.5494C46.2042 27.1837 45.602 27.1837 45.2407 27.5494C44.8794 27.9151 44.8794 28.5246 45.2407 28.8903L48.6129 32.3035L45.4816 35.4729C45.1203 35.8386 45.1203 36.4481 45.4816 36.8138C45.8429 37.1795 46.4451 37.1795 46.8064 36.8138L49.9377 33.6444L53.31 37.0576C53.6713 37.4233 54.2735 37.4233 54.6348 37.0576C54.9961 36.6919 54.9961 36.0824 54.6348 35.7167L51.2626 32.3035ZM67.642 0.3657C66.558 1.8285 65.715 3.2913 65.1128 4.9979C64.9924 5.4855 65.2332 5.97311 65.715 6.09501C66.1967 6.21691 66.6785 5.97311 66.7989 5.48551C67.2807 4.0227 68.0033 2.5599 68.9668 1.3409C69.3281 0.9752 69.2077 0.3657 68.8463 0C68.485 0 68.0033 -3.63291e-07 67.642 0.3657ZM75.3499 3.9008C75.1091 3.5351 74.5069 3.2913 74.1456 3.657L68.485 7.31401C68.1237 7.55781 68.0033 8.16731 68.2442 8.53301C68.485 8.89871 69.0872 9.14251 69.4485 8.77681L75.1091 5.1198C75.4704 4.876 75.5908 4.2665 75.3499 3.9008ZM77.036 10.4834L70.6529 10.971C70.1711 10.971 69.8098 11.4586 69.8098 11.9462C69.8098 12.4338 70.2916 12.7995 70.7733 12.7995L77.1565 12.3119C77.6382 12.3119 77.9995 11.8243 77.9995 11.3367C77.8791 10.8491 77.5178 10.4834 77.036 10.4834Z" fill="#0898A0"/>
            </svg>
            <p>With NQR you and your customers can benefit from scan and pay. <br/> Click the button below to Sign Up for NQR</p>
            <Button
              className="atn-btn px-4 rounded !bg-transparent !border !border-az-teal !text-az-teal" 
              label={"Coming soon"}
              type="flat"    
              onClick={() => {}}        
            />
          </div>
        ) : (
          <div className="has-nqr">
            <div className="qrcard-wrapper">
              {/* <div className="qrcode-wrapper">
                <Button
                  label={"New QR code"}
                  type="flat"
                  className="atn-btn"  
                  onClick={() => setShowNewNQRForm(true)}        
                />
              </div> */}
              <QRCodeCard />
              {/* <QRCodeCard />
              <QRCodeCard />
              <QRCodeCard />
              <QRCodeCard /> */}
            </div>
          </div>
        )
      }
      <SidePane
        onClose={() => setShowForm(false)}
        open={showForm}
        title={""}
      >
          <form onSubmit={handleNQROptIn} style={{width: '100%', padding: '30px 0'}}>
            <h3 className="text-3xl mb-5 text-left w-full">Opt In for NQR</h3>
            <BankInput
              overrideStyle={{
                width: '100%'
              }}
              setSelectedBank={(value) => {
                setBank(value);
                handleChange('bankName', value.bankName);
              }} 
            />
            <Input
              label="Business TIN"
              placeholder="09jhgf567"
              name="businessTIN"
              required
              value={data.businessTIN}
              onChange={handleChange}
            />
            {
              business && !business.business_phone && (
                <Input
                  type="tel"
                  label="Business Phone Number"
                  placeholder="+2347011220011"
                  name="phone"
                  required
                  value={data.phone}
                  onChange={handleChange}
                />
              )
            }
            <div className="w-full flex flex-row justify-between items-center my-8">
              <Button
                label="Cancel"
                type="contained"
                btnActionType="submit"
                onClick={() => setShowForm(false)}
                overrideStyle={{
                  width: '45%'
                }}
              />
              <Button
                label="Opt In"
                type="flat"
                btnActionType="submit"
                disabled={isLoading}
                onClick={handleNQROptIn}
                overrideStyle={{
                  width: '45%'
                }}
              />
            </div>
          </form>
        </SidePane> 
    </div>
  );
};

export const QRCodeCard = ({
  value="hey",
  name="Product",
  price="200",
}) => {
  const qrCodeWrapper = useRef<HTMLDivElement | null>(null);
  const [openQR, setOpenQR] = useState(false);
  const handlePrintQRCode = async () => {
    // @ts-ignore
    // const contentToPrint = qrCodeWrapper.current?.innerHTML;
    // let originalContents = document.body.innerHTML;
    // document.body.innerHTML = contentToPrint as string;
    await setOpenQR(true);
    window.print();
    // document.body.innerHTML = originalContents;
  };

  return (
    <div className="qrcode-wrapper">
      <div className="qrcode" onClick={() => setOpenQR(true)}>
        <QRCode
          value={value}
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          viewBox={`0 0 256 256`}
        />
      </div>
      <div className="details">
        <div>
          <h6>Product Price</h6>
          <h6>Product Name</h6>
        </div>
        <button
          onClick={handlePrintQRCode}
          style={{
            width: '40px',
            height: '40px',
            background: 'transparent',
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <img src={PrintIcon} alt="Print" />
        </button>
      </div>
      <Modal
        open={openQR}
        onClose={() => setOpenQR(false)}
        overrideStyle={{
          maxWidth: "350px",
          width: "80%",
          height: "max-content",
        }}
        title="Scan to Pay"
        footer={() => (
          <div style={{width: '100%', display: 'flex'}}>
          </div>
        )}
      >
         <div className="modal-qr-wrapper" ref={qrCodeWrapper}>
          <h4>Have you Customer Scan this code with their banking app to make payment.</h4>
          <div className="modal-qr">
            <QRCode
              value={value}
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              viewBox={`0 0 256 256`}
            />
          </div>
         </div>
        </Modal>
    </div>
  )
}

const otherTerms = {
  title: "OTHER TERMS",
  effective: "April 2023",
  details: [
    {
      title: "LICENCES",
      listType: '',
      item: ["We own all copyrights, trademarks, domains, logos, trade dress, trade secrets, patents, and other intellectual property rights (together, the “IP rights”) associated with AzatMe, Pethahiah Rehoboth Ltd and any of our services. You may not use our IP rights unless you have our prior express permission in writing and in accordance with our brand purpose, guidelines and values. We grant you a limited, revocable, non-exclusive, non-sublicensable, and non-transferable licence solely for the purpose of enabling you to use our services, subject to and in accordance with this User Agreement. No other licences or rights are granted to you by implication or otherwise."],
      description: 'By using any AzatMe service, you grant us a licence to use your data and information in order to provide you with the AzatMe services. This licence is a worldwide, non-exclusive, royalty-free, sublicensable and transferable licence to use, reproduce, distribute and display the data and information (including the content) that you upload, submit, store, send, or receive for the purpose of providing you with AzatMe services. We do not sell or provide your data or information to third parties, except where we are required to share that by applicable law or regulation or as an integral element of providing AzatMe services.'
    },
    {
      title: "SERVICE PROVISION",
      listType: '',
      item: ["Whilst we are incorporated in Nigeria, we may operate our services from elsewhere. In particular, the systems that we use may be based in other countries. We may also use or rely on third party service providers, agents, contractors, custodians and others to be able to offer our services and perform our functions. You agree that, as between us, there is no restriction on our ability to use these third parties.", "We make AzatMe available on an ‘as is’ and ‘as available’ basis. Although we use all reasonable commercial efforts to ensure that availability or use of the AzatMe services will be uninterrupted, timely, secure, error-free and virus-free, we do not guarantee, warrant or represent this will always be the case. We do not accept any responsibility or liability should AzatMe be unavailable. If we decide to discontinue some or all of our services, we will endeavour to give you 30 days’ notice, unless otherwise required by law.", "AzatMe is reliant on the availability of your network or internet service, and we do not accept any responsibility or liability for a failure of your mobile network, internet or third-party provider, or any losses or damage suffered as a result.", "Events beyond our control (force majeure) may affect the availability of AzatMe and we will not be liable for the non-provision of the AzatMe services or any losses, howsoever arising, as a result of a force majeure event."],
      description: 'In order for the AzatMe services to operate effectively, you may need to manually or automatically download and install updates from time to time. Failure to do this can impede the functionality of our service'
    },
    {
      title: "CHANGES TO OUR SERVICES",
      listType: '',
      item: ["If you have a Business Account with AzatMe and we make a change to our services which affects you, we will endeavour to notify you at least 30 days prior to making the change.", "However, we may not always be able to provide advance notice of such a change (unless otherwise required by law) and that will not preclude us from implementing that change. We reserve the right to provide less or no notice of any changes where the change is required by applicable law or regulations. We shall not be liable for any loss you may suffer as a result of changes made to our services."],
      description: 'Where we make a change to our services which affects you as a user, we will endeavour to post details of those changes on our website at least 30 days before making the change.'
    },
    {
      title: "YOUR RESPONSIBILITIES",
      listType: 'list',
      item: ["you are acting as principal and on your own behalf;", "you are bound by, and you have the power and authority to enter into and perform your obligations under this User Agreement;", "you are not insolvent or bankrupt or subject to any insolvency proceedings or arrangements and if you are a company you confirm that no steps have been taken to appoint a receiver, manager or administrator either over you or over any of your assets and that no steps have been taken for your winding-up; and", "you will not use our services or operate any AzatMe account for any purpose which is unlawful, abusive, libellous, obscene or threatening."],
      description: 'Before you begin using our services, it is important that you consider the confirmations set out below. You should continue to operate an AzatMe account only if all of these are correct not only at the date that of opening your AzatMe account but throughout the duration of your use of the AzatMe services. By agreeing to the terms of this User Agreement, you agree and confirm that the following confirmations will remain true at all such times:'
    },
    {
      title: "",
      listType: '',
      item: ["If any of these confirmations are untrue or your circumstances change, you must tell us as soon as possible and refrain from using our services."],
      description: ''
    },
    {
      title: "RESTRICTED ACTIVITIES",
      listType: 'list',
      item: ["obreach this User Agreement or any other documents which govern the relationship between you and AzatMe;", "obreach any applicable laws or regulations to which you or AzatMe are subject;", "where we have requested data from you, provide false, inaccurate or misleading data, or fail to keep such data updated;", "osend or receive funds you know or suspect to be unauthorised or derived from criminal sources;", "ointerfere or disrupt, or attempt or facilitate the interruption or disruption of our websites, software or systems (including networks and servers used to provide AzatMe services), or gain or attempt to gain, authorised access to any of the same;", "otake any action that imposes an unreasonable or disproportionately large load on our websites, software, systems (including, without limitation, any networks and servers used to provide any AzatMe services);", "ocircumvent or attempt to circumvent any action taken by AzatMe in relation to your account, including without limitation, account suspensions, closures or limitations or by attempting to open new or additional AzatMe accounts (whether with your own data or that belonging to someone else, whether or not they have consented to the same) or using someone else’s AzatMe account;", "oreveal any AzatMe, banking or device access passwords to anyone else, nor may you use anyone else's passwords, noting that we are not responsible for any losses incurred by you where that has occurred as a result of misuse of passwords; or", "oengage in any action or activity, or take any step which we reasonably believe is detrimental to AzatMe."],
      description: 'In addition to your responsibilities set out above and elsewhere in this User Agreement, you agree and confirm that by using AzatMe’ s services and software, you will not:'
    },
    {
      title: "ADDITIONAL RESTRICTED ACTIVITIES FOR THOSE RECEIVING PAYMENTS",
      listType: 'list',
      item: ["conduct or carry on any business which is illegal or run your business so as to facilitate or encourage illegal activity;", "conduct your business or use AzatMe’ s services in a manner that results in complaints or requests to AzatMe to invalidate payments made to you, or results in any liability or losses to AzatMe, other AzatMe users or third parties;", "decline to pay AzatMe’ s fees when due and payable; or", "send unsolicited communications to users or third parties."],
      description: 'In addition to your responsibilities set out above and elsewhere in this User Agreement, you agree and confirm that by using AzatMe’ s services and software, you will not:'
    },
    {
      title: "ACTIONS WE MAY TAKE",
      listType: '',
      item: ["blocking any payment transaction.", "restricting your access to the services and/or suspending, restricting or closing your AzatMe Account; and/or", "refusing to provide AzatMe services to you in the future.", "We reserve the right to close your account at any time if:", "you are, or we suspect you are, in breach of this User Agreement, laws and regulations applicable to you or us or any other condition of using AzatMe’ s services; or", "we suspect or are informed of unauthorised access on your account, (in addition to any other circumstances set out below at ‘Closing your AzatMe account and deleting your AzatMe Wallet’"],
      description: 'Should we know or suspect you are engaging in any restricted activities mentioned above, we may (in our sole discretion) take any action, immediately and without notice to you which could include but is not limited to:'
    },
    {
      title: "",
      listType: '',
      item: ["We will notify you through the contact details you have provided to us if this is the case, but we are not obliged to provide you with any reasons for such suspension, restriction or closure.", "In the event of AzatMe closing your account, you are responsible for ensuring you have satisfied all contractual obligations you may owe to third parties, and AzatMe accepts no liability to you in respect of this.", "You remain liable for any unpaid fees due and payable to AzatMe."],
      description: ''
    },
    {
      title: "YOUR AZATME WALLET TRANSACTION HISTORY",
      listType: '',
      item: ["We cannot accept any liability for the accuracy of data provided to us by you or the bank who manages your account.", "Records of your transactions are stored by us for regulatory reasons. Please note that this data may be stored in cloud infrastructure and this may mean it is sent anywhere in the world."],
      description: 'Your AzatMe Wallet will show a history of transactions made on the device on which it is stored.'
    },
    {
      title: "ASSIGNMENT AND TRANSFER",
      listType: '',
      item: [""],
      description: 'Your AzatMe account is personal to you; none of your rights or obligations in connection with your AzatMe account or your use of the AzatMe services can be assigned, transferred or delegated to any other person without our prior written consent. Any attempt to transfer, assign or delegate any of your rights or obligations in contravention of this paragraph and shall be null and void.'
    },
    {
      title: "CLOSING YOUR AZATME ACCOUNT AND DELETING YOUR AZATME WALLET",
      listType: '',
      item: ["If you are a business, you may close your AzatMe account at any time by sending us an email to support@pethahiah.com and deleting the AzatMe Wallet. We will close your AzatMe account within 30 days of receiving your email.", "As outlined above under ‘Actions we may take’, we may restrict, suspend or terminate your access to your AzatMe account or the AzatMe services at any time for suspicious or unlawful conduct, including for fraud, or if we reasonably believe you breach the terms of these User Agreement or create harm, risk or possible exposure for us, our users or other parties.", "If you are under the age of 18, we reserve the right to restrict, suspend or terminate your access to your AzatMe account or the AzatMe services at any time, without giving you any reason.", "If you believe your account has been terminated or suspended by mistake, please contact us at info@pethahiah.com."],
      description: 'You may delete your AzatMe Wallet by deleting the AzatMe App from your device.'
    },
    {
      title: "",
      listType: 'list',
      item: ["oexcept for the provisions of this User Agreement which are required to survive termination in compliance with applicable laws and regulations, this User Agreement terminates;", "oyour use of and access to our services, websites, software, systems (including any networks and servers used to provide any of the services) operated by us or on our behalf will end;", "oany incentive credits in your account will be cancelled without refund;", "oyou remain liable for any outstanding obligations under this User Agreement related to your account prior to closure; and", "osubject to any data we are required to keep for the purpose of fulfilling the requirements of any applicable laws and regulations, we will delete your data."],
      description: 'Upon closure of your AzatMe Account and/or deletion of your AzatMe Wallet:'
    },
    {
      title: "",
      listType: '',
      item: [""],
      description: 'Further details of our data retention arrangements can be found in the AzatMe Privacy Policy.'
    },
    {
      title: "LIABILITY",
      listType: 'list',
      item: ["loss of revenue, data, sales or business, agreements or contracts, anticipated savings, profits, opportunity, goodwill or reputation, or for any business interruption;", "loss or corruption of data; or", "indirect, special or consequential loss, damage, costs or other claims, howsoever caused or arising, including where arising directly or indirectly from any failure or delay in performing any obligation under these terms caused by matters beyond our reasonable control."],
      description: 'You will indemnify us for any loss or damage caused by or arising from your violation of the terms of this User Agreement or any other agreement which you enter into with us, or your use of AzatMe services. We shall not be liable for any loss you may suffer as a result of your use of the AzatMe services, or where a third-party gains access to any of your data through your use of AzatMe services, except where such loss is the direct result of our gross negligence, wilful deceit or fraud. We will not be liable to you or anyone else, whether in contract, tort (including negligence, breach of statutory duty or other tort) or otherwise, for any:'
    },
    {
      title: "",
      listType: '',
      item: ["We do not at any time hold deposits or any cash for any of our customers (individual, business or otherwise) and we are not covered by the Nigerian Deposit Insurance Corporation (NDIC). The NDIC does not cover payment services or e-money payment services."],
      description: 'Except as expressly stated elsewhere in this User Agreement, all representations, warranties, conditions and other terms, whether express or implied (by common law or statute) are hereby excluded, except in the case of fraud, death and personal injury, or where such exclusion is not permitted by law.'
    },
    {
      title: "COMPLAINTS",
      listType: '',
      item: ["If, after contacting us, the problem is not resolved to your satisfaction, you can contact the CBN’s Consumer Protection Department to look into it. This is a free, independent service."],
      description: 'If you are unhappy with any AzatMe service, please get in touch with us at info@pethahiah.com. We will promptly acknowledge your complaint in writing and investigate your complaint competently, diligently and impartially in order to assess its subject matter and determine whether the complaint should be upheld as well as, where appropriate, any remedial action and/or redress. You will be provided with a final response no later than eight weeks after we receive your complaint.'
    },
    {
      title: "SEVERANCE",
      listType: '',
      item: [""],
      description: 'If any provision or part-provision of this User Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of this User Agreement.'
    },
    {
      title: "THIRD PARTY RIGHTS",
      listType: '',
      item: [""],
      description: 'Unless expressly stated otherwise, this User Agreement does not give rise to any rights under the Contracts to enforce any term of the User Agreement.'
    },  
    {
      title: "GOVERNING LAW AND JURISDICTION",
      listType: '',
      item: [""],
      description: 'This User Agreement is governed by the laws of Federal Republic of Nigeria, and by using AZATME services you agree to submit any disputes (including non-contractual disputes) to the exclusive jurisdiction of the Nigeria Court, sitting in Ikeja Judicial Division.'
    },
  ]
};

export default otherTerms;
import LeftCubes  from '../../../../../assets/new/leftcubes.png';
import RightCubes  from '../../../../../assets/new/rightcubes.png';
export const grpTypes = [
    {
        color: '#0898A0',
      name: 'Generic',
      description: 'A time based monetary contribution towards a goal.',
    },
    {
        color: '#B47700',
      name: 'Rent',
      description: 'Create a Kontribute to split rent with your roommates.',
    },
    {
        color: '#65B400',
      name: 'Vacation Funds',
      description: 'Easily save money for group travel, and split costs with Kontribute!    ',
    },
    {
        color: '#00B45E',
      name: 'Club Membership',
      description: 'Kontribute money for Club membership fees, uniforms, games, and more.',
    },
    {
        color: '#00B4A9',
      name: 'Gifts',
      description: 'Use Kontribute to collect money for a group gift.',
    },
    {
        color: '#B47700',
      name: 'Baby Shower',
      description: 'Kontribute money for your baby shower, diaper fund, baby pools, monetary gifts, and more.',
    },
    {
        color: '#9000B4',
      name: 'Leader Appreciation',
      description: 'Kontribute to show leaders how much they’re cherished and appreciated.',
    },
    {
        color: '#0094B4',
      name: 'Graduation Gift',
      description: 'Easily collect money for way to collect money for your grad gifts with contribute.',
    },
    {
        color: '#0048B4',
      name: 'Class Reunion',
      description: 'Use Kontribute to collect funds for a reunion.',
    },
    {
        color: '#B4006C',
      name: 'Cash Registry',
      description: 'Use Kontribute for your cash registry to collect money for any type of cash fund or cash gift.',
    },
    {
        color: '#005EB4',
      name: 'Weddings',
      description: 'Kontribute towards all wedding activities, Bachelors eve, Bridal shower.',
    },
    {
        color: '#B40016',
      name: 'Community Project',
      description: 'Manage contributions for a communtiy project with Kontribute.',
    },
    {
        color: '#3A00B4',
      name: 'Club Dues',
      description: 'Start your money Kontribute Group today and enjoy being the club treasurer.',
    },
    {
        color: '#00B432',
      name: 'Emergency Funds',
      description: 'Grow your emergency savings with Kontribute.',
    },
    {
        color: '#0089B4',
      name: 'Memorials',
      description: 'Set up a memorial fund with Kontribute.',
    },
    {
        color: '#0898A0',
      name: 'Fans Support',
      description: 'Raise money for your celeb using Kontribute.',
    },
    {
        color: '#B47700',
      name: 'Neflix',
      description: 'Easily split and pay for your Netflix Subscription with others using Kontribute',
    },
    {
        color: '#65B400',
      name: 'Home Projects',
      description: 'It helps you budget & save funds for home improvement projects.',
    },
    {
        color: '#00B45E',
      name: 'ETC',
      description: 'Whatever you need. Let your creativity and need guide you.',
    },
  ]
  

const KontributeType = ({onClick}: {onClick?: () => {}}) => {
    return (
        <div className="w-screen min-h-screen bg-black flex flex-row justify-center items-start content-start p-28 flex-wrap relative">
            {
                grpTypes.map((type) => (
                    <div key={type.name} onClick={() => onClick && onClick()} className="flex flex-col justify-around items-center bg-[#222] w-[210px] h-[200px] m-2 p-3">
                        <p className="rounded-full px-4 py-1 text-white font-Onest" style={{
                            background: type.color
                        }}>{type.name}</p>
                        <p className="font-Onest text-[#ccc] text-sm !text-center">{type.description}</p>
                    </div>
                ))
            }
            <img src={LeftCubes} alt="" className='absolute top-0 left-0' />
            <img src={RightCubes} alt="" className='absolute bottom-0 right-0' />
        </div>
    )
};

export default KontributeType;
import './switchBusiness.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import CaretImg from '../../assets/caret-right.png';
import { useAppSelector } from '../../app/hooks';

const SwitchBusiness = () => {
  const {businesses} = useAppSelector((state) => state.business);
  const {state, pathname} = useLocation() as Record<string, any> || {};
  const item = state?.item;
  const navigate = useNavigate();
  const handleNavigate = (item) => {
    navigate(`/business/dashboard/${item.business_code}`, {state: {item}})
  }

  const business = businesses?.find((busi) => busi.business_code === pathname.split('/')[3]);

  return (
    <div className='switch-business'>
      <div className="dropdown">
        <span className='name'>
          {item?.business_name || business?.business_name}
          <img src={CaretImg} alt="" />
        </span>
        <div className="dropdown-content">
          {
            businesses && businesses?.map((item, index) => (
              <p
                key={`${index}${item.business_name}`}
                onClick={() => handleNavigate(item)}
              >
                {item.business_name}
              </p>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default SwitchBusiness;

import BlogHero from  '../../assets/new/BlogHeroImg.png';
import AuthorImg from '../../assets/new/blogAuthorImg.png';
import ReadIcon from '../../assets/new/readTime.png';
import BlogImg from '../../assets/new/blogImg.png';
import { Blog as BlogCard } from '../Landing/Components/Personal/Blogs';
import Facebook from  '../../assets/new/shareIcon/facebook.png';
import Twitter from  '../../assets/new/shareIcon/twitter.png';
import LinkedIn from  '../../assets/new/shareIcon/linkedin.png';
import Instagram from  '../../assets/new/shareIcon/instagram.png';
import ReactMarkdown from 'react-markdown'
import {  useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import remarkGfm from 'remark-gfm'
import { BLOG_QUERY, BLOGS_QUERY } from '../../queries';
import NotFound from '../../components/NotFound';

import {
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";

const Blog = () => {
    const params = useParams();
    const {error, data } = useQuery(BLOG_QUERY, { variables: params });
    const otherBlogs = useQuery(BLOGS_QUERY);

    const socialMedia = [
        Facebook, Instagram, LinkedIn, Twitter
    ]

    const blogDetail = {
        img: BlogHero,
        date: '23rd April, 2024',
        title: `19 Small Business Ideas You Can Easily
        Start in Nigeria`,
        readTime: 5,
        author: {
            img: AuthorImg,
            name: 'Gbemi Balogun'
        },
        details: [
            {
                title: '',
                content: `Lorem ipsum dolor sit amet consectetur. Id nisl elit facilisis egestas ultrices a. Integer
                aliquet egestas amet rutrum felis. Nulla blandit faucibus sagittis tempor mi enim integer.
                Ullamcorper lectus quam suspendisse accumsan ornare justo dolor consectetur egestas.
                Dignissim quisque nunc nec magna et arcu ac. Erat sit duis ac placerat. Arcu sed dictum
                pellentesque elementum quis. Quis convallis pellentesque lectus egestas nisi commodo
                id scelerisque nibh. `,
                img: '',
            },
            {
                title: 'Scelerisque pulvinar orci integer eleifend in sollicitudin. ',
                content: `Lorem ipsum dolor sit amet consectetur. Id nisl elit facilisis egestas ultrices a. Integer
                aliquet egestas amet rutrum felis. Nulla blandit faucibus sagittis tempor mi enim integer.
                Ullamcorper lectus quam suspendisse accumsan ornare justo dolor consectetur egestas.
                Dignissim quisque nunc nec magna et arcu ac. Erat sit duis ac placerat. Arcu sed dictum
                pellentesque elementum quis. Quis convallis pellentesque lectus egestas nisi commodo
                id scelerisque nibh. `,
                img: '',
            },
            {
                title: '',
                content: `Magna mauris integer vitae sem ornare. Arcu varius quis ante volutpat scelerisque arcu
                eleifend augue. Lorem congue suscipit nunc leo maecenas fames fermentum platea. Nisl
                sed id porttitor nunc neque senectus arcu habitant.`,
                img: '',
            },
            {
                title: '',
                content: `Magna mauris integer vitae sem ornare. Arcu varius quis ante volutpat scelerisque arcu
                eleifend augue. Lorem congue suscipit nunc leo maecenas fames fermentum platea. Nisl
                sed id porttitor nunc neque senectus arcu habitant.`,
                img: '',
            },
            {
                title: '',
                content: `Magna mauris integer vitae sem ornare. Arcu varius quis ante volutpat scelerisque arcu
                eleifend augue. Lorem congue suscipit nunc leo maecenas fames fermentum platea. Nisl
                sed id porttitor nunc neque senectus arcu habitant.`,
                img: BlogImg,
            },
            {
                title: '',
                content: `Magna mauris integer vitae sem ornare. Arcu varius quis ante volutpat scelerisque arcu
                eleifend augue. Lorem congue suscipit nunc leo maecenas fames fermentum platea. Nisl
                sed id porttitor nunc neque senectus arcu habitant.`,
                img: '',
            },
            {
                title: 'Scelerisque pulvinar orci integer eleifend in sollicitudin. ',
                content: `Lorem ipsum dolor sit amet consectetur. Id nisl elit facilisis egestas ultrices a. Integer
                aliquet egestas amet rutrum felis. Nulla blandit faucibus sagittis tempor mi enim integer.
                Ullamcorper lectus quam suspendisse accumsan ornare justo dolor consectetur egestas.
                Dignissim quisque nunc nec magna et arcu ac. Erat sit duis ac placerat. Arcu sed dictum
                pellentesque elementum quis. Quis convallis pellentesque lectus egestas nisi commodo
                id scelerisque nibh. `,
                img: BlogImg,
            },
            {
                title: '',
                content: `Magna mauris integer vitae sem ornare. Arcu varius quis ante volutpat scelerisque arcu
                eleifend augue. Lorem congue suscipit nunc leo maecenas fames fermentum platea. Nisl
                sed id porttitor nunc neque senectus arcu habitant.`,
                img: '',
            },
            {
                title: '',
                content: `Magna mauris integer vitae sem ornare. Arcu varius quis ante volutpat scelerisque arcu
                eleifend augue. Lorem congue suscipit nunc leo maecenas fames fermentum platea. Nisl
                sed id porttitor nunc neque senectus arcu habitant.`,
                img: '',
            },
        ],
        id: 3,
    }
    if (error) {
        return (
            <NotFound 
                text1="Could not load blog content."
                text2='Try again'
                btnLabel=""
                onClick={() => {}}
            />
        )
    }
    return (
        <div className='w-full px-[6%] mt-[150px]'>
            <div className='w-full my-10 flex flex-col justify-center py-5'>
                <img src={data?.post?.coverImage?.url} alt="" />
                <div className='flex lg:flex-row flex-col-reverse justify-center py-5 flex-wrap lg:justify-between'>
                    <div className='w-full lg:w-3/12'>
                        <div className='flex flex-row w-full'>
                            <img src={data?.post?.author?.picture.url} alt="" className='mr-2 w-10 h-10 rounded-full' />
                            <div>
                                <p className='text-[15px] font-medium'>{data?.post?.author?.name}</p>
                                <div className='flex flex-row w-full'>
                                    <img src={ReadIcon} alt="" className='w-5 h-5 mr-2' />
                                    <p className='text-sm font-normal'>{blogDetail.readTime} min. read</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col justify-start items-start mt-3'>
                            <p>Share post on:</p>
                            <div className='flex flex-row my-1'>
                                <FacebookShareButton url={window.location.href}>
                                    <img src={socialMedia[0]} alt="" className='mr-2 cursor-pointer' />
                                </FacebookShareButton>
                                <InstapaperShareButton url={window.location.href}>
                                    <img src={socialMedia[1]} alt="" className='mr-2 cursor-pointer' />
                                </InstapaperShareButton>
                                <LinkedinShareButton url={window.location.href}>
                                    <img src={socialMedia[2]} alt="" className='mr-2 cursor-pointer' />
                                </LinkedinShareButton>
                                <TwitterShareButton url={window.location.href}>
                                    <img src={socialMedia[3]} alt="" className='mr-2 cursor-pointer' />
                                </TwitterShareButton>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col text-left w-full lg:w-9/12'>
                        <h1 className='text-3xl font-semibold mb-10'>{data?.post?.title}</h1>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {data?.post?.content?.markdown}
                        </ReactMarkdown>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center pt-10 border-t-[1px] border-[#22222240]'>
                <h1 className='font-medium'>More Articles</h1>
            </div>
            <div className=' flex flex-row flex-wrap w-full h-fit justify-between py-10'>
                {
                    (otherBlogs?.data?.posts?.slice(0, 3))?.map((blog, index) => (
                        <BlogCard blog={blog} key={index} />
                    ))
                }
            </div>
        </div>
    )
};

export default Blog;

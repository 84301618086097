import { useEffect, useState } from "react";
import {
  EmailIcon,
  TelegramIcon,
  TelegramShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeShare } from "../../pages/Share/shareSlice";
import Button from "../Button";
import './sharecomponent.scss';

const ShareComponent = () => {
  const {open, text, title, url, email} = useAppSelector((state) => state.share);
  const dispatch = useAppDispatch();
  const [data, setData] = useState({
    url: '',
    title: '',
    text: '',
    email: '',
  });

  useEffect(() => {
    if (open) {
      setData({
        url,
        title,
        text,
        email,
      })
    }
  }, [text, title, url, open, email]);

  if (!open) return null;
  return (
    <div className="share-component" onClick={(event) => {
      event.stopPropagation();
      dispatch(closeShare())
    }}>
      <Button
        label="Close"
        type="flat"
        onClick={() => {
          dispatch(closeShare())
        }}
        className="share-btn"
      />
      <h1>Share via social media</h1><br />
      <div>
        <WhatsappShareButton
          title={`${data.title} | ${data.text}`}
          url={data.url}
          separator=""
        >
          <WhatsappIcon />
        </WhatsappShareButton>
        <button style={{background: 'transparent'}}>
          <a href={`mailto:${data.email}?subject=${data.title}&body=${data.text} ${data.url}`}>
            <EmailIcon />
          </a>
        </button>
        <TelegramShareButton title={`${data.title} | ${data.text}`} url={data.url}>
          <TelegramIcon />
        </TelegramShareButton>
      </div>
    </div>
  );
};

export default ShareComponent;

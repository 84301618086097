
import { useEffect, useState } from "react";
import ExpenseDetails from "../ExpenseDetails";
import NotFound from "../NotFound";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import { useAppSelector } from "../../app/hooks";
import { getAllInquiries } from "../../actions/adminActions";


const AllInquiry = (props) => {
  const {inquiry} = useAppSelector((state) => state.admin)
  const [expense, setExpense] = useState<Record<string, any>>({});
  const handleCloseDetail = () => {
    setExpense({});
  }

  const {setHideNav} = props;

  useEffect(() => {
    if (expense.id) {
      setHideNav(true);
    }
  }, [expense.id, setHideNav]);

  if (expense.id) {
    return <ExpenseDetails expense={expense} handleCloseDetail={handleCloseDetail} />;
  }
  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    getAllInquiries(page);
  }

  return (
    <div className="bg-[#ffffff]  border border-white">
      <div className="">
        <div className="">
          <div className='head bg-[#0898A01A] h-14 hidden lg:flex'>
            <div className='row flex flex-row justify-between !w-full items-center !h-14'>
              <div className='!w-[12%] pl-3'>Firstname</div>
              <div className='!w-[12%] pl-3'>lastname</div>
              <div className='!w-[12%] pl-3'>Phone Number</div>
              <div className='!w-[12%] pl-3'>Email</div>
              <div className='!w-[50%] !border !border-red'>Message</div>
            </div>
          </div>
          <div className="body min-h-[50vh]">
            <div className="body-section">
              {(!inquiry || inquiry?.data?.length === 0) ?
                (
                  <NotFound 
                    text1="No Inquiry in the system yet!"
                    text2='Manage all inquiry sent in here.'
                    btnLabel=""
                    onClick={() => {}}
                  />
                ) :
                inquiry?.data?.map((item, index) => {
                  return (
                    <Inquiry
                      key={item.id}
                      inquiry={item}
                    />
                  );
                })}
            </div>
          </div>
            {
                inquiry?.data?.length !== 0 && (
                <TablePaginationFooter 
                    total={inquiry?.total}
                    from={inquiry?.from}
                    to={inquiry?.to}
                    nextUrl={inquiry?.next_page_url}
                    prevUrl={inquiry?.prev_page_url}
                    handlePagination={handlePagination}
                />
                )
            }
        </div>
      </div>
    </div>
  );
};

export default AllInquiry;

export function Inquiry({ inquiry }: any) {
    
    return (
      <>
        <div className='row flex-row w-full hidden lg:flex cursor-pointer'>
          <div className='w-[10%] pl-3'>{inquiry.first_name}</div>
          <div className='w-[10%] pl-3'>{inquiry.last_name}</div>
          <div className='w-[10%] pl-3'>{inquiry.phone_number}</div>
          <div className='w-[10%] pl-3'>{inquiry.email}</div>
          <div className='w-[50%]'>{inquiry.issue}</div>
        </div>
        <div className='row rounded !flex-col !p-4 !justify-start !items-start !m-auto flex my-2 lg:hidden border border-gray !min-h-[100px] !h-max !w-[96%] cursor-pointer'>
            <div className='w-[5%]'>{inquiry.first_name} {inquiry.last_name}</div>
            <div className='w-[5%]'>{inquiry.phone_number}</div>
            <div className='w-[5%]'>{inquiry.email}</div>
            <div className='w-[50%]'>{inquiry.issue}</div>
        </div> 
      </>
    )
  }

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setShowVerificationPending } from "../../pages/Auth/authSlice";
import Button from "../Button";
import Modal from "../Modal";
import './verificationPending.scss';

const VerificationPendingModal = ({
  isOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {user} = useAppSelector((state) => state.auth);
  // @ts-ignore
  const dataSupplied = user?.bvn && user?.nimc && user?.first_name && user.last_name;
  
  const handleClose = () => {
    dispatch(setShowVerificationPending(false));
  }

  const handleDone = () => {
    handleClose();
    navigate('/dashboard/profile');
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      overrideStyle={{
        maxWidth: '400px',
        width: '80%',
        height: 'max-content',
        paddingBottom: '20px',
        paddingTop: '20px',
      }}
      title={dataSupplied ? "Verification pending" : "Incomplete profile"}
      footer={() => (
        <>
          <Button
            label="Cancel"
            onClick={handleClose}
            type="contained"
            btnActionType="button"
          />
          <Button
            label={dataSupplied ? 'Verify' : "Complete profile"}
            onClick={handleDone}
            type="flat"
            btnActionType="button"
          />
        </>
      )}
    >
      <div className="add-user-modal !w-full">
        <h3 className="text-center w-full font-regular">
          {
            dataSupplied ? 'Your provided data is pending verification.' :
              'You need to complete your profile by adding your Fullname, BVN(verified) and NIMC number to be able to carry out this action.'
          }
        </h3>
      </div>    
    </Modal>
  )
}; 

export default VerificationPendingModal;

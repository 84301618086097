import BusinessPageBuilder from "./BusinessProductBuilder";
import Hero1 from '../../assets/new/business/smotoImg.png';
import RefundIcon from '../../assets/new/business/smotoIcon.svg';
import RefProd2 from '../../assets/new/business/send.svg';
import RefProd1 from '../../assets/new/business/processing.svg';
import RefProd3 from '../../assets/new/business/security.svg';
import RefProd4 from '../../assets/new/business/customerSupport.svg';
import RefProd5 from '../../assets/new/business/chart.svg';
import RefProd6 from '../../assets/new/business/flex.svg';
import { Reason } from "../../types";
import { smoto } from "../../data/faqData";

const SMOTO = () => {
    const reasons: Reason[] = [
        {
            tagline: 'BROADER REACH',
            title: `Increased Sales and Revenue`,
            details: `Leveraging the vast user base of social
                media platforms can attract new
                customers and drive more sales.`,
            img: RefProd2,
            bg: ''
        },
        {
            tagline: 'AUTOMATION',
            title: `Streamlined Order Processing`,
            details: `Implementing chatbots and automated
                systems for order processing can reduce
                wait times and increase efficiency.`,
            img: RefProd1,
            bg: ''
        },
        {
            tagline: 'ADVANCED SECURITY',
            title: `Fraud Prevention`,
            details: `Utilizing secure payment gateways and 
encryption methods to protect customer
data and ensure safe transactions.`,
            img: RefProd3,
            bg: '#F5F8FF'
        },
        {
            tagline: 'ASSISTANCE',
            title: `Real-time Customer Support`,
            details: `Providing real-time customer support
                through social media messages or phone
                calls allows for prompt resolution of issues
                and queries.`,
            img: RefProd4,
            bg: ''
        },
        {
            tagline: 'DATA AND INSIGHTS',
            title: `Valuable Analytics`,
            details: `Social media interactions provide valuable
                data on customer preferences, behavior,
                and feedback.`,
            img: RefProd5,
            bg: ''
        },
        {
            tagline: 'ADAPTABILITY',
            title: `Flexibility and Scalability`,
            details: `SMOTO is adaptable and scalable to various 
business models, to handle increasing order 
volumes during peak times.`,
            img: RefProd6,
            bg: '#F5F8FF'
        }
    ];
    const howItWorks = [
        {
            title: 'Customer Call',
            details: `Customers call the business’s customer service or sales line to inquire 
and place orders and the representative assists the customer with 
product selection, provides information, and answers queries.`
        },
        {
            title: 'Order Details',
            details: `The representative collects details like product type, quantity, and
shipping address and also customers provides payment Information
such as credit/debit card details over the phone.`
        },
        {
            title: 'Payment Processing',
            details: `The representative enters payment details into a secure virtual terminal
or payment gateway and payment is processed in real-time, and 
authorization is confirmed while the customer is on the call.`
        },
        {
            title: 'Order Confirmation',
            details: `The representative confirms the order and payment details with
the customer and a receipt or invoice is sent to the customer via
email or physical.`
        },
        {
            title: 'Order Fulfillment',
            details: `The order is processed, shipped, and tracking information is 
provided to the customer.`
        },
        {
            title: 'Customer Follow-Up',
            details: `Follow-up calls or messages may be used to confirm delivery
and customer satisfaction.`
        },
    ];
    const howItWorks2 = [
        {
            title: 'Customer Engagement',
            details: `Customers find products or services through posts, ads etc on social 
media platforms like Instagram, Facebook etc, which they engage by
commenting on posts, sending direct messages (DMs).`
        },
        {
            title: 'Order Initiation',
            details: `Customers send a message or comment expressing interest
 in a product, which businesses can create pre-filled order forms
linked in bio or posts.`
        },
        {
            title: 'Order Confirmation',
            details: `A customer service representative or the use of chatbots, confirms or 
automate the order details through messages or calls, ensuring 
accuracy and reducing wait times.`
        },
        {
            title: 'Order Confirmation',
            details: `The representative confirms the order and payment details with
the customer and a receipt or invoice is sent to the customer via
email or physical`
        },
        {
            title: 'Payment Processing',
            details: `The business sends a secure payment link through the social media
platform or the use of In-App Payments e.g., Instagram checkout,
Facebook pay) allow customers to complete payments directly
within the app.`
        },
        {
            title: 'Order Fulfillment',
            details: `Orders are processed, shipped, and tracking details are provided 
to customers via social media messages or email.`
        },
        {
            title: 'Feedbacks and Review',
            details: `Businesses can follow up with customers for feedback, encouraging
them to leave reviews on social media and customer support addresses
any issues or queries post-purchase.`
        },
    ];
    const benefits = []
    
    return (
        <BusinessPageBuilder
            title="Shop Socially, Pay Securely From Chat to Checkout in Minutes"
            name={"SMOTO"}
            tagline={`Let your Customers Order on any social media platforms.
                Use AzatMe’ SMOTO to collect payment. Fast, easy, and reliable.
                Protect your bank account.`}
            titleIcon={RefundIcon}
            image={Hero1}
            imgText={[]}
            summary={``}
            reasons={reasons}
            howItWorks={{
                'Social Media Orders': howItWorks,
                'Telephone Orders': howItWorks2
            }}
            benefits={benefits}
            faq={smoto}
        />
    );
};

export default SMOTO;

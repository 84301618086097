import { useState, useCallback, useEffect } from "react";
import Input from "../Input";
import Modal from "../Modal";
import useGetInvitationData from "../../hooks/useGetInvitationData";
import { declineAjoInvite } from "../../actions/dashboardActions";

const DeclineAjo = () => {
    const [showForm, setShowForm] = useState(false);
    const [linkData, setLinkData] = useState({});
    const [inviteData] = useGetInvitationData();
    useEffect(() => {
        if (inviteData.action === "decline") {
            setShowForm(true);
            setLinkData(inviteData);
        }
    }, [inviteData]);
    return (
        <DeclineAjoForm showForm={showForm} linkData={linkData} />
    );
};

export default DeclineAjo;

export const DeclineAjoForm = ({showForm, linkData, handleDone = () => {}, closeFrmParent = (val: boolean) => {}}) => {
    const [showDeclineInvite, setShowDeclineInvite] = useState(false);
    const invitationData = localStorage.getItem('pendingInvitation');
    const [ajoAcceptData, setAjoAcceptData] = useState({
      declineReason: '',
      selectedAcct: '',
      position: '',
      declineNote: '',
    });

    useEffect(() => {
        setShowDeclineInvite(showForm)
    }, [showForm]);
  
    const handleChange = useCallback((name: string, value: string) => {
      setAjoAcceptData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }, []);
    
    useEffect(() => {
      if (invitationData) {
        // if (JSON.parse(invitationData)?.action === 'accept') {
        //   setShowAcceptInvite(true);
        // }
        if (JSON.parse(invitationData)?.action === 'decline') {
          setShowDeclineInvite(true);
        }
      }
    }, []);

    const handleDeclineAjo = (event) => {
        event.preventDefault();
        declineAjoInvite({
            reason:  ajoAcceptData.declineReason,
            remarks: ajoAcceptData.declineNote,
            inviteLink: `https://www.azatme.com/register?invitee_name=${linkData.invitee_name}&email=${linkData.email}&phone_number=${linkData.phone_number}&position=${linkData.position}&inviter_token=${linkData.inviter_token}&action=decline`
        }, () => {
            setShowDeclineInvite(false);
            localStorage.removeItem('pendingInvitation');
            handleDone();
        })
    }



    return (
        <Modal
          open={showDeclineInvite}
          onClose={() => {
            setShowDeclineInvite(false);
            closeFrmParent(false);
          }}
          overrideStyle={{
            maxWidth: '400px',
            width: '100%',
            height: 'max-content',
            maxHeight: '70%',
            border: '1px solid red',
          }}
          title="Decline Request"
        >
          <form action="" onSubmit={handleDeclineAjo} className='w-full'>
            <p className='py-6 text-center text-sm'>
              Are you sure you don’t want to join this Ajo club. Kindly
              state the reason for declining
            </p>
            <Input
                label='Reason'
                name={'declineReason'}
                value={ajoAcceptData.declineReason}
                onChange={handleChange}
                placeholder='Enter Decline Reason'
                required
            />
            <label htmlFor="" className='w-full text-left'>Add Notes</label>
            <textarea
                className='resize-none !border !border-[#b4b4b5] h-20 py-2 mb-6'
                name='declineNote'
                value={ajoAcceptData.declineNote}
                onChange={(event) => handleChange('declineNote', event.target.value)}
                placeholder='Give more context'
            >
            </textarea>
            <div className='flex w-full flex-row justify-around items-center mt-5'>
              <button className='w-[45%] px-5 border bg-az-teal text-white' type="submit">Decline</button>
            </div>
          </form>
        </Modal>
    );
}

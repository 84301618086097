import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { scrollUp } from '../../utils/common';
import './developer.scss'

const Developer = () => {
  useEffect(() => {
    scrollUp();
  }, []);
  return (
    <div className='notfound-wrapper'>
      <h1>Developers Section</h1>
      <span>Coming Soon!</span>
      <NavLink className="go-back" to="/">Go Back</NavLink>
    </div>
  );
};

export default Developer;

import { useState } from "react";

const TabComponent = ({components}) => {
    const tabs = Object.keys(components);
    const [currentTab, setCurrentTab] = useState(tabs[0]);
    const Component = components[currentTab];
    return (
        <div className="w-full h-full px-8">
            <div className="w-full h-16 flex flex-row justify-start items-center border-b border-az-gray-120">
                {
                    tabs.map((tab) => (
                        <button
                            key={tab} onClick={() => setCurrentTab(tab)}
                            className="w-fit min-w-[120px] mr-3 !rounded-none h-full"
                            style={{
                                borderBottom: currentTab === tab ? '4px solid #0898A0' : '4px solid transparent',
                            }}
                        >
                            {tab}
                        </button>
                    ))
                }
            </div>
            <div className="w-full flex flex-auto flex-grow">
                <Component />
            </div>
        </div>
    )
}

export default TabComponent;


import { useCallback, useState } from "react";
import Categories from "../../components/Categories";
import CreateCategory from "../../components/Categories/CreateCategory";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";

const categoriesNavItems = ["All Categories", "Create Category"];

const CategoriesPage = () => {
  const [tab, setTab] = useState(categoriesNavItems[0]);

  const handleDone = useCallback(() => {
    setTab(categoriesNavItems[0]);
  }, []);
  const renderChildren = () => {
    switch (tab) {
      case categoriesNavItems[0]:
        return <Categories />
      case categoriesNavItems[1]:
        return <CreateCategory handleDone={handleDone} />
      default:
        return <Categories />
    }
  }

  const renderNavItems = () => {
    return (
      <DashboardNavItem navItems={categoriesNavItems} tab={tab} setTab={setTab} />
    )
  }

  return (
    <DashboardComponentWrapper title="Categories" renderNavItems={renderNavItems}>
      {renderChildren()}
    </DashboardComponentWrapper>
  )
}

export default CategoriesPage
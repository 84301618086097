import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getKontributeDraft, getPaymentOpenGroups, getUserGroupsActionCreator } from "../../actions/dashboardActions";
import { AccordionData } from "../../types";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import Accordion from "./Accordion";
import "./userGroup.scss"
import { useEffect, useState } from "react";
import DashboardNavItem from "../DashboardNavItem/DashboardNavItem";
import NotFound from "../NotFound";
import { handleShareAzatMe } from "../../utils/common";
import useVerificationDone from "../../hooks/useVerificationDone";

const kontributeNavItems = [
  'Created By Me',
  'Invited To',
  'My Drafts',
  // 'Open Kontribute',
  // 'In Progress', 'Completed'
];

const UserGroup = (props) => {
  const [tab, setTab] = useState(kontributeNavItems[0]);
  const {isVerificationDone} = useVerificationDone();
  const {userGroups, openUserGroups, draftKontribute} = useAppSelector((state) => state.dashboard);
  // @ts-ignore
  const grpsByMeObj = userGroups?.getAuthUserGroupsCreated;
  // @ts-ignore
  const grpsInvitedToObj = userGroups?.getGroupsInvitedTo;
  const dispatch = useAppDispatch();
  const isMine = tab === kontributeNavItems[0]
  const selectedGrpType: Record<string, any> = isMine ? grpsByMeObj : tab === 'Open Kontribute' ? openUserGroups : tab === kontributeNavItems[2] ? draftKontribute : grpsInvitedToObj;

  useEffect(() => {
    if (props.tab) {
      setTab(props.tab);
    }
  }, [props.tab]);

  useEffect(() => {
    if (isVerificationDone) {
      getPaymentOpenGroups(1, (resp) => {
      });
    }
  }, [isVerificationDone]);

  useEffect(() => {
    if (tab === kontributeNavItems[2] && isVerificationDone) {
      handleGetKontributeDraft();
    }
  }, [tab]);

  const handleGetKontributeDraft = (page = 1) => {
    getKontributeDraft(page, (resp) => {})
  }

  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    switch (tab) {
      case kontributeNavItems[0]:
        return getUserGroupsActionCreator(page);
      case kontributeNavItems[1]:
        return getUserGroupsActionCreator(page);
      case kontributeNavItems[2]:
        return handleGetKontributeDraft(page);
      case 'Open Kontribute':
        return getPaymentOpenGroups(page, (resp) => {});
      default:
        return getUserGroupsActionCreator(page);
    }
  }

  const renderNavItems = () => {
    return (
      <DashboardNavItem 
        isSecondTier 
        navItems={tab === 'Open Kontribute' ? [] : kontributeNavItems} 
        tab={tab}
        setTab={setTab} 
      />
    )
  }

  return (
    <div className="user-group mt-6 bg-white !w-full !p-0">
      <div className="px-6" style={{width: '100%'}}>
        {renderNavItems()}
      </div>
      {
        (!selectedGrpType || (selectedGrpType?.data && !selectedGrpType?.data.length)) ?
        (
          <>
            {
              isMine ? (
                <NotFound 
                  text1="You have no Kontribute yet!"
                  text2='Begin the Journey by creating your first Kontribute.'
                  btnLabel='Create Kontribute.'
                  onClick={() => props.handleCreate()}
                />
              ) : tab === 'Open Kontribute' ? (
                <NotFound 
                  text1="You have no open Kontribute yet!"
                  text2='Begin the Journey by creating your first Kontribute.'
                  btnLabel='Create Kontribute.'
                  onClick={() => props.handleCreate()}
                />
              ) : tab === 'My Draft' ? (
                <NotFound 
                  text1="You have no draft Kontribute yet!"
                  text2='Begin the Journey by creating your first Kontribute.'
                  btnLabel='Create Kontribute.'
                  onClick={() => props.handleCreate()}
                />
              ) : (
                <NotFound 
                  text1={"You have not been invited to any Kontribute!"}
                  text2='Share the app with Your Friends to experience this.'
                  btnLabel='Share AzatMe.'
                  onClick={() => handleShareAzatMe(dispatch)}
                />
              )
            }
          </>
        ) : (
          <div className="wrapper !w-full">
            <Accordion
              items={selectedGrpType.data as unknown as AccordionData[]}
              handleMore={props.handleMore}
            />
            <TablePaginationFooter
              total={selectedGrpType?.total}
              from={selectedGrpType?.from}
              to={selectedGrpType?.to}
              nextUrl={selectedGrpType?.next_page_url}
              prevUrl={selectedGrpType?.prev_page_url}
              handlePagination={handlePagination}
            />
          </div>
        )
      }
    </div>
  )
}

export default UserGroup;

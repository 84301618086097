/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import Options from '../../assets/option.png';
import CloseIcon from "../../assets/close-icon.svg";
import Caret from "../../assets/caret-right.png";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { formatCurrency, navigatorShare } from "../../utils/common";
import "./userCard.scss";
import Modal from "../Modal";
import Input from "../Input";
import Button from "../Button";
import toast from "react-hot-toast";
import { getKontributeBalance, getRefundMeBalance } from "../../actions/dashboardActions";
import { IUserCard } from "../../utils/type";



const EditUserEmail = ({userEmail, handleSubmit, onClose, open, id}) => {
  const [email, setEmail] = useState('');
  useEffect(() => {
    setEmail(userEmail);
  }, [userEmail]);

  const onSubmit = () => {
    if (!email) {
      toast('Enter user email to continue');
      return;
    }
    handleSubmit(email, id);
  }
  return (
    <Modal
      onClose={onClose}
      open={open}
      overrideStyle={{
        height: '250px',
        minHeight: '0',
      }}
      title="Edit User"
      footer={() => (
        <>
          <Button label="Cancel" onClick={onClose} type="contained" btnActionType="submit" />
          <Button label="Save" onClick={onSubmit} type="flat" btnActionType="submit" />
        </>
      )}
    >
      <form onSubmit={onSubmit} 
        style={{
          padding: '0'
        }}
      >
        <Input
          label="User's Email"
          type="email"
          value={email}
          name="email"
          required
          onChange={(name, value) => setEmail(value)}
        />
        
      </form>
    </Modal>
  )
}

const UserCard = ({
  id,
  username, 
  email, 
  hasPaid, 
  expense, 
  user, 
  paid, 
  handleEdit,
  isMine,
  payable,
  // amtPaid,
  type = 'RefundMe',
  hasReminderLink = false,
  refetchMembers,
}: IUserCard) => {
  const [showEdit, setShowEdit] = useState(false);
  const remindText = `You are reminded to pay up your ${type}. Click the attached link to pay.`;
  const balanceText = `You are reminded to pay up the balance of the ${type}. Click the attached link to pay.`;
  const appreciateText = `Thank you for Promptly paying Up your ${type}.`;
  const [openOptions, setOpenOptions] = useState(false);
  const dispatch = useAppDispatch();
  const amtPaid = user.totalAmount;

  const handleShare = (data?: any) => {
    navigatorShare({
      title: `Paythru AzaTme ${type} ${expense.name}`,
      text: payable == amtPaid ? appreciateText : amtPaid < payable ? balanceText : remindText,
      url: data && data.payLink ? data.payLink : `https://apps.paythru.ng/services/cardfree/pay/${user.paymentReference}`,
      email,
    }, dispatch);
  }

  const renderPaid = () => {
    // unpaid | underpaid | overpaid | paid
    // a === 0 |am < pay  | am > pay | am === pa
    return (
      <>
        {payable == amtPaid ? (
          <div className="payment-status">
            Paid
          </div> 
        ) : amtPaid == 0 ? (
            <div className="payment-status unpaid">
              Unpaid
            </div>
            //@ts-ignore
          ) : parseInt(amtPaid) > parseInt(payable) ? (
            <div className="payment-status unpaid">
              Overpaid
            </div>
          ) : (
            <div className="payment-status unpaid">
              Underpaid
            </div>
          )
        }
      </>
    )
  }

  const renderStatusText = () => {
    // unpaid | underpaid | overpaid | paid
    // a === 0 |am < pay  | am > pay | am === pa
    return (
      <>
        {payable == amtPaid ? (
          'Appreciate'
        ) : amtPaid == 0 ? (
            'Remind'
            //@ts-ignore
          ) :parseInt(amtPaid) > parseInt(payable) ? (
            'Refund'
          ) : user.hasBalanceLink ? (
            'Pending Balance'
          ) : (
            'Get Balance'
          )
        }
      </>
    )
  }

  const handleStatusActn = () => {
    if (user.hasBalanceLink) {
      toast('Balance Link already created for the user!');
      return;
    }
    if (payable == amtPaid || amtPaid == 0) {
      handleShare();
    } else if (amtPaid < payable) {
      let handler;
      switch (type) {
        case 'RefundMe':
          handler = getRefundMeBalance
          break;
        case 'Kontribute':
            handler = getKontributeBalance
            break;
        default:
          handler = null
          break;
      } 
      if (handler) {
        handler({id: expense.id, uid: user.id}, (resp) => {
          const postponeShare = () => {
            handleShare(resp.data);
            refetchMembers && refetchMembers();
          }
          setTimeout(postponeShare, 2000);
        });
      }
    }
  }

  const handleShowEdit = () => {
    if (paid > 0) {
      toast.error('You can not edit members of an ongoing/completed transaction!');
      return;
    }
    setShowEdit(true);
  }
  return (
    <div className="user !w-full h-fit">
      <div className="user-wrapper" onClick={(e) => {
        e.stopPropagation();
        setOpenOptions(!openOptions);
      }}>
        {
          isMine && (
            <div className="user-options">
              <button className="options-close-btn" onClick={(e) => {
                e.stopPropagation();
                setOpenOptions(!openOptions);
              }}>
                <img src={!openOptions ? Options : CloseIcon} alt="" />
              </button>
            </div>
          )
        }
        {
          !openOptions && (
            <div className="user-image !rounded-full !text-2xl font-medium">
              <h1 className="!text-2xl">{email && email[0]}</h1>
            </div>
          )
        }

        <div className="user-info h-fit">
          {
            !openOptions && (
              <>
                {user.firstname && <small>{user.first_name} {user.last_name}</small>}
                <small className="email">{email}</small>
                {user.paymentReference && <small>Reference: {user.paymentReference}</small>}
                <div className="payment"> 
                  {renderPaid()}
                  <i><small></small>{formatCurrency({num: amtPaid})} of <small></small>{formatCurrency({num: payable})}</i> 
                </div>
              </>
            )
          }
          {
            openOptions && (
              <div className="options-wrapper">
                <button onClick={handleStatusActn}>
                  {renderStatusText()}
                </button>
                {handleEdit && <button onClick={handleShowEdit}>Edit</button>}
                {/* <button>Delete</button> */}
              </div>
            )
          }
        </div>
      </div>
      {
        handleEdit && (
          <EditUserEmail
            open={showEdit}
            handleSubmit={handleEdit}
            userEmail={email}
            onClose={() => setShowEdit(false)}
            id={id}
          />
        )
      }
    </div>
  )
}

export const TransactionUserCard = ({
  id,
  username, 
  email, 
  hasPaid, 
  expense, 
  user, 
  paid, 
  handleEdit,
  isMine,
  payable,
  // amtPaid,
  type = 'RefundMe',
  hasReminderLink = false,
  refetchMembers,
}: IUserCard) => {
  const [showEdit, setShowEdit] = useState(false);
  const remindText = `You are reminded to pay up your ${type}. Click the attached link to pay.`;
  const balanceText = `You are reminded to pay up the balance of the ${type}. Click the attached link to pay.`;
  const appreciateText = `Thank you for Promptly paying Up your ${type}.`;
  const [openOptions, setOpenOptions] = useState(false);
  const dispatch = useAppDispatch();
  const amtPaid = user.totalAmount;

  const handleShare = (data?: any) => {
    navigatorShare({
      title: `Paythru AzaTme ${type} ${expense.name}`,
      text: payable == amtPaid ? appreciateText : amtPaid < payable ? balanceText : remindText,
      url: data && data.payLink ? data.payLink : `https://apps.paythru.ng/services/cardfree/pay/${user.paymentReference}`,
      email,
    }, dispatch);
  }

  const renderPaid = () => {
    // unpaid | underpaid | overpaid | paid
    // a === 0 |am < pay  | am > pay | am === pa
    return (
      <>
        {payable == amtPaid ? (
          <div className="payment-status">
            Paid
          </div> 
        ) : amtPaid == 0 ? (
            <div className="payment-status unpaid">
              Unpaid
            </div>
            //@ts-ignore
          ) : parseInt(amtPaid) > parseInt(payable) ? (
            <div className="payment-status unpaid">
              Overpaid
            </div>
          ) : (
            <div className="payment-status unpaid">
              Underpaid
            </div>
          )
        }
      </>
    )
  }

  const renderStatusText = () => {
    // unpaid | underpaid | overpaid | paid
    // a === 0 |am < pay  | am > pay | am === pa
    return (
      <>
        {payable == amtPaid ? (
          'Appreciate'
        ) : amtPaid == 0 ? (
            'Remind'
            //@ts-ignore
          ) :parseInt(amtPaid) > parseInt(payable) ? (
            'Refund'
          ) : user.hasBalanceLink ? (
            'Pending Balance'
          ) : (
            'Get Balance'
          )
        }
      </>
    )
  }

  const handleStatusActn = () => {
    if (user.hasBalanceLink) {
      toast('Balance Link already created for the user!');
      return;
    }
    if (payable == amtPaid || amtPaid == 0) {
      handleShare();
    } else if (amtPaid < payable) {
      let handler;
      switch (type) {
        case 'RefundMe':
          handler = getRefundMeBalance
          break;
        case 'Kontribute':
            handler = getKontributeBalance
            break;
        default:
          handler = null
          break;
      } 
      if (handler) {
        handler({id: expense.id, uid: user.id}, (resp) => {
          const postponeShare = () => {
            handleShare(resp.data);
            refetchMembers && refetchMembers();
          }
          setTimeout(postponeShare, 2000);
        });
      }
    }
  }

  const handleShowEdit = () => {
    if (paid > 0) {
      toast.error('You can not edit members of an ongoing/completed transaction!');
      return;
    }
    setShowEdit(true);
  }
  return (
    <div className="user !w-full h-fit">
      <div className="user-wrapper" onClick={(e) => {
        e.stopPropagation();
        setOpenOptions(!openOptions);
      }}>
        {
          isMine && (
            <div className="user-options">
              <button className="options-close-btn" onClick={(e) => {
                e.stopPropagation();
                setOpenOptions(!openOptions);
              }}>
                <img src={!openOptions ? Options : CloseIcon} alt="" />
              </button>
            </div>
          )
        }
        {
          !openOptions && (
            <div className="user-image !rounded-full !text-2xl font-medium">
              <h1 className="!text-2xl">{email && email[0]}</h1>
            </div>
          )
        }

        <div className="user-info h-fit">
          {
            !openOptions && (
              <>
                {user.firstname && <small>{user.first_name} {user.last_name}</small>}
                <small className="email">{email}</small>
                {user.paymentReference && <small>Reference: {user.paymentReference}</small>}
                <div className="payment"> 
                  {renderPaid()}
                  <i>{formatCurrency({num: amtPaid})} of {formatCurrency({num: payable})}</i> 
                </div>
              </>
            )
          }
          {
            openOptions && (
              <div className="options-wrapper">
                <button onClick={handleStatusActn}>
                  {renderStatusText()}
                </button>
                {handleEdit && <button onClick={handleShowEdit}>Edit</button>}
                {/* <button>Delete</button> */}
              </div>
            )
          }
        </div>
      </div>
      {
        handleEdit && (
          <EditUserEmail
            open={showEdit}
            handleSubmit={handleEdit}
            userEmail={email}
            onClose={() => setShowEdit(false)}
            id={id}
          />
        )
      }
    </div>
  )
}

export default UserCard;

export const AjoUserCard = ({
  id,
  username, 
  email, 
  hasPaid,
  status,
  expense, 
  user, 
  paid, 
  handleEdit,
  isMine,
  payable,
  amtPaid,
  type = 'RefundMe',
  hasReminderLink = false,
  refetchMembers,
  collectingMember,
  paymentMap,
  userPayment,
  membersOnly,
}: IUserCard) => {
  const [showEdit, setShowEdit] = useState(false);
  const currentUser = useAppSelector((state) => state.auth.user);
  const remindText = `You are reminded to pay up your ${type}. Click the attached link to pay.`;
  const balanceText = `You are reminded to pay up the balance of the ${type}. Click the attached link to pay.`;
  const appreciateText = `Thank you for Promptly paying Up your ${type}.`;
  const [openOptions, setOpenOptions] = useState(false);
  const dispatch = useAppDispatch();


  const handleShare = (data?: any) => {
    navigatorShare({
      title: `Paythru AzaTme ${type} ${expense.name}`,
      text: payable == amtPaid ? appreciateText : amtPaid < payable ? balanceText : remindText,
      url: data && data.payLink ? data.payLink : `https://apps.paythru.ng/services/cardfree/pay/${user.paymentReference}`,
      email,
    }, dispatch);
  }

  const renderStatusText = () => {
    // unpaid | underpaid | overpaid | paid
    // a === 0 |am < pay  | am > pay | am === pa
    return (
      <>
        {payable == amtPaid ? (
          'Appreciate'
        ) : amtPaid == 0 ? (
            'Remind'
            //@ts-ignore
          ) :parseInt(amtPaid) > parseInt(payable) ? (
            'Refund'
          ) : user.hasBalanceLink ? (
            'Pending Balance'
          ) : (
            'Get Balance'
          )
        }
      </>
    )
  }

  const handleStatusActn = () => {
    if (user.hasBalanceLink) {
      toast('Balance Link already created for the user!');
      return;
    }
    if (payable == amtPaid || amtPaid == 0) {
      handleShare();
    } else if (amtPaid < payable) {
      let handler;
      switch (type) {
        case 'RefundMe':
          handler = getRefundMeBalance
          break;
        case 'Kontribute':
            handler = getKontributeBalance
            break;
        default:
          handler = null
          break;
      } 
      if (handler) {
        handler({id: expense.id, uid: user.id}, (resp) => {
          const postponeShare = () => {
            handleShare(resp.data);
            refetchMembers && refetchMembers();
          }
          setTimeout(postponeShare, 2000);
        });
      }
    }
  }

  const handleShowEdit = () => {
    if (paid > 0) {
      toast.error('You can not edit members of an ongoing/completed transaction!');
      return;
    }
    setShowEdit(true);
  }

  const renderMembersDetails = () => {
    return (
      <>
        {user.name && <small>{user.name}</small>}
        <small className="email">{email === currentUser?.email ? 'You' : email}</small>
        {user.paymentReference && <small>Reference: {user.paymentReference}</small>}
        <div className="payment w-full"> 
          <button className="rounded mx-2 !h-6 !py-0 bg-green-600 capitalize flex flex-row justify-center items-center">
            {status === 'accept' ? 'Accepted' : status === 'reject' ? 'Rejected' : 'Invite Pending'}
          </button>
          {
            collectingMember?.email === email && (
              // might move withdrawal or defaulter reminder here. 
              <button disabled={user.email !== email} className="bg-orange-500 !h-6 !py-0 flex justify-center items-center">{user.email === email ? 'Collector' : 'Collector'}</button>
            )
          }
        </div>
      </>
    )
  }

  const renderPaymentDetails = () => {
    return (
      <>
        {user.providedName && <small>{user.providedName}</small>}
        <small className="email">{user.providedEmail === currentUser?.email ? 'You' : user.providedEmail}</small>
        {user.paymentReference && <small>Reference: {user.paymentReference}</small>}
        <div className="payment w-full"> 
          <i className="!m-0"><small></small>{amtPaid}</i>
        </div>
      </>
    )
  }

  const userIcon = email ? email[0] : user.providedEmail ? (user.providedEmail)[0] : '';
  return (
    <div className="user !w-full">
      <div className="user-wrapper" onClick={(e) => {
        e.stopPropagation();
      }}>
        {
          isMine && (
            <div className="user-options">
              <button className="options-close-btn" onClick={(e) => {
                e.stopPropagation();
                // setOpenOptions(!openOptions);
              }}>
                {/* <img src={!openOptions ? Options : CloseIcon} alt="" /> */}
              </button>
            </div>
          )
        }
        {
          !openOptions && (
            <div className="user-image !rounded-full !text-2xl font-medium">
              <h1 className="!text-2xl">{userIcon}</h1>
            </div>
          )
        }

        <div className="user-info">
          {
            !openOptions && (
              <>
                {membersOnly ? renderMembersDetails() : renderPaymentDetails()}
              </>
            )
          }
          {
            openOptions && (
              <div className="options-wrapper">
                <button onClick={handleStatusActn}>
                  {renderStatusText()}
                </button>
                {handleEdit && <button onClick={handleShowEdit}>Edit</button>}
                {/* <button>Delete</button> */}
              </div>
            )
          }
        </div>
      </div>
      {
        handleEdit && (
          <EditUserEmail
            open={showEdit}
            handleSubmit={handleEdit}
            userEmail={email}
            onClose={() => setShowEdit(false)}
            id={id}
          />
        )
      }
    </div>
  )
}

export const AjoPaymentCard = ({
  email,
  expense, 
  user, 
  handleEdit,
  payable,
  amtPaid,
  type = 'RefundMe',
  refetchMembers,
  collectingMember,
  paymentMap,
  userPayment,
  membersOnly,
}: IUserCard) => {
  const currentUser = useAppSelector((state) => state.auth.user);
  const [showPayment, setShowPayment] = useState(false);

  const togglePaymentDetails = () => {
    setShowPayment(!showPayment);
  }

  const renderMembersDetails = () => {
    return (
      <div className="flex flex-col">
        {user.name && <small>{user.name}</small>}
        <small className="email">{email === currentUser?.email ? 'You' : email}</small>
        {user.paymentReference && <small>Reference: {user.paymentReference}</small>}
      </div>
    )
  }

  const renderPaymentDetails = () => {
    if (userPayment?.ajoContributors) {
      return (
        <>
          {
            userPayment.ajoContributors.map((itm, index) => (
              <div className="flex flex-col px-3 py-2 mb-2 w-11/12 rounded border border-[#ece0d1]" key={index}>
                <div className="payment w-full flex flex-row justify-between"> 
                  {itm.providedName && <small className="max-w-7/12">{itm.providedName}</small>}
                  <i className="!m-0 max-w-5/12"><small></small>{itm.residualAmount}</i>
                </div>
                <small className="email">{itm.providedEmail === currentUser?.email ? 'You' : itm.providedEmail}</small>
                {user.paymentReference && <small>Payment reference: {itm.payThruReference}</small>}
              </div>
            ))
          }
        </>
      )
    }
    return (
      <p>No payment received for this user yet.</p>
    )
  }
  return (
    <div className="!w-full px-3 py-2 rounded border border-[#ece0d1] mb-1 flex flex-col justify-start cursor-pointer">
      <div className="flex flex-row justify-between items-start" onClick={togglePaymentDetails}>
        {renderMembersDetails()}
        <img src={Caret} className={`${showPayment ? 'rotate-[-90deg]' : 'rotate-90'} w-6 h-6`} alt="caret" />
      </div>
      {
        showPayment && (
          <div className="flex flex-col justify-end items-end my-1 w-full">
            {renderPaymentDetails()}
          </div>
        )
      }
    </div>
  )
}


export const OpenKontributeUserCard = ({
  id,
  username, 
  email, 
  hasPaid,
  status,
  expense, 
  user, 
  paid, 
  handleEdit,
  isMine,
  payable,
  // amtPaid,
  type = 'RefundMe',
  hasReminderLink = false,
  refetchMembers,
}: IUserCard) => {
  const [showEdit, setShowEdit] = useState(false);
  const currentUser = useAppSelector((state) => state.auth.user);
  const remindText = `You are reminded to pay up your ${type}. Click the attached link to pay.`;
  const balanceText = `You are reminded to pay up the balance of the ${type}. Click the attached link to pay.`;
  const appreciateText = `Thank you for Promptly paying Up your ${type}.`;
  const [openOptions, setOpenOptions] = useState(false);
  const dispatch = useAppDispatch();
  const amtPaid = user.totalAmount;

  const handleShare = (data?: any) => {
    navigatorShare({
      title: `Paythru AzaTme ${type} ${expense.name}`,
      text: payable == amtPaid ? appreciateText : amtPaid < payable ? balanceText : remindText,
      url: data && data.payLink ? data.payLink : `https://apps.paythru.ng/services/cardfree/pay/${user.paymentReference}`,
      email,
    }, dispatch);
  }


  const renderStatusText = () => {
    // unpaid | underpaid | overpaid | paid
    // a === 0 |am < pay  | am > pay | am === pa
    return (
      <>
        {payable == amtPaid ? (
          'Appreciate'
        ) : amtPaid == 0 ? (
            'Remind'
            //@ts-ignore
          ) :parseInt(amtPaid) > parseInt(payable) ? (
            'Refund'
          ) : user.hasBalanceLink ? (
            'Pending Balance'
          ) : (
            'Get Balance'
          )
        }
      </>
    )
  }

  const handleStatusActn = () => {
    if (user.hasBalanceLink) {
      toast('Balance Link already created for the user!');
      return;
    }
    if (payable == amtPaid || amtPaid == 0) {
      handleShare();
    } else if (amtPaid < payable) {
      let handler;
      switch (type) {
        case 'RefundMe':
          handler = getRefundMeBalance
          break;
        case 'Kontribute':
            handler = getKontributeBalance
            break;
        default:
          handler = null
          break;
      } 
      if (handler) {
        handler({id: expense.id, uid: user.id}, (resp) => {
          const postponeShare = () => {
            handleShare(resp.data);
            refetchMembers && refetchMembers();
          }
          setTimeout(postponeShare, 2000);
        });
      }
    }
  }

  const handleShowEdit = () => {
    if (paid > 0) {
      toast.error('You can not edit members of an ongoing/completed transaction!');
      return;
    }
    setShowEdit(true);
  }
  return (
    <div className="user !w-full">
      <div className="user-wrapper" onClick={(e) => {
        e.stopPropagation();
        setOpenOptions(!openOptions);
      }}>
        {/* {
          isMine && (
            <div className="user-options">
              <button className="options-close-btn" onClick={(e) => {
                e.stopPropagation();
                setOpenOptions(!openOptions);
              }}>
                <img src={!openOptions ? Options : CloseIcon} alt="" />
              </button>
            </div>
          )
        } */}
        {
          !openOptions && (
            <div className="user-image !rounded-full !text-2xl font-medium">
              <h1 className="!text-2xl">{user.providedEmail && user.providedEmail[0]}</h1>
            </div>
          )
        }

        <div className="user-info">
          {
            !openOptions && (
              <>
                {user.providedName && <small>{user.providedName}</small>}
                <small className="email">{user.providedEmail === currentUser?.email ? 'You' : user.providedEmail}</small>
                {user.transactionReference && <small>Reference: {user.transactionReference}</small>}
                <div className="payment"> 
                  {/* {renderPaid()} */}
                  <i className="mx-2"><small></small>{user.residualAmount} of <small></small>{expense.actualAmount}</i> 
                  {/* <p className="px-1 h-6 rounded mx-2 bg-green-600 text-white capitalize flex flex-row justify-center items-center">
                    {status === 'accept' ? 'Accepted' : status === 'reject' ? 'Rejected' : 'Pending'}
                  </p> */}
                </div>
              </>
            )
          }
          {
            openOptions && (
              <div className="options-wrapper">
                <button onClick={handleStatusActn}>
                  {renderStatusText()}
                </button>
                {handleEdit && <button onClick={handleShowEdit}>Edit</button>}
                {/* <button>Delete</button> */}
              </div>
            )
          }
        </div>
      </div>
      {
        handleEdit && (
          <EditUserEmail
            open={showEdit}
            handleSubmit={handleEdit}
            userEmail={email}
            onClose={() => setShowEdit(false)}
            id={id}
          />
        )
      }
    </div>
  )
}

export const ProductCard = ({
  name,
  qty,
  unitPrice
}) => {
  return (
    <div className="w-full rounded-md border border-[#ece0d1] my-1 p-2 box-border flex flex-row justify-start items-center">
      <div className="w-10 h-10 mr-2 flex justify-center items-center font-bold text-3xl">
        {name[0]}
      </div>
      <div>
        <p>{name}</p>
        <p className="text-sm font-semibold">{formatCurrency({num: unitPrice})} x {qty}</p> 
      </div>
    </div>
  )
}
import { useNavigate } from 'react-router-dom';
import Arrow from '../../../../../assets/new/ArrowRightWhite.png';
import ReadIcon from '../../../../../assets/new/readTime.png';
import { IBlogs } from '../../../../../types';
import { useQuery } from '@apollo/client';
import { BLOGS_QUERY } from '../../../../../queries';


const Blogs = () => {

    const navigation = useNavigate();
    const { data } = useQuery(BLOGS_QUERY);
    return (
        <div className="p-[6%] px-[6%] w-full flex flex-col items-start relative h-fit bg-[#0898A00F]">
            <p className="text-sm bg-[#0898A01A] px-6 py-3 w-fit rounded-full mb-10">Blogs</p>
            <p className="text-4xl leading-[46px] font-medium mb-16">Get informed and insights</p>
            <div className='flex flex-row w-full justify-center lg:justify-between flex-wrap '>
                {
                    data?.posts && (data?.posts?.slice(0, 3)).map((blog, index) => (
                        <Blog blog={blog} key={index} />
                    ))
                }
            </div>
            <div className='w-full flex justify-center items-center mt-16'>
                <button onClick={() => navigation('/blogs')} className="bg-az-teal w-fit text-white text-sm font-medium flex flex-row justify-center items-center px-4 p-3 rounded">View all <img src={Arrow} alt="" className='mx-3' /></button>
            </div>
        </div>
    );
};

export default Blogs;

export const Blog = ({blog}) => {
    const navigation = useNavigate();
    const handleClick = () => {
        navigation(`/blog/${blog?.id}`);
    }
    const blogObj = blog as IBlogs;
    return (
        <div onClick={handleClick} className=' text-az-black flex flex-col justify-between items-center max-w-[380px] p-4 h-[400px] rounded-md bg-white my-5 lg:my-0 cursor-pointer'>
            <img className='rounded-tl-lg rounded-tr-lg' src={blogObj?.coverImage?.url} alt="" />
            <div className='w-full '>
                <p className='text-xs text-left w-full mb-3'>{blogObj?.date}</p>
                <p className='text-lg font-medium text-left'>{blogObj?.title}</p>
            </div>
            <div className='flex flex-row w-full'>
                <img src={blogObj?.author?.picture?.url} alt="" className='mr-2 w-10 h-10 rounded-full' />
                <div>
                    <p className='text-[15px] font-medium'>{blogObj?.author?.name}</p>
                    <div className='flex flex-row w-full'>
                        <img src={ReadIcon} alt="" className='w-5 h-5 mr-2' />
                        <p className='text-sm font-normal'>{5} min. read</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

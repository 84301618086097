import { useNavigate } from 'react-router-dom';
import CTAImg  from '../../../../../assets/new/personalctaimg.png';

const CallToAction = () => {
    const navigate = useNavigate();
    return (
        <div className="min-h-[50vh] px-[6%] h-fit flex flex-row justify-center items-center my-28">
            <div className='w-full bg-az-teal  flex flex-row flex-wrap justify-between items-start rounded-xl relative'>
                <div className="w-fit rounded-xl p-14 flex flex-col justify-start items-start relative z-10">
                    <p className="font-Onest text-white text-[40px] leading-[51px] w-full md:w-[400px] text-left mb-10">
                        Open your AzatMe account
                        now. It’s fast and easy.
                    </p>
                    <p className='w-full max-w-[400px] text-[16px] font-normal p-0 text-left text-white'>
                    Experience a world of convenience, security, and ease with AzatMe
                    as your trusted financial partner.
                    </p>
                    <div className="flex flex-row justify-start items-center ">
                        <button className="hover:bg-white bg-[#3AADB4] text-white text-sm font-normal hover:text-az-black-900 w-full lg:w-fit h-10 rounded font-Onest px-6 lg:flex hidden flex-row justify-between items-center stroke-white hover:stroke-az-black-900"  onClick={() => navigate('/register')}>
                            Create account in minutes
                            <svg className='stroke-white hover:stroke-az-black-900 ml-4' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.75 11.7261L4.75 11.7261" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M13.7002 5.70149L19.7502 11.7255L13.7002 17.7505" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        <button className="hover:bg-white bg-[#3AADB4] text-white text-sm font-normal hover:text-az-black-900 w-full lg:w-fit h-10 rounded font-Onest px-6 flex lg:hidden flex-row justify-between items-center stroke-white hover:stroke-az-black-900"  onClick={() => navigate('/register')}>
                            Create account
                        </button>
                        <svg className="mb-12 ml-6" xmlns="http://www.w3.org/2000/svg" width="71" height="65" viewBox="0 0 71 65" fill="none">
                            <path d="M68.657 1.47906C68.6443 0.926921 69.0816 0.469017 69.6337 0.456301C70.1858 0.443587 70.6437 0.880875 70.6565 1.43301L68.657 1.47906ZM47.7478 36.6668L48.1264 37.5924L47.7478 36.6668ZM0.936593 58.5485C0.519706 58.1862 0.475405 57.5546 0.837652 57.1377L6.74078 50.3441C7.10302 49.9272 7.73464 49.8829 8.15153 50.2452C8.56842 50.6074 8.61272 51.239 8.25047 51.6559L3.00324 57.6947L9.04201 62.9419C9.4589 63.3041 9.5032 63.9357 9.14095 64.3526C8.7787 64.7695 8.14709 64.8138 7.7302 64.4516L0.936593 58.5485ZM70.6565 1.43301C70.7595 5.90933 69.7569 13.0286 66.4316 19.9817C63.0979 26.9524 57.4075 33.7963 48.1264 37.5924L47.3692 35.7412C56.0943 32.1726 61.4582 25.7453 64.6273 19.1188C67.8048 12.4748 68.7535 5.67103 68.657 1.47906L70.6565 1.43301ZM48.1264 37.5924C42.3786 39.9433 37.9665 40.2069 34.7704 39.0852C31.5315 37.9486 29.7064 35.4465 29.0771 32.6847C28.4555 29.957 28.9883 26.9504 30.4471 24.6518C31.9215 22.3287 34.388 20.6762 37.5742 20.8768L37.4485 22.8728C35.1186 22.7261 33.2932 23.8998 32.1358 25.7235C30.9628 27.5717 30.524 30.0325 31.0271 32.2404C31.5224 34.4142 32.9183 36.3157 35.4326 37.1981C37.9897 38.0954 41.8631 37.9933 47.3692 35.7412L48.1264 37.5924ZM37.5742 20.8768C40.8854 21.0852 43.2785 23.6313 44.2388 27.0372C45.2024 30.4545 44.7956 34.869 42.5057 39.2966C37.9051 48.1919 25.7679 57.1005 1.66246 58.7912L1.52254 56.7961C25.2264 55.1336 36.5647 46.4301 40.7292 38.3778C42.8219 34.3317 43.12 30.4386 42.3139 27.58C41.5046 24.7099 39.6533 23.0116 37.4485 22.8728L37.5742 20.8768Z" fill="white"/>
                        </svg>
                    </div> 
                </div>
                <img src={CTAImg} alt="" className=' absolute z-0 right-5 object-contain h-full w-full sm:w-auto' />
            </div>
        </div>
    );
};

export default CallToAction;

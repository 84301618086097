import CallToAction from "./Components/Personal/CallToAction";
import FAQ from "./Components/Personal/FAQ";
import Hero from "./Components/Personal/Hero";
import HowItWorks from "./Components/Personal/HowItWorks";
import Service from "./Components/Personal/Service";
import Benefits from "./Components/Personal/Benefits";
import Blogs from "./Components/Personal/Blogs";

const NewLanding = () => {
    return (
        <div className="w-screen h-fit">
            <Hero />
            <Service />
            <Benefits />
            <HowItWorks />
            <Blogs />
            <FAQ />
            <CallToAction />
        </div>
    )
};

export default NewLanding;

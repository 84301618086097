import axios, { AxiosError } from "axios";
import { silentHTTPCaller, toastWrapper } from "../utils/toastWrapper";
import {
  addBusinessess,
  addBusiness,
  addCustomers,
  addCustomer,
  updateBusiness,
  deleteBusiness,
  deleteCustomer,
  addProduct,
  addProducts,
  addTransactionsMPOS,
  addOptions,
  addTransaction,
  addTransactions,
  addCreatedInvoices,
  addCreatedInvoicesMetaData,
  setAllNQRs,
  addNQR,
  addCreatedLinks,
} from "../pages/Business/businessSlice";
import getBaseURL from "../utils/getBaseURL";
import { getWithdrawalHistory } from "../pages/Auth/authSlice";
import {appDispatch} from '../app/store';

const url = getBaseURL() as string;

export const createBusiness = (dispatch, data, handleDone) => {
  const call = axios.post(`${url}create-business`, data);
  toastWrapper(call, 'Creating a Business...', (resp) => {
    dispatch(addBusiness(resp.data[1]));
    handleDone(true);
    return resp.data.message || 'Business Created Successfully!';
  }, 'Error creating Business.');
};

export const getBusinesses = (dispatch, page = 1, userId, isAdmin = false) => {
  const call = isAdmin ? axios.get(`${url}get-business-under-a-owner/${userId}`)
   : axios.get(`${url}get-business-under-a-owner/${userId}`);

  toastWrapper(call, 'Getting your Businesses...', (resp) => {
    dispatch(addBusinessess(resp.data));
    if (resp.data.length === 0) {
      return 'No Business Found!';
    }
    return resp.data.message || 'Businesses gotten successfully!';
  }, 'Error getting Your Businesses.');
}

export const handleDeleteBusiness = (dispatch, businesId, handleDone) => {
  const call = axios.delete(`${url}delete-a-business/${businesId}`,);
  toastWrapper(call, 'Deleting Business...', (resp) => {
    dispatch(deleteBusiness({businesId}));
    handleDone(true);
    return resp.data.message || 'Business deleted Successfully!';
  }, 'Error deleing Business.');
}

export const handleDeleteCustomer = (dispatch, customerId, businessCode, handleDone) => {
  const call = axios.delete(`${url}delete-a-customer/${customerId}`,);
  toastWrapper(call, 'Deleting customer...', (resp) => {
    dispatch(deleteCustomer({businessCode, customerId}));
    handleDone(true);
    return resp.data.message || 'Customer deleted Successfully!';
  }, 'Error deleting Customer.');
}

export const handleUpdateBusiness = (dispatch, data, businesId, handleDone) => {
  const call = axios.put(`${url}update-business/${businesId}`, data);
  toastWrapper(call, 'Updating Business...', (resp) => {
    dispatch(updateBusiness({data: resp.data, businesId}));
    handleDone(true);
    return resp.data.message || 'Business Updated Successfully!';
  }, 'Error updating Business.');
}

export const createCustomer = (data, dispatch, businessCode, handleDone) => {
  const call = axios.post(`${url}create-customer/${businessCode}`, data);
  toastWrapper(call, 'Adding Customer...', (resp) => {
    dispatch(addCustomer({data: resp.data, businessCode}));
    handleDone(true);
    return resp.data.message || 'Customer added Successfully!';
  }, 'Error adding customer: Customer with details exist already.');
};

export const getCustomers = (dispatch, businessCode) => {
  const call = axios.get(`${url}get-all-customers-under-a-specific-business/${businessCode}`)
  silentHTTPCaller(call, 'Getting your Customers...', (resp) => {
    dispatch(addCustomers({data: resp.data, businessCode}));
    if (resp.data.length === 0) {
      return 'No Customer Found!';
    }
    return resp.data.message || 'Customers Loaded successfully!';
  }, 'Error getting customers.');
}

export const createProduct = (data, dispatch, businessCode, handleDone) => {
  const call = axios.post(`${url}create-product`, data);
  toastWrapper(call, 'Creating Product...', (resp) => {
    dispatch(addProduct({product: resp.data, businessCode}));
    handleDone(resp.data);
    return resp.data.message || 'Product created Successfully!';
  }, 'Error creating Product.');
};

export const getProducts = (dispatch, businessCode, page) => {
  const call = axios.get(`${url}product-per-business/${businessCode}?page=${page}`);
  toastWrapper(call, 'Getting Product...', (resp) => {
    dispatch(addProducts({products: resp.data, businessCode}));
    // handleDone(resp.data);
    return resp.data.message || 'Product loaded Successfully!';
  }, 'Error loading Product.');
};

export const getTransactionOptions = (dispatch) => {
  const call = axios.get(`${url}get-option`)
  silentHTTPCaller(call, 'Getting Transaction options...', (resp) => {
    dispatch(addOptions(resp.data));
    return 'Options Loaded!'
  }, 'Error getting options.');
}

export const createTransaction = (data, dispatch, businessCode, product, isInvoice, handleDone) => {
  const call = axios({
    method:'post',
    url: `${url}initiate-business-transaction/${businessCode}`,      
    // responseType: isInvoice ? 'arraybuffer' : 'text',
    data,
    headers: { 
      // 'Content-Type': 'blob',
      // 'Accept': 'application/pdf',
    }
  })
  toastWrapper(call, 'Creating Transaction...', (resp) => {
    dispatch(addTransaction({transaction: resp.data, businessCode}));
    if (isInvoice) {
      let link = document.createElement('a');
      link.href = resp.data.link;
      link.target = '_blank'
      link.download = `${Date.now()}_invoice.pdf`;
      handleDone(resp.data, link);
    } else {
      handleDone(resp.data, '');
    }
    return resp.data.message || 'Transaction created Successfully!';
  }, 'Error creating Transaction.');
};

export const createMPOSTransaction = (data, businessCode, handleDone) => {
  const call = axios({
    method:'post',
    url: `${url}mpos-payment-option/${businessCode}`,   
    data,
  })
  toastWrapper(call, 'Creating Transaction...', (resp) => {
    handleDone(resp.data, '');
    getMPOSTransactions(businessCode, 1, () => {});
    return resp.data.message || 'Transaction created Successfully!';
  }, 'Error creating Transaction.');
};

export const createMPOSWithInventory = (data, businessCode, handleDone) => {
  const call = axios({
    method:'post',
    url: `${url}mpos-payment/${businessCode}`,   
    data,
  })
  toastWrapper(call, 'Creating Transaction...', (resp) => {
    handleDone(resp.data, '');
    getMPOSTransactions(businessCode, 1, () => {});
    return resp.data.message || 'Transaction created Successfully!';
  }, 'Error creating Transaction.');
};

export const getTransactions = (dispatch, businessCode, page) => {
  const call = axios.get(`${url}get-all-transactions-created-by-a-specific-business/${businessCode}?page=${page}`);
  toastWrapper(call, 'Getting Transactions...', (resp) => {
    dispatch(addTransactions({transactions: resp.data, businessCode}));
    return resp.data.message || 'Transactions loaded Successfully!';
  }, 'Error loading Transactions.');
};

export const getMPOSTransactions = (businessCode, page, handleDone) => {
  const call = axios.get(`${url}get-mpos-payment-history/${businessCode}?page=${page}`);
  toastWrapper(call, 'Getting Transactions...', (resp) => {
    appDispatch(addTransactionsMPOS({transactions: resp.data?.data, businessCode}));
    handleDone(resp.data);
    return resp.data.message || 'Transactions loaded Successfully!';
  }, 'Error loading Transactions.');
};

export const getMPOSTransactionStatus = (paymentRef, handleDone) => {
  const call = axios.get(`${url}get-mpos-payment-byReference/${paymentRef}`);
  silentHTTPCaller(call, 'Getting Transactions...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Transactions loaded Successfully!';
  }, 'Error loading Transactions.');
};

export const getSentInvoices = (dispatch, businessCode) => {
  const call = axios.get(`${url}all-invoices-created-by-business`)
  toastWrapper(call, 'Getting your Sent Invoices...', (resp) => {
    dispatch(addCreatedInvoices({data: resp.data, businessCode}));
    dispatch(addCreatedInvoicesMetaData({data: resp.data, businessCode}))
    if (resp.data.length === 0) {
      return 'No Invoice Found!';
    }
    return resp.data.message || 'Invoices Loaded successfully!';
  }, 'Error getting Invoices.', (error: AxiosError) => {
    // @ts-ignore
    if (error?.response?.status === 404) {
      return 'No Invoice Found!';
    }
    return 'Error getting Invoices.';
  });
}

export const saveEmailTemplate = (dispatch, businessCode) => {
  const call = axios.get(`${url}send-notification/${businessCode}`)
  toastWrapper(call, 'Saving Email Template...', (resp) => {
    return resp.data.message || 'Template saved';
  }, 'Error saving template.');
}

export const withdrawTransaction = (data, handleDone) => {
  const payload = {...data};
  delete payload['id'];
  const call = axios.post(`${url}business-settlements`, payload);
  toastWrapper(call, 'Initiating fund withdrawal...', (resp) => {
    handleDone(resp);
    if (resp?.data && resp?.data?.status === 200) {
      return 'Fund withdrawal initiated successfully';
    } else {
      return "Fund withdrawal initiated successfully";
    }
  }, 'Error initiating fund withdrawal.');
}

export const optInToNQR = (data, handleDone) => {
  const payload = {...data};
  delete payload['id'];
  const call = axios.post(`${url}nqr-merchant-registration`, payload);
  toastWrapper(call, 'Signing your business up for NQR...', (resp) => {
    if (resp.data.status === 200) {
      handleDone();
      return resp.data.message || 'NQR sign up successful';
    }
    appDispatch(addNQR(resp.data));
    handleDone();
    getNQRMerchants();
    return resp.message || 'NQR sign up successful';
  }, 'Error Signing your Business up for NQR.');
}

export const newSubMerchant = (data, handleDone) => {
  const payload = {...data};
  const call = axios.post(`${url}create-sub-merchant`, payload);
  toastWrapper(call, 'Creating NQR Sub merchant..', (resp) => {
    handleDone();
    return resp.data.message || 'Submerchant created successfully';
  }, 'Error Creating submerchant.');
}

export const getNQRTransactionsForMerchant = (data, handleDone) => {
  const call = axios.post(`${url}get-merchant-trans-report/${data}`);
  silentHTTPCaller(call, 'Getting all NQR transactions..', (resp) => {
    handleDone();
    return resp.data.message || 'Transactions loaded successfully';
  }, 'Error loading transactions.');
}

export const getNQRTransactionsSubMerchant = (data, handleDone) => {
  const payload = {...data};
  const call = axios.post(`${url}create-sub-merchant`, payload);
  silentHTTPCaller(call, 'Creating NQR Sub merchant..', (resp) => {
    handleDone();
    return resp.data.message || 'Submerchant created successfully';
  }, 'Error Creating submerchant.');
}

export const getSubMerchants = (data, handleDone) => {
  const call = axios.get(`${url}get-all-submerchant-under-merchant/${data}`);
  silentHTTPCaller(call, 'Getting NQR Sub merchant..', (resp) => {
    handleDone();
    return resp.data.message || 'Submerchant loaded successfully';
  }, 'Error getting submerchant.');
}

export const getNQRMerchants = async () => {
  const call = await axios.get(`${url}get-all-merchants`);
  if (call.status === 200) {
    appDispatch(setAllNQRs(call.data));
  }
}

export const getWithdrawalHistoryBusiness = () => {
  const call = axios.get(`${url}get-withdrawal-response`)
  toastWrapper(call, 'Getting Business withdrawal History...', (resp) => {
    appDispatch(getWithdrawalHistory({data: resp.data || null, entity: 'business'}));
    return resp.data.message || 'Withdrawal History Loaded!';
  }, 'Error loading withdrawal History!');
}

export const getBusinessLinks = (businessId) => {
  const call = axios.get(`${url}get-link/${businessId}`)
  toastWrapper(call, 'Getting your payment links...', (resp) => {
    appDispatch(addCreatedLinks({data: resp.data || null, businessId}));
    return resp.data.message || 'Payment links Loaded!';
  }, 'Error loading payment links!');
}

export const getBusinessLinksSilent = (businessId) => {
  const call = axios.get(`${url}get-link/${businessId}`)
  silentHTTPCaller(call, 'Getting your payment links...', (resp) => {
    appDispatch(addCreatedLinks({data: resp.data || null, businessId}));
    return resp.data.message || 'Payment links Loaded!';
  }, 'Error loading payment links!');
}

export const getBusinessInvoices = (businessId) => {
  const call = axios.get(`${url}get-invoice/${businessId}`)
  toastWrapper(call, 'Getting your invoices...', (resp) => {
    appDispatch(addCreatedInvoices({data: resp.data || null, businessId}));
    return resp.data.message || 'Invoices Loaded!';
  }, 'Error loading Invoices!');
}

export const getBusinessInvoicesSilent = (businessId) => {
  const call = axios.get(`${url}get-invoice/${businessId}`)
  silentHTTPCaller(call, 'Getting your invoices...', (resp) => {
    appDispatch(addCreatedInvoices({data: resp.data || null, businessId}));
    return resp.data.message || 'Invoices Loaded!';
  }, 'Error loading Invoices!');
}

export const getBusinessReport = (businessCode: any, handleDone) => {
  const call = axios.get(`${url}getBusinessReport/${businessCode}`)
  silentHTTPCaller(call, 'Sending your response...', (resp) => {
    handleDone(resp)
    return resp.data.message || 'Thank you, your response was sent successfully!';
  }, 'Error sending your response!');
}

import { useState } from "react";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper"
import DashboardNavItem from "../../components/DashboardNavItem/DashboardNavItem";
import SwitchUserRole from "../../components/SwitchUserRole/SwitchUserRole";

const navItems = ['All Users','Switch User Type'];

const Users = () => {
  const [tab, setTab] = useState(navItems[0]);

  const renderChildren = () => {
    switch (tab) {
      case navItems[0]:
        return <div>User List</div>
      case navItems[1]:
        return <SwitchUserRole />
      case navItems[2]:
        return <div>Business</div>
      default:
        return <div>Admins</div>
    }
  }

  const renderNavItems = () => {
    return (
      <DashboardNavItem navItems={navItems} tab={tab} setTab={setTab} />
    )
  }

  return (
    <DashboardComponentWrapper title='Users' renderNavItems={renderNavItems}>
      {renderChildren()}
    </DashboardComponentWrapper>
  )
}

export default Users
import { useCallback } from "react";
import Button from "../Button";
import  './tablePaginationFooter.scss';

const TablePaginationFooter = ({
  total,
  from,
  to,
  nextUrl,
  prevUrl,
  handlePagination,
}: {
  total: number,
  from: number,
  to: number,
  nextUrl: string | null,
  prevUrl: string | null,
  handlePagination: (val: string) => void;
}) => {
  const handlePress = useCallback((url: string) => {
    handlePagination(url);
  }, [handlePagination]);
  if (!total) {
    return null;
  }
  return (
    <div className="flex flex-row justify-between items-center h-10 border-t-2 pt-6 border-gray-300 px-6 my-6">
      <div className="">
        <p>Showing {from} - {to} of {total}</p>
      </div>
      <div className="h-10 flex flex-row justify-end items-start">
        <Button
          label={"Prev"}
          disabled={!prevUrl}
          type={"flat"}
          onClick={() => prevUrl && handlePress(prevUrl)}
          overrideStyle={{
            height: '100%',
            margin: '0 5px',
            padding: '0 10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        />
        <Button
          label={"Next"}
          disabled={!nextUrl}
          type={"flat"}
          onClick={() => nextUrl && handlePress(nextUrl)}
          overrideStyle={{
            height: '100%',
            margin: '0 5px',
            padding: '0 10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        />
      </div>
    </div>
  );
};

export default TablePaginationFooter;

import { FormEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Input, { Select as SelectComponent } from '../Input';
import Button from '../Button';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addUserGroupActionCreator, 
  addParticipantToGroupActionCreator, 
  getSplitformula,
  createLinkForOpenGroup,
  getUserGroupsActionCreator,
  getPaymentOpenGroups, } from '../../actions/dashboardActions';
import { AddParticipantForm, Participant, ShareParticipant } from '../CreateExpense';
import { Bank } from '../../pages/Bank/bankSlice';
import dayjs from 'dayjs';
import { navigatorShare } from '../../utils/common';


const CreateGenericGroup = (props) => {
  const dispatch = useAppDispatch();
  const {isLoading} = useAppSelector(state => state.loader);
  const {user} = useAppSelector((state) => state.auth);
  const {splitFormula} = useAppSelector((state) => state.dashboard);
  const [participantsMap, setParticipantMap] = useState<Record<string, any>>({});
  const [splitPayResult, setSplitPayResult] = useState<Array<Record<string, any>>>([]);
  const [participants, setParticipants] = useState<Array<Record<string, any>>>([]);
  const [step, setStep] = useState(0);
  const [single, setSingle] = useState<Record<string, any>>({});
  const [openData, setOpenData] = useState<Record<string, any>>({});
  const [splitId, setSplit] = useState<number>(0);
  const [selectedBank, setSelectedBank] = useState<Bank>();
  const [data, setData] = useState({
    name: '',
    amount: '',
    description: '',
    openKontribution: '',
    creatorIsPart: '',
    dueDate: '',
    id: '',
    splittingId: '',
    bankName: '',
  });


  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  useEffect(() => {
    handleChange('description', props.selectedType);
  }, [props.selectedType]);

  useEffect(() => {
    setSplit(splitFormula?.find((item) => item.split === data.splittingId)?.id as number)
  }, [data.splittingId, splitFormula]);

  useEffect(() => {
    !splitFormula && getSplitformula();
  }, [splitFormula]);

  const isFirst = step === 0;
  const isSecond = step === 1;
  const isThird = step === 2;
  const renderStep = () => {
    return (
      <div className="stepper">
        <div className={`step ${isFirst ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isSecond ? 'active' : ''}`} />
        <div className="dash" />
        <div className={`step ${isThird ? 'active' : ''}`} />
      </div>
    )
  };

  const handleAddFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (data.dueDate && dayjs().isAfter(dayjs(new Date(data.dueDate)))) {
      toast.error('Please select a future due date!');
      return;
    }
    if (parseInt(data.amount) <= 0) {
      toast.error('Amount to be kontributed must be greater than 0!')
      return;
    }
    addUserGroupActionCreator({
      ...data,
      bankName: selectedBank?.bankName,
      bankCode: selectedBank?.bankCode,
      account_number: selectedBank?.account_number,
    }, (groupId) => {
      if (data.openKontribution === 'Yes') {
        handleCreateLinkOpenKontribution(groupId);
      } else {
        setStep(1);
        handleChange('id', groupId);
      }
    });
  }

  const handleCreateLinkOpenKontribution = (groupId: string) => {
    createLinkForOpenGroup({
      payload: {
        paymentType: 4,
        expirationTime: data.dueDate.replace('T', ' ') + ':00',
      },
      groupId,
    }, (data) => {
      setStep(2);
      setOpenData(data);
    })
  }

  const addParticipants = (payload) => {
    participants.push(payload);
    const newparticipantsMap = {...participantsMap};
    newparticipantsMap[payload.email] = 1;
    setParticipantMap(newparticipantsMap);
    setParticipants([...participants]);
  }

  const handleAddParticipants = (userData: Record<string, any>) => {
    addParticipants({
      ...userData,
    });
  }

  const handleRemoveParticipant = (data) => {
    setParticipants(participants.filter((participant) => participant.email !== data));
    const newparticipantsMap = {...participantsMap};
    delete newparticipantsMap[data];
    setParticipantMap({...newparticipantsMap});
  }

  const handleDone = () => {
    if (!splitId) {
      toast.error('Select a Splitting Formula to continue!');
      return;
    };
    let participantEmails = (participants.map((participant) => participant.email)).join(';');
    addParticipantToGroupActionCreator({
      payload: {
        email: participantEmails,
        split_method_id: splitId,
        consent: data.creatorIsPart === 'Yes' ? 1 : 0
      },
      groupId: data.id,
    }, (data) => {
      setStep(2);
      if (data.splitPayResult) {
        setSplitPayResult(data.splitPayResult.result); 
      } else {
        setSingle(data);
      }
      getPaymentOpenGroups(1, () => {});
      getUserGroupsActionCreator();
    })
  }

  const handleSetPercentageMap = () => {}

  return (
    <div className="!w-full  h-full flex flex-col justify-between">
      <div className='mt-2 flex flex-col justify-center items-start'>
        {renderStep()}
        <h3 className='my-6 text-3xl text-center'>{step === 0 ? 'Create Kontribute' : openData.payLink ? 'Link Created.' : 'Add Participants'}</h3>
      </div>
      <div className='mt-6 py-10 flex w-full flex-grow flex-auto justify-center items-center'>
        {
          isFirst && (
            <form onSubmit={handleAddFormSubmit}>
              <div className="input-grp-wrapper">
                <Input
                  label="Title"
                  placeholder="Vacation Plans"
                  name="name"
                  required
                  value={data.name}
                  onChange={handleChange}
                />
                <Input
                  label="Amount"
                  placeholder="0"
                  name="amount"
                  required
                  value={data.amount}
                  onChange={handleChange}
                />
              </div>
              <Input
                label="Description"
                placeholder="Description"
                name="description"
                required
                value={data.description}
                onChange={handleChange}
                maxLength={100}
              />
              <SelectComponent
                data={['Yes', 'No']}
                label="Is this contribution accessible to everyone?"
                placeholder=""
                name="openKontribution"
                required
                value={data.openKontribution}
                onChange={handleChange}
                info='Contributions accessible to everyone is similar to crowdfunding.'
              />
              {
                data.openKontribution === 'Yes' && (
                  <Input
                    label="Due Date"
                    placeholder=""
                    type="datetime-local"
                    name="dueDate"
                    required
                    value={data.dueDate}
                    onChange={handleChange}
                  />
                )
              }
              {
                (data.openKontribution === 'No' || data.openKontribution === '') && (
                  <SelectComponent
                    data={['Yes', 'No']}
                    label="Are you a part of this Kontribution?"
                    placeholder=""
                    name="creatorIsPart"
                    required
                    value={data.creatorIsPart}
                    onChange={handleChange}
                  />
                )
              }
              <Button
                label="Create"
                type="flat"
                btnActionType="submit"
                disabled={isLoading}
              />
            </form>
          )
        }
        {
          isSecond && (
            <div className='user-group-cont !w-full'>
              <SelectComponent
                data={(splitFormula as unknown as Array<any>)?.filter((item) => item.split === 'shared')
                  .map((item) => item.split) || []}
                label="Spliting Formula"
                placeholder=""
                name="splittingId"
                required
                value={data.splittingId}
                onChange={handleChange}
              />
              <div className='user-group-wrapper'>
                {participants.length === 0 && <p className="empty-part-text">Your added participants will be displayed here, enter their email address in the input below to begin, click the Add button to add them and Done to complete the action.</p>}
              {participants.length > 0 && ( <div>
                  {
                    participants.reverse().map((participant) => (
                      <Participant
                        key={participant.email}
                        email={participant.email}
                        splitId={splitId}
                        handleRemove={handleRemoveParticipant}
                        percentage={100/(participants.length)}
                        handleSetPercentage={handleSetPercentageMap}
                      />
                    ))
                  }
                </div>)}
              </div>
                  <AddParticipantForm
                    addParticipant={handleAddParticipants}
                    participants={participantsMap}
                    setShowPartForm={handleDone}
                    doneBtnText='Done'
                    from="kontribute"
                  />
            </div>
          )
        }
        {
          isThird && (
            <div className=' w-full '>
              <h4 style={{textAlign: 'center'}} className='text-xl'>
                {
                  openData.payLink ? 'Payment Link Generated successfully, you can copy it and or share it.' : 'Users Added, and a mail containing payment link sent to their email. \n Kindly advise the recipients to check their spam or junk folder if they are unable to locate the email in their primary inbox. \n Click Share Button to manually share to other media.'
                }
              </h4>
              <div className='user-group-wrapper'>
              {splitPayResult?.length > 0 ? ( <div>
                  {
                    splitPayResult.reverse().map((participant) => (
                      <ShareParticipant
                        key={participant.receipient}
                        email={participant.receipient}
                        url={participant.paylink}
                        title={`Azatme Kontribute ${data.name} by ${user?.email}`}
                        text={`You are expected to pay NGA ${participant.amount} for ${data.description}. To pay click `}
                      />
                    ))
                  }
                </div>) : participants.length === 1 ? (
                  <ShareParticipant
                    email={participants[0].email}
                    url={single.payLink}
                    title={`Azatme Kontribute ${data.name} by ${user?.email}`}
                    text={`You are expected to pay NGA ${data.amount} for ${data.description}. To pay click `}
                  />
                ) : (
                  <div className='flex flex-col my-4 justify-around items-center'>
                    <h4>Your sharable paylink</h4>
                    <a href={openData.payLink} className='w-10/12 break-words text-blue-500 text-center'>{openData.payLink}</a>
                    <div className='w-full flex flex-row justify-around items-center flex-wrap mt-10'>
                      <div className='w-auto border !border-az-teal h-10 rounded-md cursor-pointer flex flex-row justify-center items-center px-4 py-2' onClick={() => {
                        navigator.clipboard.writeText(openData.payLink);
                        toast.success('Link copied!')
                      }}>
                        Copy Link
                      </div>
                      <div
                        className='w-auto border !border-az-teal h-10 rounded-md cursor-pointer flex flex-row justify-center items-center px-4 py-2'
                        onClick={() => {
                          navigatorShare({
                            title: `Azatme Kontribute ${data.name} by ${user?.email}`,
                            text: `Hello, you are invited to join our contribution of NGA ${data.amount} for ${data.description}. To pay click `,
                            url: openData.payLink,
                            email: '',
                          }, dispatch);
                        }}
                      >
                        Share
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Button
                type="flat"
                label='Done'
                onClick={() => {
                  props.handleDone()
                }}
              />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CreateGenericGroup;
